import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Link, useNavigate } from "react-router-dom"
import { AppBreadcrumbSchema } from "../schemas/AppBreadcrumbSchema"
import appBreadcrumbCss from "./appBreadcrumb.css?inline"

interface AppBreadcrumbProps {
  routes: { name: string; slug: string }[]
  showMeta?: boolean
}

export const AppBreadcrumb: React.FC<AppBreadcrumbProps> = ({
  routes,
  showMeta = true,
}) => {
  const navigate = useNavigate()
  return (
    <section id="breadcrumbs">
      {showMeta && <AppBreadcrumbSchema routes={routes} />}
      <section id="breadcrumb">
        <div className="container">
          <div className="row">
            <div className="d-none d-md-block col-12 col-md-12 col-lg-12">
              <ul className="list-group list-group-horizontal">
                <li className={`list-group-item`}>
                  <a
                    onClick={() => navigate(-1)}
                    // className="waitLink icon-link"
                  >
                    <FontAwesomeIcon icon={["fas", "arrow-left-long"]} />
                  </a>
                </li>
                {routes
                  .filter((r) => r.slug != "" && r.name != "")
                  .map((route, index) => {
                    if (!routes[index + 1]) {
                      return (
                        <li key={route.name} className="list-group-item">
                          <span className="gray fw-400">
                            {route.name.length > 25
                              ? route.name.substring(0, 22) + "..."
                              : route.name}
                          </span>
                        </li>
                      )
                    }
                    return (
                      <li key={route.name} className="list-group-item">
                        <Link className="gray fw-400 waitLink" to={route.slug}>
                          {route.name}
                        </Link>
                      </li>
                    )
                  })}
              </ul>
            </div>
            <div className="d-md-none col-12 col-md-12 col-lg-12">
              <button
                onClick={() => navigate(-1)}
                className="btn height-50 btn-outline-gray waitLink"
              >
                <FontAwesomeIcon icon={["fas", "arrow-left-long"]} /> Torna
                indietro
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="container">
        <div
          className="d-flex flex-row justify-content-start align-items-center mb-4"
          style={{ marginTop: "40px" }}
        >
          <div
            className="d-flex align-items-start justify-content-center cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <FontAwesomeIcon
              icon={["fas", "arrow-left-long"]}
              className="thin-icon font-27"
              style={{ marginRight: "45px" }}
            />
          </div>
          <div>
            <Breadcrumb listProps={{ className: "mb-0" }}>
              {routes.map((route, index) => {
                if (!routes[index + 1]) {
                  return (
                    <BreadcrumbItem key={index} active>
                      {route.name}
                    </BreadcrumbItem>
                  )
                }
                return (
                  <BreadcrumbItem key={index} href={route.slug}>
                    {route.name}
                  </BreadcrumbItem>
                )
              })}
            </Breadcrumb>
          </div>
        </div>
      </div> */}
      <style>{appBreadcrumbCss}</style>
    </section>
  )
}

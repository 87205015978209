import React, { useState, ChangeEvent, useEffect } from "react"
import selectNations from "../../../features/form/config/nations.json"
import Form from "react-bootstrap/Form"
import { useSelector } from "react-redux"
import { selectCurrentUser, setUser } from "../../../features/auth/authSlice"
import {
  useGetProfileQuery,
  useUpdateProfileMutation,
} from "../../../app/services/base/users"
import { useAppDispatch } from "../../../app/hooks"
import { addToast } from "../../../features/toasts/toastsSlice"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { skipToken } from "@reduxjs/toolkit/query"
import { tryToParse } from "../../../utils/objectsUtils"
import {
  UserProfile,
  UserProfileStructuredData,
} from "../../../models/base/userProfile.interface"
import { User } from "../../../models/base/user.interface"

export const Profile: React.FC = () => {
  const dispatch = useAppDispatch()
  const user = useSelector(selectCurrentUser)
  const { data: profile, refetch } = useGetProfileQuery(
    user ? user.id : skipToken,
  )
  const [updateProfile, { error }] = useUpdateProfileMutation()

  const [formData, setFormData] = useState<
    Partial<UserProfile> & UserProfileStructuredData
  >({
    id: 0,
    email: "",
    firstname: "",
    lastname: "",
    nazione: "",
    citta: "",
    username: "",
    data_nascita: "",
    sesso: 0,
    newsletter: 0,
    trans_email: 0,
    password: "",
    verifyPassword: "",
  })

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.currentTarget

    if (type === "checkbox") {
      setFormData({
        ...formData,
        [name]: checked ? 1 : 0,
      })
    } else if (type === "radio") {
      setFormData({
        ...formData,
        [name]: Number(value),
      })
    } else {
      setFormData({ ...formData, [name]: value })
    }
  }
  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.currentTarget
    setFormData({ ...formData, [name]: value })
  }
  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    try {
      await updateProfile(formData).unwrap()
      refetch().then((res) => {
        dispatch(
          setUser({
            id: res.data?.id,
            email: res.data?.email,
            firstname: res.data?.firstname,
            lastname: res.data?.lastname,
          } as User),
        )
      })
      dispatch(
        addToast({
          message: "Profilo aggiornato correttamente.",
          type: "success",
        }),
      )
    } catch (err) {
      dispatch(
        addToast({
          message: "Ops! Qualcosa è andato storto.",
          type: "danger",
        }),
      )
    }
    console.log("submit")
  }

  useEffect(() => {
    const parseData = async () => {
      if (profile && profile.structuredData) {
        const parsedData = await tryToParse(profile.structuredData)
        setFormData((prevData) => {
          const { couponPoints, structuredData, ...newProfile } = profile
          return { ...newProfile, ...parsedData }
        })
      }
    }

    if (profile && profile.structuredData) {
      parseData()
    }
  }, [profile, profile?.structuredData])

  if (!user) {
    return <></>
  }

  return (
    <div className="w-container utente white-background">
      <Form
        className="horizontal-form form form-registrazione myform form-au form-user"
        id="users-form"
        onSubmit={handleSubmit}
      >
        <div className="form-body">
          <div id="user-general-data">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <h3>I tuoi dati</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group">
                <Form.Control
                  type="text"
                  value={formData.firstname}
                  id="ClientePublicForm[subtitle]"
                  name="firstname"
                  className="form-control"
                  placeholder="Nome"
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group">
                <Form.Control
                  type="text"
                  value={formData.lastname}
                  id="ClientePublicForm[title]"
                  name="lastname"
                  className="form-control"
                  placeholder="Cognome"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group">
                <Form.Control
                  type="text"
                  value={formData.data_nascita}
                  id="ClientePublicForm[birthDate]"
                  name="data_nascita"
                  className="form-control"
                  placeholder="Data di nascita (gg-mm-aaaa)"
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group">
                <Form.Control
                  type="text"
                  value={formData.email}
                  id="ClientePublicForm[email]"
                  name="email"
                  className="form-control"
                  placeholder="E-mail"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group">
                <Form.Select
                  name="nazione"
                  className="form-control nazione"
                  value={formData.nazione}
                  onChange={handleSelectChange}
                >
                  <option value="">Seleziona una nazionalità...</option>
                  {selectNations.items.map((item: any) => {
                    return Object.entries(item).map(([key, value]) => {
                      return (
                        <option key={key} value={key}>
                          {value as string}
                        </option>
                      )
                    })
                  })}
                </Form.Select>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group">
                <Form.Control
                  type="text"
                  value={formData.citta}
                  id="ClientePublicForm[citta]"
                  name="citta"
                  className="form-control"
                  placeholder="Città"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group">
                <Form.Check className="d-inline-flex align-items-start ps-0">
                  <Form.Check.Input
                    type="checkbox"
                    id="ClientePublicForm[lat]"
                    name="newsletter"
                    className="custom-check-dark"
                    checked={formData.newsletter == 1}
                    onChange={handleInputChange}
                  />
                  <Form.Check.Label
                    htmlFor="ClientePublicForm[lat]"
                    className="ms-3"
                  >
                    Desidero ricevere la newsletter
                  </Form.Check.Label>
                </Form.Check>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group">
                <Form.Check className="d-inline-flex align-items-start ps-0">
                  <Form.Check.Input
                    type="checkbox"
                    id="ClientePublicForm[send_email]"
                    name="trans_email"
                    className="custom-check-dark"
                    checked={formData.trans_email == 1}
                    onChange={handleInputChange}
                  />
                  <Form.Check.Label
                    htmlFor="ClientePublicForm[send_email]"
                    className="ms-3"
                  >
                    Desidero ricevere le E-mail transazionali
                  </Form.Check.Label>
                </Form.Check>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group">
                <span id="ClientePublicForm_sesso">
                  <span className="me-2"> Sesso: </span>
                  <Form.Check className="d-inline-flex align-items-center ps-0">
                    <Form.Check.Input
                      type="radio"
                      id="ClientePublicForm_sesso_0"
                      name="sesso"
                      value={0}
                      className="custom-check-dark"
                      checked={formData.sesso == 0}
                      onChange={handleInputChange}
                    />
                    <Form.Check.Label
                      htmlFor="ClientePublicForm_sesso_0"
                      className="ms-3"
                    >
                      M
                    </Form.Check.Label>
                  </Form.Check>
                  <Form.Check className="d-inline-flex align-items-center ps-0">
                    <Form.Check.Input
                      type="radio"
                      id="ClientePublicForm_sesso_1"
                      name="sesso"
                      value={1}
                      className="custom-check-dark"
                      checked={formData.sesso === 1}
                      onChange={handleInputChange}
                    />
                    <Form.Check.Label
                      htmlFor="ClientePublicForm_sesso_1"
                      className="ms-3"
                    >
                      F
                    </Form.Check.Label>
                  </Form.Check>
                </span>
              </div>
            </div>
          </div>
          <div id="user-access-data">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <h3>Dati di accesso</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group">
                <Form.Control
                  type="text"
                  value={formData.username}
                  id="ClientePublicForm[username]"
                  name="username"
                  className="form-control"
                  placeholder="Username"
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group">
                <Form.Control
                  type="password"
                  value={formData.password}
                  id="ClientePublicForm[newPassword]"
                  name="password"
                  className="form-control"
                  placeholder="Nuova Password"
                  autoComplete="off"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group">
                <Form.Control
                  type="password"
                  value={formData.verifyPassword}
                  id="ClientePublicForm[verifyPassword]"
                  name="verifyPassword"
                  className="form-control"
                  placeholder="Conferma Password"
                  autoComplete="off"
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-footer">
          <div className="row">
            <div className="col-12 col-sm-12 col-md126 col-lg-12 col-xl-12 text-center">
              <button type="submit" className="btn-user-form">
                Conferma le modifiche
              </button>
            </div>
          </div>
        </div>
      </Form>
      <Link className="delete-account-btn" to="/it/areariservata/elimina">
        <FontAwesomeIcon icon={["fas", "times"]} /> Cancella account
      </Link>
    </div>
  )
}

export default Profile

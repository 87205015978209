import React from "react"
import { RegistrationAuth } from "../../../features/auth/RegistrationAuth"
import { useNavigate, useSearchParams } from "react-router-dom"

export const ClientRegistration: React.FC = () => {
  return (
    <div className="container custom-container">
      <div className="card d-flex flex-column align-items-center align-items-lg-center justify-content-center my-3 my-lg-5 py-5 px-3 px-lg-5 custom-rounded">
        <RegistrationAuth isInModal={false} afterConfirm={undefined} />
      </div>
    </div>
  )
}

import React, { useState } from "react"
import { useAppDispatch } from "../../app/hooks"
import { Link, useNavigate } from "react-router-dom"
import { logout } from "../auth/authSlice"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

interface MobileMenuItemProps {
  index: number
  menuItem: any
}

export const MobileMenuItem: React.FC<MobileMenuItemProps> = ({
  index,
  menuItem,
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  function handleLogout() {
    dispatch(logout())
    navigate("/")
  }
  return (
    <li>
      <span
        className={`user-area-icon ${
          menuItem.title == "Recensioni acquisti" ? "reviewsAcquisti" : ""
        } ${menuItem.title === "Logout" ? "svg-li" : ""}`}
      >
        <FontAwesomeIcon icon={menuItem.iconName as IconProp} />
      </span>
      {menuItem.title === "Logout" ? (
        <Link to={menuItem.link} onClick={handleLogout}>
          {menuItem.title}
        </Link>
      ) : (
        <Link to={menuItem.link}>{menuItem.title}</Link>
      )}
    </li>
  )
}

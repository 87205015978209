import React from "react"
import { Button, Modal } from "react-bootstrap"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectProductModal, setProductModal } from "./modalsSlice"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { formatNumber } from "../../utils/stringUtils"
import { skipToken } from "@reduxjs/toolkit/query"
import { useGetSecureRatingByDomainQuery } from "../../app/services/base/general"
import { useGetProductInfoQuery } from "../../app/services/esaScraper/products"

export const ProductModal: React.FC = () => {
  const dispatch = useAppDispatch()
  const product = useAppSelector(selectProductModal)
  const { data: securityRating, isFetching } = useGetSecureRatingByDomainQuery(
    product ? product.domain : skipToken,
  )
  const { data: productInfo } = useGetProductInfoQuery(
    product ? product.link_domain_id : skipToken,
  )

  const lastLowPrice =
    productInfo && productInfo.lowest_price_record
      ? formatNumber(productInfo.lowest_price_record.price, 2)
      : product && product.price
      ? formatNumber(product.price, 2)
      : 0
  const getColor = (rating: number | undefined): string => {
    if (rating === undefined) {
      return "dark-color"
    }

    if (rating >= 70) return "green-color"
    if (rating >= 45 && rating < 70) return "yellow-color"
    if (rating >= 25 && rating < 45) return "orange-color"
    if (rating >= 1 && rating < 25) return "red-color"

    return "dark-color"
  }

  return (
    <>
      <Modal
        show={product ? true : false}
        onHide={() => dispatch(setProductModal(undefined))}
        size="lg"
        centered
        aria-labelledby="productModalLabel"
        contentClassName="custom-rounded"
      >
        <Modal.Header className="text-center px-1">
          <div className="row px-1 px-lg-5 mx-auto w-100">
            <Button
              type="button"
              bsPrefix=" "
              variant=""
              className="btn-close float-end d-none d-lg-block position-relative position-left-30"
              style={{ top: "5px" }}
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => dispatch(setProductModal(undefined))}
            ></Button>
            <Button
              type="button"
              bsPrefix=" "
              variant=""
              className="btn-close float-end d-block d-lg-none position-relative"
              style={{ top: "5px" }}
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => dispatch(setProductModal(undefined))}
            ></Button>
            <div className="col-12 my-1">
              <Link
                to={`/it/eshop/${product?.domain}`}
                className="blu-color font-23 underline mb-3 line-clamp line-clamp-2"
                target="_blank"
              >
                {product?.domain}
              </Link>
            </div>
            <div className="col-12 mt-1 mb-3 px-3 px-lg-5">
              <h3 className="dark-color font-33 font-bolder line-clamp line-clamp-3">
                {product?.product_name}
              </h3>
            </div>
            <div className="col-12 mb-3">
              <img
                src={product?.cdn_image_url}
                className="img-fluid height-270 object-fit-cover mobile-height-auto mobile-mh-270"
                alt={product?.title}
                onError={(e) => (e.currentTarget.src = product?.image_url!)}
              />
            </div>
            <div className="col-12 my-3">
              <span className="gray-color font-16 line-clamp line-clamp-5">
                {product?.description}
              </span>
              <span className="dark-color font-33 font-bolder line-clamp mt-3 mb-1">
                {product?.price && formatNumber(product.price, 2)} €
              </span>
              <span className="gray-color font-16 font-semi-bold mt-1 mb-3">
                L'ultimo prezzo più basso:{" "}
                <span className="lowPriceLoader"> {lastLowPrice} € </span>
              </span>
            </div>
            <div className="col-12 my-1">
              <Link
                to={product?.url ? product.url : ""}
                className="btn btn-animation btn-action btn-gradient-orange fw-semibold height-50 px-3 px-lg-4 d-block d-lg-inline-block w-100 width-400"
                target="_blank"
                style={{ padding: "11px 0" }}
              >
                Vai al prodotto
              </Link>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-6 border-none border-lg-right-1">
              <div className="row align-items-center">
                <div className="col-3 text-end">
                  <span
                    className={`font-47 ecs-rating-shield ${getColor(
                      securityRating?.ecs_rating,
                    )}`}
                  >
                    <FontAwesomeIcon icon={["fas", "shield-halved"]} />
                  </span>
                </div>
                <div className="col-4 text-start">
                  <Link
                    to={`https://www.ecommercesicuro.com/sito/${product?.domain}`}
                    target="_blank"
                    className="font-23 xs-font-16 dark-color line-height-28 text-decoration-none"
                    rel="nofollow"
                  >
                    Security <br /> Rating
                  </Link>
                </div>
                <div className="col-5">
                  {isFetching || !securityRating ? (
                    <span className="ecs-rating-load">Aggiornamento...</span>
                  ) : (
                    <span className="ecs-rating-container">
                      <span
                        className={`font-40 xs-font-33 font-bolder ecs-rating-value ${getColor(
                          securityRating.ecs_rating,
                        )}`}
                      >
                        {securityRating.ecs_rating > 0
                          ? securityRating.ecs_rating
                          : "-"}
                      </span>
                      <span className="font-23 xs-font-16 dark-color">
                        /100
                      </span>
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="row align-items-center text-center">
                <div className="col-12">
                  <span className="font-23 xs-font-16 dark-color">
                    Valutazione recensioni
                  </span>
                </div>
                <div className="col-12">
                  <span className="font-40 xs-font-33 font-bolder simple-orange-txt">
                    {product?.score}
                  </span>
                  <span className="font-23 xs-font-16 dark-color">/5</span>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

import React, { ChangeEvent, FormEvent, useState } from "react"
import backgroundContatti from "../../assets/images/contact_us/bg-contatti.jpg"
import { Form } from "react-bootstrap"
import { Link } from "react-router-dom"
import customCss from "./contactUs.css?inline"
import useWindowSize from "../../utils/useWindowSize"
import { useContactUsMutation } from "../../app/services/base/general"
import { useAppDispatch } from "../../app/hooks"
import { addToast } from "../../features/toasts/toastsSlice"
import { ContactUsRequest } from "../../models/base/contactUsRequest.interface"

export const ContactUs: React.FC = () => {
  const dispatch = useAppDispatch()
  const [contactUs, { error }] = useContactUsMutation()

  const initialState: ContactUsRequest = {
    name: "",
    surname: "",
    email: "",
    subject: "",
    description: "",
    privacy: false,
  }

  const [formData, setFormData] = useState<{
    name: string
    surname: string
    email: string
    subject: string
    description: string
    privacy: boolean
  }>(initialState)

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value, type } = e.target

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]:
        type === "checkbox" ? (e.target as HTMLInputElement).checked : value,
    }))
  }

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    try {
      const response = await contactUs(formData).unwrap()
      if (response.response != "email sent succesfully") throw new Error()
      setFormData(initialState)
      dispatch(
        addToast({
          message:
            "Grazie per averci scritto. Ti ricontatteremo al più presto!",
          type: "success",
        }),
      )
    } catch (err) {
      dispatch(
        addToast({
          message: "Ops! Impossibile registrarsi al momento.",
          type: "danger",
        }),
      )
    }
  }

  const windowSize = useWindowSize()
  const isMobile = windowSize.width && windowSize.width <= 768

  return (
    <section
      className="contatti-page"
      style={isMobile ? {} : { backgroundImage: `url(${backgroundContatti})` }}
    >
      <div className="container">
        <div className="page-content">
          <br />
          <Form className="default-form" onSubmit={handleSubmit}>
            <div className="formc">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <h2>Contattaci</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="form-group">
                    <input
                      className="form-control"
                      placeholder="Nome"
                      name="name"
                      type="text"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="form-group">
                    <input
                      className="form-control"
                      placeholder="Cognome"
                      name="surname"
                      type="text"
                      value={formData.surname}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="form-group">
                    <input
                      className="form-control"
                      placeholder="Email"
                      name="email"
                      type="text"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="form-group">
                    <input
                      className="form-control"
                      placeholder="Oggetto"
                      name="subject"
                      type="text"
                      value={formData.subject}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      rows={6}
                      placeholder="Il tuo messaggio"
                      name="description"
                      value={formData.description}
                      onChange={handleInputChange}
                    ></textarea>
                  </div>
                  <br />
                  <div className="contact-form-submit-div">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div id="form-group">
                          <input
                            type="checkbox"
                            checked={formData.privacy}
                            className="text-privacyc me-2"
                            name="privacy"
                            onChange={handleInputChange}
                          />
                          <label className="label-pri">
                            Ho letto e accettato quanto riportato nell’{" "}
                            <a
                              href="/it/informativa-privacy?layoutName=simple&amp;iframe=true&amp;width=70%&amp;height=70%"
                              className="privacy"
                              rel="prettyPhoto[iframes]"
                            >
                              informativa sulla privacy
                            </a>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="form-group-captcha">
                          <label
                            className="recaptchaTerms"
                            htmlFor="ContactForm_captcha"
                          >
                            Questo sito è protetto da reCAPTCHA e vengono
                            applicate le{" "}
                            <Link
                              className="simple-blue-link orange-hover"
                              target="_blank"
                              to="https://policies.google.com/privacy"
                            >
                              Norme sulla Privacy
                            </Link>{" "}
                            e i{" "}
                            <Link
                              className="simple-blue-link orange-hover"
                              target="_blank"
                              to="https://policies.google.com/terms"
                            >
                              Termini di Servizio{" "}
                            </Link>{" "}
                            di Google.
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
                        <input
                          className="btn btn-form-orange button-form-contact"
                          // disabled={true}
                          type="submit"
                          name="yt0"
                          value="Invia"
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>
            <br />
          </Form>
        </div>
      </div>
      <style>{customCss}</style>
    </section>
  )
}

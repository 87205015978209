import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"

export const CookiesPolicy: React.FC = () => {
  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://cdn.iubenda.com/iubenda.js"
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <>
      <Helmet>
        <meta name="title" content={"Info cookies | eShoppingAdvisor"} />
        <meta
          name="description"
          content="Cookies sito eshoppingadvisor.com"
        ></meta>
      </Helmet>
      <div className="container">
        <div className="card d-flex flex-column align-items-center align-items-lg-center justify-content-center my-3 my-lg-5 py-5 px-3 px-lg-5 custom-rounded">
          <div className="row">
            <div className="col-12">
              <h2 className="title title dark-color font-33 font-bolder">
                Informativa cookies
              </h2>
            </div>
            <div className="col-12 dark-color font-16">
              <p>
                <br />
                <Link
                  className="iubenda-black no-brand iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed"
                  title="Cookie Policy"
                  to="https://www.iubenda.com/privacy-policy/63549753/cookie-policy"
                >
                  Cookie Policy
                  <br />
                  <br />
                </Link>
              </p>
              <p>
                <a
                  className="iubenda-cs-preferences-link"
                  style={{ textDecoration: "underline" }}
                >
                  Impostazioni dei cookie
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

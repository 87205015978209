import React, { useEffect, useState } from "react"
import {
  useGetProductsAggregationsQuery,
  useGetSearchProductsQuery,
} from "../../app/services/esaScraper/products"
import { ProductCard } from "../product_card/ProductCard"
import { useLocation } from "react-router-dom"
import {
  SearchFilter,
  SearchFilterOption,
} from "../../models/base/searchFilter.interface"
import {
  useGetDomainsByCategoryQuery,
  useGetSearchDomainsQuery,
} from "../../app/services/esaScraper/domains"
import { ShopCard } from "./ShopCard"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  resetFilters,
  selectFilters,
  selectOrderBy,
  selectSearchAfter,
  setSearchAfter,
} from "../filters/search_result/searchFiltersSlice"
import { EsaScraperProduct } from "../../models/esaScraper/esaScraperProduct.interface"
import { EsaScraperShop } from "../../models/esaScraper/esaScraperShop.interface"
import { ElementsNotFound } from "../elements_not_found/ElementsNotFound"
import customCss from "../../assets/css/old/int/custom.css?inline"
import { skipToken } from "@reduxjs/toolkit/query"
import { SearchFilters } from "../filters/search_result/SearchFilters"

interface SearchResultProps {
  searchText?: string
  eShopDomain?: string
  categorySlug?: string
  eShopEsaId?: number | string
  filtersToRender: SearchFilter[]
  ordersToRender: SearchFilterOption[]
}

export const SearchResult: React.FC<SearchResultProps> = ({
  searchText,
  eShopDomain,
  categorySlug,
  filtersToRender,
  ordersToRender,
  eShopEsaId,
}) => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const isProductsSearch = location.pathname.includes("prodotti")
  
  const searchAfter = useAppSelector(selectSearchAfter)
  const filters = useAppSelector(selectFilters)
  const orderBy = useAppSelector(selectOrderBy)
  const [searchBarValue, setSearchBarValue] = useState<string>("")
  

  const { data: results } =
    isProductsSearch && !categorySlug
      ? useGetSearchProductsQuery({
          searchText: !eShopEsaId ? searchText : searchBarValue,
          filters: !eShopEsaId
            ? {
                ...filters,
                orderBy,
              }
            : {
                eShopEsaId: {
                  name: "",
                  label: "",
                  value: eShopEsaId as string,
                },
                orderBy,
              },
          searchAfter,
        })
      : searchText
      ? useGetSearchDomainsQuery({
          searchText,
          filters: {
            ...filters,
            orderBy,
          },
          searchAfter,
        })
      : categorySlug && !isProductsSearch
      ? useGetDomainsByCategoryQuery({
          categorySlug,
          filters: {
            ...filters,
            orderBy,
          },
          searchAfter,
        })
      : categorySlug && isProductsSearch
      ? useGetSearchProductsQuery({
          filters: {
            ...filters,
            orderBy,
            categorySlug: { name: "", label: "", value: categorySlug },
          },
          searchAfter,
        })
      : { data: undefined }

  const { data: aggregations } = useGetProductsAggregationsQuery(
    isProductsSearch && !eShopDomain
      ? {
          searchText: !eShopDomain ? searchText : searchBarValue,
          filters: !eShopDomain
            ? { ...filters, orderBy }
            : { eShopDomain: { name: "", label: "", value: eShopDomain } },
          searchAfter,
        }
      : skipToken,
  )

  filtersToRender =
    isProductsSearch && aggregations && aggregations.aggregations
      ? [
          ...filtersToRender.map((filter) => {
            if (filter.title == "eCommerce") {
              return {
                title: "eCommerce",
                type: "checkbox",
                options: aggregations.aggregations?.domains.buckets.map(
                  (bucket) => {
                    const key =
                      bucket.top_domains_hits?.hits.hits &&
                      bucket.top_domains_hits.hits.hits.length > 0
                        ? bucket.top_domains_hits.hits.hits[0]._source.domain
                        : ""
                    return {
                      name: "shops",
                      label: `${key}-${bucket.doc_count}`,
                      value: `${key}`,
                    }
                  },
                ),
              } as SearchFilter
            } else if (filter.title == "Brand") {
              return {
                title: "Brand",
                type: "checkbox",
                options: aggregations.aggregations?.brands.buckets.map(
                  (bucket) => ({
                    name: "brands",
                    label: `${bucket.key}-${bucket.doc_count}`,
                    value: `${bucket.key}`,
                  }),
                ),
              } as SearchFilter
            }
            return filter
          }),
        ]
      : filtersToRender

  function handleSearch(searchBarValue: string) {
    setSearchBarValue(searchBarValue)
  }

  useEffect(() => {
    dispatch(resetFilters())
  }, [location])

  return (
    <section id="site">
      <div className="container">
        <SearchFilters
          filtersToRender={filtersToRender}
          ordersToRender={ordersToRender}
          eShopDomain={eShopDomain ? eShopDomain : undefined}
          searchBarValue={searchBarValue}
          handleSearch={handleSearch}
        />

        <div id="results-list" className="row no-gutters">
          {results && results.hits.hits.length > 0 ? (
            <>
              {results.hits.hits.map((item, index) => {
                if (isProductsSearch) {
                  return (
                    <ProductCard
                      key={`product${index}`}
                      product={item._source as EsaScraperProduct}
                    />
                  )
                } else {
                  return (
                    <ShopCard
                      key={`card${index}`}
                      shop={item._source as EsaScraperShop}
                    />
                  )
                }
              })}
              {results.has_search_after && (
                <div className="col-12 text-center mt-5">
                  <button
                    className="btn btn-animation btn-action btn-gradient-orange fw-semibold height-50 px-4"
                    onClick={() =>
                      dispatch(
                        setSearchAfter(
                          results?.hits.hits[results?.hits.hits.length - 1].sort
                            ? results?.hits.hits[results?.hits.hits.length - 1]
                                .sort!
                            : [],
                        ),
                      )
                    }
                  >
                    <span className="d-none d-md-block">
                      {" "}
                      Vedi altri risultati{" "}
                    </span>
                    <span className="d-block d-md-none"> Vedi altri </span>
                  </button>
                </div>
              )}
            </>
          ) : (
            <ElementsNotFound
              text={
                isProductsSearch
                  ? "Nessun prodotto trovato"
                  : "Nessun ecommerce trovato"
              }
            />
          )}
        </div>
      </div>
      <style>{customCss}</style>
    </section>
  )
}

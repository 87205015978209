import React from "react"
import { Link } from "react-router-dom"
import { EsaScraperProduct } from "../../models/esaScraper/esaScraperProduct.interface"
import { findSubstringParts } from "../../utils/stringUtils"

interface ProductMenuItemChildrenProps {
  product: EsaScraperProduct
  searchText: string
}

export const ProductMenuItemChildren: React.FC<
  ProductMenuItemChildrenProps
> = ({ product, searchText }) => {
  return (
    <li className="prod-search-item">
      <Link
        className="first-link active"
        to={`/it/search/prodotti/${encodeURIComponent(product.product_name)}`}
      >
        <span className="eshop-search">
          <span className="s-res">
            "
            <strong>
              {findSubstringParts(searchText, product.product_name)[0]}
            </strong>
            {findSubstringParts(searchText, product.product_name)[1]}"
          </span>
        </span>
      </Link>
    </li>
  )
}

import React, { useContext, useEffect } from "react"
import { Category } from "../../../models/base/category.interface"
import { Link } from "react-router-dom"
import {
  Accordion,
  AccordionContext,
  Card,
  useAccordionButton,
} from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import parse from "html-react-parser"
import {
  SearchFilter,
  SearchFilterOption,
} from "../../../models/base/searchFilter.interface"
import {
  selectSearchDefaultFilters,
  selectSearchDefaultOrders,
} from "../../../features/filters/search_result/searchFiltersSlice"
import { useAppSelector } from "../../../app/hooks"
import { ShopsCarouselSection } from "../../../features/shops_carousel/ShopsCarouselSection"
import { SearchResult } from "../../../features/search_result/SearchResult"
import { Articles } from "../../../features/articles/Articles"
import { Helmet } from "react-helmet"

const ContextAwareToggle: React.FC<any> = ({
  children,
  eventKey,
  callback,
}) => {
  const { activeEventKey } = useContext(AccordionContext)

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  )

  const isCurrentEventKey = activeEventKey === eventKey

  return (
    <button
      className="btn btn-link bolder-dark-title font-23 gray-hover"
      type="button"
      onClick={decoratedOnClick}
    >
      <FontAwesomeIcon
        icon={["fas", `${isCurrentEventKey ? "chevron-up" : "chevron-down"}`]}
      />
    </button>
  )
}

const TitleToggle: React.FC<any> = ({ children, eventKey, callback }) => {
  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  )
  return (
    <h1
      className="font-bolder dark-color cursor-pointer gray-hover mb-0 font-16"
      onClick={decoratedOnClick}
    >
      {children}
    </h1>
  )
}

interface SubCategoryProps {
  category: Category
}

export const SubCategory: React.FC<SubCategoryProps> = ({ category }) => {
  const defaultFilters: SearchFilter[] = useAppSelector(
    selectSearchDefaultFilters,
  )

  const defaultOrders: SearchFilterOption[] = useAppSelector(
    selectSearchDefaultOrders,
  )

  useEffect(() => {
    document.title = category.details.title // replace with your page title
  }, [])

  return (
    <>
      <Helmet>
        <title>{category.details.title}</title>
        <meta name="description" content={category.details.html} />
      </Helmet>
      <section id="top-page">
        <div className="container">
          <div className="row align-items-center mb-3">
            <div className="col-12 col-md-7 my-0 py-2 d-flex align-items-center justify-content-start">
              <h2 className="mb-0 dark-color font-bolder font-47 xs-font-33 fw-700">
                {category.name}
              </h2>
            </div>
            {category.has_coupon && (
              <div className="col-12 col-md-5 py-2 align-items-center justify-content-end d-md-flex">
                <Link
                  to={`/it/coupon/${category.slug}`}
                  className="btn btn-animation btn-action btn-gradient-orange fw-semibold height-50 float-end d-flex align-items-center justify-content-center px-4 ms-2 waitLink"
                  title={`codici sconto ${category.name}`}
                >
                  Coupon sconto
                </Link>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-md-12">
              <Accordion
                bsPrefix=" "
                className="xs-transparent-background background-white radius-75"
              >
                <Card className="no-borders mb-3 mb-md-0">
                  <Card.Header className="background-white no-borders radius-75">
                    <h5 className="mb-0">
                      <div className="row">
                        <div className="col-12 col-md-11 my-3">
                          <TitleToggle
                            eventKey="0"
                            children={category.details.subtitle}
                          />
                        </div>
                        <div className="col-12 col-md-1 text-center mt-3 mt-md-0">
                          <ContextAwareToggle eventKey="0" />
                        </div>
                      </div>
                    </h5>
                  </Card.Header>
                </Card>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <span className="border-top pt-3 w-100 d-block d-md-none"></span>
                    {parse(category.details.html)}
                  </Card.Body>
                </Accordion.Collapse>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
      <ShopsCarouselSection
        title={`Siti consigliati dagli utenti per comprare “${category.name}”`}
        categorySlug={category.slug}
      />
      <SearchResult
        filtersToRender={defaultFilters}
        ordersToRender={defaultOrders}
        categorySlug={category.slug}
      />
      <Articles
      // customTitle={`${category.name}: Guide e Consigli per il tuo Shopping Online`}
      />
    </>
  )
}

import React from "react"
import { Link } from "react-router-dom"
import { toStringFromDateTimeWithSlash } from "../../utils/stringUtils"
import { SimpleUser } from "../../models/base/simpleUser.interface"
import { Review } from "../../models/base/review.interface"
import avatarPlaceholder from "../../assets/images/avatar_placeh.png"
import { getUserAvatar } from "../../utils/userUtils"
import { RatingStars } from "../rating_stars/RatingStars"

interface ReviewsCarouselItemProps {
  review: Review
  simpleUser: SimpleUser
}

export const ReviewsCarouselItem: React.FC<ReviewsCarouselItemProps> = ({
  review,
  simpleUser,
}) => {
  return (
    <div className="item">
      <div className="recensione background-custom-gray custom-rounded">
        <div className="d-flex align-items-center justify-content-between pb-2 mb-1">
          <div className="d-flex align-items-center me-3">
            <img
              src={
                review.user_id
                  ? getUserAvatar(review.user_id)
                  : avatarPlaceholder
              }
              className="rounded-circle avatar"
              alt={simpleUser.name}
              onError={(e) => (e.currentTarget.src = avatarPlaceholder)}
            />
            {review.user_id ? (
              <Link
                to={`/it/utenti/${review.user_id}/${simpleUser.name}`}
                className="font-bolder mb-0 font-14 gray-text ms-1 line-clamp text-decoration-none"
              >
                {simpleUser.name}
              </Link>
            ) : (
              <h6 className="font-bolder mb-0 font-14 gray-text ms-1 line-clamp text-decoration-none">
                {simpleUser.name}
              </h6>
            )}
          </div>
          <span className="font-12 px-0 gray-text font-light">
            {toStringFromDateTimeWithSlash(review.insert_date)}
          </span>
        </div>
        <RatingStars
          rating={Number(review.overall_rating)}
          showAverageRating={false}
          size="small"
        />
        <p className="mt-2 mb-0">
          <Link
            to={`/it/reviews/${review.id}`}
            className="d-block mb-2 font-bolder font-16 dark-color line-clamp line-clamp-1 text-decoration-none"
          >
            {review.title}
          </Link>
          <span
            className="gray-text font-light font-16 line-clamp line-clamp-3 min-height-75"
            style={{ wordBreak: "break-word" }}
          >
            {review.description}
          </span>
        </p>
      </div>
    </div>
  )
}

import React from "react"
import { FaqAccordionItem } from "./FaqAccordionItem"
import { Accordion } from "react-bootstrap"

export const FaqAccordion: React.FC = () => {
  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-12 my-3">
          <Accordion
            className="xs-transparent-background background-white radius-75 py-3"
            bsPrefix=" "
          >
            <FaqAccordionItem
              eventKey={"1"}
              subtitle="TI BASTANO 15 MILIONI DI PRODOTTI E OLTRE 10 MILA SITI RECENSITI DA PERSONE CHE RILASCIANO RECENSIONI REALI?"
              title="Per acquistare online in sicurezza, passa prima da eShoppingAdvisor, il TripAdvisor degli e-Commerce"
              contentHtml="<p><strong>Grazie al nostro sistema di raccolta di recensioni verificate</strong> per gli e-commerce e per i loro prodotti, non solo potrai consultare, suddivisi per categorie, i <strong>negozi online più affidabili</strong> in base a <strong>recensioni reali</strong>, ma <strong>ricercare i prodotti più convenienti</strong> semplicemente digitando il nome o il tipo di prodotto che ti interessa acquistare.</p><p><strong>Puoi risparmiare sui tuoi acquisti grazie ai coupon sconto</strong> pubblicati su questo portale. Come fare per acquisire punti da spendere in codici sconto? Semplice! Quando acquisti un prodotto, non dimenticare di <a href='/scrivi-recensione' title='Scrivi una recensione per un acquisto online'>scrivere una recensione su eShoppingAdvisor</a>.</p>"
            />
            <FaqAccordionItem
              eventKey={"2"}
              subtitle="OGNI GIORNO MIGLIAIA DI PERSONE CERCANO QUI I MIGLIORI NEGOZI ONLINE E I PRODOTTI CON IL MIGLIOR RAPPORTO QUALITÀ-PREZZO"
              title="Usare eShoppingAdvisor significa premiare aziende reali: fai anche tu la tua parte!"
              contentHtml="<p><strong>eShoppingAdvisor è la guida di riferimento per uno shopping online sicuro e sostenibile</strong>. Siamo un ecosistema che dà voce ai consumatori e garantisce crescita, visibilità e credibilità, in particolare ai piccoli e medi e-commerce italiani.</p><p><strong>Acquistare online in modo sicuro</strong> per noi significa che ogni utente deve poter vivere un'esperienza di acquisto coerente con le recensioni che ha letto, evitare truffe e disservizi, tornare ad acquistare con fiducia e soddisfazione presso siti affidabili, che offrono prodotti e servizi di qualità.</p>"
            />
            <FaqAccordionItem
              eventKey={"3"}
              subtitle="LA GESTIONE DELLE RECENSIONI CLIENTI"
              title="Per migliorare la reputazione e la visibilità degli e-Commerce"
              contentHtml="<p>Per tutti gli e-commerce, dal più piccolo che vende prodotti di nicchia fino al grande brand di massa, un sistema per <strong>gestire le recensioni clienti</strong> oggi è indispensabile per tutelare e <strong>promuovere la propria reputazione online</strong>.</p><p>Se condividere la propria esperienza di acquisto è il mezzo più frequente per far emergere la qualità di un prodotto e la professionalità del venditore ogni azienda è chiamata a tutelare la propria identità e il proprio business online, sia rispetto ai consumatori sia verso i competitor.</p>"
            />
            <FaqAccordionItem
              eventKey={"4"}
              subtitle="UNA PIATTAFORMA COMPLETA DI STRUMENTI PER GLI E-COMMERCE"
              title="Per promuovere un commercio elettronico sostenibile e di qualità"
              contentHtml="<p>Per noi si traduce nel dare voce alle piccole e medie imprese rispetto ai big dell'e-commerce: significa valorizzare il Made in Italy e permettere a chiunque di trovare prodotti di qualità. </p><p><strong>Crescita e Affidabilità</strong> sono le parole chiave alla base del nostro piano di crescita per gli e-commerce italiani. I nostri <a href='https://www.eshoppingadvisor.com/it/aziende/servizi-ecommerce/' title='Strumenti online per far crescere il tuo shop' target='_blank'>strumenti e servizi per e-commerce</a> aiutano i negozi online a migliorare in termini di <strong>credibilità, visibilità, vendite e fidelizzazione</strong> dei clienti. <a href='https://www.eshoppingadvisor.com/it/aziende/piani/' target='_blank' title='Piano free per e-commerce'>Il pacchetto base è gratuito</a>.</p>"
            />
          </Accordion>
        </div>
      </div>
    </div>
  )
}

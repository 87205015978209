import React from "react"
import { Link } from "react-router-dom"
import rank1 from "../../assets/images/reviewer_ranking/rank_1.svg"
import rank2 from "../../assets/images/reviewer_ranking/rank_2.svg"
import rank3 from "../../assets/images/reviewer_ranking/rank_3.svg"
import { capitalizeEveryWord, formatNumber } from "../../utils/stringUtils"
import { Reviewer } from "../../models/base/reviewer.interface"
import greenThumb from "../../assets/icons/green_thumb.svg"

interface ReviewerRankingItemProps {
  reviewer: Reviewer
  orderBy: string
}

export const ReviewerRankingItem: React.FC<ReviewerRankingItemProps> = ({
  reviewer,
  orderBy,
}) => {
  const userLink = `/utenti/${reviewer.user_id}/${reviewer.name}`
  return (
    <div className="top_recensori_item">
      {reviewer.position <= 3 && (
        <img
          src={
            reviewer.position === 1
              ? rank1
              : reviewer.position === 2
              ? rank2
              : reviewer.position === 3
              ? rank3
              : undefined
          }
          className="top_rank_recensori"
          alt={`Rank ${reviewer.position}`}
        />
      )}

      <div className={`top_recensori_rank rank_${reviewer.position}`}>
        {reviewer.position <= 3 ? (
          <span style={{ marginLeft: "35px" }}> in classifica </span>
        ) : (
          `${reviewer.position}° in classifica`
        )}
      </div>
      <div className="top_recensori_body">
        <div className="top_recensori_body_sx">
          <div className="top_recensori_user_avatar">
            {reviewer.visibility == 1 ? (
              <Link to={userLink}>
                <img
                  src={reviewer.avatar}
                  alt={`${reviewer.name || "utente"}`}
                />
              </Link>
            ) : (
              <img src={reviewer.avatar} alt={`${reviewer.name || "utente"}`} />
            )}
          </div>
          <div className="top_recensori_user_info">
            <span className="top_recensori_user_name">
              {reviewer.visibility == 1 ? (
                <Link to={userLink}>{capitalizeEveryWord(reviewer.name)}</Link>
              ) : (
                `${capitalizeEveryWord(reviewer.name)}`
              )}
            </span>
            {reviewer.city != "" && (
              <span className="top_recensori_user_address">
                {reviewer.city}
              </span>
            )}

            {orderBy == "1" ? (
              <>
                <span className="top_recensioni_user_n_reviews">
                  <strong>
                    {reviewer.points == 1
                      ? reviewer.points
                      : formatNumber(reviewer.points, 2).replace(".", ",")}
                  </strong>{" "}
                  {reviewer.points == 1 ? "punto carriera" : "punti carriera"}
                </span>
                <span className="recensioni-user-n-reviews">
                  <strong>{reviewer.reviews}</strong>{" "}
                  {reviewer.reviews == 1 ? "recensione" : "recensioni"}
                </span>
                <span className="top_recensioni_user_n_views">
                  <strong>{reviewer.visualization}</strong>{" "}
                  {reviewer.visualization == 1
                    ? "visualizzazione"
                    : "visualizzazioni"}
                </span>
              </>
            ) : (
              <>
                <span className="top_recensioni_user_n_views">
                  <strong>{reviewer.visualization}</strong>{" "}
                  {reviewer.visualization == 1
                    ? "visualizzazione"
                    : "visualizzazioni"}
                </span>
                <span className="recensioni-user-n-reviews">
                  <strong>{reviewer.reviews}</strong>{" "}
                  {reviewer.reviews == 1 ? "recensione" : "recensioni"}
                </span>
                <span className="top_recensioni_user_n_reviews">
                  <strong>
                    {reviewer.points == 1
                      ? reviewer.points
                      : formatNumber(reviewer.points, 2).replace(".", ",")}
                  </strong>{" "}
                  {reviewer.points == 1 ? "punto carriera" : "punti carriera"}
                </span>
              </>
            )}
          </div>
        </div>
        <div className="top_recensori_body_dx">
          <div className="top_recensori_carriere">
            {reviewer.reviews_career && (
              <div
                className={`top_recensori_carriera_recensioni ${
                  orderBy === "1"
                    ? "carriera_priority order-first"
                    : "order-last d-none d-md-block"
                }`}
              >
                <span className="carriera-title">Carriera recensioni</span>
                <img
                  src={reviewer.reviews_career_image}
                  className="img-fluid carriera-user"
                  alt="carriera recensioni"
                />
                <span>{reviewer.reviews_career}</span>
              </div>
            )}

            {reviewer.visualization_career && (
              <div
                className={`top_recensori_carriera_visualizzazioni ${
                  orderBy !== "2"
                    ? "order-last d-none d-md-block"
                    : "carriera_priority order-first"
                }`}
              >
                <span className="carriera-title">Carriera visualizzazioni</span>
                <img
                  src={reviewer.visualization_career_image}
                  className="img-fluid carriera-user"
                  alt="carriera visualizzazioni"
                />
                <span>{reviewer.visualization_career}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="top_recensori_bottom">
        <div className="row">
          {reviewer.like && (
            <div className="col-12 col-sm col-md col-lg col-xl">
              <div className="top_recensori_reactions">
                <span className="top_recensori_r_positive">
                  {reviewer.like}
                  <span
                    style={{
                      content: `url(${greenThumb})`,
                      display: "inline",
                      marginLeft: "5px",
                      marginRight: "20px",
                    }}
                  ></span>
                </span>
              </div>
            </div>
          )}
          <div className="col-12 col-sm col-md col-lg col-xl">
            <Link className="top_recensori_profile_link" to={userLink}>
              Visualizza profilo
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

import React from "react"
import { useAppSelector } from "../../../app/hooks"
import { selectCurrentUser } from "../../../features/auth/authSlice"
import rec5 from "../../../assets/images/awards/rec_5.png"
import rec4 from "../../../assets/images/awards/rec_4.png"
import rec3 from "../../../assets/images/awards/rec_3.png"
import rec2 from "../../../assets/images/awards/rec_2.png"
import rec1 from "../../../assets/images/awards/rec_1.png"
import vis4 from "../../../assets/images/awards/vis_4.png"
import vis3 from "../../../assets/images/awards/vis_3.png"
import vis2 from "../../../assets/images/awards/vis_2.png"
import vis1 from "../../../assets/images/awards/vis_1.png"
import geo4 from "../../../assets/images/awards/geo_4g.png"
import geo3 from "../../../assets/images/awards/geo_3g.png"
import geo2 from "../../../assets/images/awards/geo_2g.png"
import geo1 from "../../../assets/images/awards/geo_1g.png"
import { AwardSection } from "./AwardSection"

export const Awards: React.FC = () => {
  const user = useAppSelector(selectCurrentUser)

  if (!user) {
    return <></>
  }

  return (
    <>
      <div className="w-container utente riconoscimenti white-background">
        <AwardSection
          type="Reviews"
          awards={[
            { level: "Nuovo Utente", points: 1, image: rec1 },
            { level: "Nuovo Consulente", points: 3, image: rec2 },
            { level: "Consulente", points: 5, image: rec3 },
            {
              level: "Consulente di riferimento",
              points: 20,
              image: rec4,
            },
            { level: "Consulente Top", points: 50, image: rec5 },
          ]}
        />
        <AwardSection
          type="Views"
          awards={[
            { level: "Consulente Conosciuto", points: 100, image: vis1 },
            { level: "Consulente Rinomato", points: 500, image: vis2 },
            { level: "Consulente Famoso", points: 1000, image: vis3 },
            { level: "Consulente VIP", points: 5000, image: vis4 },
          ]}
        />
        <AwardSection
          type="World"
          awards={[
            {
              level: "Consulente Nazionale",
              points: "Unica nazione",
              label: "con recensioni su eshop di una stessa nazione",
              image: geo1,
            },
            {
              level: "Consulente Internazionale",
              points: "Nazioni stesso continente",
              label:
                "con recensioni su eshop di almeno due nazioni dello stesso continente",
              image: geo2,
            },
            {
              level: "Consulente Intercontinentale",
              points: "Nazioni di continente diverso",
              label:
                "con recensioni su eshop di almeno due nazioni situate su due o più continenti",
              image: geo3,
            },
            {
              level: "Consulente Mondiale",
              points: "Nazioni nei cinque continenti",
              label:
                "con recensioni su eshop di nazioni situate su tutti e cinque i continenti​",
              image: geo4,
            },
          ]}
        />
        <div style={{ clear: "both" }}></div>
      </div>
    </>
  )
}

export default Awards

import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
  retry,
} from "@reduxjs/toolkit/query/react"
import { RootState } from "../../store"
import { logout, tokenReceived } from "../../../features/auth/authSlice"
import { Mutex } from "async-mutex"

const mutex = new Mutex()

const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_API_BASE_URL,
  prepareHeaders: (headers, { getState, endpoint }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    if (
      endpoint == "updateReview" ||
      endpoint == "deleteReview" ||
      endpoint == "deletePendingUpdate" ||
      endpoint == "sendReport" ||
      endpoint == "getProfile" ||
      endpoint == "updateProfile" ||
      endpoint == "deleteAccount" ||
      endpoint == "uploadUserProfilePic" ||
      endpoint == "getReviewForAuthUser" || 
      endpoint == "getProductReviewForAuthUser" ||
      endpoint == "updateProductReview" ||
      endpoint == "deletePendingProductUpdate" || 
      endpoint == "deleteProductReview" ||
      endpoint == "createProductReview" 
    ) {
      const token = (getState() as RootState).auth.user?.token
      if (token) {
        headers.set("Authorization", `Bearer ${token}`)
      }
    }
    return headers
  },
})

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock()
  let result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      try {
        const refreshResult = await baseQuery(
          {
            method: "POST",
            url: "/consumer-users/auth/refresh-token",
          },
          api,
          extraOptions,
        )
        if (refreshResult.data) {
          api.dispatch(tokenReceived(refreshResult.data as string))
          result = await baseQuery(args, api, extraOptions)
        } else {
          api.dispatch(logout())
        }
      } finally {
        release()
      }
    } else {
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    }
  }

  return result
}

const baseQueryWithRetry = retry(baseQueryWithReauth, {
  // maxRetries: 3,
  retryCondition: (_: any, __: any, { attempt }: any) => attempt.code == 500,
})

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const baseApi = createApi({
  /**
   * `reducerPath` is optional and will not be required by most users.
   * This is useful if you have multiple API definitions,
   * e.g. where each has a different domain, with no interaction between endpoints.
   * Otherwise, a single API definition should be used in order to support tag invalidation,
   * among other features
   */
  reducerPath: "baseApi",
  /**
   * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
   */
  baseQuery: baseQueryWithRetry,
  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  tagTypes: [
    "Articles",
    "Categories",
    "Coupons",
    "CouponsUserHistory",
    "Invitations",
    "Products",
    "Reviews",
    "SecurityRating",
    "Shops",
    "User",
  ],
  /**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   * If you want all endpoints defined in the same file, they could be included here instead
   */
  endpoints: () => ({}),
})

// export const enhancedApi = api.enhanceEndpoints({
//   endpoints: () => ({
//     getPost: () => "test",
//   }),
// })

import React from "react"

export const ReviewDeleteConfirm: React.FC = () => {
  return (
    <div className="w-container utente white-background">
      <div className="update-review mod-pl">
        <div className="row">
          <div className="col-md-12">
            <h2 className="text-center">
              La tua recensione è stata cancellata.
            </h2>
          </div>
        </div>
      </div>
    </div>
  )
}

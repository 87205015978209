import React, { useEffect, useState } from "react"
import { Shop } from "../../../models/base/shop.interface"
import { toRatingText } from "../../../utils/stringUtils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import { setEsaScoreModal } from "../../modals/modalsSlice"
import { useAppDispatch } from "../../../app/hooks"
import thumbPlaceholder from "../../../assets/images/thumb_placeholder.png"
import {
  isPremiumShop,
  getShopLogo,
  getShopThumb,
} from "../../../utils/shopUtils"
import { useGetFeedInfoQuery } from "../../../app/services/esaScraper/general"
import { RatingStars } from "../../rating_stars/RatingStars"
import debounce from "lodash/debounce"

interface EShopStickyBarProps {
  shop: Shop
}

export const EShopStickyBar: React.FC<EShopStickyBarProps> = ({ shop }) => {
  const dispatch = useAppDispatch()
  const [showLogo, setShowLogo] = useState<boolean>(true)
  const [showThumb, setShowThumb] = useState<boolean>(false)
  const [showStickyInfo, setShowStickyInfo] = useState<boolean>(false)

  let eshopUrl =
    (shop.eshopData.url.includes("http") ? "" : "http://") + shop.eshopData.url

  const { data: feedInfo } = useGetFeedInfoQuery({
    domainId: shop.id,
    kindOfFeed: "product",
  })

  useEffect(() => {
    const debouncedHandleScroll = debounce(() => {
      const ref = document.getElementById("eshop-info-top")
      const scroll = window.scrollY

      if (ref && scroll >= ref.offsetTop) {
        setShowStickyInfo(true)
      } else {
        setShowStickyInfo(false)
      }
    }, 15)

    window.addEventListener("scroll", debouncedHandleScroll)

    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll)
    }
  }, [])

  return (
    <div
      id="eshop-sticky-info"
      className={`fixed-top custom-sticky-top background-white box-shadow ${
        showStickyInfo ? "d-block" : "d-none"
      }`}
    >
      <div className="container container-custom">
        <div className="d-flex flex-column flex-lg-row align-items-center align-items-lg-center justify-content-center justify-content-lg-between py-1 py-lg-2">
          <div className="d-flex mb-4 mb-lg-0 gap-3 align-items-center w-100">
            <div
              className="custom-rounded"
              style={{
                minWidth: "70px",
                border: "2px solid #e55e0e",
                borderRadius: "10px",
                overflow: "hidden",
              }}
            >
              {showLogo && (
                <img
                  src={getShopLogo(shop.id)}
                  style={{
                    width: "70px",
                    height: "70px",
                    objectFit: "contain",
                  }}
                  onError={(e) => {
                    setShowThumb(true)
                    setShowLogo(false)
                  }}
                  alt={shop.domain}
                />
              )}
              {showThumb && (
                <img
                  src={getShopThumb(shop.id)}
                  style={{
                    width: "70px",
                    height: "70px",
                    objectFit: "contain",
                  }}
                  onError={(e) => (e.currentTarget.src = thumbPlaceholder)}
                  alt={shop.domain}
                />
              )}
            </div>
            <div className="d-flex flex-column gap-1">
              <div className="d-flex">
                <Link
                  to={`https://${shop.domain}`}
                  className="bolder-dark-title font-23 m-0 line-clamp text-decoration-none"
                  target="_blank"
                >
                  {shop.domain}
                </Link>
              </div>
              <div className="d-flex align-items-center">
                <div className="me-2">
                  <RatingStars
                    rating={shop.rating ? shop.rating : 0}
                    showAverageRating
                    size="small"
                  />
                </div>
                <div className="d-none d-lg-block font-semi-bolder simple-orange-txt font-16 me-2">
                  {toRatingText(shop.rating ? shop.rating : 0)}
                </div>
                <div
                  className="d-flex align-items-center justify-content-center cursor-pointer"
                  style={{
                    border: "1px solid #1e3d87",
                    borderRadius: "30px",
                    gap: "1px",
                    padding: "5px 8px",
                  }}
                  onClick={() => dispatch(setEsaScoreModal(shop))}
                >
                  <FontAwesomeIcon
                    icon={["fas", "info"]}
                    style={{ fontSize: "10px", color: "#1e3d87" }}
                  />
                  {/* <span className="d-lg-none font-9" style={{color: "#163c8c"}}>informazioni</span> */}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex gap-1 gap-lg-3 justify-content-end">
            {shop.eshopData.isDisabled ? (
              <span className="btn btn-gradient-orange btn-ecommerce-details py-2 mb-3 mb-lg-0 me-lg-2 d-flex align-items-center justify-content-center disabled">
                Scrivi recensione
              </span>
            ) : (
              <Link
                to={`/it/invia-recensione?e=${shop.id}`}
                className="btn btn-animation btn-action btn-gradient-orange btn-ecommerce-details py-2 mb-3 mb-lg-0 me-lg-2 d-flex align-items-center justify-content-center"
                title={`Scrivi recensione di ${shop.domain}`}
              >
                Scrivi recensione
              </Link>
            )}
            {feedInfo &&
            feedInfo.product_count &&
            feedInfo.product_count > 0 ? (
              <Link
                to={`/it/prodotti-eshop/${shop.domain}`}
                className="btn btn-outline-animation btn-outline-gradient-orange btn-ecommerce-details py-2 d-flex align-items-center justify-content-center"
                title={`Tutti i prodotti di ${shop.domain}`}
              >
                Vedi prodotti
              </Link>
            ) : (
              <Link
                to={
                  eshopUrl
                  // shop.eshopData.structured_data.urlAff
                  //   ? shop.eshopData.structured_data.urlAff
                  //   : shop.eshopData.url
                }
                target="_blank"
                className="btn btn-outline-animation btn-outline-gradient-orange btn-ecommerce-details d-flex justify-content-center align-items-center py-2"
                {...(isPremiumShop(shop.plan) ? {} : { rel: "nofollow" })}
              >
                <span
                  className="d-flex flex-wrap text-start simple-orange-txt-link me-3 line-clamp break-all"
                  style={{ whiteSpace: "normal" }}
                >
                  {" "}
                  Visita il sito{" "}
                </span>
                <span>
                  <FontAwesomeIcon
                    icon={["fas", "arrow-right-long"]}
                    className="text-center align-middle thin-icon font-18"
                  />
                </span>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

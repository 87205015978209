import React, { useState } from "react"
import { Shop } from "../../../models/base/shop.interface"
import { Fancybox } from "../../fancybox/Fancybox"

interface EShopMediaCardProps {
  shop: Shop
}

export const getDomainGalleryImageUrl = (
  domainImageName: string,
  eshopId: number,
) => {
  return (
    import.meta.env.VITE_CDN_BASE_URL +
    `/eshop-gallery/${eshopId}/${domainImageName}`
  )
}

export const EShopMediaCard: React.FC<EShopMediaCardProps> = ({ shop }) => {
  const [showAll, setShowAll] = useState<boolean>(false)
  let video_url = shop.eshopData.structured_data.video
  if (video_url && video_url.includes("youtube.com")) {
    video_url = video_url.replace("watch?v=", "embed/")
  }
  return (
    <div
      className={`card eshop-custom-card mb-0 mb-lg-2 me-2 me-lg-0 ${
        showAll ? "mobile-height-auto" : ""
      }`}
    >
      <div className="card-body d-flex flex-column justify-content-between px-3 py-4 p-lg-4">
        <div>
          <h5 className="blue-card-title m-0 mb-2 mb-lg-3">Media</h5>
          <hr className="border border-dark m-0 mb-lg-3 d-none d-lg-block" />
          {(shop.plan == "company" || shop.plan == "enterprise") &&
            video_url && (
              <div className="eshop-video">
                <iframe
                  className="me-1 mb-1 w-100"
                  src={video_url}
                  loading="lazy"
                ></iframe>
              </div>
            )}
        </div>
        {shop.eshopData.structured_data.image_gallery && (
          <>
            <Fancybox
              options={{
                Carousel: {
                  infinite: false,
                },
                Toolbar: {
                  display: {
                    left: [],
                    middle: [],
                    right: ["slideshow", "fullscreen", "thumbs", "close"],
                  },
                },
              }}
            >
              <div
                className={`d-lg-flex flex-row flex-wrap align-items-center ${
                  shop.eshopData.structured_data.video
                    ? "hideOnMobile d-none d-lg-flex"
                    : ""
                } ${showAll ? "d-flex" : "d-none"}`}
              >
                {shop.eshopData.structured_data.image_gallery?.map(
                  (imageUrl) => (
                    <div
                      key={getDomainGalleryImageUrl(imageUrl, shop.id)}
                      className="col-4 mb-1"
                    >
                      <a
                        className="text-decoration-none"
                        data-src={getDomainGalleryImageUrl(imageUrl, shop.id)}
                        data-fancybox="gallery"
                      >
                        <img
                          className="img-fluid"
                          src={getDomainGalleryImageUrl(imageUrl, shop.id)}
                          alt={shop.domain + "galleria immagini"}
                        />
                      </a>
                    </div>
                  ),
                )}
              </div>
            </Fancybox>
            {!showAll && (
              <div className="d-lg-none text-center">
                <a
                  className="simple-blue-link"
                  onClick={() => setShowAll(true)}
                >
                  Visualizza tutto
                </a>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { User } from "../../models/base/user.interface"
import Cookies from "js-cookie"

export interface AuthState {
  user?: User
}

const initialState: AuthState = {
  user: undefined,
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      localStorage.setItem("user", JSON.stringify(action.payload))
      state.user = action.payload
    },
    tokenReceived: (state, action: PayloadAction<string>) => {
      if (state.user) {
        state.user.token = action.payload
      }
    },
    logout: (state) => {
      // if (state.authType == "Facebook") {
      //   window.FB.logout((response: any) => {
      //     console.log("LOGOUT FB", response)
      //   })
      // }
      localStorage.clear()
      Cookies.remove("refresh-token")
      state.user = undefined
    },
  },
})

export const { setUser, tokenReceived, logout } = authSlice.actions

export const selectCurrentUser = (state: RootState) => state.auth.user

export default authSlice.reducer

import React from "react"
import { CategoryHighlight } from "./CategoryHighlight"
import { useGetCategoriesQuery } from "../../../app/services/base/categories"

export const CategoriesHighlights: React.FC = () => {
  const { data: categories } = useGetCategoriesQuery({ type: "suggested" })

  return (
    <>
      {categories?.slice(0, 2).map((c, i) => (
        <CategoryHighlight key={i} index={i} category={c} />
      ))}
    </>
  )
}

import { ButtonGroup, Dropdown } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { SearchFilterOption } from "../../../models/base/searchFilter.interface"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { selectOrderBy, setOrderBy } from "./searchFiltersSlice"

interface OrderByButtonProps {
  ordersToRender: SearchFilterOption[]
}

export const OrderByButton: React.FC<OrderByButtonProps> = ({
  ordersToRender,
}) => {
  const dispatch = useAppDispatch()
  const orderBy = useAppSelector(selectOrderBy)
  return (
    <div className="filtro d-none d-lg-block">
      <Dropdown as={ButtonGroup}>
        <Dropdown.Toggle
          type="button"
          id="eshopOrder"
          className={`btn btn-white btn-60 text-start font-bolder font-16 dropdown-toggle line-clamp pe-5
          ${orderBy.value != "" ? "orange-border" : ""}`}
        >
          {orderBy.label}
          <FontAwesomeIcon
            icon={["fas", "chevron-down"]}
            style={{
              position: "absolute",
              right: "20px",
              top: "20px",
            }}
          />
        </Dropdown.Toggle>
        <Dropdown.Menu as={"ul"} className="w-100" aria-labelledby="eshopOrder">
          {ordersToRender.map((orderFilter) => (
            <Dropdown.Item
              as={"li"}
              key={orderFilter.label}
              onClick={() => dispatch(setOrderBy(orderFilter))}
            >
              {orderFilter.label == "Ordina per"
                ? "Ordinamento predefinito"
                : orderFilter.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

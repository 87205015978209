import React from "react"
import { Link } from "react-router-dom"
import errorImage from "../../assets/images/error_img.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import notFoundCss from "./notFound.css?inline"

export const NotFound: React.FC = () => {
  return (
    <section className="error-page user-area-page">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="w-container utente">
              <div className="row">
                <div className="col-12 text-center">
                  <img src={errorImage} className="img-fluid d-inline" />
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center">
                  <h3>Cerchi qualcosa in particolare?</h3>
                  <p>
                    Ci dispiace. L’indirizzo Web inserito corrisponde a una
                    pagina non funzionante nel nostro sito.
                  </p>
                  <span className="back-to-home">
                    <FontAwesomeIcon icon={["fas", "caret-right"]} />
                    Vai alla <Link to="/">Home Page</Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>{notFoundCss}</style>
    </section>
  )
}

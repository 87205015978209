import React, { FormEvent, useState } from "react"
import { Form } from "react-bootstrap"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { useAppDispatch } from "../../app/hooks"
import { useCreateUserMutation } from "../../app/services/base/users"
// import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { addToast } from "../toasts/toastsSlice"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  setGuestModal,
  setLoginModal,
  setRegistrationModal,
  selectEmailInserted,
} from "../modals/modalsSlice"
import { useAppSelector } from "../../app/hooks"
import { FacebookAuth } from "./FacebookAuth"
import { GoogleAuth } from "./GoogleAuth"
import { InvitationOrder } from "../../models/base/invitationOrder.interface"
import { Helmet } from "react-helmet"
import { setUser } from "./authSlice"
import { User } from "../../models/base/user.interface"

interface RegistrationAuthProps {
  isInModal: boolean
  invitationOrder?: InvitationOrder
  afterConfirm: undefined | ((tempUser: User | undefined) => void)
}

export const RegistrationAuth: React.FC<RegistrationAuthProps> = ({
  isInModal,
  invitationOrder,
  afterConfirm = undefined,
}) => {
  const emailInserted = useAppSelector(selectEmailInserted)
  const [formData, setFormData] = useState<{
    name: string
    email: string
    password: string
    // verifyPassword: string
    privacy: boolean
  }>({
    name: "",
    email: emailInserted || "",
    password: "",
    // verifyPassword: "",
    privacy: true,
    // recaptchaToken: "",
  })
  const [submittedFormData, setSubmittedFormData] = useState<{
    name: string
    email: string
    password: string
    // verifyPassword: string
    privacy: boolean
  }>({
    name:
      invitationOrder && !invitationOrder.customer_is_registered
        ? invitationOrder.customer_firstname || ""
        : "",
    email:
      invitationOrder && !invitationOrder.customer_is_registered
        ? invitationOrder.customer_email
        : "",
    password: "",
    // verifyPassword: "",
    privacy: true,
    // recaptchaToken: "",
  })
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [createUser, { error }] = useCreateUserMutation()
  // const { executeRecaptcha } = useGoogleReCaptcha()
  const [searchParams, setSearchParams] = useSearchParams()
  const [validated, setValidated] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  // const [showVerifyPassword, setShowVerifyPassword] = useState<boolean>(false)
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.currentTarget
    setFormData({ ...formData, [name]: value })
  }

  function handleCheckboxChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, checked } = e.currentTarget
    setFormData({ ...formData, [name]: checked })
  }
  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()

    const form = e.currentTarget
    if (form.checkValidity() === false) {
      e.stopPropagation()
    } else {
      try {
        const { name, email, password /*, verifyPassword */ } = formData
        const response = await createUser({
          name,
          email,
          password,
          // verifyPassword,
        }).unwrap()
        if (!response.success) throw new Error()

        const tempUser = {
          id: response.user_id,
          firstname: name,
          lastname: "",
          email: email,
          couponPoints: null,
          structuredData: null,
          token: "", // Ensure this is the intended value or initialize appropriately
          // Uncomment or remove the following lines based on requirements
          // reviewsNumber: 0,
          // reviews: 0,
          // visualization: 0,
          // points: 0,
          // like: 0,
          // rank_position: 0,
          visualization_career: "Nuovo utente",
          visualization_career_image: "",
          reviews_career: "Nuovo utente",
          reviews_career_image: "",
          geo_carreer: "",
          geo_career_image: "",
        } as User
        // dispatch(setUser(tempUser))
        if (afterConfirm) {
          afterConfirm(tempUser)
          dispatch(setLoginModal(undefined))
          dispatch(setRegistrationModal(undefined))
          setValidated(false)
        } else {
          navigate("/registrazione/ok")
        }
      } catch (err: any) {
        debugger
        dispatch(
          addToast({
            message: err.data?.message || "Errore durante la registrazione",
            type: "danger",
          }),
        )
      }
    }

    setSubmittedFormData(formData)
    setValidated(true)
  }

  // const handleReCaptchaVerify = useCallback(async () => {
  //   if (!executeRecaptcha) {
  //     return
  //   }
  //   const token = await executeRecaptcha("clientRegistration")
  //   setFormData({ ...formData, recaptchaToken: token })
  // }, [executeRecaptcha])

  // useEffect(() => {
  //   handleReCaptchaVerify()
  // }, [handleReCaptchaVerify])

  return (
    <>
      <Helmet>
        <title>Registrati su eShoppingAdvisor</title>
        <meta
          name="description"
          content=" Registrati ad eShoppingAdvisor ed entra nella più grande community di
        recensori di siti"
        />
      </Helmet>
      <h1 className="font-33 dark-color font-bolder m-0 mt-2 mt-lg-0 mb-1">
        Registrati
      </h1>
      <h2 className="font-14 lightgray-txt text-center m-0 mb-4">
        Registrati ad eShoppingAdvisor ed entra nella più grande community di
        recensori di siti
      </h2>
      <Form
        className={`login-form myform reg_container ${
          isInModal ? "w-100" : "w-75"
        }`}
        noValidate
        // validated={validated}
        onSubmit={handleSubmit}
      >
        <p className="lightgray-txt font-14 m-0 mb-3 text-center">
          Registrati con
        </p>
        <div className="d-flex flex-column flex-lg-row align-items-start justify-content-center w-100 mb-3 gap-lg-1">
          <div id="hoauthWidgetyw0" className="hoauthWidget d-flex w-100">
            <FacebookAuth type="Signup" showName={true} />
            <GoogleAuth type="Signup" showName={true} />
          </div>
        </div>
        <p className="lightgray-txt font-14 m-0 mb-3 text-center">oppure</p>
        <Form.Group className="d-flex flex-column w-100 mb-3" controlId="name">
          <Form.Control
            className="form-control form-control-custom form-control-gray my-1"
            autoComplete="name"
            placeholder="Digita qui il tuo nome"
            required={true}
            name="name"
            type="text"
            onChange={handleChange}
            value={formData.name}
          />
          {validated &&
            (submittedFormData.name.trim().length === 0 ||
              submittedFormData.name.length < 2) && (
              <Form.Control.Feedback type="invalid" className="d-block">
                Inserire un nome valido.
              </Form.Control.Feedback>
            )}
        </Form.Group>
        <Form.Group className="d-flex flex-column w-100 mb-3">
          <Form.Control
            className="form-control form-control-custom form-control-gray my-1"
            autoComplete="email"
            placeholder="Digita qui la tua email"
            required={true}
            name="email"
            type="text"
            onChange={handleChange}
            value={formData.email}
          />
          {validated && !emailRegex.test(submittedFormData.email) && (
            <Form.Control.Feedback type="invalid" className="d-block">
              Indirizzo email non valido.
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <div className="d-flex align-items-start justify-content-center w-100 gap-1">
          <Form.Group className="d-flex flex-column w-100 mb-4">
            <Form.Control
              className="form-control form-control-custom form-control-gray form-control-padding-end"
              autoComplete="off"
              placeholder="Password"
              required={true}
              name="password"
              type={showPassword ? "text" : "password"}
              maxLength={255}
              onChange={handleChange}
              value={formData.password}
            />
            <span
              className="showPasswordTrigger cursor-pointer dark-color"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <FontAwesomeIcon id="pwdIcon" icon={["fas", "eye-slash"]} />
              ) : (
                <FontAwesomeIcon id="pwdIcon" icon={["fas", "eye"]} />
              )}
            </span>
            {validated && (
              <Form.Control.Feedback type="invalid" className="d-block">
                {submittedFormData.password.length < 8 &&
                  "Inserirsci almeno 8 caratteri."}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          {/* <Form.Group className="d-flex flex-column w-100 mb-4">
            <Form.Control
              className="form-control form-control-custom form-control-gray form-control-padding-end"
              autoComplete="off"
              placeholder="Conferma password"
              required={true}
              name="verifyPassword"
              type={showVerifyPassword ? "text" : "password"}
              maxLength={255}
              onChange={handleChange}
              value={formData.verifyPassword}
            />
            <span
              className="showPasswordTrigger cursor-pointer dark-color"
              onClick={() => setShowVerifyPassword(!showVerifyPassword)}
            >
              {showVerifyPassword ? (
                <FontAwesomeIcon id="pwdIcon2" icon={["fas", "eye-slash"]} />
              ) : (
                <FontAwesomeIcon id="pwdIcon2" icon={["fas", "eye"]} />
              )}
            </span>
            {validated &&
              submittedFormData.password !=
                submittedFormData.verifyPassword && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  Le due password non coincidono.
                </Form.Control.Feedback>
              )}
          </Form.Group> */}
        </div>
        <div className="d-flex flex-column w-100 mb-3">
          <Form.Group className="d-flex align-items-start justify-content-start gap-1">
            <Form.Control
              className="form-check-input custom-check-lightgray my-1 me-1"
              autoComplete="off"
              placeholder="Accetta Privacy Policy"
              required={true}
              name="privacy"
              id="privacy"
              type="checkbox"
              onChange={handleCheckboxChange}
              checked={formData.privacy}
            />
            <Form.Label
              className="form-check-label font-14 lightgray-txt font-bolder my-1 ms-1 required"
              htmlFor="privacy"
            >
              Accetta Privacy Policy <span className="required">*</span>
            </Form.Label>
          </Form.Group>
        </div>
        <button
          type="submit"
          className={`btn btn-animation btn-action btn-form-orange btn-gradient-orange font-semi-bolder w-100 ${
            isInModal ? "mb-3" : "mb-4"
          }`}
          style={{ height: "50px" }}
        >
          Registrati
        </button>
        {searchParams.get("v") && (
          <button
            className="btn btn-outline-animation btn-outline-gradient-blue d-flex justify-content-center align-items-center font-14 w-100 my-3 p-3"
            onClick={() => dispatch(setGuestModal(true))}
            style={{ height: "50px" }}
          >
            Continua senza registrarti
            <FontAwesomeIcon
              icon={["fas", "arrow-right-long"]}
              className="align-middle thin-icon font-18 ms-2"
            />
          </button>
        )}
        <p className="dark-color font-14 m-0 mb-4 text-center">
          Hai già un account?{" "}
          {isInModal ? (
            <span
              className="simple-blue-link cursor-pointer"
              onClick={() => dispatch(setLoginModal(true))}
            >
              Accedi subito qui
            </span>
          ) : (
            <Link
              to="/it/areariservata/login"
              className="simple-blue-link cursor-pointer"
            >
              Accedi subito qui
            </Link>
          )}
        </p>
        {/* <Form.Control
          type="hidden"
          name="recaptcha_response"
          value={formData.recaptchaToken}
        /> */}
      </Form>
      {!isInModal && (
        <p className="font-14 lightgray-txt text-center my-3 mx-0 mx-lg-5">
          <label className="recaptchaTerms">
            Questo sito è protetto da reCAPTCHA e vengono applicate le{" "}
            <Link
              className="simple-blue-link orange-hover"
              target="_blank"
              to="https://policies.google.com/privacy"
            >
              Norme sulla Privacy
            </Link>{" "}
            e i{" "}
            <Link
              className="simple-blue-link orange-hover"
              target="_blank"
              to="https://policies.google.com/terms"
            >
              Termini di Servizio
            </Link>{" "}
            di Google.
          </label>
        </p>
      )}
      <p className="font-14 lightgray-txt text-center my-3 mx-0 mx-lg-5">
        Confermo di aver preso visione dell'{" "}
        <Link
          to="/it/informativa-privacy"
          className="simple-blue-link orange-hover"
          target="_blank"
        >
          informativa sulla privacy
        </Link>
        , della{" "}
        <Link
          to="/it/informativa-cookies"
          className="simple-blue-link orange-hover"
          target="_blank"
        >
          cookies policy
        </Link>{" "}
        e delle{" "}
        <Link
          to="/it/manleva"
          className="simple-blue-link orange-hover"
          target="_blank"
        >
          condizioni d’uso
        </Link>{" "}
        delle recensioni e acconsento all’invio della vostra newsletter.
      </p>
    </>
  )
}

import React from "react"
import spinnerImage from "../../assets/images/loader.webp"

interface LoaderProps {
  customContainerClass?: string
  customLoaderSize?: string
}

const Loader: React.FC<LoaderProps> = ({
  customContainerClass = "",
  customLoaderSize = "",
}) => {
  const loaderSize = customLoaderSize.length > 0 ? customLoaderSize : "200px"
  const containerClass =
    customContainerClass.length > 0
      ? customContainerClass
      : "d-flex w-100 vh-100 justify-content-center align-items-center"

  return (
    <div className={containerClass}>
      <div id="waitLoader" className="waitLoader">
        <img
          alt="loading-spinner"
          src={spinnerImage}
          className="img-fluid"
          style={{ width: loaderSize }}
        />
      </div>
    </div>
  )
}

export default Loader

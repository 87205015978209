import React, { useState } from "react"
import { Category } from "../../../models/base/category.interface"
import { Link } from "react-router-dom"

interface EShopCategoriesCardProps {
  categories: Category[]
}

export const EShopCategoriesCard: React.FC<EShopCategoriesCardProps> = ({
  categories,
}) => {
  const [showAll, setShowAll] = useState<boolean>(false)
  return (
    <div
      className={`card eshop-custom-card mb-0 mb-lg-2 me-2 me-lg-0 ${
        showAll ? "mobile-height-auto" : ""
      }`}
    >
      <div className="card-body d-flex flex-column px-3 py-4 p-lg-4">
        <h5 className="blue-card-title m-0 mb-3">Categorie</h5>
        <hr className="border border-dark m-0 mb-lg-3 d-none d-lg-block" />
        <div className="d-flex flex-row flex-wrap">
          {categories.map((category) => (
            <Link
              key={category.slug}
              to={`/it/categorie/${category.slug}`}
              className="btn categories-selection font-lighter font-14 dark-color mb-2 me-lg-2 text-decoration-none "
            >
              {category.name}
            </Link>
          ))}
        </div>
        {!showAll && categories.length > 2 && (
          <div className="d-lg-none text-center">
            <a className="simple-blue-link" onClick={() => setShowAll(true)}>
              Visualizza tutto
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

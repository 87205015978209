import spinnerImage from "../../assets/images/coda-bianca.webp"
import { useAppSelector } from "../../app/hooks"

export const LoadingSpinner: React.FC = () => {
  const isAnythingLoading = useAppSelector((state) => {
    const queries = [
      ...Object.values(state.baseApi.queries),
      ...Object.values(state.esaScraperApi.queries),
    ]

    const addLoader = [
      "getUserReviews",
      "getUserProductReviews",
      "getInvitationsOrders",
    ]
    const queriesWithLoader = queries.filter(
      (query) => query?.endpointName && addLoader.includes(query?.endpointName),
    )
    return queriesWithLoader.some((entry) => entry?.status == "pending")
  })

  if (!isAnythingLoading) {
    return <></>
  }

  return (
    <div id="waitOverlay" className={"waitOverlay"}>
      <img
        alt="coda-bianca"
        src={spinnerImage}
        className="img-fluid"
        style={{ width: "200px" }}
      />
    </div>
  )
}

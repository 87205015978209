import React from "react"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Popover from "react-bootstrap/Popover"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"

interface SocialShareProps {
  title: string
  url: string
  id: number
}

export const SocialShareOld: React.FC<SocialShareProps> = (props) => {
  const popover = (
    <Popover>
      <Popover.Body className="white-background">
        <ul className="social-share-new-menu">
          <li>
            <a
              rel="nofollow"
              className="icon-facebook-sign"
              title="share facebook"
              onClick={(e) => {
                window.open(
                  `http://www.facebook.com/sharer.php?u=${props.url}`,
                  "_blank",
                  "width=600,height=400",
                )
                return false
              }}
            >
              <span className="share-icon-container me-1">
                <FontAwesomeIcon icon={["fab", "facebook-f"]} />
              </span>
              Facebook
            </a>
          </li>

          <li>
            <a
              rel="nofollow"
              className="icon-twitter-sign"
              title="share twitter"
              onClick={(e) => {
                window.open(
                  `http://twitter.com/intent/tweet?url=${props.url}`,
                  "_blank",
                  "width=600,height=400",
                )
                return false
              }}
            >
              <span className="share-icon-container me-1">
                <FontAwesomeIcon icon={["fab", "twitter"]} />
              </span>
              Twitter
            </a>
          </li>

          <li>
            <a
              rel="nofollow"
              className="icon-linkedin-sign"
              title="share linkedin"
              onClick={(e) => {
                window.open(
                  `https://www.linkedin.com/sharing/share-offsite?mini=true&url=${props.url}`,
                  "_blank",
                  "width=600,height=400",
                )
                return false
              }}
            >
              <span className="share-icon-container me-1">
                <FontAwesomeIcon icon={["fab", "linkedin"]} />
              </span>
              Linkedin
            </a>
          </li>
        </ul>
      </Popover.Body>
    </Popover>
  )

  return (
    <div className="social-share-new">
      <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
        <a className="social-menu-trigger">
          <FontAwesomeIcon icon={["fas", "share-alt"]} className="me-1" />{" "}
          Condividi{" "}
        </a>
      </OverlayTrigger>
    </div>
  )
}

export default SocialShareOld

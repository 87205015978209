import { EsaScraperResponse } from "../../../models/esaScraper/esaScraperResponse.interface"
import { buildElasticQueryForReviews } from "../../../utils/elasticSearchUtils"
import { esaScraperApi } from "./esaScraperApi"
import { EsaScraperReview } from "../../../models/esaScraper/esaScraperReview.interface"
import { EsaScraperReviewsTag } from "../../../models/esaScraper/esaScraperReviewsTag.interface"
import { esaScraperReviewToReview } from "../../../utils/typesUtils"
import { ReviewUserUnion } from "../../../models/base/reviewUserUnion.interface"
import { EsaScraperReviewStats } from "../../../models/esaScraper/esaScraperReviewStats.interface"

export const reviewsApi = esaScraperApi.injectEndpoints({
  endpoints: (build) => ({
    // MAPPATO
    getSearchReviews: build.query<
      EsaScraperResponse<ReviewUserUnion>,
      {
        esaDomainId: number
        query_string?: string
        review_prod?: boolean
        filters: Record<
          string,
          string | number | boolean | string[] | number[] | undefined
        >
        searchAfter: number[] | undefined
      }
    >({
      query: ({
        esaDomainId,
        query_string,
        review_prod,
        filters,
        searchAfter,
      }) => {
        const body = buildElasticQueryForReviews(filters, searchAfter)
        return {
          url: `esasearch/reviews/search/${esaDomainId}/`,
          method: "POST",
          params: {
            query_string,
            review_prod,
          },
          body: body,
        }
      },
      providesTags: (result, error, args) => {
        if (!args.review_prod) {
          return [{ type: "Reviews" }]
        } else {
          return [{ type: "ProductReviews" }]
        }
      },
      transformResponse: (response: EsaScraperResponse<EsaScraperReview>) => ({
        ...response,
        hits: {
          ...response.hits,
          hits: response.hits.hits.map((hit) => ({
            ...hit,
            _source: esaScraperReviewToReview(hit._source),
          })),
        },
      }),
      serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
        return endpointName + queryArgs.review_prod
      },
      merge: (
        currentCache,
        newItems,
        { arg, baseQueryMeta, fulfilledTimeStamp, requestId },
      ) => {
        if (arg.searchAfter != undefined) {
          return {
            ...newItems,
            hits: {
              ...newItems.hits,
              hits: [...currentCache.hits.hits, ...newItems.hits.hits],
            },
          }
        } else {
          return newItems
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg
      },
    }),
    // MAPPATO
    getReviewsTags: build.query<
      EsaScraperReviewsTag,
      { esaDomainId: number; review_prod?: boolean }
    >({
      query: ({ esaDomainId, review_prod }) => {
        return {
          url: `esasearch/reviews/tags/${esaDomainId}/`,
          method: "GET",
          params: {
            review_prod,
          },
        }
      },
      providesTags: (result, error, esaDomainId) => [
        { type: "ReviewsTags", esaDomainId },
      ],
    }),
    // MAPPATO
    getReviewStats: build.query<EsaScraperReviewStats, number>({
      query: (esaDomainId) => {
        return {
          url: `esasearch/reviews/stats/${esaDomainId}/`,
          method: "GET",
        }
      },
      providesTags: (result, error, esaDomainId) => [
        { type: "ReviewStats", esaDomainId },
      ],
    }),
  }),
})

export const {
  useGetSearchReviewsQuery,
  useGetReviewsTagsQuery,
  useGetReviewStatsQuery,
} = reviewsApi

import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../../app/store"
import {
  SearchFilter,
  SearchFilterOption,
} from "../../../models/base/searchFilter.interface"
import { getObjectDifferences } from "../../../utils/objectsUtils"

const ratingFilter: SearchFilter = {
  title: "Valutazione maggiore di",
  type: "radio",
  options: [
    {
      name: "rating",
      label: "Valutazione maggiore di 0",
      value: "0",
    },
    {
      name: "rating",
      label: "Valutazione maggiore di 1",
      value: "1",
    },
    {
      name: "rating",
      label: "Valutazione maggiore di 2",
      value: "2",
    },
    {
      name: "rating",
      label: "Valutazione maggiore di 3",
      value: "3",
    },
    {
      name: "rating",
      label: "Valutazione maggiore di 4",
      value: "4",
    },
  ],
}
const minNumberOfReviews: SearchFilter = {
  title: "eCommerce con almeno",
  type: "radio",
  options: [
    {
      name: "minNumberOfReviews",
      label: "Tutti",
      value: "0",
    },
    {
      name: "minNumberOfReviews",
      label: "5 recensioni",
      value: "5",
    },
    {
      name: "minNumberOfReviews",
      label: "10 recensioni",
      value: "10",
    },
  ],
}
const minNumberOfLatestReviews: SearchFilter = {
  title: "Recensioni recenti",
  type: "radio",
  options: [
    {
      name: "minNumberOfLatestReviews",
      label: "Tutti",
      value: "0",
    },
    {
      name: "minNumberOfLatestReviews",
      label: "Almeno 5 recensioni",
      value: "5",
    },
    {
      name: "minNumberOfLatestReviews",
      label: "Almeno 10 recensioni",
      value: "10",
    },
  ],
}
const onlyVerifiedReviews: SearchFilter = {
  type: "checkbox",
  options: [
    {
      name: "onlyVerifiedReviews",
      label: "Solo recensioni verificate",
      value: false,
    },
  ],
}
const searchType: SearchFilter = {
  title: "Ricerca",
  type: "radio",
  options: [
    {
      name: "searchType",
      label: "eCommerce / Servizi",
      value: "0",
    },
    {
      name: "searchType",
      label: "Servizi",
      value: "1",
    },
    {
      name: "searchType",
      label: "eCommerce",
      value: "2",
    },
  ],
}
const brands: SearchFilter = {
  title: "Brand",
  type: "checkbox",
  options: [
    {
      name: "brands",
      label: "Filtro per brand",
      value: [],
    },
  ],
}
const shops: SearchFilter = {
  title: "eCommerce",
  type: "checkbox",
  options: [
    {
      name: "shops",
      label: "Filtro per eCommerce",
      value: [],
    },
  ],
}
const includeAdultProducts: SearchFilter = {
  type: "checkbox",
  options: [
    {
      name: "includeAdultProducts",
      label: "Includi prodotti per adulti",
      value: false,
    },
  ],
}
const onlySuggestedProducts: SearchFilter = {
  type: "checkbox",
  options: [
    {
      name: "onlySuggestedProducts",
      label: "Visualizza solo prodotti consigliati",
      value: false,
    },
  ],
}

const orderByDefault: SearchFilterOption = {
  name: "orderBy",
  label: "Ordina per",
  value: "",
}
const orderByPriceDesc: SearchFilterOption = {
  name: "orderBy",
  label: "Prezzo più alto",
  value: "price-desc",
}
const orderByPriceAsc: SearchFilterOption = {
  name: "orderBy",
  label: "Prezzo più basso",
  value: "price-asc",
}
const orderByScoreDesc: SearchFilterOption = {
  name: "orderBy",
  label: "Valutazione più alta",
  value: "score-desc",
}
const orderByScoreAsc: SearchFilterOption = {
  name: "orderBy",
  label: "Valutazione più bassa",
  value: "score-asc",
}
const orderByDomainAsc: SearchFilterOption = {
  name: "orderBy",
  label: "Ordine alfabetico [a-z]",
  value: "domain-asc",
}
const orderByDomainDesc: SearchFilterOption = {
  name: "orderBy",
  label: "Ordine alfabetico [z-a]",
  value: "domain-desc",
}
const orderByReviews30Desc: SearchFilterOption = {
  name: "orderBy",
  label: "Recensioni più recenti",
  value: "reviews_30-desc",
}

export interface SearchFiltersState {
  filters: Record<string, SearchFilterOption>
  orderBy: SearchFilterOption
  searchAfter?: number[]
  isSidebarOpen: boolean
}

const initialState: SearchFiltersState = {
  filters: {
    rating: ratingFilter.options[0],
    minNumberOfReviews: minNumberOfReviews.options[0],
    minNumberOfLatestReviews: minNumberOfLatestReviews.options[0],
    onlyVerifiedReviews: onlyVerifiedReviews.options[0],
    searchType: searchType.options[0],
    brands: brands.options[0],
    shops: shops.options[0],
    includeAdultProducts: includeAdultProducts.options[0],
    onlySuggestedProducts: onlySuggestedProducts.options[0],
  },
  orderBy: orderByDefault,
  searchAfter: undefined,
  isSidebarOpen: false,
}

export const searchFiltersSlice = createSlice({
  name: "searchFilters",
  initialState,
  reducers: {
    setFilters: (
      state,
      action: PayloadAction<Record<string, SearchFilterOption>>,
    ) => {
      state.filters = action.payload
      state.isSidebarOpen = false
      state.searchAfter = undefined
    },
    removeFilter: (state, action: PayloadAction<SearchFilterOption>) => {
      const { name } = action.payload
      if (name == "orderBy") {
        state.orderBy = initialState.orderBy
      } else {
        state.filters = { ...state.filters, [name]: initialState.filters[name] }
      }
      state.searchAfter = undefined
    },
    setOrderBy: (state, action: PayloadAction<SearchFilterOption>) => {
      state.orderBy = action.payload
      state.searchAfter = undefined
    },
    resetFilters: (state) => {
      return initialState
    },
    setIsSidebarOpen: (state, action: PayloadAction<boolean>) => {
      state.isSidebarOpen = action.payload
    },
    setSearchAfter: (state, action: PayloadAction<number[]>) => {
      state.searchAfter = action.payload
    },
  },
})

export const {
  setFilters,
  setOrderBy,
  removeFilter,
  setIsSidebarOpen,
  resetFilters,
  setSearchAfter,
} = searchFiltersSlice.actions

export const selectFilters = (state: RootState) => state.searchFilters.filters

export const selectOrderBy = (state: RootState) => state.searchFilters.orderBy

export const selectIsSidebarOpen = (state: RootState) =>
  state.searchFilters.isSidebarOpen

export const selectSearchAfter = (state: RootState) =>
  state.searchFilters.searchAfter

export const selectActiveFilters = createSelector(
  (state: RootState) => state.searchFilters.filters,
  (filters: Record<string, SearchFilterOption>) =>
    getObjectDifferences(initialState.filters, filters),
)

export const selectSearchDefaultFilters = createSelector(
  (state: RootState) => state.searchFilters.filters,
  (filters: Record<string, SearchFilterOption>) => [
    ratingFilter,
    minNumberOfReviews,
    minNumberOfLatestReviews,
    onlyVerifiedReviews,
  ],
)

export const selectSearchDefaultOrders = createSelector(
  (state: RootState) => state.searchFilters.filters,
  (filters: Record<string, SearchFilterOption>) => [
    orderByDefault,
    orderByScoreAsc,
    orderByScoreDesc,
    orderByDomainAsc,
    orderByDomainDesc,
    orderByReviews30Desc,
  ],
)

export const selectSearchProductsDefaultFilters = createSelector(
  (state: RootState) => state.searchFilters.filters,
  (filters: Record<string, SearchFilterOption>) => [
    brands,
    shops,
    includeAdultProducts,
    onlySuggestedProducts,
    ratingFilter,
    minNumberOfReviews,
    minNumberOfLatestReviews,
    onlyVerifiedReviews,
  ],
)

export const selectSearchProductsDefaultOrders = createSelector(
  (state: RootState) => state.searchFilters.filters,
  (filters: Record<string, SearchFilterOption>) => [
    orderByDefault,
    orderByPriceAsc,
    orderByPriceDesc,
    orderByScoreAsc,
    orderByScoreDesc,
  ],
)

export const selectSearchShopsDefaultFilters = createSelector(
  (state: RootState) => state.searchFilters.filters,
  (filters: Record<string, SearchFilterOption>) => [
    // searchType,
    ratingFilter,
    minNumberOfReviews,
    minNumberOfLatestReviews,
    onlyVerifiedReviews,
  ],
)

export default searchFiltersSlice.reducer

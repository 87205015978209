import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ChangeEvent, useState } from "react"
import { FilterOptionsGroup } from "./FilterOptionsGroup"
import {
  SearchFilter,
  SearchFilterOption,
} from "../../../models/base/searchFilter.interface"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import {
  resetFilters,
  selectActiveFilters,
  selectFilters,
  setFilters,
  setIsSidebarOpen,
} from "./searchFiltersSlice"

interface SearchFiltersSidebarProps {
  filtersToRender: SearchFilter[]
}

export const SearchFiltersSidebar: React.FC<SearchFiltersSidebarProps> = ({
  filtersToRender,
}) => {
  const dispatch = useAppDispatch()
  const filters = useAppSelector(selectFilters)
  const [tempSelectedFilters, setTempSelectedFilters] =
    useState<Record<string, SearchFilterOption>>(filters)

  function handleFilterChange(e: ChangeEvent<HTMLInputElement>) {
    const { name, type, ariaLabel, value } = e.currentTarget
    if (name == "brands" || name == "shops") {
      const prevKeys = tempSelectedFilters[name].value as string[]
      const isSelected = prevKeys.some((key) => key == value)

      const updatedOptions = isSelected
        ? prevKeys.filter((prevKey) => prevKey != value)
        : [...prevKeys, value]

      setTempSelectedFilters({
        ...tempSelectedFilters,
        [name]: { ...tempSelectedFilters[name], value: updatedOptions },
      })
    } else if (type == "checkbox") {
      const prev = tempSelectedFilters[name] as SearchFilterOption | undefined
      const updatedValue = prev ? !prev.value : true

      setTempSelectedFilters({
        ...tempSelectedFilters,
        [name]: {
          name,
          label: ariaLabel!,
          value: updatedValue,
        },
      })
    } else if (type == "radio") {
      setTempSelectedFilters({
        ...tempSelectedFilters,
        [name]: {
          name,
          label: ariaLabel!,
          value: value,
        },
      })
    }
  }

  const activeFilters = useAppSelector(selectActiveFilters) as Record<
    string,
    SearchFilterOption
  >[]

  const formChanges = Object.keys(filters)
    .filter(
      (key) =>
        JSON.stringify(filters[key]) !==
        JSON.stringify(tempSelectedFilters[key]),
    )
    .map((key) => tempSelectedFilters[key])

  return (
    <div className="offcanvas show" id="eshopFilters">
      <form className="w-100">
        <header className="offcanvas-canvas-header">
          <div className="row align-items-center">
            <div className="col-10">
              <h4 className="d-inline-block mb-0 font-bolder font-23">
                Filtri
              </h4>
            </div>
            <div className="col-2">
              <button
                id="closeTrigger"
                type="button"
                className="btn close"
                aria-label="Close"
                onClick={() => dispatch(setIsSidebarOpen(false))}
              >
                <span aria-hidden="true" className="font-23">
                  <FontAwesomeIcon icon={["fas", "xmark"]} />
                </span>
              </button>
            </div>
          </div>
        </header>

        <div className="offcanvas-canvas-content">
          {filtersToRender.map((filter, index) => {
            if (filter.options.length > 0) {
              return (
                <FilterOptionsGroup
                  key={index}
                  filter={filter}
                  tempSelectedFilters={tempSelectedFilters}
                  handleFilterChange={handleFilterChange}
                />
              )
            } else {
              return <></>
            }
          })}
        </div>

        <div
          className="row justify-content-center align-items-center background-white p-3 box-shadow-top"
          style={{ position: "sticky", bottom: "0" }}
        >
          <div className="col-6 text-center">
            <button
              type="button"
              className={`btn btn btn-outline-gray background-white w-100 height-50 d-flex justify-content-center align-items-center ${
                activeFilters.length > 0 ? "" : "disabled"
              }`}
              onClick={() => dispatch(resetFilters())}
            >
              Resetta filtri
            </button>
          </div>
          <div className="col-6 text-center">
            <button
              type="button"
              className={`btn btn-gradient-orange w-100 height-50 d-flex justify-content-center align-items-center ${
                formChanges.length > 0 ? "" : "disabled"
              }`}
              onClick={() => dispatch(setFilters(tempSelectedFilters))}
            >
              Applica filtri
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

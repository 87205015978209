import React from "react"
import OwlCarousel from "react-owl-carousel"
import { OwlCoupon } from "./OwlCoupon"
import { HomeCoupon } from "../../../../models/base/homeCoupon.interface"

interface OwlCouponsProps {
  coupons: HomeCoupon[]
}

export const OwlCoupons: React.FC<OwlCouponsProps> = ({ coupons }) => {
  return (
    <OwlCarousel
      // id="#couponhighlightsMobile-carousel"
      className="owlTheme px-3"
      dots={false}
      loop={true}
      dotsEach={3}
      autoplay={true}
      autoplayTimeout={4500}
      autoplayHoverPause={true}
      autoplaySpeed={650}
      nav={false}
      responsive={{
        0: {
          items: 1,
        },
        576: {
          items: 1,
        },
        768: {
          items: 1,
        },
        992: {
          items: 1,
        },
        1200: {
          items: 1,
        },
      }}
    >
      {coupons.map((c, i) => (
        <OwlCoupon key={i} coupon={c} />
      ))}
    </OwlCarousel>
  )
}

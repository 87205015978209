import React, { useRef, useEffect } from "react"
import "@fancyapps/ui/dist/fancybox/fancybox.css"

export const Fancybox: React.FC<any> = (props) => {
  const containerRef = useRef(null)

  useEffect(() => {
    let NativeFancybox: any;

    const loadFancybox = async () => {
      const module = await import("@fancyapps/ui")
      NativeFancybox = module.Fancybox

      const container = containerRef.current
      const delegate = props.delegate || "[data-fancybox]"
      const options = props.options || {}

      NativeFancybox.bind(container, delegate, options)
    }

    loadFancybox()

    return () => {
      if (NativeFancybox) {
        NativeFancybox.unbind(containerRef.current)
        NativeFancybox.close()
      }
    }
  }, [props.delegate, props.options])

  return <div ref={containerRef}>{props.children}</div>
}

import React, { useEffect, useState } from "react"
import mobileMenuCss from "../../assets/css/old/mobile-menu.css?inline"
import { useAppSelector } from "../../app/hooks"
import { selectCurrentUser } from "../auth/authSlice"
import { getUserAvatar } from "../../utils/userUtils"
import avatarPlaceholder from "../../assets/images/avatar_placeh.png"
import { useGetProfileQuery } from "../../app/services/base/users"
import { skipToken } from "@reduxjs/toolkit/query"
import { tryToParse } from "../../utils/objectsUtils"
import { UserProfileStructuredData } from "../../models/base/userProfile.interface"
import { Link, useLocation } from "react-router-dom"
import menuItems from "../../config/reservedMenuConfig.json"
import { MobileMenuItem } from "./MobileMenuItem"

export const MobileMenu: React.FC = () => {
  const user = useAppSelector(selectCurrentUser)

  const { data: profile } = useGetProfileQuery(user ? undefined : skipToken)

  const [structuredData, setStructuredData] =
    useState<UserProfileStructuredData>()

  useEffect(() => {
    const parseData = async () => {
      if (profile && profile.structuredData) {
        const parsedData = await tryToParse(profile.structuredData)
        setStructuredData(parsedData)
      }
    }

    if (profile) {
      parseData()
    }
  }, [profile])

  return (
    <div className="mobile-header-overlay open-menu">
      <div className="outer-menu"></div>
      {profile ? (
        <div className="mobile-header-menu">
          <div className="user-area">
            <div className="header-user-area d-flex px-0 justify-content-left align-items-center">
              <div className="avatar">
                <img
                  src={getUserAvatar(profile.id, true)}
                  alt={structuredData?.username}
                  onError={(e) => (e.currentTarget.src = avatarPlaceholder)}
                />
              </div>
              <div className="user-name font-23 bolder-dark-title">
                {structuredData?.username}.<br />
                <span style={{ fontSize: "15px", fontWeight: "normal" }}>
                  {structuredData?.citta && (
                    <>
                      {structuredData.citta}
                      {structuredData.nazione && (
                        <>, {structuredData.nazione}</>
                      )}
                    </>
                  )}
                </span>
              </div>
              <div style={{ clear: "both" }}></div>
            </div>
            <div className="links-user-area">
              <ul>
                {menuItems.items.map((menuItem, index) => (
                  <MobileMenuItem index={index} menuItem={menuItem} />
                ))}
              </ul>
            </div>
          </div>
          <div className="link-list">
            <ul>
              <li>
                <Link to="/it/categorie" className="cat-menu-trigger">
                  Cerca shop per categoria
                </Link>
              </li>
              <li>
                <Link to="https://eshoppingadvisor.com/blog">
                  Guide e consigli dal nostro blog
                </Link>
              </li>
              <li>
                <Link
                  to="/it/scrivi-recensione"
                  className="btn btn-gradient-orange px-3 w-100"
                >
                  Scrivi recensione
                </Link>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <div className="mobile-header-menu">
          <div className="link-list link-list-border">
            <ul>
              <li>
                <Link to="/it/areariservata/user-login">
                  Accedi o Registrati
                </Link>
              </li>
              {/* <li>
                <Link to="/it/registrazione/cliente">Registrati</Link>
              </li> */}
              <li>
                <Link
                  to="/it/scrivi-recensione"
                  className="btn btn-gradient-orange px-3"
                >
                  Scrivi recensione
                </Link>
              </li>
            </ul>
          </div>
          <div className="link-list">
            <ul>
              <li>
                <Link to="il-progetto">Progetto</Link>
              </li>
              <li>
                <Link to="/it/categorie">Categorie</Link>
              </li>
              <li>
                <Link to="https://eshoppingadvisor.com/blog">
                  Guide e consigli dal nostro blog
                </Link>
              </li>
              <li>
                <Link to="https://business.eshoppingadvisor.com/blog/">
                  Strumenti e consigli per eCommerce
                </Link>
              </li>
              <li>
                <Link
                  to="https://business.eshoppingadvisor.com/"
                  className="btn btn-gradient-blue"
                  target="_blank"
                >
                  Accesso Aziende
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}
      <style>{mobileMenuCss}</style>
    </div>
  )
}

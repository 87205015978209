import { CouponWithShop } from "../../../models/base/couponWithShop.interface"
import { CouponsCategoryList } from "../../../models/base/couponsCategoryList.interface"
import { HomeCoupon } from "../../../models/base/homeCoupon.interface"
import { CouponsUserHistoryResponse } from "../../../models/base/couponsUserHistoryResponse.interface"
import { ListResponse } from "../../../models/base/listResponse.interface"
import { SuggestedCoupon } from "../../../models/base/suggestedCoupon.interface"
import { baseApi } from "./baseApi"
import { Coupon } from "../../../models/base/coupon.interface"

export const couponsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    // MAPPATO
    getCoupon: build.query<CouponWithShop, string>({
      query: (couponId) => {
        return {
          url: `coupons/frontend/detail/${couponId}`,
        }
      },
      providesTags: (result, error, couponId) => [
        { type: "Coupons", couponId },
      ],
    }),
    // MAPPATO
    getCoupons: build.query<
      ListResponse<CouponWithShop>,
      {
        eshop_id?: number
        category?: string
        domain?: string
        expand?: string
        gift?: 0 | 1
        page: number
        perPage: number
      }
    >({
      query: ({
        eshop_id,
        category,
        domain,
        expand,
        gift,
        page = 1,
        perPage = 10,
      }) => {
        return {
          url: "coupons/frontend",
          params: {
            eshop_id,
            category,
            domain,
            expand,
            gift,
            page,
            "per-page": perPage,
          },
        }
      },
      providesTags: (result, error, page) =>
        result
          ? [
              ...result.items.map(({ coupon: { id } }) => ({
                type: "Coupons" as const,
                id,
              })),
              { type: "Coupons", id: "LIST" },
            ]
          : [{ type: "Coupons", id: "LIST" }],
    }),
    // MAPPATO
    getCouponsUserHistory: build.query<
      CouponsUserHistoryResponse,
      { userId: number; since?: string; until?: string }
    >({
      query: ({ userId, since, until }) => {
        return {
          url: `coupons/frontend/user-history/${userId}`,
          params: { since, until },
        }
      },
      providesTags: (result, error, page) =>
        result
          ? [
              ...result.purchases.map(({ domain, purchase_date }) => ({
                type: "CouponsUserHistory" as const,
                id: domain + purchase_date,
              })),
              { type: "CouponsUserHistory", id: "LIST" },
            ]
          : [{ type: "CouponsUserHistory", id: "LIST" }],
    }),
    // MAPPATO
    getCouponsCategoryList: build.query<CouponsCategoryList, void>({
      query: () => {
        return {
          url: "coupons/frontend/category-list",
        }
      },
      providesTags: (result, error, page) =>
        result
          ? [
              ...Object.keys(result.items).map((key) => ({
                type: "Coupons" as const,
                id: key,
              })),
              { type: "Coupons", id: "LIST" },
            ]
          : [{ type: "Coupons", id: "LIST" }],
    }),
    // MAPPATO
    getHomeCoupons: build.query<HomeCoupon[], void>({
      query: () => {
        return {
          url: "coupons/frontend/home-carousel",
        }
      },
      providesTags: (result = []) => [
        ...result.map(
          ({ coupon_id }) => ({ type: "Coupons", coupon_id } as const),
        ),
        { type: "Coupons" as const, id: "LIST" },
      ],
    }),
    // MAPPATO
    getSuggestedCoupons: build.query<SuggestedCoupon[], string>({
      query: (searchTerm) => {
        return {
          url: `coupons/frontend/suggester/${searchTerm}`,
        }
      },
      providesTags: (result = []) => [
        ...result.map(({ id }) => ({ type: "Coupons", id } as const)),
        { type: "Coupons" as const, id: "LIST" },
      ],
    }),
    // MAPPATO
    unlockCoupon: build.mutation<boolean, { userId: number; couponId: number }>(
      {
        query: ({ userId, couponId }) => {
          return {
            url: `coupons/frontend/unlock-coupon/${userId}/${couponId}`,
            method: "GET",
          }
        },
        transformResponse: (response: any, meta, arg) => {
          return response.code ? true : false
        },
        invalidatesTags: [{ type: "CouponsUserHistory" }],
      },
    ),
  }),
})

export const {
  useGetCouponQuery,
  useGetCouponsQuery,
  useGetCouponsUserHistoryQuery,
  useGetHomeCouponsQuery,
  useGetSuggestedCouponsQuery,
  useGetCouponsCategoryListQuery,
  useUnlockCouponMutation,
} = couponsApi

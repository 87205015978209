import React from "react"
import { Helmet } from "react-helmet"
import { Shop } from "../../models/base/shop.interface"
import defaultThumb from "../../assets/images/thumb_placeholder.png"
import { capitalizeFirstLetter } from "../../utils/stringUtils"
import { getShopThumb } from "../../utils/shopUtils"
import { ReviewUserUnion } from "../../models/base/reviewUserUnion.interface"

interface LocalBusinessSchemaProps {
  shop: Shop
  reviews: ReviewUserUnion[]
  hideReviews: boolean
  hideDataset: boolean
}

export const LocalBusinessSchema: React.FC<LocalBusinessSchemaProps> = ({
  shop,
  reviews,
  hideReviews,
  hideDataset,
}) => {
  let arrayReviews = [] as any

  reviews.forEach((reviewWithUser) => {
    let userUrl = `${import.meta.env.BASE_URL}utenti/${
      reviewWithUser.review.user_id
    }/${reviewWithUser.user.name}`
    let author

    debugger
    if (reviewWithUser.user) {
      if (reviewWithUser.user.name != "" || reviewWithUser.user.surname != "") {
        author = [
          {
            "@type": "Person",
            name: `${
              reviewWithUser.user.name + " " + reviewWithUser.user.surname
            }`,
            url: "$userUrl",
          },
        ]
      }
    } else {
      author = [
        {
          "@type": "Organization",
          name: "eShoppingAdvisor",
          url: `${import.meta.env.BASE_URL}eshop/${shop.domain}`,
        },
      ]
    }

    arrayReviews.push({
      "@type": "Review",
      itemReviewed: {
        "@type": "Thing",
        name: shop.eshopData.showcaseData.eshop_name
          ? shop.eshopData.showcaseData.eshop_name
          : shop.domain,
      },
      author: author,
      datePublished: reviewWithUser.review.insert_date,
      headline: reviewWithUser.review.title,
      reviewBody: reviewWithUser.review.description,
      reviewRating: {
        "@type": "Rating",
        bestRating: "5",
        worstRating: "0",
        ratingValue: reviewWithUser.review.overall_rating,
      },
      publisher: {
        "@type": "Organization",
        name: "eShoppingAdvisor",
        sameAs: "https://www.eshoppingadvisor.com",
      },
      inLanguage: "it",
    })
  })

  let arrayReviewsJson = JSON.stringify(arrayReviews)

  return (
    <Helmet>
      {/* <meta name="robots" content="max-snippet: -1, max-image-preview: large" /> */}
      <script type="application/ld+json">
        {`[
            {
                "@context": "http://schema.org/",
                "@type": "LocalBusiness",
                "@id": "${import.meta.env.BASE_URL + `eshop/${shop.domain}`}",
                "url": "${shop.eshopData.url}",
                "name": "${
                  shop.eshopData.showcaseData.eshop_name
                    ? shop.eshopData.showcaseData.eshop_name
                    : shop.domain
                }",
                "telephone": "${shop.eshopData.showcaseData.phone}",
                "image": "${
                  getShopThumb(shop.id) ? getShopThumb(shop.id) : defaultThumb
                }",
                ${
                  shop.rating && shop.rating > 0 && reviews.length > 0
                    ? `
                    "aggregateRating" : {
                        "@type": "AggregateRating",
                        "bestRating": "5",
                        "worstRating": "0",
                        "ratingValue": "${shop.rating ? shop.rating : 0}",
                        "reviewCount": "${shop.nRecensioniEsa}"
                    },
                `
                    : ""
                }
                "address" : {
                    "@type": "PostalAddress",
                    "streetAddress": "${
                      shop.eshopData.registration_address?.address
                        ?.replace("\\", "")
                        .replace(/"/g, '\\"') || ""
                    }",
                    "addressLocality": "${
                      shop.eshopData.registration_address?.city
                        ? capitalizeFirstLetter(
                            shop.eshopData.registration_address?.city.toLowerCase(),
                          )
                        : ""
                    }",
                    "addressCountry": "${
                      shop.eshopData.registration_address?.nation
                    }"
                } 
                ${
                  reviews.length > 0 && !hideReviews
                    ? `
                ,"review" : ${arrayReviewsJson}
                `
                    : ""
                }
            }${!hideDataset ? "," : ""}
            ${
              !hideDataset
                ? `
            {
                "@context": "http://schema.org",
                "@type": "Dataset",
                "name": "${shop.domain}",
                "description": "eShoppingAdvisor - Valutazione su ecommerce: ${
                  shop.domain
                }",
                "keywords": "recensioni ${shop.domain} ${
                    shop.domain.split(".")[0]
                  }",
                "publisher": {
                    "@type": "Organization",
                    "name": "eShoppingAdvisor",
                    "sameAs": "https://www.eshoppingadvisor.com"
                }
            }
            `
                : ""
            }
        ]`}
      </script>
    </Helmet>
  )
}

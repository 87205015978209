import { Review } from "../models/base/review.interface"

export const getReviewImageUrl = (
  reviewImageName: string,
  review_id: number,
  isProduct: boolean | number
) => {
  return  import.meta.env.VITE_CDN_BASE_URL + `/${(isProduct ? 'productReviewsGallery' : 'reviewsGallery')}/${review_id}/${reviewImageName}`
}

export const isReportable = (review: Review, loggedUserId?: number) => {
  let userReport = review.reports
    ? review.reports?.find((report) => report.consumer_id == loggedUserId)
    : undefined

  if (!userReport && review.certified == 0 && !review.source) {
    return true
  }

  return false
}

export const noSpamCharFunction = (e: any) => {
  const target = e.currentTarget as HTMLInputElement
  const str = target.value

  const res = str.charAt(str.length - 1)
  const res2 = str.substr(str.length - 2)
  const res3 = str.substr(str.length - 3)
  const doubleInput = e.key + e.key

  if (e.charCode === 32) {
    if (res === " ") {
      e.preventDefault()
    }
  } else if (e.charCode === 46 && (res3 === "..." || res === " ")) {
    e.preventDefault()
  } else if (e.charCode !== 46 && /^[a-zA-Z0-9]$/.test(e.key) === false) {
    if (res3 === "..." || res === "." || e.key === res) {
      e.preventDefault()
    }
  } else if (/^[a-zA-Z]$/.test(e.key) === true) {
    if (/^[aeiou]$/.test(e.key) === true) {
      if (res === e.key) {
        e.preventDefault()
      }
    } else {
      if (res2 === doubleInput) {
        e.preventDefault()
      }
    }
  }
}

import { Link } from "react-router-dom"
import "../../assets/css/old/mobile-menu.css"
import { useState } from "react"
import { useAppSelector } from "../../app/hooks"
import { selectCurrentUser } from "../auth/authSlice"
import menuItems from "../../config/reservedMenuConfig.json"
import { DropdownMenuHeaderItem } from "./DropdownMenuHeaderItem"
import avatarPlaceholder from "../../assets/images/avatar_placeh.png"
import { getUserAvatar } from "../../utils/userUtils"

export const DropdownMenuHeader: React.FC = () => {
  const [isHover, setIsHover] = useState<boolean>(false)
  const user = useAppSelector(selectCurrentUser)

  return (
    <ul
      className="user-actions mx-3 my-0 d-flex justify-content-center align-items-center"
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <li className="dropdown-link mu">
        <span className="avatar">
          <img
            src={user ? getUserAvatar(user.id, true) : avatarPlaceholder}
            alt="avatar-utente"
            className="img-fluid rounded-circle"
            loading="lazy"
            onError={(e) => (e.currentTarget.src = avatarPlaceholder)}
          />
        </span>
        <Link
          to="#"
          className="simple-white-link btn btn-simple-link user-name-link notWait"
        >
          Ehilà {user?.firstname}.
        </Link>
        <ul
          className="dropdown-link-list"
          style={isHover ? { display: "block" } : { display: "none" }}
        >
          {menuItems.items.map((mi, i) => (
            <DropdownMenuHeaderItem key={i} index={i} menuItem={mi} />
          ))}
        </ul>
      </li>
    </ul>
  )
}

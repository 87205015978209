import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectCouponModal, setCouponModal } from "../modals/modalsSlice"
import { selectCurrentUser } from "../auth/authSlice"
import { CouponWithShop } from "../../models/base/couponWithShop.interface"
import { addToast } from "../toasts/toastsSlice"
import { useUnlockCouponMutation } from "../../app/services/base/coupons"
import { Coupon } from "../../models/base/coupon.interface"
import { CouponModal } from "../modals/CouponModal"
import ticketBackground from "../../assets/images/ticket-background.png"

interface CouponBandProps {
  couponWithShop: CouponWithShop
  isUnlocked: boolean
}

export const CouponBand: React.FC<CouponBandProps> = ({
  couponWithShop,
  isUnlocked,
}) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectCurrentUser)
  const couponModal = useAppSelector(selectCouponModal)
  const [isCopied, setIsCopied] = useState<boolean>(false)
  const [unlockCoupon, { error }] = useUnlockCouponMutation()

  async function handleUnlockCoupon(coupon: Coupon) {
    try {
      const response = user
        ? await unlockCoupon({
            userId: user.id,
            couponId: coupon.id,
          }).unwrap()
        : false

      if (response) {
        dispatch(
          addToast({
            message: "Coupon sbloccato!",
            type: "success",
          }),
        )
      } else {
        dispatch(
          addToast({
            message: "Ops! Non hai abbastanza punti per sbloccare il coupon.",
            type: "danger",
          }),
        )
      }
    } catch (err) {
      dispatch(
        addToast({
          message: "Ops! Qualcosa è andato storto.",
          type: "danger",
        }),
      )
    }
  }
  return (
    <>
      <div className="background-gradient-orange-reverse">
        <div
          className="ticket-background mobile-no-background"
          style={{
            backgroundPositionX: "calc(70%)",
            backgroundImage: `url(${ticketBackground})`,
          }}
        >
          <div className="container py-3">
            <div className="row align-items-center">
              <div className="col-12 col-xl-5 my-2 text-center text-xl-start">
                <span className="font-33 white-color font-bolder d-block mb-2">
                  Utilizza Coupon{" "}
                  <div className="d-inline-block">
                    {couponWithShop.coupon.discount.endsWith(".00")
                      ? couponWithShop.coupon.discount.substring(
                          0,
                          couponWithShop.coupon.discount.indexOf("."),
                        )
                      : couponWithShop.coupon.discount}
                    {couponWithShop.coupon.discount_type === 1 ? "%" : " €"}
                  </div>
                </span>
                <span className="font-16 white-color font-semi-bold d-block">
                  Puoi usufruire di un coupon per questo prodotto, sbloccalo
                  adesso ed utilizzalo in fase di checkout.
                </span>
              </div>
              <div className="col-12 col-xl-7 my-2 text-start text-xl-end">
                <div
                  className={`c-coupon-codice btn btn-outline-orange background-white px-3 width-400 ${
                    isUnlocked ? "" : "d-none"
                  }`}
                >
                  <span className="c-val-detail float-start">
                    {isUnlocked ? couponWithShop.coupon.code : ""}
                  </span>
                  <span
                    className="c-label-detail cursor-pointer copy-trigger float-end font-light font-14"
                    onClick={async () => {
                      try {
                        await navigator.clipboard.writeText(
                          couponWithShop?.coupon.code,
                        )
                        setIsCopied(true)
                        dispatch(
                          addToast({
                            message: "Testo copiato!",
                            type: "success",
                          }),
                        )
                      } catch (err) {
                        dispatch(
                          addToast({
                            message: "Ops! Impossibile copiare il testo.",
                            type: "danger",
                          }),
                        )
                      }
                    }}
                  >
                    Copia
                    <FontAwesomeIcon
                      icon={["fas", "copy"]}
                      className="me-2 ms-2"
                    />
                  </span>
                  {isCopied && (
                    <span className="gray-text font-14 text-center">
                      <FontAwesomeIcon icon={["fas", "check"]} /> Copiato
                    </span>
                  )}
                </div>
                {!isUnlocked && (
                  <span
                    className="btn btn-outline-orange background-white px-3 width-400"
                    onClick={() => {
                      if (!user) {
                        dispatch(setCouponModal(couponWithShop))
                      } else {
                        handleUnlockCoupon(couponWithShop.coupon)
                      }
                    }}
                  >
                    Sblocca coupon
                  </span>
                )}

                <div className="d-block">
                  <a
                    className="d-inline-block text-center coupon-modal-button simple-blue-link font-12 m-0 width-400 dark-color font-bolder cursor-pointer"
                    onClick={() => dispatch(setCouponModal(couponWithShop))}
                  >
                    Condizioni e modalità di utilizzo
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {couponModal && <CouponModal />}
    </>
  )
}

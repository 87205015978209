import React from "react"
import { Link } from "react-router-dom"
import { Shop } from "../../../models/base/shop.interface"

interface EShopDisclaimerProps {
  isMobile: boolean | 0 | undefined
  shop: Shop
  isReviewDetails: boolean
}

export const EShopDisclaimer: React.FC<EShopDisclaimerProps> = ({
  isMobile,
  shop,
  isReviewDetails,
}) => {
  return (
    <div
      className={`${
        isMobile
          ? "disclaimer d-flex flex-column justify-content-between p-2 p-lg-0 mb-4 mb-lg-0 d-lg-none"
          : "card eshop-custom-card disclaimer d-none d-lg-block mb-3"
      }`}
    >
      {isMobile && (
        <h6 className="simple-orange-txt font-semi-bolder font-16 mb-lg-2">
          Come funziona eShoppingAdvisor?
        </h6>
      )}
      <p className="font-lighter font-14 dark-color mb-lg-3">
        Con il nostro portale aiutiamo chi, come te, effettua i suoi acquisti
        online, a scrivere recensioni di qualità e a trovare e-commerce sempre
        più sicuri, efficienti ed affidabili.
        <br /> La tua valutazione contribuirà alla formazione del rating medio
        del negozio online e quindi alla sua reputazione così da orientare
        meglio gli acquisti di altri utenti come te.
        {isReviewDetails && (
          <>
            <br />
            <br />
          </>
        )}
        {isReviewDetails && (
          <>
            Il tuo contributo è prezioso per il web!
            <br />
            Sii volpe, scrivi la tua recensione!
          </>
        )}
      </p>
      {shop.eshopData.isDisabled ? (
        <span className="btn btn-gradient-orange btn-ecommerce-details py-2 mb-3 mb-lg-0 me-lg-2 d-flex align-items-center justify-content-center disabled">
          Scrivi recensione
        </span>
      ) : (
        <Link
          to={`/it/invia-recensione?e=${shop.id}`}
          className={`${
            isMobile
              ? "btn btn-gradient-orange d-flex align-items-center justify-content-center"
              : "btn btn-animation btn-action btn-gradient-orange d-flex align-items-center justify-content-center"
          }`}
          style={{ height: "50px" }}
          title={`Scrivi recensione di ${shop.domain}`}
        >
          Scrivi recensione
        </Link>
      )}
    </div>
  )
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { Dropdown } from "react-bootstrap"
import Cookies from "js-cookie"
import { useNavigate, useParams } from "react-router-dom"

export const GoogleTranslate: React.FC = () => {
  const { lang } = useParams()
  const navigate = useNavigate()
  const [selected, setSelected] = useState<string>(
    lang ? `/auto/${lang}` : "/auto/it",
  )

  const languages = [
    { label: "Italiano", value: "/auto/it" },
    { label: "English", value: "/auto/en" },
    { label: "Español", value: "/auto/es" },
  ]

  function langChange(newLanguage: string) {
    if (Cookies.get("googtrans")) {
      Cookies.set("googtrans", decodeURI(newLanguage))
      setSelected(newLanguage)
    } else {
      Cookies.set("googtrans", newLanguage)
      setSelected(newLanguage)
    }
    const destinationUrl = `/${newLanguage.split("/")[2]}`
    navigate(destinationUrl)
    window.location.replace(destinationUrl)
  }

  function googleTranslateElementInit() {
    new (window as any).google.translate.TranslateElement(
      {
        pageLanguage: "auto",
        autoDisplay: false,
        includedLanguages: "it,en,es",
        layout: (window as any).google.translate.TranslateElement.InlineLayout
          .SIMPLE,
      },
      "google_translate_element",
    )
  }

  useEffect(() => {
    if (!lang && Cookies.get("googtrans")) {
      setSelected(Cookies.get("googtrans")!)
    } else if (lang && Cookies.get("googtrans")) {
      Cookies.remove("googtrans")
      if (lang != "it") {
        Cookies.set("googtrans", `/auto/${lang}`)
      }
      setSelected(`/auto/${lang}`)
    } else {
      setSelected("/auto/it")
    }

    var script = document.createElement("script")
    script.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit",
    )
    document.body.appendChild(script)
    ;(window as any).googleTranslateElementInit = googleTranslateElementInit

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <div className="gtranslate-div notranslate">
      <Dropdown>
        <Dropdown.Toggle
          bsPrefix={" "}
          className="dropdown btn btn-outline-white d-block text-left overflow-visible"
        >
          <span className="lang-sel" data-bs-toggle="dropdown">
            {" "}
            {languages.find((lang) => lang.value == selected)?.label}{" "}
            <span className="float-right">
              {" "}
              <FontAwesomeIcon icon={["fas", "chevron-down"]} />{" "}
            </span>
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {languages.map((lang) => (
            <Dropdown.Item
              key={lang.value}
              as="li"
              className="lang-elem"
              onClick={() => langChange(lang.value)}
            >
              {lang.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <div
        id="google_translate_element"
        style={{
          width: "0px",
          height: "0px",
          position: "absolute",
          left: "50%",
          zIndex: -99999,
        }}
      ></div>
    </div>
  )
}

import { findIconDefinition } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useContext } from "react"
import {
  Accordion,
  AccordionContext,
  Card,
  useAccordionButton,
} from "react-bootstrap"
import { Link } from "react-router-dom"
import { Category } from "../../models/base/category.interface"
import { Helmet } from "react-helmet"
import { CategorySchema } from "../schemas/CategorySchema"

interface CustomToggleProps {
  children: any
  eventKey: any
  callback: any
  category: Category
  isClickable: boolean
}

const CustomToggle: React.FC<CustomToggleProps> = ({
  children,
  eventKey,
  callback,
  category,
  isClickable,
}) => {
  const { activeEventKey } = useContext(AccordionContext)
  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey, isActive),
  )

  const isActive = activeEventKey?.includes(eventKey)

  return (
    <button
      className={`accordion-button plus-accordion-button accordion-button-coll-dark py-4 px-3 px-lg-4 ${
        isActive ? "" : "collapsed"
      }`}
      type="button"
      onClick={decoratedOnClick}
    >
      <span className="me-2">
        <FontAwesomeIcon
          icon={findIconDefinition(category.icon)}
          className="font-22"
        />
      </span>
      {isClickable ? (
        <Link
          to={`/it/categorie/${category.slug}/`}
          className="dark-color font-bolder font-23 me-4 cat-item orange-hover text-decoration-none waitLink"
          title={`Recensioni siti di ${category.name}`}
        >
          {children}
        </Link>
      ) : (
        <span className="dark-color font-bolder font-23 me-4 cat-item">
          {children}
        </span>
      )}
    </button>
  )
}

interface CategoryAccordionItemProps {
  category: Category
  onClick: (eventKey: string, isActive: any) => void
  isClickable: boolean
}

export const CategoryAccordionItem: React.FC<CategoryAccordionItemProps> = ({
  category,
  onClick,
  isClickable,
}) => {
  return (
    <>
      <CategorySchema category={category} />
      <Card bsPrefix="accordion-item" className="cat-container-item py-2">
        <Card.Header as={"h2"} bsPrefix="accordion-header">
          <CustomToggle
            eventKey={category.id.toString()}
            callback={onClick}
            category={category}
            isClickable={isClickable}
          >
            {category.name}
          </CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey={category.id.toString()}>
          <Card.Body
            bsPrefix="accordion-body"
            className="d-flex justify-content-end p-0 px-3"
          >
            <div className="d-flex flex-wrap justify-content-start align-items-start category-list">
              {category.subCategories?.map((sb: Category, idx: number) => (
                <Link
                  key={idx}
                  to={`/it/categorie/${sb.slug}/`}
                  className="subcat-item lightgray-txt font-16 font-semi-bolder text-decoration-none m-0 mb-4 pe-2 orange-hover waitLink"
                  title={`Recensioni siti di ${sb.name}`}
                >
                  {sb.name}
                </Link>
              ))}
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </>
  )
}

import React from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  selectGuestModal,
  setGuestModal,
  setRegistrationModal,
  setUserCheckModal,
} from "./modalsSlice"
import { Modal } from "react-bootstrap"
import { useGetCouponsQuery } from "../../app/services/base/coupons"
import { CouponTicket } from "../coupon_ticket/CouponTicket"

interface GuestModalProps {
  afterConfirm: () => void
}

export const GuestModal: React.FC<GuestModalProps> = ({ afterConfirm }) => {
  const dispatch = useAppDispatch()
  const guest = useAppSelector(selectGuestModal)

  const { data: couponsWithShops } = useGetCouponsQuery({ page: 1, perPage: 1 })
  const randomCouponWithShop =
    couponsWithShops && couponsWithShops.items.length > 0
      ? couponsWithShops.items[0]
      : undefined
  return (
    <Modal
      show={guest ? true : false}
      onHide={() => dispatch(setGuestModal(undefined))}
      // size="lg"
      centered
      aria-labelledby="guestModalLabel"
      dialogClassName="custom-modal-max-width"
      contentClassName="bg-transparent border-0"
    >
      <div
        className="card d-flex flex-column align-items-center justify-content-center p-3 p-lg-4"
        style={{ borderRadius: "20px" }}
      >
        <h6 className="font-33 dark-color font-bolder text-center px-2 m-0 mt-2 mt-lg-0 mb-3">
          Sei sicuro di non volerti registrare?
        </h6>
        <p className="font-16 lightgray-txt text-center px-5 m-0 mb-2">
          Ecco cosa ti stai perdendo...
        </p>
        <div className="d-flex align-items-center justify-content-start my-4">
          <h3
            className="simple-orange-txt font-33 font-bolder m-0 me-3"
            style={{ minWidth: "125px", maxWidth: "100%" }}
          >
            1 pt ESA
          </h3>
          <p className="font-16 lightgray-txt m-0 w-50">
            Utile per utilizzare gli sconti dei tuoi eCommerce preferiti
          </p>
        </div>
        <div className="d-flex flex-column w-100 my-2 list-s">
          {randomCouponWithShop && (
            <ul className="list-group list-style-none">
              <CouponTicket
                couponWithShop={randomCouponWithShop}
                isUnlocked={false}
                onlyInfo={true}
                removeLinkVetrina={true}
              />
            </ul>
          )}
        </div>
        <p className="lightgray-txt font-16 text-center m-0 mt-4 mb-5 mb-lg-4 px-3 px-lg-5 mx-lg-5 font-18">
          <span
            className="simple-blue-link cursor-pointer font-18 d-block"
            onClick={() => dispatch(setRegistrationModal(true))}
          >
            Registrati ora{" "}
          </span>
          Per guadagnare centinaia di codici sconto!{" "}
        </p>
        <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center w-100 mt-2 mb-3 gap-lg-1">
          <button
            className="btn btn-outline-gray d-flex align-items-center justify-content-center w-100 p-3 mb-3 mb-lg-0 me-lg-1"
            style={{ height: "50px" }}
            onClick={() => dispatch(setUserCheckModal(true))}
          >
            Indietro
          </button>
          <button
            id="no-login-btn"
            className="btn btn-gradient-orange font-semi-bolder w-100 ms-lg-1"
            style={{ height: "50px" }}
            onClick={() => afterConfirm()}
          >
            Pubblica
          </button>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center mt-4">
        <button
          type="button"
          className="btn-close btn-close-white"
          onClick={() => dispatch(setGuestModal(undefined))}
        ></button>
      </div>
    </Modal>
  )
}

import React, { useState } from "react"
import InvitationOrderItem from "./InvitationOrderItem"
import { useAppSelector } from "../../../app/hooks"
import { selectCurrentUser } from "../../../features/auth/authSlice"
import { skipToken } from "@reduxjs/toolkit/query"
import { AppPagination } from "../../../features/app_pagination/AppPagination"
import { useGetInvitationsOrdersQuery } from "../../../app/services/base/invitations"

export const InvitationsOrders: React.FC = () => {
  const user = useAppSelector(selectCurrentUser)
  const [page, setPage] = useState<number>(1)
  const { data: invitationsOrders } = useGetInvitationsOrdersQuery(
    user ? { email: user.email, page: page, perPage: 10 } : skipToken,
  )

  function handlePageChange(page: number) {
    setPage(page)
  }

  if (!invitationsOrders) {
    return <></>
  }

  return (
    <div className="w-container white-background utente reviews-list">
      <h2>Recensioni acquisti</h2>
      <div id="reviewAcquistoList" className="list-view">
        <div className="container">
          {invitationsOrders && invitationsOrders.length > 0 ? (
            <>
              {invitationsOrders.map((invitationOrder) => (
                <InvitationOrderItem
                  key={invitationOrder.id}
                  invitationOrder={invitationOrder}
                />
              ))}
              <AppPagination
                currentPage={page}
                totalPages={Number(invitationsOrders?.length) / 10}
                handlePageChange={handlePageChange}
              />{" "}
            </>
          ) : (
            <span className="empty">
              <div className="wellx">Nessun acquisto presente.</div>
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

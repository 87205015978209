import React, { ChangeEvent, useEffect, useState } from "react"
import { Outlet, useParams } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Category } from "../../models/base/category.interface"
import { useGetCategoriesQuery } from "../../app/services/base/categories"
import { CategoryAccordion } from "../../features/category_accordion/CategoryAccordion"
import { useAppDispatch } from "../../app/hooks"
import { forceIsOpenTo } from "../../features/search_engine/searchEngineSlice"
import { Helmet } from "react-helmet"

export const Categories: React.FC = () => {
  const dispatch = useAppDispatch()
  let { categorySlug } = useParams()
  const { data: categories } = useGetCategoriesQuery({ type: "normal" })
  const [searchText, setSearchText] = useState<string>("")
  const [categoriesList, setCategoriesList] = useState<
    (Category | null)[] | undefined
  >([])
  const [activeKeys, setActiveKeys] = useState<any[]>([])

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    if (e.currentTarget.value.length > 0) {
      setActiveKeys(categories?.map((c: any) => c.id.toString()) || [])
      setCategoriesList(
        categories
          ?.map((category) => {
            const matchingSubCategories = category.subCategories?.filter(
              (subCategory) =>
                subCategory.name
                  .toLowerCase()
                  .includes(e.currentTarget.value.toLowerCase()),
            )

            if (matchingSubCategories && matchingSubCategories.length > 0) {
              return {
                ...category,
                subCategories: matchingSubCategories,
              } as Category
            } else {
              return null
            }
          })
          .filter((category) => category !== null),
      )
    } else {
      setActiveKeys([])
      setCategoriesList(categories)
    }

    setSearchText(e.currentTarget.value)
  }

  function handleClick(eventKey: string, isActive: any) {
    if (isActive) {
      setActiveKeys(activeKeys?.filter((id) => id != eventKey))
    } else {
      setActiveKeys([...activeKeys, eventKey])
    }
  }

  useEffect(() => {
    setCategoriesList(categories)
  }, [categories])

  useEffect(() => {
    dispatch(forceIsOpenTo(false))
  }, [])

  return (
    <>
      <Helmet>
        <title>
          Cerca per categoria tra i negozi online con recensioni verificate e
          certificate
        </title>
        <link rel="canonical" type="" href={window.location.href} />
      </Helmet>
      {!categorySlug && (
        <div className="container custom-container">
          <div className="d-flex flex-column align-items-center justify-content-center py-3">
            <h1 className="font-33 dark-color font-bolder text-center text-lg-start mt-4 mb-3 mb-lg-4 px-lg-5">
              Cerca per categoria tra i negozi online con recensioni verificate
              e certificate
            </h1>
            <div className="input-group search-bar-blue mt-3">
              <input
                id="categoriesSearch"
                type="text"
                className="form-control font-16 dark-color font-semi-bolder"
                placeholder="Cerca una categoria"
                aria-label="Cerca una categoria"
                aria-describedby="basic-addon2"
                onChange={handleChange}
                value={searchText}
              />
              <button
                className="input-group-text btn btn-gradient-blue"
                id="basic-addon2"
              >
                <FontAwesomeIcon
                  icon={["fas", "magnifying-glass"]}
                  className="font-28"
                />
              </button>
            </div>
            <CategoryAccordion
              categories={categoriesList}
              activeKeys={activeKeys}
              handleClick={handleClick}
            />
          </div>
        </div>
      )}
      <Outlet />
    </>
  )
}

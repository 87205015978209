import { LoaderFunction, LoaderFunctionArgs } from "react-router-dom"
import { BaseLoader } from "./base.loader"
import { articlesApi } from "../services/base/articles"
import { categoriesApi } from "../services/base/categories"
import { Article } from "../../models/base/article.interface"
import { Category } from "../../models/base/category.interface"
import { shopsApi } from "../services/base/shops"
import { Shop } from "../../models/base/shop.interface"
import { reviewsApi } from "../services/base/reviews"
import { Review } from "../../models/base/review.interface"
import { couponsApi } from "../services/base/coupons"
import { Coupon } from "../../models/base/coupon.interface"
import { ListResponse } from "../../models/base/listResponse.interface"
import { ReviewShopUserUnion } from "../../models/base/reviewShopUserUnion.interface"
import { HomeCoupon } from "../../models/base/homeCoupon.interface"

export class HomeLoader extends BaseLoader {
  listLoader: LoaderFunction = async ({
    params,
    request,
  }: LoaderFunctionArgs) => {
    const categories = (await this._loader(
      categoriesApi.endpoints.getCategories,
      request,
      { type: "visited" },
    )) as Category[]

    const suggestedShops = (await this._loader(
      shopsApi.endpoints.getSuggestedShops,
      request,
    )) as Shop[]

    const recommendedCategories = (await this._loader(
      categoriesApi.endpoints.getCategories,
      request,
      { type: "recommended" },
    )) as Category

    const latestReviews = (await this._loader(
      reviewsApi.endpoints.getLatestReviews,
      request,
    )) as ListResponse<ReviewShopUserUnion>

    const coupons = (await this._loader(
      couponsApi.endpoints.getHomeCoupons,
      request,
    )) as HomeCoupon[]

    const articles = (await this._loader(
      articlesApi.endpoints.getArticles,
      request,
    )) as Article[]

    return {
      categories,
      suggestedShops,
      recommendedCategories,
      latestReviews,
      coupons,
      articles,
    }
  }
}

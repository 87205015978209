import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../../app/store"

export interface RatingProgressBarState {
  selectedProgressRatings: number[]
}

const initialState: RatingProgressBarState = {
  selectedProgressRatings: [],
}

export const ratingProgressBarSlice = createSlice({
  name: "ratingProgressBar",
  initialState,
  reducers: {
    setSelectedProgressRatings: (state, action: PayloadAction<number>) => {
      let index = state.selectedProgressRatings.indexOf(action.payload)

      if (index !== -1) {
        state.selectedProgressRatings.splice(index, 1)
      } else {
        state.selectedProgressRatings.push(action.payload)
      }
    },
  },
})

export const { setSelectedProgressRatings } = ratingProgressBarSlice.actions

export const selectProgressRatings = (state: RootState) =>
  state.ratingProgressBar.selectedProgressRatings

export default ratingProgressBarSlice.reducer

import React from "react"
import background from "../../assets/images/scrivi_recensione_bg.png"
import writeReviewCss from "./writeReview.css?inline"
import { SearchEngine } from "../../features/search_engine/SearchEngine"

export const WriteReview: React.FC = () => {
  return (
    <section
      className="scrivi-recensione-page"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="container hp_searchContainer hp_boxSearch">
        <h1 id="title-h" className="title">
          Quale negozio online vuoi recensire?
        </h1>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div id="search-header" className="">
              <div className="row search-row no-gutters w-100">
                <div
                  id="searchContainer"
                  className="searchContainer col-12 col-lg-8"
                >
                  <div className="px-2 px-lg-3 dark-fade-background border-radius-75">
                    <SearchEngine isFixed={false} hideLabel={true} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>{writeReviewCss}</style>
    </section>
  )
}

import React from "react"
import ReviewItemOld from "../../../features/review_item_old/ReviewItemOld"
import { ReviewShopUserUnion } from "../../../models/base/reviewShopUserUnion.interface"

interface ReviewsModifyViewProps {
  data: ReviewShopUserUnion
}

export const ReviewsModifyView: React.FC<ReviewsModifyViewProps> = ({
  data,
}) => {
  return (
    <div className="w-container utente vetrina update-review white-background">
      <div className="page-user-middle">
        <div className="review-list">
          <div className="row">
            {data && (
              <ReviewItemOld
                review={data.review}
                shop={data.eshop}
                activateActions={true}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

import React from "react"
import ReservedMenu from "./ReservedMenu"
import reservedCss from "./reserved.css?inline"

interface ReservedProps {
  content: any
  hideSidebar?: boolean
}

export const Reserved: React.FC<ReservedProps> = (props) => {
  const content = React.createElement(props.content)
  const colValue = !props.hideSidebar ? "col-12  col-lg-9" : "col-12"
  return (
    <section className="user-area-page">
      <div className="container">
        <div className="row">
          {!props.hideSidebar ? <ReservedMenu /> : ""}
          <div className={colValue}>
            {content}
          </div>
        </div>
      </div>
      <style>{reservedCss}</style>
    </section>
  )
}

import React from "react"
import { useRouteError } from "react-router-dom"
import { Link } from "react-router-dom"
import errorImage from "../../assets/images/error_img.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import errorPageCss from "./errorPage.css?inline"

export const ErrorPage: React.FC = () => {
  const error: any = useRouteError()

  return (
    <section className="error-page user-area-page">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="w-container utente">
              <div className="row">
                <div className="col-12 text-center">
                  <img src={errorImage} className="img-fluid d-inline" />
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center">
                  <h3>Oops! Qualcosa non ha funzionato.</h3>
                  <p>Ci dispiace. Si è verificato un errore imprevisto.</p>
                  <p>
                    <i>{error.statusText || error.message}</i>
                  </p>
                  <span className="back-to-home">
                    <FontAwesomeIcon icon={["fas", "caret-right"]} />
                    Vai alla <Link to="/">Home Page</Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>{errorPageCss}</style>
    </section>
  )
}

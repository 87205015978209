import { useAppSelector } from "../../app/hooks"
import { InvitationOrderProduct } from "../../models/base/invitationOrder.interface"
import { Shop } from "../../models/base/shop.interface"
import {
  selectSendReviewAverageScore,
  selectSendReviewConfirmedProducts,
  selectSendReviewFormData,
} from "../../pages/send_review/sendReviewSlice"
import { getShopLogo } from "../../utils/shopUtils"
import { ReviewRecapItem } from "./ReviewRecapItem"
import { ReviewRecapReminderItem } from "./ReviewRecapReminderItem"

interface StepThreeProps {
  shop?: Shop
  products?: InvitationOrderProduct[]
  changeStep: (stepTo: "prev" | "next", forceStep?: number) => void
}

export const StepThree: React.FC<StepThreeProps> = ({
  shop,
  products,
  changeStep,
}) => {
  const averageScore = useAppSelector(selectSendReviewAverageScore)
  const formData = useAppSelector(selectSendReviewFormData)
  const confirmedProducts = useAppSelector(selectSendReviewConfirmedProducts)
  return (
    <>
      <ReviewRecapItem
        item={{
          logo: getShopLogo(shop?.id ? shop.id : 0),
          title: shop?.domain,
          domain: shop?.domain,
          rating: averageScore,
          revTitle: formData.shop.title,
          revDescription: formData.shop.description,
          checkInputName: "isAnonymous",
          anonymous_reviews:
            shop?.eshopData.structured_data?.anonymous_reviews || 0,
        }}
        changeStep={changeStep}
        customContainerClass="orange-border"
      />

      {products
        ?.filter((product) => confirmedProducts.hasOwnProperty(product.id))
        .map((product) => (
          <ReviewRecapItem
            key={product.id}
            item={{
              logo: product.img_prod,
              title: product.name,
              rating: confirmedProducts[product.id].score,
              revDescription: confirmedProducts[product.id].description,
              checkInputName: product.id.toString(),
              anonymous_reviews:
                shop?.eshopData.structured_data?.anonymous_reviews || 0,
            }}
            changeStep={changeStep}
            customContainerClass="orange-border"
          />
        ))}

      {products &&
        products.filter(
          (product) => !confirmedProducts.hasOwnProperty(product.id),
        ).length > 0 && (
          <div>
            <span className="font-bolder dark-color font-16 mt-5 mb-3 d-block">
              Hai ancora prodotti da recensire:
            </span>
            {products
              ?.filter(
                (product) => !confirmedProducts.hasOwnProperty(product.id),
              )
              .map((product) => (
                <ReviewRecapReminderItem
                  key={product.id}
                  item={{
                    logo: product.img_prod,
                    title: product.name || "",
                    domain: shop?.domain || "",
                  }}
                  changeStep={changeStep}
                />
              ))}
          </div>
        )}
    </>
  )
}

import React from "react"
import { Accordion, Card } from "react-bootstrap"
import { CategoryAccordionItem } from "./CategoryAccordionItem"
import { Category } from "../../models/base/category.interface"

interface CategoryAccordionProps {
  category?: Category
  categories?: (Category | null)[]
  activeKeys: string[]
  handleClick: (eventKey: string, isActive: any) => void
}

export const CategoryAccordion: React.FC<CategoryAccordionProps> = ({
  category,
  categories,
  activeKeys,
  handleClick,
}) => {
  const isCategoryNameClickable = category ? false : true

  return (
    <Card
      className="border-transparent w-100"
      style={category ? {} : { marginTop: "60px", marginBottom: "60px" }}
    >
      <Accordion
        activeKey={activeKeys ? activeKeys : category?.id.toString()}
        flush
        alwaysOpen
      >
        {category ? (
          <CategoryAccordionItem
            key={category?.id}
            category={category}
            isClickable={isCategoryNameClickable}
            onClick={handleClick}
          />
        ) : (
          categories?.map((c: any) => (
            <CategoryAccordionItem
              key={c.id}
              category={c}
              onClick={handleClick}
              isClickable={true}
            />
          ))
        )}
      </Accordion>
    </Card>
  )
}

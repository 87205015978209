import React from "react"
import OwlCarousel from "react-owl-carousel"
import { ShopsCarouselItem } from "./ShopsCarouselItem"
import {
  useGetDomainsByCategoryQuery,
  useGetSearchDomainsQuery,
} from "../../app/services/esaScraper/domains"

interface ShopsCarouselSectionProps {
  title: string
  categorySlug?: string
  searchText?: string
}

export const ShopsCarouselSection: React.FC<ShopsCarouselSectionProps> = ({
  title,
  searchText,
  categorySlug,
}) => {
  const { data: shops } = searchText
    ? useGetSearchDomainsQuery({
        searchText: searchText,
        filters: {},
        searchAfter: undefined,
      })
    : categorySlug
    ? useGetDomainsByCategoryQuery({
        categorySlug: categorySlug,
        filters: {},
        searchAfter: undefined,
      })
    : { data: undefined }

  if (!shops || (shops && shops.hits.hits.length == 0)) {
    return <></>
  }

  return (
    <section
      // id="eshopCarousel_3"
      className="background-white py-5"
    >
      <div className="container padding-r15-15-0">
        <div className="row">
          <div className="col-md-12 padding-l15-0">
            <h2 className="font-33 gray-text font-bolder margin-x0-15 text-xs-center text-sm-start">
              {title}
            </h2>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 col-md-12 col-lg-12">
            <div className="carousel-wrap">
              <OwlCarousel
                className="owl-theme row"
                loop={false}
                autoplay={true}
                margin={0}
                responsiveClass={true}
                nav={true}
                navText={[
                  '<div class="nav-btn prev-slide simple-orange-txt"><svg class="orange" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><path fill="currentColor" d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg></div>',
                  '<div class="nav-btn next-slide simple-orange-txt"><svg class="orange" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><path fill="currentColor" d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg></div>',
                ]}
                responsive={{
                  0: {
                    items: 1.05,
                    autoplay: true,
                    dots: false,
                    nav: true,
                    margin: 5,
                  },
                  375: {
                    items: 1.1,
                    autoplay: true,
                    dots: false,
                    nav: true,
                    margin: 5,
                  },
                  768: {
                    items: 1.8,
                    autoplay: true,
                    nav: false,
                    dots: false,
                    margin: 5,
                  },
                  1000: {
                    items: 2.7,
                    autoplay: true,
                    nav: true,
                    dots: false,
                    margin: 12,
                  },
                }}
              >
                {shops.hits.hits.map((shop) => (
                  <ShopsCarouselItem
                    key={shop._source.esa_id}
                    shop={shop._source}
                  />
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Toast } from "react-bootstrap"
import { Variant } from "react-bootstrap/esm/types"
import { useAppDispatch } from "../../app/hooks"
import { removeToast } from "./toastsSlice"

interface SimpleToastProps {
  index: number
  message: string
  type: Variant
}

export const SimpleToast: React.FC<SimpleToastProps> = ({
  index,
  message,
  type,
}) => {
  const dispatch = useAppDispatch()

  return (
    <Toast
      onClose={() => dispatch(removeToast(index))}
      show={true}
      delay={2000}
      autohide
      bg={type}
      className="align-items-center text-white border-0"
    >
      <div className="d-flex">
        <Toast.Body> {message} </Toast.Body>
        <span
          className="me-3 m-auto"
          onClick={() => dispatch(removeToast(index))}
        >
          <FontAwesomeIcon icon={["fas", "times"]} />
        </span>
      </div>
    </Toast>
  )
}

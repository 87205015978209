import React, { useContext } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import parse from "html-react-parser"
import {
  Accordion,
  AccordionContext,
  Card,
  useAccordionButton,
} from "react-bootstrap"

const ContextAwareToggle: React.FC<any> = ({
  children,
  eventKey,
  callback,
}) => {
  const { activeEventKey } = useContext(AccordionContext)

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  )

  const isCurrentEventKey = activeEventKey === eventKey

  return (
    <button
      className="btn btn-link bolder-dark-title font-23 gray-hover"
      type="button"
      onClick={decoratedOnClick}
    >
      <FontAwesomeIcon
        icon={["fas", `${isCurrentEventKey ? "chevron-up" : "chevron-down"}`]}
      />
    </button>
  )
}

const TitleToggle: React.FC<any> = ({ children, eventKey, callback }) => {
  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  )
  return (
    <h2
      className="font-bolder dark-color cursor-pointer gray-hover mb-0 font-23"
      onClick={decoratedOnClick}
    >
      {children}
    </h2>
  )
}

interface FaqAccordionItemProps {
  eventKey: string
  subtitle: string
  title: string
  contentHtml: string
}

export const FaqAccordionItem: React.FC<FaqAccordionItemProps> = ({
  eventKey,
  subtitle,
  title,
  contentHtml,
}) => {
  return (
    <Card className="no-borders mb-3 mb-md-0">
      <Card.Header className="background-white no-borders radius-75">
        <h5 className="mb-0">
          <div className="row">
            <div className="col-12 mb-3 mb-md-0">
              <span className="gray-text font-14"> {subtitle} </span>
            </div>
            <div className="col-12 col-md-11 mt-3">
              <TitleToggle eventKey={eventKey} children={title} />
            </div>
            <div className="col-12 col-md-1 text-center mt-3 mt-md-0">
              <ContextAwareToggle eventKey={eventKey} />
            </div>
          </div>
        </h5>
      </Card.Header>
      <Accordion.Collapse eventKey={eventKey}>
        <Card.Body>
          <span className="border-top pt-3 w-100 d-block d-md-none"> </span>
          {parse(contentHtml)}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  )
}

import React from "react"
import cassetta from "../../assets/images/cassetta.png"
import credentialRecoveryConfirmedCss from "./credentialRecoveryConfirmed.css?inline"

export const CredentialRecoveryConfirmed: React.FC = () => {
  return (
    <section className="access-page">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="access-box">
              <div className="optin-ok-body recover-container">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="optin_inbox">
                      <img src={cassetta} width="130px" height="120px" />
                    </div>
                    <h2 className="title-main text-center">
                      Ripristina password
                    </h2>
                    <p className="optinSub">
                      Per completare il processo di modifica password segui le
                      istruzioni sulla mail che ti abbiamo inviato.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>{credentialRecoveryConfirmedCss}</style>
    </section>
  )
}

import React from "react"
import OwlCarousel from "react-owl-carousel"
import customCss from "../../assets/css/old/int/custom.css?inline"
import { Hit } from "../../models/esaScraper/esaScraperResponse.interface"
import { EsaScraperProduct } from "../../models/esaScraper/esaScraperProduct.interface"
import { Shop } from "../../models/base/shop.interface"
import { useAppSelector } from "../../app/hooks"
import { selectProductModal } from "../modals/modalsSlice"
import { ProductCard } from "../product_card/ProductCard"
import { ProductModal } from "../modals/ProductModal"

interface ProductsCarouselProps {
  products: Hit<EsaScraperProduct>[]
  shop: Shop
}

export const ProductsCarousel: React.FC<ProductsCarouselProps> = ({
  products,
  shop,
}) => {
  const productModal = useAppSelector(selectProductModal)
  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-12">
          <div className="carousel-wrap">
            <OwlCarousel
              className="owl-theme"
              dots={false}
              loop={true}
              margin={0}
              responsiveClass={true}
              dotsEach={3}
              autoplay={true}
              autoplayTimeout={4500}
              autoplayHoverPause={true}
              autoplaySpeed={650}
              nav={true}
              navText={[
                '<div class="carousel-control dark-color mx-2 nav-btn prev-slide left-negative-60"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><path fill="currentColor" d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg></div>',
                '<div class="carousel-control dark-color mx-2 nav-btn next-slide right-negative-60"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><path fill="currentColor" d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg></div>',
              ]}
              responsive={{
                0: {
                  items: 1.2,
                  autoplay: true,
                  dots: false,
                  nav: true,
                  margin: 4,
                },
                576: {
                  items: 2,
                  autoplay: true,
                  nav: true,
                  dots: false,
                  loop: true,
                  margin: 4,
                },
                1000: {
                  items: 4,
                  autoplay: true,
                  nav: true,
                  dots: false,
                  loop: true,
                  margin: 11,
                },
              }}
            >
              {products.map((product) => (
                <ProductCard
                  key={product._id}
                  product={product._source}
                  shop={shop}
                  hideCol
                  hideSchema
                  // customItemClass="p-2"
                />
              ))}
            </OwlCarousel>
          </div>
        </div>
        <style>{customCss}</style>
      </div>
      {productModal && <ProductModal />}
    </>
  )
}

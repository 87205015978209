import { ContactUsRequest } from "../../../models/base/contactUsRequest.interface"
import { ListResponse } from "../../../models/base/listResponse.interface"
import { SecurityRatingResponse } from "../../../models/eCommerceSicuro/securityRatingResponse.interface"
import { baseApi } from "./baseApi"

export const generalApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    // MAPPATO
    getSecureRatingByDomain: build.query<SecurityRatingResponse, string>({
      query: (eShopDomain) => ({
        url: `domains/frontend/ecs-rating/${eShopDomain.replace("/", "*")}`,
      }),
      providesTags: (result, error, page) => [{ type: "SecurityRating", page }],
    }),
    // MAPPATO
    getReviewers: build.query<
      ListResponse<any>,
      { order?: string; name?: string; id?: string }
    >({
      query: ({ order, name, id }) => {
        return {
          url: "reviews/frontend/fetch-reviewer-standing",
          params: { order, name, id },
        }
      },
    }),
    // MAPPATO
    contactUs: build.mutation<{ response: string }, ContactUsRequest>({
      query: (body) => {
        return {
          url: "communications/frontend/contacts",
          method: "POST",
          body,
        }
      },
    }),
    // MAPPATO
    subscribeNewsletter: build.mutation<
      { response: string },
      { name: string; email: string; privacy: boolean; type: string }
    >({
      //TODO
      query: (body) => {
        return {
          url: "communications/frontend/newsletter",
          method: "POST",
          body,
        }
      },
    }),
    // MAPPATO
    activateNewsletter: build.mutation<
      { response: string },
      { id: string; code: string; }
    >({
      //TODO
      query: ({id,code}) => {
        return {
          url: `communications/frontend/newsletter-activate/${id}/${code}`,
          method: "PUT",
        }
      },
    }),
  }),
})

export const {
  useGetReviewersQuery,
  useContactUsMutation,
  useSubscribeNewsletterMutation,
  useGetSecureRatingByDomainQuery,
  useActivateNewsletterMutation,
} = generalApi

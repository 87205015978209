import React from "react"
import { SearchEngine } from "../search_engine/SearchEngine"

export const SearchEngineHeaderContainer: React.FC = () => {
  return (
    <div
      // id="headerContainer"
      className="mb-4 headerContainer"
    >
      <div className="container">
        <div className="row py-3 pb-5">
          <div className="col-12">
            <div className="px-3 dark-fade-background border-radius-75">
              <SearchEngine isFixed={true} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { useState } from "react"
import { Shop } from "../../../models/base/shop.interface"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  advReplyTxt,
  esaFunctionsTxt,
  freeProfileTxt,
  getTimeUnit,
  hibridProfileTxt,
  invitationsTxt,
  negativeReplyTxt,
  paidProfileTxt,
  positiveReplyTxt,
} from "../../../utils/stringUtils"
import {
  faCalendarDays,
  faCoins,
  faEnvelopeOpenText,
  faReplyAll,
  faShop,
  faShopSlash,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons"
import { Popover, OverlayTrigger } from "react-bootstrap"

interface EShopEsaInfoCardProps {
  shop: Shop
}

export const EShopEsaInfoCard: React.FC<EShopEsaInfoCardProps> = ({ shop }) => {
  const [visiblePopover, setVisiblePopover] = useState<string | null>(null)
  const [showAll, setShowAll] = useState<boolean>(false)
  const isIbrid = shop.plan == "ibrido"
  const hasInvitation = !["ibrido", "free", "basic"].includes(shop.plan)
  const hasPlan = !["free", "ibrido"].includes(shop.plan)
  const percReplyPositiveReview = shop.percReplyPositiveReview
  const percReplyNegativeReview = shop.percReplyNegativeReview
  const nReviews = shop.nRecensioniEsa || false
  const nReviewsPositive = shop.nReviewsEsaPositive || false
  const nReviewsNegative = shop.nReviewsEsaNegative || false
  const replyAdvTime = shop.avgReplyTime
  const replyPercPositive = percReplyPositiveReview
    ? Math.floor(percReplyPositiveReview)
    : false
  const replyPercNegative = percReplyNegativeReview
    ? Math.floor(percReplyNegativeReview)
    : false
  const replyAdvDate = replyAdvTime
    ? getTimeUnit(replyAdvTime).value + " " + getTimeUnit(replyAdvTime).unit
    : false

  const profileTxt = hasPlan ? paidProfileTxt : freeProfileTxt
  const managedTxt = isIbrid ? hibridProfileTxt : profileTxt

  const getPlacement = () => {
    if (typeof window !== "undefined") {
      return window.innerWidth < 768 ? "bottom" : "left"
    }
    return "left"
  }

  const handleMouseEnter = (id: string) => setVisiblePopover(id)
  const handleMouseLeave = () => setVisiblePopover(null)

  const popoverContent = (text: string, id: string) => (
    <Popover
      id={`popover-${id}`}
      onMouseEnter={() => handleMouseEnter(id)}
      onMouseLeave={handleMouseLeave}
    >
      <Popover.Body dangerouslySetInnerHTML={{ __html: text }} />
    </Popover>
  )

  return (
    <div
      className={`card eshop-custom-card mb-0 mb-lg-2 me-2 me-lg-0 ${
        showAll ? "mobile-height-auto" : ""
      }`}
    >
      <div className="card-body d-flex flex-column justify-content-between px-3 py-4 p-lg-4">
        <div>
          <h5 className="blue-card-title m-0 mb-2 mb-lg-3">
            Attività dell'eCommerce
          </h5>
          <hr className="border border-dark m-0 mb-lg-3 d-none d-lg-block" />
          <OverlayTrigger
            placement={getPlacement()}
            overlay={popoverContent(managedTxt, "managed")}
            show={visiblePopover === "managed"}
            trigger={[]}
          >
            <p
              className="dark-color font-14 m-0 mb-3"
              onMouseEnter={() => handleMouseEnter("managed")}
              onMouseLeave={handleMouseLeave}
            >
              <span className="lightgray-txt font-14 m-0 mb-1">
                <FontAwesomeIcon
                  icon={isIbrid ? faShopSlash : faShop}
                  className="me-2"
                />
              </span>
              {isIbrid ? "Profilo non gestito" : "Profilo gestito"}
            </p>
          </OverlayTrigger>
          {hasInvitation && (
            <OverlayTrigger
              placement={getPlacement()}
              overlay={popoverContent(invitationsTxt, "invitations")}
              show={visiblePopover === "invitations"}
              trigger={[]}
            >
              <p
                className="d-flex dark-color font-14 m-0 mb-3"
                onMouseEnter={() => handleMouseEnter("invitations")}
                onMouseLeave={handleMouseLeave}
              >
                <span className="lightgray-txt font-14 m-0 mb-1">
                  <FontAwesomeIcon icon={faEnvelopeOpenText} className="me-2" />
                </span>
                {"Invita a scrivere recensioni, sia positive che critiche"}
              </p>
            </OverlayTrigger>
          )}
          {hasPlan && (
            <OverlayTrigger
              placement={getPlacement()}
              overlay={popoverContent(esaFunctionsTxt, "esaFunctions")}
              show={visiblePopover === "esaFunctions"}
              trigger={[]}
            >
              <p
                className="d-flex dark-color font-14 m-0 mb-3"
                onMouseEnter={() => handleMouseEnter("esaFunctions")}
                onMouseLeave={handleMouseLeave}
              >
                <span className="lightgray-txt font-14 m-0 mb-1">
                  <FontAwesomeIcon icon={faCoins} className="me-2" />
                </span>
                {"Paga per utilizzare funzionalità avanzate"}
              </p>
            </OverlayTrigger>
          )}
          {!isIbrid && nReviews && (
            <div className={`d-lg-block ${showAll ? "d-block" : "d-none"}`}>
              {nReviewsPositive && (
                <OverlayTrigger
                  placement={getPlacement()}
                  overlay={popoverContent(positiveReplyTxt, "positiveReply")}
                  show={visiblePopover === "positiveReply"}
                  trigger={[]}
                >
                  <p
                    className="d-flex dark-color font-14 m-0 mb-3"
                    onMouseEnter={() => handleMouseEnter("positiveReply")}
                    onMouseLeave={handleMouseLeave}
                  >
                    <span className="lightgray-txt font-14 m-0 mb-1">
                      <FontAwesomeIcon icon={faReplyAll} className="me-2" />
                    </span>
                    {replyPercPositive
                      ? `Ha risposto al ${replyPercPositive}%  delle recensioni positive ricevute`
                      : "Non ha ancora risposto alle recensioni positive ricevute"}
                  </p>
                </OverlayTrigger>
              )}
              {nReviewsNegative && (
                <OverlayTrigger
                  placement={getPlacement()}
                  overlay={popoverContent(negativeReplyTxt, "negativeReply")}
                  show={visiblePopover === "negativeReply"}
                  trigger={[]}
                >
                  <p
                    className="d-flex dark-color font-14 m-0 mb-3"
                    onMouseEnter={() => handleMouseEnter("negativeReply")}
                    onMouseLeave={handleMouseLeave}
                  >
                    <span className="lightgray-txt font-14 m-0 mb-1">
                      <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        className="me-2"
                      />
                    </span>
                    {replyPercNegative
                      ? `Ha risposto al ${replyPercNegative}% delle recensioni critiche o negative ricevute`
                      : "Non ha ancora risposto alle recensioni critiche ricevute"}
                  </p>
                </OverlayTrigger>
              )}
              {replyAdvDate && (
                <OverlayTrigger
                  placement={getPlacement()}
                  overlay={popoverContent(advReplyTxt, "advReply")}
                  show={visiblePopover === "advReply"}
                  trigger={[]}
                >
                  <p
                    className="d-flex dark-color font-14 m-0 mb-3"
                    onMouseEnter={() => handleMouseEnter("advReply")}
                    onMouseLeave={handleMouseLeave}
                  >
                    <span className="lightgray-txt font-14 m-0 mb-1">
                      <FontAwesomeIcon icon={faCalendarDays} className="me-2" />
                    </span>
                    {`Risponde alle recensioni entro ${replyAdvDate}`}
                  </p>
                </OverlayTrigger>
              )}
            </div>
          )}
        </div>

        {!showAll && (
          <div className="d-lg-none text-center">
            <a className="simple-blue-link" onClick={() => setShowAll(true)}>
              Visualizza tutto
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

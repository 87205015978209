import { BaseLoader } from "./base.loader"
import { LoaderFunction, LoaderFunctionArgs } from "react-router-dom"
import { Shop } from "../../models/base/shop.interface"
import { domainsApi } from "../services/esaScraper/domains"
import { EsaScraperResponse } from "../../models/esaScraper/esaScraperResponse.interface"
import { EsaScraperShop } from "../../models/esaScraper/esaScraperShop.interface"

export class SearchLoader extends BaseLoader {
  listLoader: LoaderFunction = async ({
    params,
    request,
  }: LoaderFunctionArgs) => {
    const shopsBySearchText = (await this._loader(
      domainsApi.endpoints.getSearchDomains,
      request,
      { searchText: params.searchText, filter: {}, searchAfter: undefined },
    )) as EsaScraperResponse<EsaScraperShop>

    const results = (await this._loader(
      domainsApi.endpoints.getSearchDomains,
      request,
      { searchText: params.searchText, filter: {}, searchAfter: undefined },
    )) as EsaScraperResponse<EsaScraperShop>

    return { shopsBySearchText, results }
  }
}

import { SearchFilterOption } from "../../../models/base/searchFilter.interface"
import { EsaScraperResponse } from "../../../models/esaScraper/esaScraperResponse.interface"
import { buildElasticQuery } from "../../../utils/elasticSearchUtils"
import { esaScraperApi } from "./esaScraperApi"
import { EsaScraperShop } from "../../../models/esaScraper/esaScraperShop.interface"

export const domainsApi = esaScraperApi.injectEndpoints({
  endpoints: (build) => ({
    // MAPPATO
    getSearchDomains: build.query<
      EsaScraperResponse<EsaScraperShop>,
      {
        searchText?: string
        filters: Record<string, SearchFilterOption>
        searchAfter: number[] | undefined
      }
    >({
      query: ({ searchText, filters, searchAfter }) => {
        const elasticQuery = buildElasticQuery("Shops", filters, searchAfter)
        return {
          url: "esasearch/domain/search/",
          method: "POST",
          params: {
            query_string: searchText,
          },
          body: elasticQuery,
        }
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      merge: (
        currentCache,
        newItems,
        { arg, baseQueryMeta, fulfilledTimeStamp, requestId },
      ) => {
        if (arg.searchAfter != undefined) {
          return {
            ...newItems,
            hits: {
              ...newItems.hits,
              hits: [...currentCache.hits.hits, ...newItems.hits.hits],
            },
          }
        } else {
          return newItems
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg
      },
    }),
    // MAPPATO
    getDomainsByCategory: build.query<
      EsaScraperResponse<EsaScraperShop>,
      {
        categorySlug?: string
        filters: Record<string, SearchFilterOption>
        searchAfter: number[] | undefined
      }
    >({
      query: ({ categorySlug, filters, searchAfter }) => {
        const elasticQuery = buildElasticQuery("Shops", filters, searchAfter)

        return {
          url: `esasearch/categories/domains/${categorySlug}/`,
          method: "POST",
          body: elasticQuery,
        }
      },
      serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
        return endpointName + queryArgs.categorySlug
      },
      merge: (
        currentCache,
        newItems,
        { arg, baseQueryMeta, fulfilledTimeStamp, requestId },
      ) => {
        if (arg.searchAfter != undefined) {
          return {
            ...newItems,
            hits: {
              ...newItems.hits,
              hits: [...currentCache.hits.hits, ...newItems.hits.hits],
            },
          }
        } else {
          return newItems
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg
      },
    }),
    // MAPPATO
    getSuggestedDomains: build.query<
      EsaScraperResponse<EsaScraperShop>,
      {
        searchText: string
        size?: number
      }
    >({
      query: ({ searchText, size = 6 }) => {
        return {
          url: "esasearch/domain/suggester/",
          params: {
            query_string: searchText,
            size,
          },
          method: "GET",
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.hits.hits.map(({ _id }) => ({
                type: "Domains" as const,
                _id,
              })),
              { type: "Domains", id: "LIST" },
            ]
          : [{ type: "Domains", id: "LIST" }],
    }),
  }),
})

export const {
  useGetSearchDomainsQuery,
  useGetDomainsByCategoryQuery,
  useGetSuggestedDomainsQuery,
} = domainsApi

import React from "react"

interface ReviewRecapReminderItemProps {
  item: { logo: string; title: string; domain: string }
  changeStep: (stepTo: "prev" | "next", forceStep?: number) => void
}

export const ReviewRecapReminderItem: React.FC<
  ReviewRecapReminderItemProps
> = ({ item, changeStep }) => {
  return (
    <div
      id="<?= ($customId) ? $customId : 'reviewRecapReminder'; ?>"
      className="card w-100 mb-3 <?= $customContainerClass; ?>"
    >
      <div className="border-bottom-fade d-flex align-items-center justify-content-start p-3 recapReminderItem">
        {item.logo && (
          <div
            style={{ width: "70px", height: "70px" }}
            className="d-flex align-items-center ms-1 me-3  overflow-hidden"
          >
            <img src={item.logo} alt="" width="100%" />
          </div>
        )}
        <div className="d-flex flex-column flex-grow-1 align-items-start justify-content-center step-header gap-1 me-1">
          <h6
            className="font-16 dark-color font-bolder step-header-title m-0"
            style={{ maxWidth: "375px" }}
          >
            {item.title}
          </h6>
          {item.title != item.domain && (
            <p className="lightgray-txt m-0" style={{ maxWidth: "375px" }}>
              {item.domain}
            </p>
          )}
        </div>
        <span
          className="btn btn-secondary-orange p-2 font-14 border-radius-25 clickProductTrigger"
          onClick={() => changeStep("prev")}
          style={{ minWidth: "fit-content" }}
        >
          Scrivi recensione
        </span>
      </div>
    </div>
  )
}

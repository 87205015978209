import React, { ChangeEvent, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { skipToken } from "@reduxjs/toolkit/query"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { selectCurrentUser } from "../../../features/auth/authSlice"
import barsMovie from "../../../assets/css/old/bars-movie.css?inline"
import { addToast } from "../../../features/toasts/toastsSlice"
import { useCreateProductReviewMutation } from "../../../app/services/base/reviews"
import { useGetInvitationsOrdersQuery } from "../../../app/services/base/invitations"
import { noSpamCharFunction } from "../../../utils/reviewUtils"

const ratings = [
  { value: 1, text: "Pessimo" },
  { value: 2, text: "Scarso" },
  { value: 3, text: "Nella media" },
  { value: 4, text: "Molto buono" },
  { value: 5, text: "Eccellente" },
]

export const ProductReview: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const user = useAppSelector(selectCurrentUser)
  const { invitationOrderId, key, productId } = useParams()
  const { data: invitationsOrders } = useGetInvitationsOrdersQuery(
    user ? { email: user.email, page: 1, perPage: 10 } : skipToken,
  )
  const product = invitationsOrders
    ? invitationsOrders
        ?.find((io) => io.id == Number(invitationOrderId))
        ?.products?.find((p) => p.id == productId)
    : undefined

  const [createProductReview] = useCreateProductReviewMutation()

  const [formData, setFormData] = useState<{
    description: string
    rating: string
  }>({ description: "", rating: "0" })

  function updateRating(key: string, value: string) {
    setFormData({
      ...formData,
      [key]: value,
    })
  }

  function handleInputChange(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    const { name, value } = e.currentTarget
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  async function sendProductReview() {
    try {
      const response = await createProductReview({
        score: formData.rating,
        description: formData.description,
        order_id: invitationOrderId || "",
        prod_id: productId!,
      }).unwrap()
      navigate(`/areariservata/reviews-acquisti?o=${productId}`)
    } catch (err) {
      dispatch(
        addToast({
          message: "Ops! Impossibile recensire il prodotto al momento.",
          type: "danger",
        }),
      )
    }
  }

  if (!invitationsOrders) {
    return <></>
  }
  return (
    <div className="w-container utente vetrina update-review white-background">
      <div className="container">
        <form id="review-form" className="default-form myform">
          <div className="row justify-content-center text-center my-3">
            <div className="col-12">
              <h2 className="title title-prod"> Recensisci Prodotto </h2>
            </div>
          </div>

          <div id="prods-container">
            <div className="row justify-content-center my-3">
              <div className="col-12">
                <div className="row prod-container align-items-center mx-1 p-1">
                  <div className="col-12 my-2">
                    {product?.img_prod && (
                      <img
                        src={product.img_prod}
                        height="72"
                        width="72"
                        loading="lazy"
                        alt={product.name}
                      />
                    )}
                    <span className="item-prod-name mx-3">
                      {" "}
                      {product?.name}{" "}
                    </span>
                  </div>
                </div>
                <div
                  className={`prod-review-container ${
                    key == "0" ? "show" : ""
                  } my-1`}
                >
                  <div className="row mx-1 my-2">
                    <div className="col-12 form-group p-0">
                      <span className="step-title-txt font-weight-bold d-block my-3">
                        {" "}
                        Valutazione Complessiva{" "}
                      </span>
                      <div className="d-flex justify-content-start align-items-center">
                        <div className="br-wrapper br-theme-bars-movie">
                          <select
                            className="form-control"
                            name="rating"
                            id={`ReviewUpdateForm_rating`}
                            style={{ display: "none" }}
                            value={formData.rating}
                          >
                            <option value="">Vota</option>
                            <option value="0"></option>
                            {ratings.map((r) => (
                              <option key={r.value} value={r.value}>
                                {r.text}
                              </option>
                            ))}
                          </select>
                          <div className="br-widget">
                            <div className="br-current-rating br-selected-5 br-active-3 br-active-2 br-active-1 br-active-4 br-active-5">
                              <em>Eccellente</em>
                            </div>
                            {ratings.map((r) => (
                              <a
                                title={r.text}
                                className={`${
                                  Number(formData.rating) >= r.value
                                    ? `br-selected-${formData.rating}`
                                    : ""
                                } ${
                                  Number(formData.rating) == r.value
                                    ? "br-current"
                                    : ""
                                }`}
                                onClick={() =>
                                  updateRating("rating", r.value.toString())
                                }
                              >
                                <FontAwesomeIcon icon={["fas", "star"]} />
                              </a>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mx-1 my-2">
                    <div className="col-12 form-group p-0">
                      <span className="step-title-txt font-weight-bold d-block my-3">
                        {" "}
                        Aggiungi una recensione scritta
                      </span>

                      <textarea
                        id="ProductReviewsForm_descrizione_<?=$item->id?>"
                        value={formData.description}
                        name="description"
                        className="form-control form-desc mt-3 noSpamChar"
                        rows={3}
                        placeholder="Racconta la tua esperienza e aiuta gli altri utenti ad acquistare in modo più consapevole."
                        maxLength={500}
                        minLength={30}
                        onKeyPress={noSpamCharFunction}
                        onChange={handleInputChange}
                      ></textarea>
                      <span className="counter">
                        caratteri min 30, max 500, rimanenti:{" "}
                        {500 - formData.description.length}
                      </span>
                    </div>
                  </div>
                  <div className="row mx-1 my-2">
                    <div className="col-12 form-group p-0">
                      <div>
                        <Link
                          to="/it/areariservata/reviews-acquisti"
                          className="txt-blue-bold float-left"
                        >
                          {" "}
                          <FontAwesomeIcon icon={["fas", "arrow-left"]} /> Torna
                          indietro{" "}
                        </Link>

                        <button
                          type="button"
                          className={`btn btn-invia-rec btn-orange btn-fullscreen-mobile float-right `}
                          onClick={() => sendProductReview()}
                          disabled={
                            formData.description.length < 30 ||
                            formData.rating == "0"
                              ? true
                              : false
                          }
                        >
                          {" "}
                          Invia{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="d-block my-3"></div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <style>{barsMovie}</style>
    </div>
  )
}

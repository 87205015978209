import React, { ChangeEvent, useState } from "react"
import { FiltersButton } from "./FiltersButton"
import { OrderByButton } from "./OrderByButton"
import { SearchFiltersSidebar } from "./SearchFiltersSidebar"
import { ActiveSearchFilters } from "./ActiveSearchFilters"
import {
  SearchFilter,
  SearchFilterOption,
} from "../../../models/base/searchFilter.interface"
import { useAppSelector } from "../../../app/hooks"
import { selectIsSidebarOpen } from "./searchFiltersSlice"
import { ProductsSearchEngine } from "../../products_search_engine/ProductsSearchEngine"

interface SearchFilterProps {
  filtersToRender: SearchFilter[]
  ordersToRender: SearchFilterOption[]
  eShopDomain?: string
  searchBarValue?: string
  handleSearch?: (searchBarValue: string) => void
}

export const SearchFilters: React.FC<SearchFilterProps> = ({
  filtersToRender,
  ordersToRender,
  eShopDomain,
  searchBarValue,
  handleSearch,
}) => {
  const [search, setSearch] = useState<string>(
    searchBarValue ? searchBarValue : "",
  )
  const isSidebarOpen = useAppSelector(selectIsSidebarOpen)
  return (
    <>
      <div className="row no-gutters">
        <div
          id="search-engine-container"
          className="col-12 col-md-12 col-lg-12"
        >
          <div className="search-engine-container d-flex align-items-center justify-content-between mb-3">
            {!eShopDomain ? (
              <FiltersButton />
            ) : (
              <div className="col-12 col-md-12 col-lg-7">
                <ProductsSearchEngine
                  eShopDomain={eShopDomain}
                  handleSearch={handleSearch}
                />
              </div>
            )}
            <OrderByButton ordersToRender={ordersToRender} />
            {isSidebarOpen && (
              <SearchFiltersSidebar filtersToRender={filtersToRender} />
            )}
          </div>
        </div>
      </div>
      <ActiveSearchFilters />
    </>
  )
}

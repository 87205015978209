import React, { useState } from "react"
import starBig from "../../../../assets/icons/star_big.webp"
import { Carousel } from "react-bootstrap"
import "./BootstrapCarousel.css"
import { BootstrapCoupons } from "./BootstrapCoupons"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { HomeCoupon } from "../../../../models/base/homeCoupon.interface"

interface BootstrapCarouselProps {
  coupons: HomeCoupon[]
}

export const BootstrapCarousel: React.FC<BootstrapCarouselProps> = ({
  coupons,
}) => {
  const [index, setIndex] = useState(0)
  const [isActive, setIsActive] = useState<boolean>(true)

  const selectedData = coupons[index]

  const filterForNextElements = (couponsArray: any[], index: number): any[] => {
    // Get the next 3 elements after the iterator
    const nextThree = couponsArray.slice(index + 1, index + 4)

    // If there are not enough elements in the array after the iterator,
    // take elements from the beginning of the array to complete the count
    const remaining = 3 - nextThree.length
    if (remaining > 0) {
      nextThree.push(...couponsArray.slice(0, remaining))
    }

    return nextThree
  }

  const handlePrevClick = () => {
    if (index > 0) {
      setIndex(index - 1)
    } else if (index === 0) setIndex(coupons.length - 1)
  }

  const handleNextClick = () => {
    if (index === coupons.length - 1) {
      setIndex(0)
    } else if (index === 0 || index > 0) setIndex(index + 1)
  }

  const handleSelectByID = (selectedID: string) => {
    const indexByID = coupons.findIndex((c) => c.coupon_id === selectedID)
    setIndex(indexByID)
  }

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex)
  }

  const handleOnSlide = (eventKey: number, direction: "end" | "start") => {
    setIsActive(false)
  }

  const handleOnSlid = (eventKey: number, direction: "end" | "start") => {
    setIsActive(true)
  }

  return (
    <div
      // id="highlightsContainer"
      className="mb-3 mb-md-5"
      data-tm="TM_home_sponsoredshop_view_coupon_title"
    >
      <div
        // id="coupon-highlights-background"
        className="coupon-highlights-background card my-5 no-border-radius background-no-repeat background-image-fit"
        style={{
          backgroundImage: `url(${selectedData.category_img})`,
        }}
      >
        <div className="containerDarkBackdrop">
          <div className="row align-content-center min-h-650 w-100 h-100">
            <div className="col-12 col-md-4 offset-md-1">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex align-items-center justify-content-left py-2">
                      <img
                        src={starBig}
                        className="width-50 d-inline-flex img-fluid"
                        alt="Punteggio recensioni"
                      />
                      <span className="bolder-white-title text-overflow-ellipis xs-font-33 font-47 d-inline-flex align-items-baseline ms-3">
                        <span
                          // id="coupon-highlights-rating"
                          className="coupon-highlights-rating font-bolder"
                        >
                          {" "}
                          {selectedData.eshop_rating.rating}{" "}
                        </span>
                        <span className="fade-white-txt ms-2 font-25">
                          {" "}
                          /5{" "}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-block py-2">
                      <Link
                        to={`/it/eshop/${selectedData.eshop_domain}`}
                        // id="coupon-highlights-domain"
                        className="coupon-highlights-domain simple-white-link bolder-white-title line-clamp font-40"
                        title={selectedData.eshop_domain}
                      >
                        {selectedData.eshop_domain}
                      </Link>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="sd-block py-2">
                      <span className="simple-white-txt font-23 line-clamp line-clamp-4">
                        Approfitta degli sconti!
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-7">
              <div className="container text-center my-3">
                <div className="row mx-auto my-auto">
                  <Carousel
                    id="cardCarousel"
                    className="w-100"
                    interval={5000}
                    wrap={true}
                    indicators={false}
                    controls={false}
                    activeIndex={index}
                    onSelect={handleSelect}
                    onSlide={handleOnSlide}
                    onSlid={handleOnSlid}
                  >
                    {coupons.map((coupon, couponIndex) => (
                      <Carousel.Item
                        key={couponIndex}
                        className={`w-100 align-items-center`}
                      >
                        <BootstrapCoupons
                          isActive={isActive}
                          coupon={coupon}
                          nextCoupons={filterForNextElements(
                            coupons,
                            couponIndex,
                          )}
                          onSelect={handleSelectByID}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center position-bottom-25-center">
            <span
              className="simple-link"
              role="button"
              onClick={handlePrevClick}
            >
              <span
                // id="highlights-prev"
                className="carousel-control white-color mx-2"
              >
                {" "}
                <FontAwesomeIcon icon={["fas", "chevron-left"]} />{" "}
              </span>
            </span>
            <span
              className="simple-link next"
              role="button"
              onClick={handleNextClick}
            >
              <span
                // id="highlights-next"
                className="carousel-control white-color mx-2"
              >
                {" "}
                <FontAwesomeIcon icon={["fas", "chevron-right"]} />{" "}
              </span>
            </span>
            <span className="">
              <Link
                to="/it/coupon/ultimi-coupon"
                className="btn btn-blur px-4 py-2 mx-3 waitLink position-absolute"
              >
                Vai al catalogo
              </Link>
            </span>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  )
}

import React from "react"
import star from "../../assets/icons/star.webp"
import { formatNumber, toRatingText } from "../../utils/stringUtils"

interface RatingStarsProps {
  rating: number
  size?: "small" | "big" | "big-xs-small" | "normal-xs-small"
  customClass?: string
  showAverageRating?: boolean
  showAverageRatingLet?: boolean
  bigRating?: boolean
  nReviewsCompact?: number
  nReviews?: number
  customNReviewText?: string
  customNReviewClass?: string
}

export const RatingStars: React.FC<RatingStarsProps> = ({
  rating,
  size,
  customClass,
  showAverageRating,
  showAverageRatingLet,
  bigRating,
  nReviewsCompact,
  nReviews,
  customNReviewText,
  customNReviewClass,
}) => {
  rating = rating ? rating : 0
  function calcWidth(position: number) {
    // debug if
    try {
      // cast rating to number
      rating = Number(rating.toFixed(1))
    } catch (e) {
      rating = Number(rating)
    }
    let base = parseInt(rating.toString().split(".")[0])
    let float = parseInt(rating.toString().split(".")[1])
    if (isNaN(float)) {
      float = 0
    }

    if (rating > position) {
      return {
        width: "100%",
      }
    } else if (rating < position && base == position - 1) {
      return {
        width: `${float}0%`,
        borderRadius: ".25rem 0 0 .25rem",
      }
    } else if (rating < position && base < position) {
      return {
        width: "0%",
      }
    }
  }

  return (
    <>
      <div
        className={`d-flex justify-content-left align-items-center rating-eshop ${
          customClass ? customClass : ""
        }`}
      >
        {[1, 2, 3, 4, 5].map((number) => (
          <div
            key={number}
            className={`d-inline-flex justify-content-center align-items-center star-container me-1 ${
              size == "small"
                ? "star-small-container"
                : size == "big"
                ? "star-big-container"
                : size == "big-xs-small"
                ? "star-big-container  xs-star-small-container"
                : size == "normal-xs-small"
                ? "xs-star-small-container"
                : ""
            }`}
          >
            <div className="star-progress-container">
              <div
                className={`star-progress star-${number}`}
                style={calcWidth(number)}
              ></div>
            </div>
            <img src={star} alt={`punteggio recensioni ${number}`} />
          </div>
        ))}
        {showAverageRating && (
          <span
            className={`rating-number-eshop font-bolder ms-1 ms-sm-2 ${
              bigRating ? "font-33 xs-font-16" : "font-23"
            }`}
          >
            {formatNumber(rating, 1)}
          </span>
        )}
        {showAverageRatingLet && (
          <span className="rating-let dark-color font-18 lg-font-33 font-bolder lg-font-semi-bold ms-2">
            {toRatingText(rating)}
          </span>
        )}
        {nReviewsCompact != null && (
          <span className="ms-2 font-16 font-semi-bold gray-color">
            {`(${nReviewsCompact})`}
          </span>
        )}
        {nReviews != null && (
          <span
            className={`ms-auto gray-text text-right font-light d-none d-xs-block ${customNReviewClass}`}
          >
            {nReviews > 0 ? (
              <>
                {customNReviewText} {nReviews}{" "}
                {nReviews === 1 ? "recensione" : "recensioni"}
              </>
            ) : (
              "Nessuna Recensione"
            )}
            {customNReviewText} {nReviews}{" "}
            {nReviews === 1 ? "recensione" : "recensioni"}
          </span>
        )}
      </div>
      {nReviews != null && (
        <div className="d-block d-xs-none">
          <span
            className={`ms-auto gray-text text-right font-light ${customNReviewClass}`}
          >
            {nReviews > 0 ? (
              <>
                {customNReviewText} {nReviews}{" "}
                {nReviews === 1 ? "recensione" : "recensioni"}
              </>
            ) : (
              "Nessuna Recensione"
            )}
          </span>
        </div>
      )}
    </>
  )
}

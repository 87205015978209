import React from "react"
import { Helmet } from "react-helmet"

interface DefaultSchemaProps {
  showOrganization: boolean
  customUrl?: string
  showImageObject: boolean
  metaTitle: string
  metaDescription: string
}

export const DefaultSchema: React.FC<DefaultSchemaProps> = ({
  showOrganization,
  customUrl,
  showImageObject,
  metaTitle,
  metaDescription,
}) => {
  const url = customUrl ? customUrl : window.location.href
  return (
    <Helmet>
      <script type="application/ld+json">
        {`{
            "@context": "https://schema.org",
            "@graph":[
                ${
                  showOrganization &&
                  `
                    {
                        "@type":"Organization",
                        "@id":"${url}#organization",
                        "name":"eShoppingAdvisor",
                        "url":"${url}",
                        ${
                          showImageObject &&
                          `
                            "logo":{
                                "@type":"ImageObject",
                                "@id":"${url}#logo",
                                "url":"https://www.eshoppingadvisor.com/logo.png",
                                "width":1200,
                                "height":1200,
                                "caption":"${metaTitle}"
                            },
                            "image":{
                                "@id":"${url}#logo"
                            },
                        `
                        }
                        "sameAs": [
                            "https://twitter.com/Eshoppingadvis",
                            "https://facebook.com/eshoppingadvisoritalia/",
                            "https://www.instagram.com/eshoppingadvisor/",
                            "https://www.linkedin.com/company/eshoppingadvisor",
                            "https://www.youtube.com/channel/UCtMuZfE3wPcyQDlPzFxaImA"
                        ]
                    },
                `
                }
                {
                    "@type": "WebSite",
                    "@id": "${url}#website",
                    "url": "${url}",
                    "name": "eShoppingAdvisor",
                    "publisher":{
                        "@id":"${url}#organization"
                    },
                    "potentialAction": {
                        "@type": "SearchAction",
                        "target": {
                          "@type": "EntryPoint",
                          "urlTemplate": "${
                            import.meta.env.VITE_B2C_BASE_URL
                          }it/search/eshopSearch/{search_term_string}"
                        },
                        "query-input": "required name=search_term_string"
                    },
                    "description": "${metaDescription}",
                    "inLanguage": "it-IT"
                },
                
                ${
                  showImageObject &&
                  `
                    {
                        "@type": "ImageObject",
                        "@id": "${url}#primaryimage",
                        "inLanguage": "it-IT",
                        "url":"https://www.eshoppingadvisor.com/logo.png",
                        "width":1200,
                        "height":1200
                    },
                `
                }
                {
                    "@type": "WebPage",
                    "@id": "${url}#webpage",
                    "url": "${url}",
                    "name": "${metaTitle}",
                    "isPartOf": {
                        "@id": "${url}#website"
                    },
                    "primaryImageOfPage": {
                        "@id": "${url}#primaryimage"
                    },
                    "description": "${metaDescription}",
                    "inLanguage": "it-IT"
                }
            ]
        }`}
      </script>
    </Helmet>
  )
}

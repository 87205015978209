import React, { useState } from "react"
import { useAppDispatch } from "../../app/hooks"
import { Link, useNavigate } from "react-router-dom"
import { logout } from "../auth/authSlice"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

interface DropdownMenuHeaderItemProps {
  index: number
  menuItem: any
}

export const DropdownMenuHeaderItem: React.FC<DropdownMenuHeaderItemProps> = ({
  index,
  menuItem,
}) => {
  const [isHover, setIsHover] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  function handleLogout() {
    dispatch(logout())
    navigate("/")
  }
  return (
    <li
      key={index}
      className={`d-flex align-items-center jusify-content-center ${
        isHover ? "active" : ""
      }`}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <FontAwesomeIcon
        icon={menuItem.iconName as IconProp}
        className={`user-action-icon ${isHover ? "orange-color" : ""}`}
      />
      {menuItem.title === "Logout" ? (
        <Link to={menuItem.link} onClick={handleLogout}>
          {menuItem.title}
        </Link>
      ) : (
        <Link to={menuItem.link}>{menuItem.title}</Link>
      )}
    </li>
  )
}

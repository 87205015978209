import React from "react"
import { Modal, Nav, Tab } from "react-bootstrap"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectEsaValuesModal, setEsaValuesModal } from "./modalsSlice"
import { useGetReviewsStatsQuery } from "../../app/services/base/reviews"
import { formatDate } from "../../utils/stringUtils"
import { skipToken } from "@reduxjs/toolkit/query"
import { ReviewsStats } from "../../models/base/reviewsStats.interface"
import { RatingStars } from "../rating_stars/RatingStars"

const ESAValuesModalTabContent: React.FC<{ reviewsStats: ReviewsStats }> = ({
  reviewsStats,
}) => {
  return (
    <div className="eshop-container">
      <div className="eshop-detailed-rating">
        <ol className="detalied-rating row justify-content-center border-bottom mb-3 pb-3">
          {reviewsStats &&
            Object.entries(reviewsStats.params).map(([key, value]) => (
              <li className="font-16 font-bolder gray-color">
                <div className="d-block d-lg-flex align-items-center justify-content-between my-3">
                  <span className="font-bolder">{key} </span>
                  <div className="d-flex justify-content-end">
                    <RatingStars rating={value ? value : 0} showAverageRating />
                  </div>
                </div>
              </li>
            ))}
        </ol>
      </div>
      <div className="based-on-container my-3">
        <span className="font-14 dark-color float-start">
          Basato su
          <span className="orange-color font-bolder">
            {" "}
            {reviewsStats.total_reviews}{" "}
          </span>
          recensioni <b> eShoppingAdvisor </b>
        </span>
      </div>
    </div>
  )
}

export const ESAValuesModal: React.FC = () => {
  const dispatch = useAppDispatch()
  const shop = useAppSelector(selectEsaValuesModal)

  const { data: reviewsStats, isLoading: isLoading } = useGetReviewsStatsQuery(
    shop
      ? {
          eshopId: shop.id,
        }
      : skipToken,
  )

  const { data: reviewsStats3, isLoading: isLoading3 } =
    useGetReviewsStatsQuery(
      shop
        ? {
            eshopId: shop.id,
            since: formatDate(
              new Date(new Date().setMonth(new Date().getMonth() - 3)),
            ),
            until: formatDate(new Date()),
          }
        : skipToken,
    )

  const { data: reviewsStats12, isLoading: isLoading12 } =
    useGetReviewsStatsQuery(
      shop
        ? {
            eshopId: shop.id,
            since: formatDate(
              new Date(new Date().setMonth(new Date().getMonth() - 12)),
            ),
            until: formatDate(new Date()),
          }
        : skipToken,
    )

  return (
    <>
      {shop && (
        <Modal
          show={shop ? true : false}
          onHide={() => dispatch(setEsaValuesModal(undefined))}
          size="lg"
          centered
          aria-labelledby="esaValuesModal"
          contentClassName="custom-rounded"
        >
          <Tab.Container defaultActiveKey={"allReviews"}>
            <Modal.Header className="text-center px-1 px-lg-3 no-borders">
              <nav
                className="hide-scrollbar"
                style={{
                  overflowX: "scroll",
                  width: "100%",
                  minWidth: "80%",
                  msOverflowStyle: "none",
                  scrollbarWidth: "none",
                }}
              >
                <Nav
                  variant="underline"
                  className="hide-scrollbar custom-tabs flex-row overflow-x-scroll flex-nowrap my-3 px-3"
                >
                  {reviewsStats && (
                    <Nav.Link
                      eventKey="allReviews"
                      className="font-16 p-1 gray-color"
                    >
                      Tutte le recensioni
                    </Nav.Link>
                  )}
                  {reviewsStats3 && (
                    <Nav.Link
                      eventKey="reviews3"
                      className="font-16 p-1 gray-color"
                    >
                      Ultimi 3 mesi
                    </Nav.Link>
                  )}
                  {reviewsStats12 && (
                    <Nav.Link
                      eventKey="reviews12"
                      className="font-16 p-1 gray-color"
                    >
                      Ultimi 12 mesi
                    </Nav.Link>
                  )}
                </Nav>
              </nav>
              <div className="row px-1 px-lg-5 mx-auto w-100">
                <button
                  type="button"
                  className="btn-close float-end d-none d-lg-block position-relative position-left-30"
                  style={{ top: "5px" }}
                  onClick={() => dispatch(setEsaValuesModal(undefined))}
                ></button>
                <button
                  type="button"
                  className="btn-close d-block d-lg-none position-relative m-auto"
                  style={{ top: "5px" }}
                  onClick={() => dispatch(setEsaValuesModal(undefined))}
                ></button>
              </div>
            </Modal.Header>
            <Modal.Body className="px-1 px-lg-3">
              <Tab.Content className="px-3">
                {reviewsStats && (
                  <Tab.Pane
                    eventKey="allReviews"
                    aria-labelledby="Tutte le recensioni"
                  >
                    <ESAValuesModalTabContent reviewsStats={reviewsStats} />
                  </Tab.Pane>
                )}
                {reviewsStats3 && (
                  <Tab.Pane eventKey="reviews3" aria-labelledby="Ultimi 3 mesi">
                    <ESAValuesModalTabContent reviewsStats={reviewsStats3} />
                  </Tab.Pane>
                )}
                {reviewsStats12 && (
                  <Tab.Pane
                    eventKey="reviews12"
                    aria-labelledby="Ultimi 12 mesi"
                  >
                    <ESAValuesModalTabContent reviewsStats={reviewsStats12} />
                  </Tab.Pane>
                )}
              </Tab.Content>
            </Modal.Body>
          </Tab.Container>
        </Modal>
      )}
    </>
  )
}

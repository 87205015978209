import React from "react"
import { useParams } from "react-router-dom"
import { useGetCategoryQuery } from "../../../app/services/base/categories"
import { MacroCategory } from "../macro_category/MacroCategory"
import { SubCategory } from "../sub_category/SubCategory"
import { AppBreadcrumb } from "../../../features/app_breadcrumb/AppBreadcrumb"
import { CategorySchema } from "../../../features/schemas/CategorySchema"

export const Category: React.FC = () => {
  const { categorySlug } = useParams()
  const { data: category } = useGetCategoryQuery(categorySlug!)

  if (!category) {
    return <></>
  }

  return (
    <>
      <div className="container">
        <CategorySchema category={category} />
        <AppBreadcrumb
          routes={[
            { name: "Home", slug: "/" },
            { name: "Categorie", slug: "/it/categorie" },
            { name: category.name, slug: category.slug },
          ]}
        />
      </div>
      {category && category!.subCategories ? (
        <MacroCategory category={category} />
      ) : (
        <SubCategory category={category!} />
      )}
    </>
  )
}

import React from "react"
import { Helmet } from "react-helmet"

interface AppBreadcrumbSchemaProps {
  routes: { name: string; slug: string }[]
}

export const AppBreadcrumbSchema: React.FC<AppBreadcrumbSchemaProps> = ({
  routes,
}) => {
  return (
    <Helmet>
      <script type="application/ld+json">
        {`
            {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [
                ${routes.map((route, index) => {
                  let last = false
                  if (index + 1 === routes.length) {
                    last = true
                  }
                  return `{
                    "@type": "ListItem",
                    "position": ${index + 1},
                    "name": "${route.name}",
                    "item": "${import.meta.env.BASE_URL + route.slug}"
                  }`
                })}
              ]
            }
          `}
      </script>
    </Helmet>
  )
}

import React, { useState } from "react"
import { RecommendedCategory } from "./RecommendedCategory"
import { useGetCategoriesQuery } from "../../../app/services/base/categories"

export const RecommendedCategories: React.FC = () => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(0)
  const { data: recommendedCategories } = useGetCategoriesQuery({
    type: "recommended",
  })

  const handleHover = (index: number) => {
    setHoveredIndex(index)
  }

  if (!recommendedCategories) {
    return <></>
  }

  return (
    <div
      // id="CategoriesRecommended-section"
      className="container mb-3 mb-lg-5"
      data-tm="TM_home_carousel_sponsored_eshop_category_title"
    >
      <div className="d-block d-lg-flex overflow-hidden w-100 mw-100 mx-auto">
        {recommendedCategories.map((rc, i) => (
          <RecommendedCategory
            key={rc.id}
            index={i}
            hoveredIndex={hoveredIndex}
            onHover={handleHover}
            category={rc}
          />
        ))}
      </div>
    </div>
  )
}

import React from "react"
import { useParams } from "react-router-dom"
import { useGetReviewQuery } from "../../app/services/base/reviews"
import { skipToken } from "@reduxjs/toolkit/query"
import { ReviewsModifyUpdate } from "./ReviewsModifyUpdate"

export const ReviewsModifyPublic: React.FC = () => {
  const { reviewId, code } = useParams()
  const { data } = useGetReviewQuery(reviewId ? reviewId : skipToken)

  if (!data || !code) {
    return <></>
  }

  return (
    <>
      {data.review.status != 4 ? (
        <ReviewsModifyUpdate data={data} code={code} />
      ) : (
        <div className="w-container utente white-background">
          <div className="update-review mod-ok">
            <div className="row">
              <div className="col-md-12">
                <h1>Recensione eliminata</h1>
                <h2>
                  La recensione che vuoi visualizzare ci risulta eliminata.
                </h2>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

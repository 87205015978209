import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectUpdateReviewModal, setUpdateReviewModal } from "./modalsSlice"
import { Modal } from "react-bootstrap"

interface UpdateReviewModalProps extends React.HTMLAttributes<HTMLDivElement> {
  callback: any
}

export const UpdateReviewModal: React.FC<UpdateReviewModalProps> = ({
  callback,
}) => {
  const dispatch = useAppDispatch()
  const updateModal = useAppSelector(selectUpdateReviewModal)

  return (
    <Modal
      show={updateModal ? true : false}
      onHide={() => dispatch(setUpdateReviewModal(undefined))}
      size="sm"
      centered
      aria-labelledby="shareModalLabel"
      dialogClassName="justify-content-center"
      contentClassName="bg-transparent position-relative border-0"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="bootbox-close-button close float-end white-background"
              style={{ marginTop: "-10px" }}
              onClick={() => dispatch(setUpdateReviewModal(undefined))}
            >
              ×
            </button>
            <div className="bootbox-body">
              <h4 className="popupTitle text-center orange-color">
                Attenzione!
              </h4>
              Dichiaro di aver scritto questa recensione in base alla mia
              personale esperienza di acquisto e di non avere alcuna relazione
              con il negozio online recensito.
              <br />
              Non mi sono stati offerti incentivi di alcun tipo per scrivere
              questa recensione.
            </div>
          </div>
          <div className="modal-footer">
            <button
              data-bb-handler="cancel"
              type="button"
              className="btn btn-outline"
              onClick={() => dispatch(setUpdateReviewModal(undefined))}
            >
              Rifiuto
            </button>
            <button
              data-bb-handler="confirm"
              type="button"
              className="btn btn-gradient-orange"
              onClick={() => {
                dispatch(setUpdateReviewModal(undefined))
                callback()
              }}
            >
              Accetto
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

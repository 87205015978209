import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDeleteAccountMutation } from "../../../app/services/base/users"
import { useAppDispatch } from "../../../app/hooks"
import { logout } from "../../../features/auth/authSlice"
import { addToast } from "../../../features/toasts/toastsSlice"

export const DeleteAccount: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [deleteAccount, { error }] = useDeleteAccountMutation()
  const [isDeleted, setIsDeleted] = useState<boolean>(false)

  async function handleDelete() {
    try {
      deleteAccount().then(() => {
        dispatch(
          addToast({
            message: "Account eliminato correttamente.",
            type: "success",
          }),
        )
        dispatch(logout())
        navigate("/")
      })
    } catch (err) {
      dispatch(
        addToast({
          message: "Ops! Impossibile eliminare l'account al momento.",
          type: "danger",
        }),
      )
    }
  }
  return (
    <div className="w-container utente elimina white-background">
      {!isDeleted ? (
        <>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <h3>Cancella account </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <form id="delete-confirm">
                <p>
                  Vuoi davvero cancellare il tuo account? L'operazione non è
                  reversibile
                </p>
                <fieldset>
                  <button
                    className="btn btn-danger"
                    type="button"
                    onClick={handleDelete}
                  >
                    Si, confermo
                  </button>
                  <button
                    className="btn btn-default btn-def-c"
                    type="button"
                    onClick={() => navigate("/areariservata/profilo")}
                  >
                    No, non confermare
                  </button>
                </fieldset>
              </form>
            </div>
          </div>
        </>
      ) : (
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <h3 className="title">Cancella account</h3>
          <div className="well" style={{ textAlign: "center" }}>
            Cancellazione dal sito avvenuta con successo <br />
          </div>
        </div>
      )}
    </div>
  )
}

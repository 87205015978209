import { useState, lazy, Suspense, useEffect } from "react"
import { Shop } from "../../models/base/shop.interface"
import { Carousel } from "react-bootstrap"
import { ShopRating } from "./ShopRating"
import { ReviewFieldInput } from "./ReviewFieldInput"
import { FilePond, registerPlugin } from "react-filepond"
import { Form } from "react-bootstrap"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  selectSendReviewFormData,
  updateImages,
  updateVideo,
  updateVideoUrl,
} from "../../pages/send_review/sendReviewSlice"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type"
// import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size"
// import FilePondPluginFileEncode from "filepond-plugin-file-encode"
// import FilePondPluginImagePreview from "filepond-plugin-image-preview"

import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"
import "filepond/dist/filepond.min.css"
import { useSearchParams } from "react-router-dom"
import { ReviewFieldTextarea } from "./ReviewFieldTextarea"
import { ReviewFieldCheck } from "./ReviewFieldCheck"
import shopPlaceholderLogo from "../../assets/images/thumb_placeholder.png"
import { getShopLogo } from "../../utils/shopUtils"

// Define the types for the plugins
type Plugin = {
  default: any
}
interface StepOneProps {
  isMobile: boolean | 0 | undefined
  slide: number
  shop?: Shop
  changeSlide: (stepTo: "prev" | "next") => void
}

export const StepOne: React.FC<StepOneProps> = ({
  isMobile,
  slide,
  shop,
  changeSlide,
}) => {
  useEffect(() => {
    Promise.all<Plugin>([
      import("filepond-plugin-file-validate-type"),
      import("filepond-plugin-file-validate-size"),
      import("filepond-plugin-file-encode"),
      import("filepond-plugin-image-preview"),
    ]).then(
      ([
        { default: FilePondPluginFileValidateType },
        { default: FilePondPluginFileValidateSize },
        { default: FilePondPluginFileEncode },
        { default: FilePondPluginImagePreview },
      ]) => {
        registerPlugin(
          FilePondPluginFileValidateType,
          FilePondPluginFileValidateSize,
          FilePondPluginFileEncode,
          FilePondPluginImagePreview,
        )
      },
    )
  }, [])
  const [searchParams, setSearchParams] = useSearchParams()
  const eShopUrlParam = searchParams.get("e")
  const dispatch = useAppDispatch()
  const formData = useAppSelector(selectSendReviewFormData)

  const [isMedia, setIsMedia] = useState<boolean>(false)
  function renderReviewCardInputs(startIndex: number, endIndex?: number) {
    const renderScores = {
      "Chiarezza sito web": {
        infoText:
          "Il sito web visitato è chiaro e di semplice navigabilità nel suo complesso? Hai trovato facilmente ciò che cercavi?",
      },
      "Valutazione prodotto/servizio": {
        infoText:
          "Come valuti il prodotto o il servizio acquistato in ordine alla qualità e al prezzo?",
      },
      "Servizio di pagamento": {
        infoText:
          "E' stato facile effettuare il pagamento? Hai trovato il metodo di pagamento che preferisci?",
      },
      "Consegna/Reso": {
        infoText: "La consegna è avvenuta entro i tempi promessi?",
      },
      "Assistenza clienti": {
        infoText:
          "Se ne hai usufruito, gli operatori dell'assistenza clienti sono stati disponibili e cortesi? La problematica esposta è stata risolta? Se hai dovuto effettuare un reso, come è andata l'operazione in termini di semplicità e velocità?",
      },
      "Lo consiglieresti a qualcuno?": {
        infoText:
          "Sulla base della tua esperienza generale di acquisto consiglieresti a qualcuno questo ecommerce?",
      },
    }
    const entries = Object.entries(renderScores).slice(startIndex, endIndex)
    return entries.map(([key, value], index) => (
      <ReviewFieldInput
        key={key}
        index={startIndex + index + 1}
        label={key}
        infoText={value.infoText}
        inputName={key}
        reviewType="shop"
      />
    ))
  }

  const handleSelect = (selectedSlide: number) => {
    changeSlide(slide < selectedSlide ? "next" : "prev")
  }

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      {/* <!-- Start eCommerce header --> */}
      <div className="card d-flex flex-row align-items-center justify-content-start w-100 p-2 mb-2">
        {shop && (
          <div
            style={{ width: "70px", height: "70px" }}
            className="d-flex align-items-center ms-1 me-3 overflow-hidden"
          >
            <img
              src={getShopLogo(shop.id)}
              alt={`Scrivi la tua recensione di ${shop.domain}`}
              width="100%"
              onError={(e) => (e.currentTarget.src = shopPlaceholderLogo)}
            />
          </div>
        )}
        <div className="d-flex flex-column align-items-start justify-content-center step-header gap-1 me-1">
          <h1 className="font-16 dark-color font-bolder step-header-title m-0">
            {shop ? `Scrivi la tua recensione di ${shop.domain}` : ""}
          </h1>
          {/* <?php if($eshop->displayName != $eshop->domain): ?> */}
          <p className="lightgray-txt m-0 line-clamp">
            {shop?.domain ? shop.domain : "Recensisci eCommerce"}
          </p>
        </div>
      </div>
      {/* <!-- End eCommerce header --> */}

      {((isMobile && slide === 2) || (!isMobile && slide === 1)) && (
        <ShopRating />
      )}
      <div className="card w-100 p-3 mt-2 mb-4">
        <Carousel
          activeIndex={slide}
          slide={true}
          interval={null}
          controls={false}
          keyboard={false}
          wrap={false}
          touch={false}
          onSelect={handleSelect}
        >
          <Carousel.Item className="mb-5">
            <div
              id="slideOneServices"
              className="d-flex flex-column align-items-center justify-content-center"
            >
              <h2 className="font-23 dark-color font-bolder m-0 my-2">
                {shop?.domain
                  ? `Valuta i servizi di `
                  : "Valutazione dei servizi"}
                {shop?.domain && (
                  <span className="d-inline-block">{shop.domain}</span>
                )}
              </h2>
              {isMobile
                ? renderReviewCardInputs(0, 3)
                : renderReviewCardInputs(0)}
            </div>
          </Carousel.Item>
          {isMobile && (
            <Carousel.Item className="mb-5">
              <div
                id="slideTwoServices"
                className="d-flex flex-column align-items-center justify-content-center"
              >
                <h4 className="font-23 dark-color font-bolder m-0 my-2">
                  Valutazione dei servizi
                </h4>
                {renderReviewCardInputs(3)}
              </div>
            </Carousel.Item>
          )}
          <Carousel.Item className="mb-5">
            <div className="d-flex flex-column align-items-start justify-content-center">
              <ReviewFieldTextarea
                inputName="title"
                inputLabel="Titolo della recensione"
                placeholder="Dai un titolo alla tua recensione"
                minCharacters={3}
                maxCharacters={50}
              />
              <ReviewFieldTextarea
                inputName="description"
                inputLabel="Recensione"
                placeholder="Raccontaci la tua esperienza..."
                minCharacters={10}
                maxCharacters={600}
              />

              {shop &&
                shop.eshopData.structured_data.anonymous_reviews &&
                shop.eshopData.structured_data.anonymous_reviews == 1 && (
                  <div className="d-flex flex-column mb-4">
                    <ReviewFieldCheck inputName={"isAnonymous"} infoText />
                  </div>
                )}

              {!isMedia ? (
                <div
                  id="uploadMediaShow"
                  className="custom-file d-flex align-items-center justify-content-center w-100 mb-4 cursor-pointer"
                  onClick={() => setIsMedia(!isMedia)}
                >
                  <FontAwesomeIcon
                    icon={["fas", "photo-film"]}
                    className="me-1 font-23"
                  />
                  <span className="font-semi-bolder ms-2">
                    Carica foto/video
                  </span>
                </div>
              ) : null}

              {isMedia ? (
                <div id="media-row" className="row">
                  <div className="col-12">
                    <h4 className="font-16 dark-color font-bolder">
                      <b> Aggiungi una foto </b>
                    </h4>
                    <span className="font-12 dark-color">
                      E' possibile caricare fino a 5 immagini
                    </span>
                    <Suspense fallback={<div>Loading...</div>}>
                      <FilePond
                        ref={(ref) => ((window as any).pond = ref)}
                        className={"custom-filepond"}
                        allowFileEncode={true}
                        allowImagePreview={true}
                        imagePreviewHeight={100}
                        allowFileTypeValidation={true}
                        acceptedFileTypes={[
                          "image/jpg",
                          "image/jpeg",
                          "image/png",
                        ]}
                        allowFileSizeValidation={true}
                        maxFileSize={"3MB"}
                        maxTotalFileSize={"15MB"}
                        allowMultiple={true}
                        maxFiles={5}
                        credits={undefined}
                        allowPaste={false}
                        storeAsFile={true}
                        labelIdle="Trascina il tuo file qui oppure <span class='filepond--label-action'> Seleziona dai files </span>"
                        onupdatefiles={(fileItems) => {
                          dispatch(
                            updateImages(
                              fileItems.map((fileItem) => ({
                                source: fileItem.filename,
                                options: {
                                  type: "local",
                                  file: {
                                    name: fileItem.filename,
                                    size: fileItem.fileSize,
                                    type: fileItem.fileType,
                                  },
                                  metadata: {
                                    poster: fileItem.origin,
                                  },
                                },
                              })),
                            ),
                          )
                        }}
                      />
                    </Suspense>
                  </div>
                  <div className="col-12">
                    <h4 className="font-16 dark-color font-bolder">
                      <b> Carica un video </b>
                    </h4>
                    <span className="font-12 dark-color">
                      Il video deve avere una durata massima di 1:30 minuti e un
                      peso sotto i 250MB (.mp4, .mov, .avi, .flv, .wmv)
                    </span>
                    <div className="upload-video-area my-3">
                      {formData.shop.videoUrl == "" && (
                        <Suspense fallback={<div>Loading...</div>}>
                          <FilePond
                            allowFileEncode={true}
                            allowFileTypeValidation={true}
                            acceptedFileTypes={[
                              "video/mp4",
                              "video/mov",
                              "video/avi",
                              "video/flv",
                              "video/wmv",
                            ]}
                            allowFileSizeValidation={true}
                            maxFileSize={"250MB"}
                            maxTotalFileSize={"250MB"}
                            allowMultiple={false}
                            maxFiles={1}
                            credits={undefined}
                            allowPaste={false}
                            storeAsFile={true}
                            labelIdle="Trascina il tuo file qui oppure <span class='filepond--label-action'> Seleziona dai files </span>"
                            onupdatefiles={(fileItems) => {
                              dispatch(
                                updateVideo(
                                  fileItems.map((fileItem) => ({
                                    file: fileItem.file,
                                  })),
                                ),
                              )
                            }}
                          />
                        </Suspense>
                      )}
                      {!formData.shop.video && (
                        <div className="url-zone my-3">
                          <h4 className="font-16 dark-color font-bolder">
                            <b> Inserisci un link </b>
                          </h4>
                          <div className="video-url-input my-3">
                            <Form.Control
                              as={"textarea"}
                              name={"videoUrl"}
                              className="form-control form-control-custom form-control-gray d-block resize-none w-100"
                              placeholder="Inserisci una url di Youtube o Vimeo"
                              rows={1}
                              autoComplete="off"
                              style={{ height: "52px" }}
                              value={formData.shop.videoUrl}
                              onChange={(e) =>
                                dispatch(updateVideoUrl(e.currentTarget.value))
                              }
                              // isInvalid={!!errors[name]}
                            ></Form.Control>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </Carousel.Item>
        </Carousel>
        <div className="d-flex align-items-center justify-content-center mb-2 gap-1">
          <button
            type="button"
            id="slidePrevBtn"
            className="btn btn-outline-gray background-white font-16 font-semi-bolder flex-grow-1 me-1"
            style={{ height: "50px" }}
            onClick={() => changeSlide("prev")}
            disabled={slide == 0 ? true : false}
          >
            Precedente
          </button>
          <button
            type="button"
            className="btn btn-gradient-orange font-16 font-semi-bolder flex-grow-1 ms-1"
            style={{ height: "50px" }}
            onClick={() => changeSlide("next")}
          >
            Successivo
          </button>
        </div>
      </div>
    </div>
  )
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { Variant } from "react-bootstrap/esm/types"

export interface ToastsState {
  toasts: { message: string; type: Variant }[]
}

const initialState: ToastsState = {
  toasts: [],
}

export const toastsSlice = createSlice({
  name: "toasts",
  initialState,
  reducers: {
    addToast: (
      state,
      action: PayloadAction<{ message: string; type: Variant }>,
    ) => {
      // ROMPE IL FUNZIONAMENTO PERCHE NON RICEVIAMO L'EVENTO TOGGLE SE APERTI VELOCEMENTE
      // state.toasts.push(action.payload)
      //   QUINDI FORZO UNA SOLA NOTIFICA PER VOLTA
      state.toasts = [action.payload]
    },
    removeToast: (state, action: PayloadAction<number>) => {
      state.toasts.splice(action.payload, 1)
    },
  },
})

export const { addToast, removeToast } = toastsSlice.actions

export const selectToasts = (state: RootState) => state.toasts.toasts

export default toastsSlice.reducer

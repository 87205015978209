import { FetchBaseQueryError } from "@reduxjs/toolkit/query"

export function isFetchBaseQueryError(
  error: unknown,
): error is FetchBaseQueryError {
  return typeof error === "object" && error != null && "status" in error
}

export function isErrorWithMessage(
  error: unknown,
): error is { message: string } {
  return (
    typeof error === "object" &&
    error != null &&
    "message" in error &&
    typeof (error as any).message === "string"
  )
}

export class BaseLoader {
  _store = {} as any
  _dispatch = () => {}
  constructor(store: any) {
    this._store = store
    this._dispatch = store.dispatch
  }

  _loader = async (
    endpoint: any,
    request: any,
    query?: any,
    queryOptions?: any,
  ) => {
    const promise = this._store.dispatch(endpoint.initiate(query, queryOptions))
    request.signal.onabort = promise.abort
    const res = await promise
    const { data, isError, error } = res
    if (isError) {
      if (isFetchBaseQueryError(error)) {
        const errMsg =
          "error" in error ? error.error : JSON.stringify(error.data)
        throw new Response("", {
          status: typeof error.status === "number" ? error.status : 403,
          statusText: errMsg,
        })
      } else if (isErrorWithMessage(error)) {
        throw new Response("", {
          status: 403,
          statusText: error.message,
        })
      }
    }
    return data
  }
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"

export interface SearchEngineState {
  isScrolled: boolean
  isOpen: boolean
}

const initialState: SearchEngineState = {
  isScrolled: false,
  isOpen: true,
}

export const searchEngineSlice = createSlice({
  name: "searchEngine",
  initialState,
  reducers: {
    setSearchIsScrolled: (state, action: PayloadAction<boolean>) => {
      state.isScrolled = action.payload
    },
    toggleSearchIsOpen: (state) => {
      state.isOpen = !state.isOpen
    },
    forceIsOpenTo: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload
    },
  },
})

export const { setSearchIsScrolled, toggleSearchIsOpen, forceIsOpenTo } =
  searchEngineSlice.actions

export const selectSearchIsScrolled = (state: RootState) =>
  state.searchEngine.isScrolled

export const selectSearchIsOpen = (state: RootState) =>
  state.searchEngine.isOpen

export default searchEngineSlice.reducer

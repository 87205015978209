import React from "react"
import { Modal } from "react-bootstrap"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  selectUserCheckModal,
  setEmailInserted,
  setUserCheckModal,
  setUserType,
} from "./modalsSlice"
import { InvitationOrder } from "../../models/base/invitationOrder.interface"
import { UserCheck } from "../auth/UserCheck"

interface LoginModalProps {
  invitationOrder?: InvitationOrder
}

export const UserCheckModal: React.FC<LoginModalProps> = ({
  invitationOrder,
}) => {
  const toShow = useAppSelector(selectUserCheckModal)
  const dispatch = useAppDispatch()

  const handleCloseModal = () => {
    dispatch(setUserCheckModal(false))
  }

  return (
    <>
      <Modal
        show={toShow ? true : false}
        onHide={() => handleCloseModal()}
        // size="lg"
        centered
        aria-labelledby="loginModalLabel"
        dialogClassName="justify-content-center custom-modal-max-width"
        contentClassName="bg-transparent border-0"
      >
        <div className="d-flex align-items-center justify-content-center d-none d-lg-flex mb-4">
          <button
            type="button"
            className="btn-close btn-close-white"
            onClick={() => handleCloseModal()}
          ></button>
        </div>
        <div
          className="card d-flex flex-column align-items-center justify-content-center p-3 p-lg-4"
          style={{ borderRadius: "20px" }}
        >
          <UserCheck isInModal={true} invitationOrder={invitationOrder} />
        </div>
        <div className="d-flex align-items-center justify-content-center d-lg-none mt-4">
          <button
            type="button"
            className="btn-close btn-close-white"
            onClick={() => handleCloseModal()}
          ></button>
        </div>
      </Modal>
    </>
  )
}

import React from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectOnExitModal, setOnExitModal } from "./modalsSlice"
import { Modal } from "react-bootstrap"
import { selectMacroCategory } from "../../utils/categoryUtils"
import { Link } from "react-router-dom"
import onExitLogo from "../../assets/images/onexit-logo.png"
import onExitImg from "../../assets/images/onexit-img.png"
import lunarCss from "../../assets/css/old/lunar.css?inline"

export const OnExitModal: React.FC = () => {
  const dispatch = useAppDispatch()
  const shop = useAppSelector(selectOnExitModal)

  const macroCategory = shop
    ? selectMacroCategory(shop.esa_categories)
    : undefined

  return (
    <Modal
      show={shop ? true : false}
      onHide={() => dispatch(setOnExitModal(undefined))}
      size={shop?.status != 2 ? "xl" : undefined}
      centered
      aria-labelledby="onExitModalLabel"
      dialogClassName="custom-modal"
    >
      {shop?.status != 2 && macroCategory ? (
        <div className="container-fluid">
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={() => dispatch(setOnExitModal(undefined))}
            style={{
              height: "2rem",
              width: "2rem",
              fontSize: "14px",
              border: "none",
            }}
          >
            <span aria-hidden="true">×</span>
          </button>
          <div className="row">
            <div className="col-md-12 pb-3 px-0 my-auto text-center">
              <div className="d-block">
                <img
                  // src="https://esacdnstorage.azureedge.net/category-image/124-5f2bd4c1-f716-497d-a735-c375ceccb7ce.webp"
                  src={
                    macroCategory?.imageUrl ? macroCategory.imageUrl : onExitImg
                  }
                  className="img-fluid"
                />
                <div className="gradientback"> </div>
              </div>
              <div className="px-3 pt-4">
                <h4 className="t-sm-3 mb-4 custom-txt font-weight-light">
                  Stai cercando un prodotto in particolare?
                </h4>
                <p>
                  <Link
                    className="btn btn-gradient-orange px-3 py-3 w-100 text-overflow-ellipsis"
                    to={`/it/categorie/${macroCategory?.slug}`}
                    target="_blank"
                  >
                    Siti che vendono "{macroCategory?.name}"
                  </Link>
                </p>
                <p className="text-muted text-center"> Oppure </p>
                <p>
                  <Link
                    className="btn btn-gradient-blue px-3 py-3 w-100 text-overflow-ellipsis"
                    to={`/it/search/migliori-prodotti/${macroCategory?.slug}`}
                    target="_blank"
                  >
                    Cerca prodotti per "{macroCategory?.name}"
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <button
            type="button"
            className="close box-shadow"
            aria-label="Close"
            onClick={() => dispatch(setOnExitModal(undefined))}
            style={{
              height: "2rem",
              width: "2rem",
              fontSize: "14px",
              border: "none",
            }}
          >
            <span aria-hidden="true">×</span>
          </button>
          <div className="modal-body rounded my-3 px-3 px-lg-5">
            <div className="row">
              <div className="col-12 col-md-3 my-auto text-center">
                <img
                  src={onExitLogo}
                  className="img-fluid"
                  width="100"
                  alt="esa"
                  loading="lazy"
                />
              </div>
              <div className="col-12 col-md-9 my-auto text-center">
                <div className="media align-items-center">
                  <div className="media-body">
                    <h5 className="my-3 custom-txt">
                      Le informazioni su questo sito non ti hanno soddisfatto?
                    </h5>
                    <div className="my-3">
                      <Link
                        to="/it/categorie"
                        className="btn btn-gradient-orange px3 px-lg-5 py-3"
                      >
                        Cerca subito un'alternativa!
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <style>{lunarCss}</style>
    </Modal>
  )
}

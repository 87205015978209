import React from "react"
import { Shop } from "../../../models/base/shop.interface"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"

interface EShopSocialsCardProps {
  shop: Shop
}

export const EShopSocialsCard: React.FC<EShopSocialsCardProps> = ({ shop }) => {
  return (
    <div className="card eshop-custom-card mb-0 mb-lg-4 me-2 me-lg-0">
      <div className="card-body d-flex flex-column px-3 py-4 p-lg-4">
        <h5 className="blue-card-title m-0 mb-2 mb-lg-3">Social network</h5>
        <hr className="border border-dark m-0 mb-lg-3 d-none d-lg-block" />
        <div className="d-flex flex-row flex-wrap justify-content-around align-items-center">
          {shop.eshopData.showcaseData.facebook && (
            <Link
              key={shop.eshopData.showcaseData.facebook}
              to={shop.eshopData.showcaseData.facebook}
              className="m-1 font-32 simple-orange-txt"
            >
              <FontAwesomeIcon icon={["fab", "facebook"]} />
            </Link>
          )}
          {shop.eshopData.showcaseData.instagram && (
            <Link
              key={shop.eshopData.showcaseData.instagram}
              to={shop.eshopData.showcaseData.instagram}
              className="m-1 font-32 simple-orange-txt"
            >
              <FontAwesomeIcon icon={["fab", "instagram"]} />
            </Link>
          )}
          {shop.eshopData.showcaseData.linkedin && (
            <Link
              key={shop.eshopData.showcaseData.linkedin}
              to={shop.eshopData.showcaseData.linkedin}
              className="m-1 font-32 simple-orange-txt"
            >
              <FontAwesomeIcon icon={["fab", "linkedin"]} />
            </Link>
          )}
          {shop.eshopData.showcaseData.pinterest && (
            <Link
              key={shop.eshopData.showcaseData.pinterest}
              to={shop.eshopData.showcaseData.pinterest}
              className="m-1 font-32 simple-orange-txt"
            >
              <FontAwesomeIcon icon={["fab", "pinterest"]} />
            </Link>
          )}
          {shop.eshopData.showcaseData.twitter && (
            <Link
              key={shop.eshopData.showcaseData.twitter}
              to={shop.eshopData.showcaseData.twitter}
              className="m-1 font-32 simple-orange-txt"
            >
              <FontAwesomeIcon icon={["fab", "x-twitter"]} />
            </Link>
          )}
          {shop.eshopData.showcaseData.youtube && (
            <Link
              key={shop.eshopData.showcaseData.youtube}
              to={shop.eshopData.showcaseData.youtube}
              className="m-1 font-32 simple-orange-txt"
            >
              <FontAwesomeIcon icon={["fab", "youtube"]} />
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

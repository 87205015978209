import { baseApi } from "./baseApi"
import { InvitationCodeGenerator, InvitationOrder } from "../../../models/base/invitationOrder.interface"

export const invitationsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    // MAPPATO
    getInvitationsOrders: build.query<
      InvitationOrder[],
      {
        email?: string
        eshopId?: string
        domain?: string
        page: number
        perPage: number
      }
    >({
      query: ({ email, eshopId, domain, page = 1, perPage = 10 }) => {
        return {
          url: "invitations/frontend/invitation-orders",
          params: {
            "filter[customer_email]": email == "" ? undefined : email,
            "filter[eshop_id]": eshopId == "" ? undefined : eshopId,
            "filter[domain]": domain == "" ? undefined : domain,
            page,
            perPage,
          },
        }
      },
      providesTags: (result, error, invitationOrderId) => [
        { type: "Invitations", invitationOrderId },
      ],
    }),
    // MAPPATO
    getInvitationById: build.query<InvitationOrder, string>({
      query: (orderId) => ({
        url: `invitations/frontend/invitation-order/${orderId}`,
      }),
    }),
    // MAPPATO
    validateInvitationOrder: build.query<
      InvitationOrder,
      { e: string; v: string }
    >({
      query: ({ e, v }) => ({
        url: `invitations/frontend/invitation-orders-validator`,
        params: { e, v },
      }),
    }),
    // MAPPATO
    getInvitationCode: build.query<InvitationCodeGenerator, {eshop_id:string, order_id:string }>({
      query: ({eshop_id, order_id}) => ({
        url: `invitations/frontend/invitation-code-generator`,
        params: {eshop_id, order_id},
      }),
    })
  }),
})

export const {
  useGetInvitationsOrdersQuery,
  useGetInvitationByIdQuery,
  useValidateInvitationOrderQuery,
  useGetInvitationCodeQuery,
} = invitationsApi

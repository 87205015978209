import React, { useEffect, useState } from "react"
import regOk from "../../assets/images/regOk.svg"
import regOkCheck from "../../assets/images/regOkCheck.svg"
import { Link, useNavigate, useParams } from "react-router-dom"
import emailVerifiedCss from "./emailVerified.css?inline"
import { useCheckOptinMutation } from "../../app/services/base/users"
import { useAppDispatch } from "../../app/hooks"
import { setUser } from "../../features/auth/authSlice"

export const EmailVerified: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { optinCode } = useParams()

  const [isValid, setIsValid] = useState<boolean>(false)
  const [userName, setUserName] = useState<string>("")

  const [checkOptin, { isError }] = useCheckOptinMutation()

  useEffect(() => {
    const checkActivation = async () => {
      try {
        if (!optinCode) throw new Error()
        const response = await checkOptin(optinCode).unwrap()
        dispatch(setUser(response))

        setUserName(response.firstname)
        setIsValid(true)
      } catch (error) {
        navigate("/404")
      }
    }

    checkActivation()
  }, [])

  if (!isValid) {
    return <></>
  }

  return (
    <section className="access-page">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="access-box">
              <div
                className="regOk-img"
                style={{ background: `url(${regOk})` }}
              ></div>
              <div className="access-form">
                <div className="optin-ok-body">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="regOk-check">
                        <img src={regOkCheck} width="120px" height="125px" />
                      </div>
                      <span className="regOk-title">
                        Grazie {userName}, la tua mail è stata verificata!
                      </span>
                      <div className="text-center">
                        <Link
                          to="/it/areariservata/profilo"
                          className="btn link-orange optin-btn"
                        >
                          Accedi
                        </Link>
                        <p className="regOk-txt">
                          Vai alla{" "}
                          <Link to="/" className="regOk-link">
                            Home page
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ clear: "both" }}></div>
            </div>
          </div>
        </div>
      </div>
      <style>{emailVerifiedCss}</style>
    </section>
  )
}

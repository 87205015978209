import React from "react"
import letterBox from "../../assets/icons/letter_box.png"
import registrationConfirmedCss from "./registrationConfirmed.css?inline"

export const RegistrationConfirmed: React.FC = () => {
  const emailTo = "supporto@eshoppingadvisor.com"
  const emailSubject = "[Supporto] Mail di conferma registrazione non ricevuta"
  const emailBody =
    "Ho effettuato una registrazione ma non ho ricevuto la mail di conferma. Potete aiutarmi?%0D%0A%0D%0Ala mia email è: [INSERISCI LA TUA EMAIL]"

  return (
    <section className="access-page">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="access-box">
              <div className="create-ok-body reg_container">
                <h3 className="log-a">Ci siamo quasi!</h3>
                <img src={letterBox} className="img-fluid" />
                <p className="create-ok-text">
                  La richiesta di registrazione è stata inoltrata con successo!
                  Per completare l’operazione segui le istruzioni contenute
                  nell’email che ti abbiamo inviato
                </p>
                <p className="create-ok-text text-danger mt-5 my-3 font-16">
                  <h3 className="font-18">Non ti è arrivata la mail?</h3>{" "}
                  Controlla la cartella spam o{" "}
                  <a
                    href={`mailto:${emailTo}?subject=${emailSubject}&body=${emailBody}`}
                  >
                    clicca qui
                  </a>{" "}
                  per segnalarcelo
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>{registrationConfirmedCss}</style>
    </section>
  )
}

import React, { createContext, useContext, useState, useEffect } from "react"
import Loader from "./Loader"

// Create a context for the loading state
const LoadingContext = createContext<{
  setReady: (ready: boolean) => void
  isReady: boolean
}>({
  setReady: () => {},
  isReady: false,
})

// Custom hook to use in components
export const useReadyState = () => {
  const context = useContext(LoadingContext)
  if (!context) {
    throw new Error("useReadyState must be used within a LoaderWrapper")
  }
  return context.setReady
}

interface LoaderWrapperProps {
  children: React.ReactNode
  loader?: React.ReactNode
}

export const RouteLoader: React.FC<LoaderWrapperProps> = ({
  children,
  loader,
}) => {
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    // Reset ready state when the component mounts or updates
    setIsReady(false)
    return () => setIsReady(false)
  }, [])

  const setReady = (ready: boolean) => {
    setIsReady(ready)
  }

  return (
    <LoadingContext.Provider value={{ setReady, isReady }}>
      {!isReady && (loader || <Loader />)}
      {children}
    </LoadingContext.Provider>
  )
}

export default RouteLoader

import React from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectRegistrationModal, setRegistrationModal } from "./modalsSlice"
import { Modal } from "react-bootstrap"
import { RegistrationAuth } from "../auth/RegistrationAuth"
import { InvitationOrder } from "../../models/base/invitationOrder.interface"
import { User } from "../../models/base/user.interface"

interface RegistrationModalProps {
  invitationOrder?: InvitationOrder
  afterConfirm: (tempUser: User | undefined) => void
}

export const RegistrationModal: React.FC<RegistrationModalProps> = ({
  invitationOrder,
  afterConfirm,
}) => {
  const dispatch = useAppDispatch()
  const registration = useAppSelector(selectRegistrationModal)

  return (
    <Modal
      show={registration ? true : false}
      onHide={() => dispatch(setRegistrationModal(undefined))}
      centered
      aria-labelledby="registrationModalLabel"
      dialogClassName="custom-modal-max-width"
      contentClassName="bg-transparent border-0"
    >
      <div className="d-flex align-items-center justify-content-center d-none d-lg-flex mb-4">
        <button
          type="button"
          className="btn-close btn-close-white"
          onClick={() => dispatch(setRegistrationModal(undefined))}
        ></button>
      </div>
      <div
        className="card d-flex flex-column align-items-center justify-content-center p-3 p-lg-4"
        style={{ borderRadius: "20px" }}
      >
        <RegistrationAuth
          isInModal
          invitationOrder={invitationOrder}
          afterConfirm={afterConfirm}
        />
      </div>
      <div className="d-flex align-items-center justify-content-center d-lg-none mt-4">
        <button
          type="button"
          className="btn-close btn-close-white"
          onClick={() => dispatch(setRegistrationModal(undefined))}
        ></button>
      </div>
    </Modal>
  )
}

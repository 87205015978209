import { Article } from "../../../models/base/article.interface"
import { baseApi } from "./baseApi"

export const articlesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    // MAPPATO
    getArticles: build.query<Article[], void>({
      query: () => ({ url: "articles" }),
      providesTags: (result = []) => [
        ...result.map(({ title }) => ({ type: "Articles", title } as const)),
        { type: "Articles" as const, id: "LIST" },
      ],
      transformResponse(response: Record<string, Article>) {
        return Object.values(response)
      },
    }),
  }),
})

export const { useGetArticlesQuery } = articlesApi

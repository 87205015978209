import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { ReactNode } from "react"

interface WarningProps {
  title: ReactNode
  message: ReactNode
  customClass?: string
}

export const Warning: React.FC<WarningProps> = ({
  title,
  message,
  customClass,
}) => {
  return (
    <div
      className={`d-block warning-background align-items-center p-3 my-3 ${
        customClass ? customClass : ""
      }`}
      role="alert"
    >
      <div className="row align-items-center text-start ps-3 pe-5">
        <div className="col-1">
          <FontAwesomeIcon
            icon={["fas", "flag"]}
            className="orange-color font-23"
          />
        </div>
        <div className="col-11">{title}</div>
      </div>
      <div className="row align-items-center text-start mt-3 ps-3 pe-5">
        <div className="col-12">{message}</div>
      </div>
    </div>
  )
}

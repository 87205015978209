import React from "react"
import { Form } from "react-bootstrap"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  selectSendReviewFormData,
  toggleShopIsAnonymous,
} from "../../pages/send_review/sendReviewSlice"

interface ReviewFieldCheckProps {
  inputName: string
  infoText: boolean
}

export const ReviewFieldCheck: React.FC<ReviewFieldCheckProps> = ({
  inputName,
  infoText,
}) => {
  const dispatch = useAppDispatch()
  const formData = useAppSelector(selectSendReviewFormData)
  return (
    <>
      <Form.Group className="d-flex align-items-center">
        <Form.Control
          // id={inputName}
          className="form-check-input custom-check-dark me-1"
          type="checkbox"
          name={inputName}
          checked={formData.shop.isAnonymous}
          onChange={(e) => {
            const { name, value } = e.currentTarget
            dispatch(toggleShopIsAnonymous())
          }}
        />
        <Form.Label
          className="dark-color font-bolder font-14 ms-2"
          style={{ marginTop: "5px", marginBottom: "0.5rem" }}
          // htmlFor={inputName}
        >
          Recensione Anonima
        </Form.Label>
      </Form.Group>
      {infoText && (
        <p
          className="lightgray-txt font-14 d-none d-lg-block"
          style={{ marginLeft: "26px", marginBottom: "0px" }}
        >
          Pubblica la recensione indicando solo le tue iniziali e un avatar di
          default.
        </p>
      )}
    </>
  )
}

import React from "react"
import { Modal } from "react-bootstrap"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectInfoModal, setInfoModal } from "./modalsSlice"

export const InfoModal: React.FC = () => {
  const dispatch = useAppDispatch()
  const info = useAppSelector(selectInfoModal)

  return (
    <Modal
      show={info ? true : false}
      onHide={() => dispatch(setInfoModal(undefined))}
      size={info?.title == "Descrizione del prezzo" ? "lg" : "xl"}
      centered
      aria-labelledby="infoModalLabel"
      dialogClassName="justify-content-center"
      contentClassName="bg-transparent position-relative border-0"
    >
      <div className="d-flex flex-column align-items-center justify-content-center w-100">
        <div
          className="card p-3 px-lg-5"
          style={
            info?.title == "Descrizione del prezzo"
              ? { borderRadius: "20px" }
              : {}
          }
        >
          <div className="d-flex flex-column align-items-center justify-content-center border-0">
            <button
              type="button"
              className="btn-close btn-close-absolute d-none d-lg-block position-absolute p-0"
              aria-label="Close"
              onClick={() => dispatch(setInfoModal(undefined))}
            ></button>
            <div className="my-2 px-2 dark-color font-33 xs-font-23 font-bolder">
              {info?.title}
            </div>
          </div>
          {info?.body}
        </div>
        <div className="d-flex align-items-center justify-content-center d-lg-none mt-4">
          <button
            type="button"
            className="btn-close btn-close-white"
            aria-label="Close"
            onClick={() => dispatch(setInfoModal(undefined))}
          ></button>
        </div>
      </div>
    </Modal>
  )
}

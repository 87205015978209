import React, { useEffect } from 'react';

declare global {
  interface Window {
    myLandbot: any;
    Landbot: {
      Livechat: new (config: { configUrl: string }) => any;
    };
  }
}

const LandbotScript: React.FC = () => {
  useEffect(() => {
    const initLandbot = (): void => {
      if (!window.myLandbot) {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.addEventListener('load', () => {
          window.myLandbot = new window.Landbot.Livechat({
            configUrl: 'https://storage.googleapis.com/landbot.online/v3/H-1728025-MDNSS0V1A1V8XR6Y/index.json',
          });
        });
        s.src = 'https://cdn.landbot.io/landbot-3/landbot-3.0.0.js';
        const x = document.getElementsByTagName('script')[0];
        x.parentNode?.insertBefore(s, x);
      }
    };

    const centraSx = (): number | void => {
      const siteScope = window;
      return siteScope.document.styleSheets[0].insertRule('.LandbotLivechat { left: 0px !important}');
    };

    window.addEventListener('mouseover', initLandbot, { once: true });
    window.addEventListener('touchstart', initLandbot, { once: true });

    setTimeout(centraSx, 500);

    // Cleanup function
    return () => {
      window.removeEventListener('mouseover', initLandbot);
      window.removeEventListener('touchstart', initLandbot);
    };
  }, []);

  return null;
};

export default LandbotScript;
interface StepIndicatorsProps {
  step: number
  isProductReview: boolean
}

export const StepIndicators: React.FC<StepIndicatorsProps> = ({
  step,
  isProductReview,
}) => {
  const steps = !isProductReview
    ? ["Recensione eCommerce", "Riepilogo"]
    : ["Recensione eCommerce", "Recensione Prodotto", "Riepilogo"]

  return (
    <div
      className="step-indicator-container d-flex align-items-end justify-content-start custom-container mx-auto mb-4"
      style={{ marginTop: "40px" }}
    >
      {steps.map((title, index) => (
        <div
          key={index}
          id={`stepIndicator${index + 1}`}
          className={`step-indicator d-flex flex-column align-items-center justify-content-center gap-1 ${
            step === index ? "active" : ""
          }`}
        >
          <h5 className="lightgray-txt font-bolder m-1">Step {index + 1}</h5>
          <p className="lightgray-txt text-center m-1">{title}</p>
          <div className="bullet m-1"></div>
        </div>
      ))}
    </div>
  )
}

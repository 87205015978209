import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from "react"
import { useRecoverPasswordMutation } from "../../../app/services/base/users"
import { Link, useNavigate } from "react-router-dom"
// import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { LostPassRequest } from "../../../models/base/lostPassRequest.interface"
import { useAppDispatch } from "../../../app/hooks"
import { addToast } from "../../../features/toasts/toastsSlice"
import lostPasswordCss from "./lostPassword.css?inline"

export const LostPassword: React.FC = () => {
  const [formData, setFormData] = useState<LostPassRequest>({
    email: "",
    // recaptchaToken: "",
  })
  const [recoverPassword, { error }] = useRecoverPasswordMutation()
  const navigate = useNavigate()
  // const { executeRecaptcha } = useGoogleReCaptcha()
  const dispatch = useAppDispatch()

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.currentTarget
    setFormData({ ...formData, [name]: value })
  }

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    try {
      const response = await recoverPassword(formData).unwrap()
      // dispatch(setUser(user))
      if (!response.success) throw new Error()
      navigate("/areariservata/recupera-credenziali/ok")
    } catch (err) {
      dispatch(
        addToast({
          message: "Ops! Impossibile procedere.",
          type: "danger",
        }),
      )
    }
  }

  // const handleReCaptchaVerify = useCallback(async () => {
  //   if (!executeRecaptcha) {
  //     return
  //   }
  //   const token = await executeRecaptcha("lostPassword")
  //   setFormData({ ...formData, recaptchaToken: token })
  // }, [executeRecaptcha])

  // useEffect(() => {
  //   handleReCaptchaVerify()
  // }, [handleReCaptchaVerify])

  return (
    <section className="access-page">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="access-box">
              <div className="optin-ok-body">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="well">
                      <form
                        className="recover-form recover-container"
                        id="recoverPublic-form"
                        onSubmit={handleSubmit}
                      >
                        <h2 className="title-main text-center">
                          Ripristina password
                        </h2>
                        <br />

                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label
                                className="control-label sr-only error required"
                                htmlFor="RecoverForm_recoverMail"
                              >
                                Email di registrazione
                                <span className="required">*</span>
                              </label>
                              <input
                                className="form-control placeholder-no-fix error"
                                autoComplete="off"
                                placeholder="Email di registrazione"
                                name="email"
                                id="RecoverForm_recoverMail"
                                type="text"
                                required={true}
                                onChange={handleChange}
                                value={formData.email}
                              />
                              {error && (
                                <div className="form-error-msg">
                                  L'indirizzo email non risulta presente in
                                  archivio
                                </div>
                              )}
                              <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 form-group">
                                  <div className="reg-input-container recoverCaptcha">
                                    <label
                                      className="recaptchaTerms"
                                      htmlFor="RecoverForm_verifyCode"
                                    >
                                      Questo sito è protetto da reCAPTCHA e
                                      vengono applicate le{" "}
                                      <Link
                                        className="simple-blue-link orange-hover"
                                        target="_blank"
                                        to="https://policies.google.com/privacy"
                                      >
                                        Norme sulla Privacy
                                      </Link>{" "}
                                      e i{" "}
                                      <Link
                                        className="simple-blue-link orange-hover"
                                        target="_blank"
                                        to="https://policies.google.com/terms"
                                      >
                                        Termini di Servizio{" "}
                                      </Link>{" "}
                                      di Google.
                                    </label>
                                    {/* <input
                                      type="hidden"
                                      name="recaptcha_response"
                                      // id="recaptchaResponse"
                                      value={formData.recaptchaToken}
                                    /> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="text-center">
                              <button
                                type="submit"
                                className="btn btn-form-orange btn-large"
                              >
                                Rigenera password
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>{lostPasswordCss}</style>
    </section>
  )
}

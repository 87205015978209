import React from "react"
import { LoginAuth } from "../../../features/auth/LoginAuth"

export const Login: React.FC = () => {
  return (
    <div className="container custom-container">
      <div className="card d-flex flex-column align-items-center align-items-lg-center justify-content-center my-3 my-lg-5 py-5 px-3 px-lg-5 custom-rounded">
        <LoginAuth isInModal={false} />
      </div>
    </div>
  )
}

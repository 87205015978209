import { Link } from "react-router-dom"

export const UpdateFileInfo: React.FC = () => {
  return (
    <div className="d-block font-18 xs-font-16 dark-color my-3">
      <p>
        In base alla legge europea sulla sicurezza dei dati (Art.13, Regolamento
        Europeo 2016/679) l’Utente prende atto che{" "}
        <strong>World Advisor S.r.l.</strong>&nbsp;si impegna a trattare i dati
        personali dell’Utente secondo i principi di correttezza, liceità e
        trasparenza e di tutela della riservatezza. Pertanto, si informa
        l’Utente che:
      </p>
      <ol>
        <li>
          <strong>CATEGORIE DI DATI:&nbsp;</strong>oggetto del trattamento
          possono essere i suoi dati personali (quali dati identificativi, dati
          anagrafici e dati di contatto) presenti sulla prova di acquisto
          caricata sulla piattaforma eshoppingadvisor.com ai fini di certificare
          la recensione rilasciata
        </li>
        <li>
          <strong>TITOLARE DEL TRATTAMENTO:&nbsp;</strong>Il titolare del
          trattamento è
          <strong>
            {" "}
            World Advisor Srl – Via Val Di Sangro, 52 – 00141 Roma, Italia P.IVA
            13826511001 – C.F. 13826511001 &nbsp;Tel.{" "}
          </strong>
          <Link to="tel:+390621117480">06/21117480</Link>{" "}
          <strong>– Mail: info@eshoppingadvisor.com</strong>
        </li>
        <li>
          <strong>FONTE DEI DATI PERSONALI:&nbsp;</strong>I dati personali in
          possesso del Titolare sono raccolti direttamente presso l’interessato
          e, nello specifico, sono da lui volontariamente caricati sulla
          piattaforma attraverso upload di una prova di acquisto, al fine di
          certificare l’avvenuto acquisto del prodotto oggetto di recensione.
        </li>
        <li>
          <strong>FINALITÀ DI TRATTAMENTO DEI DATI E BASE GIURIDICA</strong>: Il
          trattamento dei Suoi dati, raccolti ed archiviati in relazione alla
          certificazione di avvenuto acquisto, ha come base giuridica il suo
          consenso ed è effettuato per le seguenti finalità: certificare agli
          altri utenti che il soggetto che sta recensendo un venditore
          (e-commerce) o un prodotto abbia realmente effettuato il relativo
          acquisto.
        </li>
        <li>
          <strong>DESTINATARI DEI DATI</strong>: i suoi dati personali non
          saranno oggetto di trasferimento a soggetti terzi. Potranno essere
          trattati da incaricati al trattamento dati e da responsabili del
          trattamento adeguatamente identificati dal Titolare del Trattamento.
        </li>
        <li>
          <strong>TRASFERIMENTO DEI DATI ALL’ESTERO</strong>: i dati raccolti
          non sono oggetto di trasferimento verso paesi terzi al di fuori della
          comunità europea.
        </li>
        <li>
          <strong>PERIODO DI CONSERVAZIONE</strong>: I dati raccolti verranno
          conservati per un arco di tempo non superiore al conseguimento delle
          finalità per le quali sono trattati (“principio di limitazione della
          conservazione”, art.5, GDPR) o in base alle scadenze previste dalle
          norme di legge. La verifica sulla obsolescenza dei dati conservati in
          relazione alle finalità per cui sono stati raccolti viene effettuata
          periodicamente. Il Titolare, di norma, non tratterà i dati per più di
          10 anni dalla data dell’inserimento.
        </li>
        <li>
          <strong>DIRITTI DELL’INTERESSATO</strong>: L’interessato ha sempre
          diritto a richiedere al Titolare l’accesso ai Suoi dati, la rettifica
          o la cancellazione degli stessi, la limitazione del trattamento o la
          possibilità di opporsi al trattamento, di richiedere la portabilità
          dei dati, di revocare il consenso al trattamento facendo valere questi
          e gli altri diritti previsti dal GDPR tramite semplice comunicazione
          al Titolare. L‘interessato può proporre reclamo anche a un’autorità di
          controllo.
        </li>
        <li>
          <strong>OBBLIGATORIETÀ O MENO DEL CONSENSO</strong>: La informiamo che
          il conferimento dei dati è facoltativo e l’eventuale mancato
          conferimento non comporta la mancata esecuzione degli ulteriori
          servizi.
        </li>
        <li>
          <strong>MODALITÀ DI TRATTAMENTO DEI DATI:</strong>&nbsp;I dati
          personali da Lei forniti, formeranno oggetto di operazioni di
          trattamento nel rispetto della normativa sopracitata e degli obblighi
          di riservatezza cui è ispirata l’attività del Titolare. I dati
          verranno trattati sia con strumenti informatici sia su supporti
          cartacei sia su ogni altro tipo di supporto idoneo, nel rispetto delle
          misure adeguate di sicurezza ai sensi del GDPR.
        </li>
      </ol>
      <p>&nbsp;</p>{" "}
    </div>
  )
}

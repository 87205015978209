import { ChangeEvent } from "react"
import {
  SearchFilter,
  SearchFilterOption,
} from "../../../models/base/searchFilter.interface"
import { RatingStars } from "../../rating_stars/RatingStars"

interface FilterOptionProps {
  option: SearchFilterOption
  tempSelectedFilters: Record<string, SearchFilterOption>
  handleFilterChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const FilterCheckboxOption: React.FC<FilterOptionProps> = ({
  option,
  tempSelectedFilters,
  handleFilterChange,
}) => {
  return (
    <li
      key={option.label}
      className={
        option.name == "includeAdultProducts" ||
        option.name == "onlySuggestedProducts"
          ? "align-items-center d-flex list-group-item px-2 py-3"
          : "list-group-item list-decoration-none d-flex align-items-center"
      }
    >
      <div
        className={
          option.name == "includeAdultProducts" ||
          option.name == "onlySuggestedProducts"
            ? "d-flex"
            : "input-group-text"
        }
      >
        <input
          id={`${option.name}${option.value}`}
          type="checkbox"
          className={"custom-checkbox"}
          name={option.name}
          aria-label={option.label}
          checked={tempSelectedFilters[option.name]?.value as boolean}
          onChange={(e) => handleFilterChange(e)}
        />
        <label
          htmlFor={`${option.name}${option.value}`}
          className="radio-custom-label"
        >
          {option.label}
        </label>
      </div>
    </li>
  )
}

const FilterRadioOption: React.FC<FilterOptionProps> = ({
  option,
  tempSelectedFilters,
  handleFilterChange,
}) => {
  return (
    <li
      key={option.label}
      className={
        "list-group-item list-decoration-none d-flex align-items-center"
      }
    >
      <div className={"input-group-text"}>
        <input
          id={`${option.name}${option.value}`}
          type="radio"
          className={`custom-checkbox ${
            option.name == "rating" ? "margin-r8 mt-0" : ""
          }`}
          name={option.name}
          aria-label={option.label}
          value={option.value as string}
          checked={tempSelectedFilters[option.name]?.value == option.value}
          onChange={(e) => handleFilterChange(e)}
        />
        {option.name == "rating" && typeof option.value == "string" ? (
          <RatingStars
            rating={Number.parseInt(option.value)}
            showAverageRating={false}
          />
        ) : (
          <label
            htmlFor={`${option.name}${option.value}`}
            className="radio-custom-label"
          >
            {option.label}
          </label>
        )}
      </div>
    </li>
  )
}

const FilterMultipleSelectionOption: React.FC<FilterOptionProps> = ({
  option,
  tempSelectedFilters,
  handleFilterChange,
}) => {
  const prevKeys = tempSelectedFilters[option.name].value as string[]

  return (
    <li className="d-flex align-items-center">
      <div className="input-group-text background-white w-100 no-borders">
        <input
          id={option.name + option.value}
          name={option.name}
          className="mt-0 margin-r8 custom-checkbox"
          type="checkbox"
          aria-label={option.label}
          value={option.value as string}
          checked={prevKeys.some((key) => key == option.value)}
          onChange={(e) => handleFilterChange(e)}
        />
        <label
          htmlFor={option.name + option.value}
          className="radio-custom-label font-14 gray-text line-clamp"
        >
          {option.label.split("-")[0]}
        </label>
        <span className="ms-auto dark-color font-14">
          {" "}
          {option.label.split("-")[1]}
        </span>
      </div>
    </li>
  )
}

interface FilterOptionsGroupProps {
  filter: SearchFilter
  tempSelectedFilters: Record<string, SearchFilterOption>
  handleFilterChange: (e: ChangeEvent<HTMLInputElement>) => void
}

export const FilterOptionsGroup: React.FC<FilterOptionsGroupProps> = ({
  filter,
  tempSelectedFilters,
  handleFilterChange,
}) => {
  return (
    <div className="transparent-card">
      {filter.title && (
        <div className="card-header pb-0">
          <h5 className="font-bolder font-16 gray-text padding-t0">
            {filter.title}
          </h5>
        </div>
      )}
      <div className="card-body-other">
        <ul
          className={
            filter.title == "eCommerce" || filter.title == "Brand"
              ? "list-group list-group-flush border custom-rounded ecommerce mh-150 overflow-y-scroll p-2"
              : "list-group list-group-flush"
          }
        >
          {filter.options.map((option) => {
            if (option.name == "shops" || option.name == "brands") {
              return (
                <FilterMultipleSelectionOption
                  key={option.name + option.value}
                  option={option}
                  tempSelectedFilters={tempSelectedFilters}
                  handleFilterChange={handleFilterChange}
                />
              )
            } else if (filter.type == "checkbox") {
              return (
                <FilterCheckboxOption
                  key={option.name + option.value}
                  option={option}
                  tempSelectedFilters={tempSelectedFilters}
                  handleFilterChange={handleFilterChange}
                />
              )
            } else if (filter.type == "radio") {
              return (
                <FilterRadioOption
                  key={option.name + option.value}
                  option={option}
                  tempSelectedFilters={tempSelectedFilters}
                  handleFilterChange={handleFilterChange}
                />
              )
            }
          })}
        </ul>
      </div>
    </div>
  )
}

import React from "react"
import { Shop } from "../../models/base/shop.interface"
import { Link } from "react-router-dom"
import { RatingStars } from "../rating_stars/RatingStars"

interface HeadStickyBarProps {
  title: string
  rating: number
  shop: Shop
  thumbImage: string
  ctaOne: string
  urlCtaOne: string
  ctaTwo?: string
  urlCtaTwo?: string
  toSiteCta: string
}

export const HeadStickyBar: React.FC<HeadStickyBarProps> = ({
  title,
  rating,
  shop,
  thumbImage,
  ctaOne,
  urlCtaOne,
  ctaTwo,
  urlCtaTwo,
  toSiteCta,
}) => {
  return (
    <div className="fixed-top custom-sticky-top background-white box-shadow">
      <div className="container container-custom">
        <div className="d-flex flex-column flex-lg-row align-items-center align-items-lg-center justify-content-center justify-content-lg-between py-1 py-lg-2">
          <div className="d-flex mb-4 mb-lg-0 gap-3 align-items-center w-100">
            <div
              className="custom-rounded"
              style={{
                minWidth: "70px",
                border: "2px solid #e55e0e",
                borderRadius: "10px",
                overflow: "hidden",
              }}
            >
              <img
                src={thumbImage}
                style={{
                  width: "70px",
                  height: "70px",
                  objectFit: "contain",
                }}
              />
            </div>
            <div className="d-flex flex-column gap-1 pe-3">
              <div className="d-flex">
                <Link
                  to={toSiteCta}
                  className="bolder-dark-title font-23 m-0 line-clamp text-decoration-none"
                  target="_blank"
                  rel="nofollow"
                >
                  {title}
                </Link>
              </div>
              <div className="d-flex align-items-center">
                <div className="me-2">
                  <RatingStars rating={rating} showAverageRating size="small" />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex gap-1 gap-lg-3 justify-content-end align-items-center">
            <Link
              to={urlCtaOne}
              target="_blank"
              className="btn btn-animation btn-outline-animation btn-gradient-orange py-2 px-2 px-lg-5"
              rel="nofollow"
            >
              {ctaOne}
            </Link>
            {ctaTwo && (
              <Link
                to={urlCtaTwo ? urlCtaTwo : ""}
                className="btn simple-orange-link font-16"
              >
                {ctaTwo}
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

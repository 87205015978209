import React from "react"
import { ReviewsCarousel } from "./ReviewsCarousel"
import logo from "../../assets/images/logo.png"
import { Hit } from "../../models/esaScraper/esaScraperResponse.interface"
import { ReviewUserUnion } from "../../models/base/reviewUserUnion.interface"
import {
  capitalizeFirstLetter,
  formatNumber,
  toRatingText,
} from "../../utils/stringUtils"
import { RatingStars } from "../rating_stars/RatingStars"

interface ReviewsCarouselSectionProps {
  title: string
  customTitle?: string
  rating?: number
  customReviews?: Hit<ReviewUserUnion>[]
  nReviews: number
}

export const ReviewsCarouselSection: React.FC<ReviewsCarouselSectionProps> = ({
  title,
  customTitle,
  rating,
  customReviews,
  nReviews,
}) => {
  return (
    <section id="review" className="background-white">
      <div className="container padding-r15-15-0">
        <div className="row">
          <div className="col-md-12 padding-l15-0">
            <h2 className="font-33 gray-text fw-semibold margin-x0-15 text-xs-center text-sm-start">
              {title}
              {customTitle && (
                <>
                  {" "}
                  <span className="xs-font-14 d-xs-block d-sm-inline-block">
                    {customTitle}
                  </span>
                </>
              )}
            </h2>
          </div>
        </div>
        <div className="margin-t30">
          <ReviewsCarousel reviewsUsers={customReviews!} />
          <div className="row mt-3">
            <div className="col-md-12 padding-l15-0">
              <div className="d-flex justify-content-center align-items-center">
                <div className="py-2">
                  <div className="d-flex align-items-center">
                    <RatingStars
                      rating={rating ? rating : 0}
                      showAverageRating={false}
                      size="small"
                    />
                    <span className="rating-number-eshop font-bolder justify-content-left d-flex align-items-center">
                      {formatNumber(rating ? rating : 0, 1)}
                    </span>
                    <span className="gray-text font-18 font-light d-none d-md-block d-lg-block mx-2">
                      {capitalizeFirstLetter(toRatingText(rating ? rating : 0))}
                    </span>
                    <span className="d-none d-400-block gray-text ms-2 text-right font-16 xs-font-14 font-light">
                      Basato su{" "}
                      <span className="font-bolder">
                        {nReviews} {nReviews == 1 ? "recensione" : "recensioni"}
                      </span>
                    </span>
                    <img
                      src={logo}
                      alt="eShoppingAdvisor"
                      className="d-none d-md-block d-lg-block logo ms-4"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

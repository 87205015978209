import React, { ChangeEvent, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import logo from "../../assets/images/logo.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { PageLink } from "../../models/base/pageLink.interface"
import { GoogleTranslate } from "../google_translate/GoogleTranslate"
import linksConfig from "./links/links.json"
import socialLinks from "./links/socialLinks.json"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { Collapse } from "react-bootstrap"

interface LinkListProps {
  linkList: PageLink[]
}

const LinkList: React.FC<LinkListProps> = ({ linkList }) => {
  return linkList.map((l, i) => (
    <Link
      key={i}
      to={l.url}
      className="simple-link mb-2 d-block"
      target={l.target}
    >
      {" "}
      {l.text}{" "}
    </Link>
  ))
}

interface SocialLink {
  url: string
  icon: string[]
}

interface SocialListProps {
  socialList: SocialLink[]
}

const SocialList: React.FC<SocialListProps> = ({ socialList }) => {
  return (
    <ul className="social-list">
      {socialList.map((sl, i) => (
        <Link key={i} to={sl.url} target="_blank">
          <FontAwesomeIcon icon={sl.icon as IconProp} />
        </Link>
      ))}
    </ul>
  )
}

const LinksColumn: React.FC<any> = ({ config }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <div className="col-12 col-lg-2 my-3 my-lg-0 mx-auto">
        <h5 className="mb-0 mb-md-3">
          <span
            className="bolder-dark-title font-16 footer-collapse-trigger"
            onClick={() => setOpen(!open)}
            aria-controls={config.title}
            aria-expanded={open}
          >
            {" "}
            {config.title}{" "}
            <span className="d-inline d-lg-none float-right">
              {" "}
              <FontAwesomeIcon icon={["fas", "chevron-down"]} />{" "}
            </span>
          </span>
        </h5>
        <Collapse in={open}>
          <div
            id={config.title}
            className="footer-collapse-section mt-3 mt-md-0"
          >
            <LinkList linkList={config.links} />
          </div>
        </Collapse>
        <span className="border-top border-dark mt-3 w-100 d-block d-lg-none">
          {" "}
        </span>
      </div>
    </>
  )
}

export const Footer: React.FC = () => {
  const navigate = useNavigate()
  const initialState = { email: "", privacy: false }
  const [formData, setFormData] = useState<{ email: string; privacy: boolean }>(
    initialState,
  )

  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    const { name, value, type, checked } = e.target

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  async function handleNewsletterSubmit() {
    navigate("/newsletter/iscriviti", { state: { email: formData.email } })
    setFormData(initialState)
  }

  return (
    <footer data-tm="TM_footer_links">
      <div className="footer-top">
        <div className="container">
          <div className="row py-3 py-md-5">
            {/* Accedi o Iscriviti */}
            <div className="col-12 col-lg-3 px-4 my-3 my-lg-0 text-center">
              <Link to="/" className="d-block footer-logo">
                <img
                  src={logo}
                  alt="eShoppingAdvisor"
                  className="img-fluid"
                  loading="lazy"
                />
              </Link>
              <div className="d-none d-lg-block">
                <Link
                  to="/it/areariservata/login"
                  className="btn btn-gradient-orange d-block py-3 my-3"
                >
                  {" "}
                  Accedi{" "}
                </Link>
                <Link
                  to="/it/registrazione/cliente"
                  className="btn btn-outline-orange d-block py-3 my-3"
                >
                  {" "}
                  Iscriviti gratis{" "}
                </Link>
              </div>
            </div>

            {linksConfig.map((config, index) => (
              <LinksColumn key={index} config={config} />
            ))}

            {/* Newsletter */}
            <div className="col-12 col-lg-3 px-md-4">
              <h5 className="bolder-dark-title"> Newsletter </h5>
              <form
                // id="newsletter-form"
                className="closed horizontal-form form form-registrazione myform mt-3 mt-md-0"
              >
                <div className="form-group mb-3">
                  <input
                    type="email"
                    name="email"
                    autoComplete="email"
                    className="form-control form-control-custom gray-txt"
                    placeholder="Inserisci la tua email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </div>
                <p className="privacy-newsletter">
                  <input
                    type="radio"
                    id="newsletter-accept"
                    className="newsletter-accept me-1"
                    name="privacy"
                    checked={formData.privacy}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="newsletter-accept">
                    Accetta{" "}
                    <Link to="/it/informativa-privacy"> Privacy Policy </Link>
                  </label>
                </p>
                <button
                  type="button"
                  className="btn btn-gradient-orange d-block py-3 my-3 w-100"
                  disabled={
                    formData.email == "" || !formData.privacy ? true : false
                  }
                  onClick={() => handleNewsletterSubmit()}
                >
                  {" "}
                  Iscriviti alla newsletter{" "}
                </button>
              </form>
              <span className="border-top border-dark mt-3 w-100 d-block d-lg-none">
                {" "}
              </span>
            </div>

            {/* Accedi o Iscriviti Mobile */}
            <div className="col-12 d-lg-none">
              <div className="row mt-3">
                <div className="col-6">
                  <Link
                    to="/it/areariservata/login"
                    className="btn btn-gradient-orange d-block py-3 my-3"
                  >
                    {" "}
                    Accedi{" "}
                  </Link>
                </div>
                <div className="col-6">
                  <Link
                    to="/it/registrazione/cliente"
                    className="btn btn-outline-orange d-block py-3 my-3"
                  >
                    {" "}
                    Iscriviti gratis{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom Footer */}
      <div className="footer-bottom text-center">
        <div className="container">
          <div className="row py-4 align-items-center">
            <div className="col-12 col-lg-2">
              <GoogleTranslate />
            </div>
            <div className="col-12 col-lg-7">
              <div className="row">
                <div className="col-12">
                  <span className="footer-info">
                    <Link to="/it/manleva" className="bold-white-link">
                      {" "}
                      Condizioni di utilizzo{" "}
                    </Link>
                    <span className="bold-white-txt d-none d-lg-inline-block">
                      {" "}
                      |{" "}
                    </span>
                    <Link
                      to="/it/informativa-privacy"
                      className="bold-white-link"
                    >
                      {" "}
                      Policy privacy{" "}
                    </Link>
                    <span className="bold-white-txt d-none d-lg-inline-block">
                      {" "}
                      |{" "}
                    </span>
                    <Link to="/it/disclaimer" className="bold-white-link">
                      {" "}
                      Disclaimer{" "}
                    </Link>
                    <span className="bold-white-txt d-none d-lg-inline-block">
                      {" "}
                      |{" "}
                    </span>
                    <Link
                      to="#"
                      className="bold-white-link iubenda-cs-preferences-link"
                    >
                      {" "}
                      Impostazioni dei cookie{" "}
                    </Link>
                  </span>
                </div>
                <div className="col-12">
                  <span className="footer-info simple-white-txt">
                    {" "}
                    World Advisor S.r.l. All rights reserved P.Iva 13826511001 |{" "}
                    <a
                      href="mailto:info@eshoppingadvisor.com"
                      className="simple-white-link"
                    >
                      info@eshoppingadvisor.com
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <SocialList socialList={socialLinks} />
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

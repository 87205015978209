import React from "react"
import OwlCarousel from "react-owl-carousel"
import customCss from "../../assets/css/old/int/custom.css?inline"
import { ReviewsCarouselItem } from "./ReviewsCarouselItem"
import { Hit } from "../../models/esaScraper/esaScraperResponse.interface"
import { ReviewUserUnion } from "../../models/base/reviewUserUnion.interface"

interface ReviewsCarouselProps {
  reviewsUsers: Hit<ReviewUserUnion>[]
}

export const ReviewsCarousel: React.FC<ReviewsCarouselProps> = ({
  reviewsUsers,
}) => {
  return (
    <div className="carousel-wrap">
      <OwlCarousel
        // className="owl-theme"
        dots={false}
        loop={reviewsUsers.length > 4 ? true : false}
        margin={0}
        responsiveClass={true}
        dotsEach={3}
        autoplay={true}
        autoplayTimeout={4500}
        autoplayHoverPause={true}
        autoplaySpeed={650}
        nav={reviewsUsers.length > 4 ? true : false}
        navText={[
          '<div class="nav-btn prev-slide simple-orange-txt"><svg class="orange" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><path fill="currentColor" d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg></div>',
          '<div class="nav-btn next-slide simple-orange-txt"><svg class="orange" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><path fill="currentColor" d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg></div>',
        ]}
        responsive={{
          0: {
            items: 1.1,
            margin: 26,
          },
          768: {
            items: 3,
            margin: 5,
          },
          1000: {
            items: 4,
            margin: 26,
          },
        }}
      >
        {reviewsUsers.map((reviewUser) => (
          <ReviewsCarouselItem
            key={reviewUser._id}
            review={reviewUser._source.review}
            simpleUser={reviewUser._source.user}
          />
        ))}
      </OwlCarousel>
      <style>{customCss}</style>
    </div>
  )
}

import { Link } from "react-router-dom"

export const CouponRegulationInfo: React.FC = () => {
  return (
    <div className="d-block font-18 xs-font-16 dark-color my-3">
      <p>
        Tutti gli sconti e i bonus parte dei coupon sono messi volontariamente a
        disposizione dagli e-commerce registrati sul portale
        eShoppingAdvisor.com, in base alle singole strategie aziendali. <br />
        <br /> eShoppingAdvisor non si assume quindi alcuna responsabilità in
        merito alla mancata applicazione delle condizioni oggetto dei medesimi
        coupon. <br />
        <br /> Prima dell’utilizzo dei coupon consigliamo di consultare le
        modalità e le condizioni di utilizzo pubblicate dagli e-commerce
        registrati e a contattare il negozio online stesso per ogni eventuale
        dubbio o esigenza. <br />
        <br />
        Per ogni anomalia o disservizio derivante dall’utilizzo dei coupon è
        possibile inviare una segnalazione via e-mail all’indirizzo{" "}
        <Link
          to="mailto:info@eshoppingadvisor.com"
          target="_blank"
          rel="nofollow noreferrer"
        >
          info@eshoppingadvisor.com
        </Link>{" "}
        o a contattarci al numero &nbsp;06/21117480. Sarà nostra cura verificare
        le segnalazioni e valutare l’eliminazione del coupon sino all’inibizione
        dell’e-commerce stesso a caricarne e immetterne di nuovi.&nbsp; <br />
        <br />
        <strong>Come accumulare punti per guadagnare coupon sconto: </strong>
        <br />
        <br /> Per poter sbloccare un coupon è sufficiente accumulare un punto.
        Per accumulare un punto occorre semplicemente scrivere una recensione
        certificata mentre, per ogni recensione verificata i punti accumulati
        saranno pari a 0,25. Le recensioni vengono certificate quanto viene
        caricata una prova di acquisto (fattura, ricevuta, conferma d’ordine,
        ecc.) oppure derivino da un invito a scrivere una recensione da parte
        dell’e-commerce (via email o sms). In tutti gli altri casi parliamo di
        una recensione verificata del valore di 0,25 punti. <br />
        In alto a destra della sezione coupon, all’interno dell’area riservata
        utente, è presente il saldo punto e il relativo estratto conto (lista
        transazioni).
      </p>
    </div>
  )
}

import { BaseLoader } from "./base.loader"
import { categoriesApi } from "../services/base/categories"
import { Category } from "../../models/base/category.interface"
import { LoaderFunction, LoaderFunctionArgs } from "react-router-dom"
import { Shop } from "../../models/base/shop.interface"
import { domainsApi } from "../services/esaScraper/domains"

export class CategoriesLoader extends BaseLoader {
  listLoader: LoaderFunction = async ({
    params,
    request,
  }: LoaderFunctionArgs) => {
    const categories = (await this._loader(
      categoriesApi.endpoints.getCategories,
      request,
      { type: "normal" },
    )) as Category[]

    return { categories }
  }

  detailLoader: LoaderFunction = async ({
    params,
    request,
  }: LoaderFunctionArgs) => {
    const category = (await this._loader(
      categoriesApi.endpoints.getCategory,
      request,
      params.categorySlug,
    )) as Category

    if (!category.subCategories) {
      const shopsByCategory1 = (await this._loader(
        domainsApi.endpoints.getDomainsByCategory,
        request,
        {
          categorySlug: params.categorySlug,
          filters: {},
          searchAfter: undefined,
        },
      )) as Shop[]

      const shopsByCategory2 = (await this._loader(
        domainsApi.endpoints.getDomainsByCategory,
        request,
        {
          categorySlug: params.categorySlug,
          filters: {
            rating: {
              name: "rating",
              label: "Valutazione maggiore di 0",
              value: "0",
            },
            minNumberOfReviews: {
              name: "minNumberOfReviews",
              label: "Tutti",
              value: "0",
            },
            minNumberOfLatestReviews: {
              name: "minNumberOfLatestReviews",
              label: "Tutti",
              value: "0",
            },
            onlyVerifiedReviews: {
              name: "onlyVerifiedReviews",
              label: "Solo recensioni verificate",
              value: false,
            },
            searchType: {
              name: "searchType",
              label: "eCommerce / Servizi",
              value: "0",
            },
            brands: {
              name: "brands",
              label: "Filtro per brand",
              value: [],
            },
            shops: {
              name: "shops",
              label: "Filtro per eCommerce",
              value: [],
            },
            includeAdultProducts: {
              name: "includeAdultProducts",
              label: "Includi prodotti per adulti",
              value: false,
            },
            onlySuggestedProducts: {
              name: "onlySuggestedProducts",
              label: "Visualizza solo prodotti consigliati",
              value: false,
            },
            orderBy: {
              name: "orderBy",
              label: "Ordina per",
              value: "",
            },
          },
          searchAfter: undefined,
        },
      )) as Shop[]

      return {
        category,
        shopsByCategory1,
        shopsByCategory2,
      }
    }

    return { category }
  }
}

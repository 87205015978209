import React from "react"
import { Link } from "react-router-dom"
import { EsaScraperProduct } from "../../models/esaScraper/esaScraperProduct.interface"
import { Hit } from "../../models/esaScraper/esaScraperResponse.interface"
import { ProductsCarousel } from "../products_carousel/ProductsCarousel"
import { Shop } from "../../models/base/shop.interface"

interface CardCarouselSectionProps {
  id: "related" | "site"
  title: string
  showMoreLink?: string
  showMoreTitle?: string
  products: Hit<EsaScraperProduct>[]
  typeOfProducts: "RelatedProducts" | "EShopProducts"
  shop: Shop
}

export const CardCarouselSection: React.FC<CardCarouselSectionProps> = ({
  id,
  title,
  showMoreLink,
  showMoreTitle,
  products,
  typeOfProducts,
  shop,
}) => {
  return (
    <section id={id}>
      <div className="container padding-r15-15-0">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10">
            <div className="row d-flex align-items-center justify-content-between mb-4">
              <div className="col-auto my-1">
                <h3 className="font-33 xs-font-23 mb-0 gray-text fw-semibold">
                  {title}
                </h3>
              </div>
              {showMoreLink && (
                <div className="col-auto my-1 me-2">
                  <Link
                    to={showMoreLink}
                    className="btn btn-solid-gray font-light waitLink"
                  >
                    {showMoreTitle}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
        {typeOfProducts === "RelatedProducts" && (
          <ProductsCarousel products={products} shop={shop} />
        )}
        {typeOfProducts === "EShopProducts" && (
          <ProductsCarousel products={products} shop={shop} />
        )}
      </div>
    </section>
  )
}

import { Shop } from "../models/base/shop.interface"

export const getShopLogo = (shopId: number) => {
  return  import.meta.env.VITE_CDN_BASE_URL + `/logos/${shopId}.webp`
}

export const getShopThumb = (shopId: number) => {
  return  import.meta.env.VITE_CDN_BASE_URL + `/thumb/${shopId}.webp`
}

export const isPremiumShop = (shopPlan: string) => {
  return shopPlan == "ibrido" || shopPlan == "free" || shopPlan == "basic"
    ? false
    : true
}

export const isPlanWithBanners = (shopPlan: string) => {
  const plansWithBanners = ["free", "ibrido"]
  return plansWithBanners.includes(shopPlan)
}

export const calcTotalReviews = (shop: Shop) => {
  let reviewsImported =
    (shop?.plan === "pro" || shop?.plan === "company") && shop.nRecensioniImport
      ? shop.nRecensioniImport
      : 0
  let reviewsEsa = shop?.nRecensioniEsa ? shop.nRecensioniEsa : 0
  return reviewsImported + reviewsEsa
}

export const eshopIdEncoder = (shopId: number) => {
  return shopId * 4324 / 2 + 1234  // encrypt shop.esa_id to avoid scraping
}

export const eshopIdDecoder = (encodedId: number) => {
  return (encodedId - 1234) * 2 / 4324
}
import { Navigate, Outlet, useLocation } from "react-router-dom"
import { useAppSelector } from "../../app/hooks"
import { selectCurrentUser } from "../auth/authSlice"

export const ProtectedRoute: React.FC = () => {
  const location = useLocation()
  const user = useAppSelector(selectCurrentUser)

  // First render user is empty, and then redux fill it
  // from the localstorage with a useffect on the Root Component
  // This is a workaround for this problem
  const storedUser = localStorage.getItem("user")

  if (!user && !storedUser)
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return (
      <Navigate
        to="/it/areariservata/login"
        state={{ from: location }}
        replace
      />
    )

  return <Outlet />
}

import React from "react"
import { ToastContainer } from "react-bootstrap"
import { useAppSelector } from "../../app/hooks"
import { selectToasts } from "./toastsSlice"
import { SimpleToast } from "./SimpleToast"

export const ToastsContainer: React.FC = () => {
  const toasts = useAppSelector(selectToasts)

  return (
    <>
      {toasts.length > 0 && (
        <div
          aria-live="polite"
          aria-atomic="true"
          className="bg-dark position-relative"
          style={{ minHeight: "240px" }}
        >
          <ToastContainer
            containerPosition="fixed"
            // className="p-3 end-0 hide-mobile"
            className="p-3 end-0"
            style={{ top: "90px" }}
          >
            {toasts.map((toast, index) => (
              <SimpleToast key={index} index={index} {...toast} />
            ))}
          </ToastContainer>
        </div>
      )}
    </>
  )
}

import { BaseLoader } from "./base.loader"
import { LoaderFunction, LoaderFunctionArgs } from "react-router-dom"
import { reviewsApi } from "../services/base/reviews"
import { Review } from "../../models/base/review.interface"

export class ReviewsLoader extends BaseLoader {
  detailLoader: LoaderFunction = async ({
    params,
    request,
  }: LoaderFunctionArgs) => {
    const review = (await this._loader(
      reviewsApi.endpoints.getReview,
      request,
      params.reviewId,
    )) as Review

    return { review }
  }
}

import React, { ChangeEvent } from "react"
import { useSelector } from "react-redux"
import { ReservedMenuItem } from "./ReservedMenuItem"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import menuItems from "../../../config/reservedMenuConfig.json"
import { selectCurrentUser } from "../../../features/auth/authSlice"
import avatarPlaceholder from "../../../assets/images/avatar_placeh.png"
import { getUserAvatar } from "../../../utils/userUtils"
import { useUploadUserProfilePicMutation } from "../../../app/services/base/users"
import { useAppDispatch } from "../../../app/hooks"
import { addToast } from "../../../features/toasts/toastsSlice"

export const ReservedMenu: React.FC = () => {
  const user = useSelector(selectCurrentUser)
  const dispatch = useAppDispatch()

  const [uploadUserProfilePic, { error }] = useUploadUserProfilePicMutation()

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const file = e.currentTarget.files ? e.currentTarget.files[0] : undefined
      if (file) {
        const request = new FormData()
        request.append("consumer_id", user!.id.toString())
        request.append("image", file, file.name)
        const response = await uploadUserProfilePic(request).unwrap()
        if (!response.success) throw new Error()
        dispatch(
          addToast({
            message: "Immagine del profilo aggiornata.",
            type: "success",
          }),
        )
        location.reload()
      }
    } catch (error) {
      dispatch(
        addToast({
          message: "Ops! Qualcosa è andato storto.",
          type: "danger",
        }),
      )
    }
  }

  return (
    <div className="col-md-3 col-lg-3 col-xl-3 d-none d-lg-block">
      <div className="userArea-menu white-background">
        <div className="userArea-top">
          <div className="user-avatar">
            <img
              src={user ? getUserAvatar(user.id, true) : avatarPlaceholder}
              className="img-fluid rounded-circle"
              onError={(e) => (e.currentTarget.src = avatarPlaceholder)}
            />
            <label
              className="upload-avatar-label"
              htmlFor="ClienteImageForm_file"
            >
              <FontAwesomeIcon
                icon={["fas", "camera"]}
                className="white-color"
              />
            </label>
            <input
              type="file"
              id="ClienteImageForm_file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </div>
          <div className="user-data">
            <span className="username">{user?.firstname}</span>
          </div>
        </div>
        <div className="userArea-middle">
          <ul className="user-menu">
            {menuItems.items.map((item, index) => (
              <ReservedMenuItem
                iconName={item.iconName as IconProp}
                link={item.link}
                title={item.title}
                key={index}
              />
            ))}
          </ul>
        </div>
        <div className="userArea-bottom">
          <img
            src={user?.reviews_career_image}
            className="img-fluid carriera-user"
          />
          <span></span>
        </div>
      </div>
    </div>
  )
}

export default ReservedMenu

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

interface ElementsNotFoundProps {
  text: string
}

export const ElementsNotFound: React.FC<ElementsNotFoundProps> = ({ text }) => {
  return (
    <div
      className="alert alert-warning d-flex align-items-center p-5 "
      role="alert"
    >
      <div className="row w-100">
        <div className="col-12 text-center">
          <FontAwesomeIcon
            icon={["fas", "exclamation-triangle"]}
            className="pe-1"
          />
          {text}
        </div>
      </div>
    </div>
  )
}

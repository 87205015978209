import React from "react"
import { BootstrapCarousel } from "./bootstrap_carousel/BootstrapCarousel"
import { OwlCoupons } from "./owl_carousel/OwlCoupons"
import { useGetHomeCouponsQuery } from "../../../app/services/base/coupons"
import useWindowSize from "../../../utils/useWindowSize"
import { selectCouponModal } from "../../../features/modals/modalsSlice"
import { useAppSelector } from "../../../app/hooks"
import { CouponModal } from "../../../features/modals/CouponModal"

export const CouponHighlights: React.FC = () => {
  const { data: homeCoupons } = useGetHomeCouponsQuery()
  const windowSize = useWindowSize()
  const couponModal = useAppSelector(selectCouponModal)

  const isMobile = windowSize.width && windowSize.width <= 768

  if (!homeCoupons || homeCoupons.length === 0) {
    return <></>
  }

  return (
    <div
    // id="couponhighlights-carousel"
    >
      {isMobile ? (
        <OwlCoupons coupons={homeCoupons} />
      ) : (
        <BootstrapCarousel coupons={homeCoupons} />
      )}
      {couponModal && <CouponModal />}
    </div>
  )
}

import React from "react"
import { Link } from "react-router-dom"

export const CallToActions: React.FC = () => {
  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-12 col-md-6 my-0 my-md-3">
          <div className="background-blue py-3 px-5 p-md-5 rounded">
            <h3 className="my-3 bolder-white-title font-33">
              Sei un negozio online?
            </h3>
            <span className="my-3 d-block font-14 simple-white-txt">
              {" "}
              Accresci la tua reputazione e le vendite del tuo e-commerce!
              Scopri tutti i servizi business che abbiamo pensato per te.{" "}
            </span>
            <Link
              to="https://business.eshoppingadvisor.com"
              className="btn btn-outline-animation btn-outline-white my-3 d-block d-md-inline-block px-4 py-3 font-16 font-bolder"
            >
              Inizia subito
            </Link>
          </div>
        </div>
        <div className="col-12 col-md-6 my-0 mt-3 my-md-3">
          <div className="background-outline-blue py-3 px-5 p-md-5 rounded">
            <h3 className="my-3 bolder-dark-title font-33">
              Acquisti spesso online?
            </h3>
            <span className="my-3 d-block font-14 dark-color">
              {" "}
              Condividi la tua esperienza di acquisto ed ottieni sconti e
              vantaggi grazie alle tue recensioni.{" "}
            </span>
            <Link
              to="/it/scrivi-recensione"
              className="btn btn-outline-animation btn-outline-gradient-blue d-block d-md-inline-block my-3 px-4 py-3 font-16 font-bolder"
            >
              Scrivi la tua recensione
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

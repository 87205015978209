import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { ChangeEvent, useState } from "react"
import { EsaScraperResponse } from "../../models/esaScraper/esaScraperResponse.interface"
import { EsaScraperProduct } from "../../models/esaScraper/esaScraperProduct.interface"
import { useAppDispatch } from "../../app/hooks"
import { productsApi } from "../../app/services/esaScraper/products"
import codaBianca from "../../assets/images/coda-bianca.webp"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { findSubstringParts } from "../../utils/stringUtils"

interface ProductsSearchEngineProps {
  eShopDomain: string
  handleSearch: ((searchBarValue: string) => void) | undefined
}

export const ProductsSearchEngine: React.FC<ProductsSearchEngineProps> = ({
  eShopDomain,
  handleSearch,
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const s = searchParams.get("s")
  const [searchText, setSearchText] = useState<string>(s ? s : "")
  const [timeoutId, setTimeoutId] = useState<any>(undefined)
  const [data, setData] = useState<
    EsaScraperResponse<EsaScraperProduct> | undefined
  >(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState<boolean>(false)

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (searchText.length > 100) return

    const newText = e.target.value
    setSearchText(newText)

    clearTimeout(timeoutId)

    // Only debounce if input length is 3 or more
    if (newText.length >= 3) {
      const newTimeoutId = setTimeout(() => {
        fetchData(newText)
      }, 1000)

      setTimeoutId(newTimeoutId)
    } else {
      setData(undefined)
    }
  }

  const fetchData = async (queryString: string) => {
    setIsLoading(true)

    const { data, isError } = await dispatch(
      productsApi.endpoints.getSuggestedProducts.initiate({
        searchText: queryString.toLowerCase(),
      }),
    )

    if (isError) {
      setIsError(true)
    } else {
      setData(data)
    }

    setIsLoading(false)
  }

  function handleOnClick(productName: string) {
    setSearchText(productName)
    handleSearch!(productName)
    setData(undefined)
  }

  function handleReset() {
    setSearchText("")
    setData(undefined)
    if (s) {
      window.location.href = `/prodotti-eshop/${eShopDomain}/`
    } else {
      handleSearch!("")
    }
  }

  function handleSubmit() {
    handleSearch!(searchText)
    setData(undefined)
    navigate(`/prodotti-eshop/${eShopDomain}`)
  }

  return (
    <div
      // id="search-bar"
      className="search-bar col-12"
    >
      <div
        //   id="search-engine-container"
        className="search-engine-container"
      >
        <input
          type="text"
          id="searchBar"
          className="w-100 py-3 px-3 search-engine typeahead"
          name="esassearch"
          placeholder={`Cerca prodotti di ${eShopDomain.replace("*", "/")}`}
          value={searchText}
          onChange={handleInputChange}
          autoComplete="off"
        />
        <ul
          className="typeahead dropdown-menu"
          style={data ? { top: "60px", left: "0px", display: "block" } : {}}
        >
          {data && data.hits.hits.length > 0 ? (
            <>
              {data.hits.hits.map((product) => (
                <li className="prod-search-item active">
                  <Link
                    className="first-link"
                    to={`/it/prodotti-eshop/${eShopDomain}?s=${product._source.product_name}`}
                    onClick={() => handleOnClick(product._source.product_name)}
                  >
                    <span className="eshop-search">
                      <span className="s-res">
                        "
                        <strong>
                          {
                            findSubstringParts(
                              searchText,
                              product._source.product_name,
                            )[0]
                          }
                        </strong>
                        {
                          findSubstringParts(
                            searchText,
                            product._source.product_name,
                          )[1]
                        }
                        "
                      </span>
                    </span>
                  </Link>
                </li>
              ))}
            </>
          ) : data && data.hits.hits.length == 0 ? (
            <li className="prod-search-item no-suggestions active">
              <Link
                className="first-link"
                to={`/it/search/prodotti/${searchText}`}
              >
                <span className="eshop-search">
                  <span className="s-res">
                    Nessun prodotto trovato, <u> fai una ricerca generale </u>
                  </span>
                </span>
              </Link>
            </li>
          ) : (
            <></>
          )}
        </ul>
        {searchText.length > 0 && (
          <a
            id="reset-search-searchBar"
            className="reset-search active cursor-pointer"
            style={{ top: "calc(50% - 12px)" }}
            onClick={handleReset}
          >
            <FontAwesomeIcon icon={["fas", "times"]} />
          </a>
        )}
        <button
          id="inner-search-engine-submit"
          className="btn btn-gradient-blue search-engine-btn float-right justify-content-center align-items-center"
          onClick={handleSubmit}
          style={{ zIndex: 3 }}
        >
          {isLoading ? (
            <img className="wait-res search-engine-loader" src={codaBianca} />
          ) : (
            <FontAwesomeIcon icon={["fas", "magnifying-glass"]} />
          )}
        </button>
      </div>
    </div>
  )
}

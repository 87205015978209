import React from "react"
import { Link, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import starSquare from "../../assets/icons/star_square.png"
import { EsaScraperShop } from "../../models/esaScraper/esaScraperShop.interface"
import { findSubstringParts, formatNumber } from "../../utils/stringUtils"
import { eshopIdEncoder } from "../../utils/shopUtils"

interface ShopMenuItemChildrenProps {
  shop: EsaScraperShop
  searchText: string
}

export const ShopMenuItemChildren: React.FC<ShopMenuItemChildrenProps> = ({
  shop,
  searchText,
}) => {
  const parsedDomain = shop.domain.replace(/\//g, "*")
  // encrypt shop.esa_id to avoid scraping
  const eshopId = eshopIdEncoder(shop.esa_id)

  return (
    <li className="border-bottom">
      <Link className="first-link" to={`/it/eshop/${parsedDomain}`}>
        <span className="eshop-search">
          <span className="s-res">
            <div className="row">
              <div className="col-12">
                <span className="line-clamp font-16">
                  <strong>
                    {shop.name_public_brand ||
                      findSubstringParts(searchText, shop.domain)[0]}
                  </strong>
                  {shop.name_public_brand
                    ? ""
                    : findSubstringParts(searchText, shop.domain)[1]}
                </span>
              </div>
              <div className="col-12">
                <small className="res-reviews line-clamp font-12">
                  {shop.domain}
                  <FontAwesomeIcon
                    icon={["fas", "circle"]}
                    className="mx-2 mb-1"
                    style={{
                      fontSize: "5px",
                    }}
                  />
                  {shop.reviews}{" "}
                  {shop.reviews == 1 ? "recensione" : "recensioni"}
                </small>
              </div>
            </div>
          </span>
        </span>
      </Link>
      <Link
        className="second-link active"
        to={`/it/invia-recensione?e=${shop.esa_id}`}
      >
        <span className="d-flex align-items-center justify-content-end">
          <img className="img-fluid img-rating-search" src={starSquare} />
          <span className="orange-color font-semibolder font-20 xs-font-14 ms-1 me-1 me-xl-4">
            {formatNumber(shop.score, 1)}
          </span>
        </span>
      </Link>
    </li>
  )
}

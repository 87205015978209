import React from "react"
import { useParams, useSearchParams } from "react-router-dom"
import {
  useGetReviewForAuthUserQuery,
  useGetReviewQuery,
} from "../../../app/services/base/reviews"
import { skipToken } from "@reduxjs/toolkit/query"
import { ReviewsModifyView } from "./ReviewsModifyView"
import { ReviewsModifyUpdate } from "./ReviewsModifyUpdate"
import { useAppSelector } from "../../../app/hooks"
import { selectCurrentUser } from "../../../features/auth/authSlice"

export const ReviewsModify: React.FC = () => {
  const user = useAppSelector(selectCurrentUser)
  const { reviewId } = useParams()
  const { data } = !user
    ? useGetReviewQuery(reviewId ? reviewId : skipToken)
    : useGetReviewForAuthUserQuery(reviewId ? reviewId : skipToken)
  const [searchParams, setSearchParams] = useSearchParams()
  const ac = searchParams.get("ac")

  if (!data) {
    return <></>
  }

  return (
    <>
      {data.review.status != 4 && ac == "view" ? (
        <ReviewsModifyView data={data} />
      ) : data.review.status != 4 && ac == "update" ? (
        <ReviewsModifyUpdate data={data} />
      ) : data.review.status != 4 ? (
        <ReviewsModifyView data={data} />
      ) : (
        <div className="w-container utente white-background">
          <div className="update-review mod-ok">
            <div className="row">
              <div className="col-md-12">
                <h1>Recensione eliminata</h1>
                <h2>
                  La recensione che vuoi visualizzare ci risulta eliminata.
                </h2>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useGetSearchReviewsQuery } from "../../app/services/esaScraper/reviews"
import { skipToken } from "@reduxjs/toolkit/query"
import { useGetShopsQuery } from "../../app/services/base/shops"
import { ReviewContainer } from "../../features/eshop/reviews/ReviewContainer"
import { Hit } from "../../models/esaScraper/esaScraperResponse.interface"
import { ReviewUserUnion } from "../../models/base/reviewUserUnion.interface"
import { Helmet } from "react-helmet"
import { AppBreadcrumb } from "../../features/app_breadcrumb/AppBreadcrumb"

export const NoIndexReviews: React.FC = () => {
  const [reviews, setReviews] = useState<Hit<ReviewUserUnion>[]>([])
  const { eShopDomain, source } = useParams()
  const { eShop, isError } = useGetShopsQuery(
    eShopDomain ? { domain: eShopDomain } : skipToken,
    {
      selectFromResult: ({ data, isError }) => ({
        eShop: data?.find(
          (shop) =>
            shop.domain == eShopDomain ||
            shop.domain.replace("/", "*") == eShopDomain,
        ),
        isError,
      }),
    },
  )

  const { data: noIndexReviews } = useGetSearchReviewsQuery(
    eShop
      ? {
          esaDomainId: eShop.id,
          query_string: undefined,
          filters: {
            enrichData: true,
            size: 10,
            orderBy: "publication_datetime-desc",
            esa_user_id: undefined,
            import_source: source,
          },
          review_prod: false,
          searchAfter: undefined,
        }
      : skipToken,
  )

  useEffect(() => {
    if (noIndexReviews) {
      setReviews(noIndexReviews.hits?.hits)
    }
  }, [noIndexReviews])

  if (isError || !eShop || !source) {
    return ""
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <section className="review-page">
        <div className="review-wrap">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <AppBreadcrumb
                  routes={[
                    { name: "Home", slug: "/" },
                    { name: eShop?.domain, slug: "/it/eshop/" + eShop?.domain },
                    { name: `Recensioni ${source}`, slug: "#" },
                  ]}
                />
              </div>
            </div>
            <div className="row my-3">
              {reviews && reviews.length > 0 ? (
                reviews.map((review) => (
                  <div className="my-3 py-3 border-bottom">
                    <ReviewContainer
                      shop={eShop}
                      review={review._source.review}
                      simpleUser={review._source.user}
                    />
                  </div>
                ))
              ) : (
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div className="no-review">
                    <div className="no-review-wrap text-center">
                      <h3>Nesuna recensione importata da {source}</h3>
                      <p>
                        Al momento non ci sono recensioni "{source}" per questo
                        eshop.
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

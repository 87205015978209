import React from "react"
import { Modal, Tab } from "react-bootstrap"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectEsaScoreModal, setEsaScoreModal } from "./modalsSlice"
import { formatDate, toRatingText } from "../../utils/stringUtils"
import { useGetReviewsStatsQuery } from "../../app/services/base/reviews"
import { skipToken } from "@reduxjs/toolkit/query"
import loader from "../../assets/images/loader.webp"
import { RatingStars } from "../rating_stars/RatingStars"

const RatingDetails: React.FC<{
  title: string
  rating: number
  isLoading: boolean
}> = ({ title, rating, isLoading }) => {
  return (
    <div className="col-12 col-lg-4 text-center my-3">
      <div
        className="px-2 py-3 radius-10 bordered mx-auto"
        style={{ maxWidth: "210px" }}
      >
        <h5 className="gray-color font-16 font-bolder">{title}</h5>
        {!isLoading ? (
          <div className="row justify-content-center">
            <div className="col-12 mx-2">
              <span
                className="orange-color font-33 font-bolder"
                style={{ position: "relative", top: "10px" }}
              >
                {rating}
              </span>
              <span className="dark-color font-23 d-block font-bolder">
                {rating > 0 ? toRatingText(rating) : "Non valutato"}
              </span>
            </div>
            <div className="d-flex justify-content-center col-12">
              <RatingStars rating={rating} showAverageRating={false} />
            </div>
          </div>
        ) : (
          <div className="row justify-content-center">
            <span className="justify-content-center align-items-center">
              <img className="spin mx-1 mt-2" height="35px" src={loader} />
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

export const EsaScoreModal: React.FC = () => {
  const dispatch = useAppDispatch()
  const shop = useAppSelector(selectEsaScoreModal)

  const { data: reviewsStats3, isLoading: isLoading3 } =
    useGetReviewsStatsQuery(
      shop
        ? {
            eshopId: shop.id,
            since: formatDate(
              new Date(new Date().setMonth(new Date().getMonth() - 3)),
            ),
            until: formatDate(new Date()),
          }
        : skipToken,
    )

  const { data: reviewsStats12, isLoading: isLoading12 } =
    useGetReviewsStatsQuery(
      shop
        ? {
            eshopId: shop.id,
            since: formatDate(
              new Date(new Date().setMonth(new Date().getMonth() - 12)),
            ),
            until: formatDate(new Date()),
          }
        : skipToken,
    )

  return (
    <>
      {shop && (
        <Modal
          show={shop ? true : false}
          onHide={() => dispatch(setEsaScoreModal(undefined))}
          size="lg"
          centered
          aria-labelledby="EsaScoreModal"
          contentClassName="custom-rounded"
        >
          <Tab.Container defaultActiveKey="allReviews">
            <Modal.Header className="text-center px-1 px-lg-3 no-borders">
              <div className="row px-1 px-lg-5 mx-auto w-100">
                <button
                  type="button"
                  className="btn-close float-end d-none d-lg-block position-relative position-left-30"
                  style={{ top: "5px" }}
                  onClick={() => dispatch(setEsaScoreModal(undefined))}
                ></button>
                <button
                  type="button"
                  className="btn-close d-block d-lg-none position-relative m-auto"
                  style={{ top: "5px" }}
                  onClick={() => dispatch(setEsaScoreModal(undefined))}
                ></button>
                <span className="font-33 dark-color font-bolder text-center">
                  Calcolo del rating #ESA SCORE
                </span>
              </div>
            </Modal.Header>
            <Modal.Body className="px-1 px-lg-3">
              <div className="modal-esa-score pr-4 pl-4 eshop-container">
                <div className="row mx-3 pb-3">
                  <span className="font-16 gray-color">
                    Il rating su tutte le recensioni viene calcolato in base al
                    nostro algoritmo “ESA SCORE” che misura il grado di
                    affidabilità dell’e-commerce prendendo in considerazione non
                    solo la valutazione media complessiva delle recensioni
                    rilasciate dagli utenti ma anche la loro data di rilascio.
                    “ESA SCORE” decresce in base all’”anzianità” delle
                    recensioni stesse fornendo quindi una "fotografia" della
                    situazione sempre aggiornata evitando che rating eccellenti
                    siano derivanti da recensioni datate piuttosto che da
                    recensioni recenti.
                    <br />
                    <br />
                    <span>
                      L'andamento del rating nel tempo consente di misurare
                      ulteriormente il livello di sicurezza e affidabilità
                      dell'e-commerce.
                    </span>
                  </span>
                </div>
                <div className="row mx-3 pt-3 px-0 px-lg-3">
                  <RatingDetails
                    title="Tutte le recensioni"
                    rating={shop.rating ? shop.rating : 0}
                    isLoading={shop ? false : true}
                  />
                  <RatingDetails
                    title="Ultimi 3 mesi"
                    rating={
                      reviewsStats3?.average_rating
                        ? reviewsStats3.average_rating
                        : 0
                    }
                    isLoading={isLoading3}
                  />
                  <RatingDetails
                    title="Ultimi 12 mesi"
                    rating={
                      reviewsStats12?.average_rating
                        ? reviewsStats12.average_rating
                        : 0
                    }
                    isLoading={isLoading12}
                  />
                </div>
              </div>
            </Modal.Body>
          </Tab.Container>
        </Modal>
      )}
    </>
  )
}

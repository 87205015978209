import { BaseLoader } from "./base.loader"
import { LoaderFunction, LoaderFunctionArgs } from "react-router-dom"
import { shopsApi } from "../services/base/shops"
import { Shop } from "../../models/base/shop.interface"

export class ShopsLoader extends BaseLoader {
  detailLoader: LoaderFunction = async ({
    params,
    request,
  }: LoaderFunctionArgs) => {
    const shop = (await this._loader(shopsApi.endpoints.getShops, request, {
      domain: params.eShopDomain,
    }).then((data) =>
      data.find((shop: Shop) => shop.domain == params.eShopDomain),
    )) as Shop

    return { shop }
  }
}

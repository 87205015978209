import React from "react"
import { CategoriesCarousel } from "./CategoriesCarousel"
import headerImage from "/header.webp"
import { SearchEngine } from "../../../features/search_engine/SearchEngine"

export const Hero: React.FC = () => {
  return (
    <div
      // id="headerContainer"
      className="headerContainer d-block pt-5"
      style={{ backgroundImage: `url(${headerImage})` }}
    >
      <div id="headerContainerBackdrop" className="d-block mt-5">
        <div className="container">
          <div className="row pt-3">
            <div
              // id="title-container"
              className="title-container col-12 col-md-7 mt-3"
            >
              <h4 className="my-3"> Cerca, confronta e acquista. </h4>
              <h2 className="my-3">
                La tua guida per uno shopping online sicuro, sostenibile e di
                qualità.
              </h2>
            </div>
          </div>
          <div className="row py-3 pb-5">
            <div
              id="searchContainer"
              className="searchContainer col-12 col-lg-8"
            >
              <div className="px-2 px-lg-3 dark-fade-background border-radius-75">
                <SearchEngine isFixed={false} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <CategoriesCarousel />
    </div>
  )
}

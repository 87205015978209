import { Link } from "react-router-dom"

export const CouponGuideInfo: React.FC = () => {
  return (
    <div className="d-block font-18 xs-font-16 dark-color my-3">
      <p className="p1">&nbsp;</p>
      <p className="p2">
        <span style={{ fontSize: "12pt" }}>
          <span className="s1">
            Grazie alle recensioni che scrivi su eShoppingAdvisor potrai
            sbloccare i{" "}
          </span>
          <span className="s2">coupon sconto&nbsp;</span>
          <span className="s1">
            messi a disposizione dagli e-commerce registrati e diventare un
            micro influencer sulla community e sul web, scalando la “
          </span>
          <span className="s2">Classifica recensori</span>
          <span className="s1">”.</span>
        </span>
      </p>
      <p className="p2">
        <span className="s1" style={{ fontSize: "12pt" }}>
          Ogni volta che scrivi una recensione accumuli infatti dei punti che
          potrai utilizzare per scaricare i Coupon Sconto.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p className="p3">
        <span className="s1" style={{ fontSize: "12pt" }}>
          <strong>Come funziona?</strong>
        </span>
      </p>
      <ul style={{ listStyleType: "disc" }}>
        <li className="p2">
          <span className="s1" style={{ fontSize: "12pt" }}>
            Per ogni recensione verificata accumuli <strong>0,25 punti</strong>
          </span>
        </li>
        <li className="p2">
          <span className="s1" style={{ fontSize: "12pt" }}>
            Per ogni recensione certificata accumuli <strong>1,00 punto</strong>
          </span>
        </li>
      </ul>
      <p className="p2">
        <span className="s1" style={{ fontSize: "12pt" }}>
          Le recensioni vengono certificate quando, dopo averla scritta, carichi
          una prova di acquisto (fattura, ricevuta, conferma d’ordine, ecc.)
          oppure quando la scrivi a seguito di un invito (via email o sms) da
          parte dell’e-commerce nel quale hai fatto l’acquisto.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
      <p className="p2">
        <span className="s1" style={{ fontSize: "12pt" }}>
          In tutti gli altri casi parliamo di una recensione verificata del
          valore di 0,25 punti.
        </span>
      </p>
      <p className="p3">
        <span className="s1" style={{ fontSize: "12pt" }}>
          <strong>Condizioni pubblicazione recensioni</strong>
        </span>
      </p>
      <p className="p2">
        <span className="s1" style={{ fontSize: "12pt" }}>
          Una recensione può essere validata e quindi pubblicata sulla vetrina
          dell’e-commerce recensito solo se rispetta i seguenti requisiti:
        </span>
      </p>
      <ul style={{ listStyleType: "disc" }}>
        <li className="p2">
          <span className="s1" style={{ fontSize: "12pt" }}>
            La recensione deve descrivere una vera e propria esperienza
            d’acquisto;
          </span>
        </li>
        <li className="p2">
          <span className="s1" style={{ fontSize: "12pt" }}>
            La recensione non può contenere parole offensive e diffamatorie, le
            quali potrebbero essere censurate o determinare la mancata
            pubblicazione della recensione stessa;
          </span>
        </li>
        <li className="p2">
          <span className="s1" style={{ fontSize: "12pt" }}>
            La recensione potrà essere certificata solo se la prova caricata
            attesterà l’effettivo acquisto (fattura, ricevuta, conferma
            d’ordine, ecc.).
          </span>
        </li>
      </ul>
      <p className="p2">
        <span className="s1" style={{ fontSize: "12pt" }}>
          Per ogni domanda o chiarimento è possibile inviare una e-mail
          all’indirizzo{" "}
          <Link to="mailto:info@eshoppingadvisor.com">
            <span className="s4">info@eshoppingadvisor.com</span>
          </Link>{" "}
          o contattarci via chat.
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>
    </div>
  )
}

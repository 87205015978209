import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

interface PaginationProps {
  currentPage: number
  totalPages: number
  handlePageChange: (page: number) => void
}

export const AppPagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  handlePageChange,
}) => {
  const maxDisplayedPages = 5
  let startPage = Math.max(currentPage - Math.floor(maxDisplayedPages / 2), 1)
  let endPage = Math.min(startPage + maxDisplayedPages - 1, totalPages)

  if (endPage - startPage + 1 < maxDisplayedPages) {
    startPage = Math.max(endPage - maxDisplayedPages + 1, 1)
  }

  const pages = Array.from(
    { length: endPage - startPage + 1 },
    (_, index) => startPage + index,
  )

  return (
    <div className="pager">
      <ul className="pagination justify-content-center p-3 m-0" id="yw0">
        {currentPage !== 1 && (
          <>
            <li className="first">
              <a onClick={() => handlePageChange(1)}>
                <span className="page-link">
                  <FontAwesomeIcon
                    icon={["fas", "angles-left"]}
                    className="fl-page"
                  />
                </span>
              </a>
            </li>
            <li className="previous">
              <a onClick={() => handlePageChange(currentPage - 1)}>
                <span className="page-link">
                  <FontAwesomeIcon icon={["fas", "chevron-left"]} />
                </span>
              </a>
            </li>
          </>
        )}

        {pages.map((page) => (
          <li
            key={page}
            className={`page-link ${page === currentPage ? "active" : ""}`}
          >
            <a onClick={() => handlePageChange(page)}>{page}</a>
          </li>
        ))}

        {totalPages > 5 && currentPage != totalPages && (
          <>
            <li className="next">
              <a onClick={() => handlePageChange(currentPage + 1)}>
                <span className="page-link">
                  <FontAwesomeIcon icon={["fas", "chevron-right"]} />
                </span>
              </a>
            </li>
            <li className="last">
              <a onClick={() => handlePageChange(totalPages)}>
                <span className="page-link">
                  <FontAwesomeIcon
                    icon={["fas", "angles-right"]}
                    className="fl-page"
                  />
                </span>
              </a>
            </li>
          </>
        )}
      </ul>
    </div>
  )
}

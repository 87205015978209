import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import { baseApi } from "./services/base/baseApi"
import authReducer from "../features/auth/authSlice"
import searchEngineReducer from "../features/search_engine/searchEngineSlice"
import modalsReducer from "../features/modals/modalsSlice"
import searchFiltersReducer from "../features/filters/search_result/searchFiltersSlice"
import toastsReducer from "../features/toasts/toastsSlice"
import sendReviewReducer from "../pages/send_review/sendReviewSlice"
import ratingProgressBarReducer from "../features/eshop/rating_progress_bar/ratingProgressBarSlice"
import { setupListeners } from "@reduxjs/toolkit/query"
import { esaScraperApi } from "./services/esaScraper/esaScraperApi"

export const store = configureStore({
  reducer: {
    auth: authReducer,
    searchEngine: searchEngineReducer,
    modals: modalsReducer,
    toasts: toastsReducer,
    searchFilters: searchFiltersReducer,
    sendReview: sendReviewReducer,
    ratingProgressBar: ratingProgressBarReducer,
    [baseApi.reducerPath]: baseApi.reducer,
    [esaScraperApi.reducerPath]: esaScraperApi.reducer,
  },
  enhancers: (defaultEnhancers) => [...defaultEnhancers],
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(baseApi.middleware)
      .concat(esaScraperApi.middleware),
})

setupListeners(store.dispatch)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

import React from "react"
import { Form } from "react-bootstrap"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  selectSendReviewFormData,
  selectSendReviewFormErrors,
  updateProductReview,
  updateShopReview,
} from "../../pages/send_review/sendReviewSlice"
import { noSpamCharFunction } from "../../utils/reviewUtils"

interface ReviewFieldTextareaProps {
  inputName: string
  inputLabel?: string
  placeholder: string
  minCharacters: number
  maxCharacters: number
}

export const ReviewFieldTextarea: React.FC<ReviewFieldTextareaProps> = ({
  inputName,
  inputLabel,
  placeholder,
  minCharacters,
  maxCharacters,
}) => {
  const dispatch = useAppDispatch()
  const formData = useAppSelector(selectSendReviewFormData)
  const errors = useAppSelector(selectSendReviewFormErrors)
  const value =
    inputName != "title" && inputName != "description"
      ? formData.products[inputName]?.description
      : inputName === "title"
      ? formData.shop.title
      : formData.shop.description

  return (
    <Form.Group
      key={inputName}
      className={`d-flex flex-column w-100 ${
        inputName === "title" ? "mb-3" : "mb-4"
      }`}
    >
      <div
        className={`${
          inputName != "title" && inputName != "description"
            ? "d-flex"
            : "d-block d-lg-flex"
        } align-items-center justify-content-between mb-2`}
      >
        <Form.Label
          htmlFor={inputName}
          className="font-16 dark-color font-bolder"
        >
          {inputLabel ? inputLabel : "Recensione"}
        </Form.Label>
        <p
          className={`${
            inputName != "title" && inputName != "description"
              ? "d-none d-lg-block"
              : "d-block"
          } lightgray-txt m-0`}
          style={
            value?.length >= minCharacters
              ? { color: "rgb(51, 204, 0)" }
              : value?.length >= 1 && value?.length < minCharacters
              ? { color: "rgb(254, 33, 49)" }
              : {}
          }
        >
          Caratteri min {minCharacters} max. {maxCharacters}, rimanenti
          <span> {maxCharacters - (value?.length || 0)} </span>
        </p>
      </div>
      <Form.Control
        as={"textarea"}
        id={inputName}
        name={inputName}
        className="form-control form-control-custom form-control-gray my-1 resize-none w-100 noSpamChar"
        placeholder={placeholder}
        minLength={minCharacters}
        maxLength={maxCharacters}
        autoComplete="off"
        style={
          inputName === "title"
            ? { height: "52px", whiteSpace: "pre-wrap" }
            : { whiteSpace: "pre-wrap" }
        }
        required={
          inputName != "title" && inputName != "description" ? false : true
        }
        value={value}
        onKeyPress={noSpamCharFunction}
        onChange={(e) => {
          const { name, value } = e.currentTarget
          if (name != "title" && name != "description") {
            dispatch(updateProductReview({ name, value }))
          } else {
            dispatch(updateShopReview({ name, value }))
          }
        }}
        isInvalid={!!errors[inputName]}
      ></Form.Control>
      {errors[inputName] && (
        <div className="invalid-feedback mt-2">{errors[inputName]}</div>
      )}
    </Form.Group>
  )
}

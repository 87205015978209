import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useActivateNewsletterMutation } from "../../app/services/base/general"
import newsletterCss from "./newsletter.css?inline"

export const NewsletterActivated: React.FC = () => {
  const dispatch = useDispatch()
  const { id, code } = useParams()
  const [activateNewsletter] = useActivateNewsletterMutation()

  if (!id || !code) {
    return
  }

  const activateNewsletterAction = async () => {
    const result = await activateNewsletter({ id: id, code: code })
    dispatch({ type: "ACTIVATE_NEWSLETTER", payload: result })
  }

  useEffect(() => {
    activateNewsletterAction()
  }, [])

  return (
    <section className="newsletter-page">
      <div id="newsletter">
        <div className="container">
          <div className="row justify-content-center">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-12 col-md-12 col-lg-auto text-center">
                <div className="newsletter-ok">
                  <h3 className="titolo">Iscrizione effettuata</h3>
                  <p>
                    Attivazione newsletter effettuata con successo.
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>{newsletterCss}</style>
    </section>
  )
}

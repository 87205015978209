import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { ReactNode } from "react"

interface AlertProps {
  message: ReactNode
  hideIcon?: boolean
  customClass?: string
}

export const Alert: React.FC<AlertProps> = ({
  message,
  hideIcon,
  customClass,
}) => {
  return (
    <div
      className={`alert alert-warning d-flex align-items-center py-5 px-3 px-lg-5 ${
        customClass ? customClass : ""
      }`}
      role="alert"
    >
      <div className="row w-100">
        <div className="col-12 text-center">
          {!hideIcon && (
            <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />
          )}
          {message}
        </div>
      </div>
    </div>
  )
}

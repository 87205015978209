import React from "react"
import { Helmet } from "react-helmet"
import { formatNumber } from "../../utils/stringUtils"
import { EsaScraperProduct } from "../../models/esaScraper/esaScraperProduct.interface"
import { ReviewUserUnion } from "../../models/base/reviewUserUnion.interface"
interface Review {
  title: string
  description: string
  overall_rating?: number
}

interface User {
  name: string
}


interface ProductSchemaProps {
  product: EsaScraperProduct
  reviews?: ReviewUserUnion[]
  isAffiliated: boolean
}

export const ProductSchema: React.FC<ProductSchemaProps> = ({
  product,
  reviews,
  isAffiliated,
}) => {
  const generateReviews = () => {
    return reviews?.map(({ review, user }) => ({
      "@type": "Review",
      reviewRating: {
        "@type": "Rating",
        ratingValue: review.overall_rating || 0,
        bestRating: 5,
      },
      headline: review.title.replace(/"/g, "'"),
      reviewBody: review.description.replace(/"/g, "'"),
      author: {
        "@type": "Person",
        name: user.name,
      },
      publisher: {
        "@type": "Organization",
        name: "eShoppingAdvisor",
        url: import.meta.env.BASE_URL,
        logo: {
          "@type": "ImageObject",
          url: "https://www.eshoppingadvisor.com/logo.png",
          width: 1200,
          height: 1200,
          caption: "eShoppingAdvisor logo",
        },
      },
    }))
  }

  const parseDescription = (
    description: string | { value: string },
  ): string => {
    if (typeof description === "string") {
      // Check if it's a stringified Python-like dictionary
      const match = description.match(/^\s*{\s*'value'\s*:\s*'(.+)'\s*}\s*$/)
      if (match) {
        return match[1]
      }
      try {
        const parsedDesc = JSON.parse(description)
        return parsedDesc.value || description
      } catch {
        return description
      }
    } else if (typeof description === "object" && description.value) {
      return description.value
    }
    return String(description)
  }

  const reviewsJson =
    reviews && reviews.length > 0
      ? `"review": ${JSON.stringify(generateReviews())},`
      : ""

  const aggregateRatingJson =
    reviews && reviews.length > 0
      ? `"aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": ${product.score || 0},
        "reviewCount": ${product.reviews || 0}
      },`
      : ""

  const urlJson =
    !isAffiliated && !product.has_affiliation && product.url
      ? `"url": "${product.url}",`
      : ""

  const cleanDescription = parseDescription(product.description)
    .replace(/"/g, "'")
    .replace(/\n/g, " ")
    .replace(/\\/g, "") // Remove backslashes

  const schemaJson = `
    {
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": "${product.product_name.replace(/"/g, "'")}",
      "image": "${product.cdn_image_url || product.image_url}",
      "description": "${cleanDescription}",
      ${reviewsJson}
      ${aggregateRatingJson}
      "offers": {
        "@type": "Offer",
        ${urlJson}
        "priceCurrency": "EUR",
        "price": "${formatNumber(product.price || product.high_price || 0, 2)}",
        "availability": "https://schema.org/InStock"
      }
    }
  `

  return (
    <Helmet>
      <script type="application/ld+json">{schemaJson}</script>
    </Helmet>
  )
}

import "./Root.css"
import { useAppDispatch, useAppSelector } from "./app/hooks"
import { useEffect, useState } from "react"
import { selectCurrentUser, setUser } from "./features/auth/authSlice"
import {
  Outlet,
  ScrollRestoration,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom"
import { User } from "./models/base/user.interface"
import { DefaultSchema } from "./features/schemas/DefaultSchema"
import TagManager from "react-gtm-module"
import { tryToParse } from "./utils/objectsUtils"
import { MobileMenu } from "./features/mobile_menu/MobileMenu"
import { Header } from "./features/header/Header"
import { Footer } from "./features/footer/Footer"
import { LoadingSpinner } from "./features/loading_spinner/LoadingSpinner"
import { ToastsContainer } from "./features/toasts/ToastsContainer"
import { setCouponModal, setReportModal } from "./features/modals/modalsSlice"

const Root = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false)
  const params = useParams()
  const [isUserLoaded, setIsUserLoaded] = useState<boolean>(false)
  const user = useAppSelector(selectCurrentUser)
  const [showDefaultSchema, setShowDefaultSchema] = useState<boolean>(true)
  const excludedDefaultSchemaPaths = ["/it/eshop/", "/it/reviews/"]
  const excludedExactlyDefaultSchemaPaths = ["/it/", "/it"]

  TagManager.dataLayer({
    dataLayer: {
      event: "pageview",
    },
  })

  function toggleMobileMenu() {
    if (isMobileMenuOpen) {
      document.body.classList.remove("disable-scroll")
    } else {
      document.body.classList.add("disable-scroll")
    }
    setIsMobileMenuOpen(!isMobileMenuOpen)
  }

  useEffect(() => {
    document.body.classList.remove("disable-scroll")
    setIsMobileMenuOpen(false)
  }, [location])

  useEffect(() => {
    const loadUserFromStorage = async () => {
      const storedUser = await localStorage.getItem("user")
      if (storedUser) {
        const user: User = await tryToParse(storedUser)
        if (user) {
          dispatch(setUser(user))
        }
      }
      setIsUserLoaded(true)
    }

    const validLanguages = ["it", "en", "es"]
    const defaultLang = "it"
    const pathParts = location.pathname.split("/").filter(Boolean)
    // Check if the first part of the path is a valid language
    if (!validLanguages.includes(pathParts[0])) {
      // If it's not, redirect to the same path with the default language prefix
      navigate(`/${defaultLang}${location.pathname}${location.search}`, {
        replace: true,
      })
    } else if (params.lang && !validLanguages.includes(params.lang)) {
      window.location.href = '/404';
    }

    loadUserFromStorage()
  }, [dispatch, navigate, params.lang])

  useEffect(() => {
    // With this function i'm sure that the modals that uses state redirect
    // aren't open after the redirect to login page and the user not do the log in, and navigate to others pages
    if (
      location.pathname != "/areariservata/login" &&
      location.pathname != "/registrazione/cliente" &&
      !user
    ) {
      dispatch(setCouponModal(undefined))
      dispatch(setReportModal(undefined))
    }
    if (
      excludedDefaultSchemaPaths
        .map((path) => location.pathname.includes(path))
        .includes(true)
    ) {
      setShowDefaultSchema(false)
    }

    if (excludedExactlyDefaultSchemaPaths.includes(location.pathname)) {
      setShowDefaultSchema(false)
    }
  }, [location])

  return (
    <>
      {isUserLoaded && (
        <>
          {showDefaultSchema && (
            <DefaultSchema
              showOrganization
              showImageObject
              metaTitle="eShoppingAdvisor: leggi e scrivi Recensioni, fai acquisti Sicuri"
              metaDescription="Leggi le recensioni di tutti gli e-commerce e dei loro prodotti, trova le migliori offerte. Acquista online sui siti più sicuri e risparmia!"
            />
          )}
          <Header
            isMobileMenuOpen={isMobileMenuOpen}
            toggleMobileMenu={toggleMobileMenu}
          />
          <Outlet />
          <Footer />
          {isMobileMenuOpen && <MobileMenu />}
          <LoadingSpinner />
          <ToastsContainer />
          <ScrollRestoration />
        </>
      )}
    </>
  )
}

export default Root

import React, { ChangeEvent, useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useSubscribeNewsletterMutation } from "../../app/services/base/general"
import { Link, useLocation } from "react-router-dom"
import newsletterCss from "./newsletter.css?inline"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { forceIsOpenTo } from "../../features/search_engine/searchEngineSlice"

export const Newsletter: React.FC = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const [subscribeNewsletter] = useSubscribeNewsletterMutation()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const [isSubscribed, setIsSubscribed] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [formData, setFormData] = useState<{
    name: string
    email: string
    privacy: boolean
    recaptchaToken: string
  }>({ name: "", email: "", privacy: true, recaptchaToken: "" })

  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    const { name, value, type, checked } = e.target

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  async function handleNewsletterSubmit() {
    try {
      const response = await subscribeNewsletter({
        ...formData,
        type: "consumer",
      }).unwrap()
      if (response.response != "email sent succesfully") throw new Error()
      setIsSubscribed(true)
    } catch (err) {
      setIsError(true)
    }
  }

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return
    }
    const token = await executeRecaptcha("clientRegistration")
    const email = location.state.email || ""
    setFormData({ ...formData, email: email, recaptchaToken: token })
  }, [executeRecaptcha])

  useEffect(() => {
    handleReCaptchaVerify()
  }, [handleReCaptchaVerify])

  useEffect(() => {
    if (location.state && location.state.email) {
      setIsSubscribed(false)
      setFormData({ ...formData, email: location.state.email })
    }
  }, [location.state])

  useEffect(() => {
    dispatch(forceIsOpenTo(false))
  }, [])

  return (
    <section className="newsletter-page">
      <div id="newsletter">
        <div className="container">
          <div className="row justify-content-center">
            {!isSubscribed ? (
              <div className="col-12 col-sm-12 col-md-auto">
                <form
                  className="horizontal-form form form-registrazione"
                  id="newsletter-form"
                >
                  <h3 className="titolo d-block mb-4 text-center">
                    Iscriviti alla newsletter
                  </h3>
                  <div className="form-group">
                    <input
                      className="form-control"
                      placeholder="Inserisci il tuo nome"
                      name="name"
                      id="NewsletterPublicForm_nome"
                      type="text"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      className={`form-control ${isError ? "error" : ""}`}
                      placeholder="Inserisci la tua email"
                      name="email"
                      id="NewsletterPublicForm_email"
                      type="text"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                    {isError && (
                      <div className="help-block">
                        Indirizzo email già utilizzato in un'altro account
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <label
                      className="recaptchaTerms"
                      htmlFor="NewsletterPublicForm_verifyCode"
                    >
                      Questo sito è protetto da reCAPTCHA e vengono applicate le{" "}
                      <Link
                        className="simple-blue-link orange-hover"
                        target="_blank"
                        to="https://policies.google.com/privacy"
                      >
                        Norme sulla Privacy
                      </Link>{" "}
                      e i{" "}
                      <Link
                        className="simple-blue-link orange-hover"
                        target="_blank"
                        to="https://policies.google.com/terms"
                      >
                        Termini di Servizio{" "}
                      </Link>{" "}
                      di Google.
                    </label>
                    {/* <Form.Control
          type="hidden"
          name="recaptcha_response"
          value={formData.recaptchaToken}
        /> */}
                  </div>
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={handleNewsletterSubmit}
                  >
                    Invia
                  </button>
                </form>
              </div>
            ) : (
              <div className="row justify-content-center">
                <div className="col-12 col-sm-12 col-md-12 col-lg-auto text-center">
                  <div className="newsletter-ok">
                    <h3 className="titolo">Iscrizione effettuata</h3>
                    <p>
                      Iscrizione alla newsletter effettuata con successo.
                      <br />
                      Segui le istruzioni nella mail che riceverai per
                      completare la registrazione
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <style>{newsletterCss}</style>
    </section>
  )
}

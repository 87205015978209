import { Category } from "../../../models/base/category.interface"
import { baseApi } from "./baseApi"

export const categoriesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    // MAPPATO
    getCategory: build.query<Category, string>({
      query: (categorySlug) => ({ url: `category/${categorySlug}` }),
      providesTags: (result, error, categorySlug) => [
        { type: "Categories", categorySlug },
      ],
    }),
    // MAPPATO
    getCategories: build.query<Category[], { type: string }>({
      query: ({ type }) => {
        return { url: "categories", params: { type } }
      },
      providesTags: (result = []) => [
        ...result.map(({ id }) => ({ type: "Categories", id } as const)),
        { type: "Categories" as const, id: "LIST" },
      ],
      transformResponse: (response: Category[], meta, arg) => {
        return response.sort((a, b) => Number(a.id) - Number(b.id))
      },
    }),
  }),
})

export const { useGetCategoryQuery, useGetCategoriesQuery } = categoriesApi

import React from "react"
import "./progetto.css"
import img_1 from "../../assets/images/il_progetto/img_1.png"
import img_2 from "../../assets/images/il_progetto/img_2.png"
import img_3 from "../../assets/images/il_progetto/img_3.png"
import img_4 from "../../assets/images/il_progetto/img_4.png"
import sez_1 from "../../assets/images/il_progetto/sez-1.png"
import sez_2 from "../../assets/images/il_progetto/sez-2.png"
import sez_3 from "../../assets/images/il_progetto/sez-3.png"
import sez_4 from "../../assets/images/il_progetto/sez-4.png"
import sez_5 from "../../assets/images/il_progetto/sez-5.png"
import { Helmet } from "react-helmet"

// Project component
export const Project: React.FC = () => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Acquista locale e sostieni gli e-commerce italiani"
        />
        <link rel="canonical" type="" href={window.location.href} />
      </Helmet>
      <section className="il-progetto-page">
        <div className="progetto-header text-center">
          <div className="header-container">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <span className="header-title"> Acquista locale </span>
                  <h1 className="header-subtitle">
                    Sostieni i piccoli e medi ecommerce italiani
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="info-section info-section-0">
          <div className="container text-center">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <span className="page-title">
                  {" "}
                  Contribuisci anche tu a far crescere il Robin Hood degli
                  ecommerce
                </span>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 record-container">
                <div className="img-contaier">
                  <img
                    className="img-fluid info-img"
                    alt="<strong> + 3200 </strong> Ecommerce registrati"
                    src={img_1}
                  />
                </div>
                <span className="img-txt">
                  {" "}
                  <strong> + 3200 </strong> Ecommerce registrati{" "}
                </span>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 record-container">
                <div className="img-contaier">
                  <img
                    className="img-fluid info-img"
                    alt="<strong> + 15 MLN </strong> di prodotti presenti sul motore di ricerca"
                    src={img_2}
                  />
                </div>
                <span className="img-txt">
                  {" "}
                  <strong> + 15 MLN </strong> di prodotti presenti sul motore di
                  ricerca{" "}
                </span>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 record-container">
                <div className="img-contaier">
                  <img
                    className="img-fluid info-img"
                    alt="<strong> + 10000 </strong> Ecommerce valutati dai clienti"
                    src={img_3}
                  />
                </div>
                <span className="img-txt">
                  {" "}
                  <strong> + 10000 </strong> Ecommerce valutati dai clienti{" "}
                </span>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 record-container">
                <div className="img-contaier">
                  <img
                    className="img-fluid info-img"
                    alt="<strong> + 14000  </strong> Ecommerce presenti con i propri prodotti sul portale"
                    src={img_4}
                  />
                </div>
                <span className="img-txt">
                  {" "}
                  <strong> + 14000 </strong> Ecommerce presenti con i propri
                  prodotti sul portale{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="info-section info-section-1">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center order-md-2">
                <img
                  className="img-fluid info-img"
                  alt="Vogliamo aiutarti a trovare il prodotto che desideri"
                  src={sez_1}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 order-md-1">
                <h2 className="section-title">
                  Vogliamo aiutarti a trovare il prodotto che desideri
                </h2>
                <span className="section-txt">
                  {" "}
                  suggerendoti solo piccoli e medi ecommerce italiani e aiutando
                  nel contempo le attività online a emergere e prosperare sul
                  web, nonostante i grandi marketplace e i big dell’online
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="info-section info-section-2">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
                <img
                  className="img-fluid info-img"
                  alt="Per questo abbiamo costruito un luogo unico dove trovare qualsiasi prodotto"
                  src={sez_2}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h2 className="section-title">
                  Per questo abbiamo costruito un luogo unico dove trovare
                  qualsiasi prodotto
                </h2>
                <span className="section-txt">
                  {" "}
                  anche di nicchia e acquistarlo in sicurezza: il portale
                  eShoppingAdvisor.com.{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="info-section info-section-3">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center order-md-2">
                <img
                  className="img-fluid info-img"
                  alt="Un luogo unico nel quale, nel tempo, confluiscano tutti gli ecommerce italiani"
                  src={sez_3}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 order-md-1">
                <h2 className="section-title">
                  Un luogo unico nel quale, nel tempo, confluiscano tutti gli
                  ecommerce italiani
                </h2>
                <span className="section-txt">
                  {" "}
                  con i prodotti e servizi da loro venduti e le valutazioni da
                  parte degli utenti, per garantire, appunto, un acquisto in
                  totale sicurezza.{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="info-section info-section-4">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
                <img
                  className="img-fluid info-img"
                  alt="Un luogo quindi rassicurante che ti faccia sentire protetto"
                  src={sez_4}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h2 className="section-title">
                  Un luogo quindi rassicurante che ti faccia sentire protetto
                </h2>
                <span className="section-txt">
                  {" "}
                  nel corso del tuo shopping online, grazie anche alle
                  valutazioni dei venditori derivanti dalle recensioni di tutti.{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="info-section info-section-5 text-center">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <img
                  className="img-fluid info-img"
                  alt="Ti aspettano <b> milioni di prodotti </b> con <b> migliaia di specializzazioni </b> e <b> numerosi buoni sconto </b> messi a disposizione dagli ecommerce italiani <b> in cambio solo della tua opinione.</b>"
                  src={sez_5}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                <span className="section-txt">
                  {" "}
                  Ti aspettano <b> milioni di prodotti </b> con{" "}
                  <b> migliaia di specializzazioni </b> e{" "}
                  <b> numerosi buoni sconto </b> messi a disposizione dagli
                  ecommerce italiani <b> in cambio solo della tua opinione.</b>{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="info-section info-section-6 text-center">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="progetto-video-row">
                  <div className="progetto-video-container">
                    <iframe
                      className="progetto-video"
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/TBKGAFVc0h8"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      style={{ border: "none", overflow: "hidden" }}
                    ></iframe>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                <span className="section-txt">
                  {" "}
                  Insieme possiamo{" "}
                  <b>
                    {" "}
                    contribuire alla ripresa dell’economia di casa nostra,
                  </b>{" "}
                  sostenendo le <b> piccole e medie realtà dell’online </b> e
                  rendendo <b> il web un luogo molto più sicuro </b> nel quale
                  acquistare, grazie anche alla{" "}
                  <b> condivisione delle recensioni sulle tue esperienze. </b>{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

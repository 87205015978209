import { EsaScraperFeedInfo } from "../../../models/esaScraper/esaScraperFeedInfo.interface"
import { esaScraperApi } from "./esaScraperApi"

export const generalApi = esaScraperApi.injectEndpoints({
  endpoints: (build) => ({
    checkDns: build.query<
      boolean,
      { domain: string; }
      >({
      query: ({ domain }) => {
        return {
          url: `domain/check/dns/`,
          params: { domain: domain },
        }
      },
      transformResponse(response: {is_valid: boolean}) {
        return response.is_valid ? true : false;
      },
      }),
    // MAPPATO
    getFeedInfo: build.query<
      EsaScraperFeedInfo,
      { domainId: number; kindOfFeed?: string }
    >({
      query: ({ domainId, kindOfFeed }) => {
        return {
          url: `esasearch/feed/data/${domainId}/`,
          params: { kind_of_feed: kindOfFeed },
        }
      },
    }),
  }),
})

export const { useGetFeedInfoQuery, useCheckDnsQuery } = generalApi

import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectCouponModal, setCouponModal } from "./modalsSlice"
import { Link, useLocation } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { addToast } from "../toasts/toastsSlice"
import { selectCurrentUser } from "../auth/authSlice"
import { formatNumber } from "../../utils/stringUtils"
import {
  useGetCouponsUserHistoryQuery,
  useUnlockCouponMutation,
} from "../../app/services/base/coupons"
import { Coupon } from "../../models/base/coupon.interface"
import shopPlaceholderImage from "../../assets/images/thumb_placeholder.png"
import { RatingStars } from "../rating_stars/RatingStars"
import { skipToken } from "@reduxjs/toolkit/query"

export const CouponModal: React.FC = () => {
  const user = useAppSelector(selectCurrentUser)
  const dispatch = useAppDispatch()
  const couponWithShop = useAppSelector(selectCouponModal)
  const [isCopied, setIsCopied] = useState<boolean>(false)
  const [isUnlocked, setIsUnlocked] = useState<boolean>(false)
  const [unlockCoupon, { error }] = useUnlockCouponMutation()
  const location = useLocation()
  const cdnBaseUrl = import.meta.env.VITE_CDN_BASE_URL
  const couponImg = "/logos/" + couponWithShop?.eshop.id + ".webp"

  const { data: unlockedCoupons } = useGetCouponsUserHistoryQuery(
    couponWithShop && user
      ? {
          userId: user.id,
        }
      : skipToken,
  )

  useEffect(() => {
    if (couponWithShop && unlockedCoupons) {
      const isUnlocked = unlockedCoupons?.purchases.some(
        (purchase) => purchase.id == couponWithShop.coupon.id,
      )

      setIsUnlocked(isUnlocked)
    }
  }, [couponWithShop, unlockedCoupons])

  if (!couponWithShop) {
    return <></>
  }

  async function handleUnlockCoupon(coupon: Coupon) {
    try {
      const response = user
        ? await unlockCoupon({
            userId: user.id,
            couponId: coupon.id,
          }).unwrap()
        : false

      if (response) {
        setIsUnlocked(true)
        dispatch(
          addToast({
            message: "Coupon sbloccato!",
            type: "success",
          }),
        )
      } else {
        dispatch(
          addToast({
            message: "Ops! Non hai abbastanza punti per sbloccare il coupon.",
            type: "danger",
          }),
        )
      }
    } catch (err) {
      dispatch(
        addToast({
          message: "Ops! Qualcosa è andato storto.",
          type: "danger",
        }),
      )
    }
  }

  return (
    <>
      <Modal
        show={couponWithShop ? true : false}
        onHide={() => dispatch(setCouponModal(undefined))}
        size="lg"
        centered
        aria-labelledby="couponModalLabel"
        dialogClassName="justify-content-center"
        contentClassName="bg-transparent position-relative border-0"
      >
        <div className="d-flex flex-column align-items-center justify-content-center w-100">
          <div className="card w-100 p-3 px-lg-5">
            <div className="d-flex flex-column align-items-center justify-content-center border-0">
              <button
                type="button"
                className="btn-close btn-close-absolute d-none d-lg-block position-absolute p-0"
                onClick={() => dispatch(setCouponModal(undefined))}
              ></button>
            </div>
            <div className="d-flex flex-column justify-content-center justify-content-lg-start align-items-center align-items-lg-start">
              <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center mb-3">
                <div className="me-lg-3">
                  <div className="coupon-details-photo d-flex align-items-center">
                    <img
                      src={cdnBaseUrl + couponImg}
                      className="img-fluid p-3"
                      loading="lazy"
                      onError={(e) =>
                        (e.currentTarget.src = shopPlaceholderImage)
                      }
                    />
                  </div>
                </div>
                <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center justify-content-lg-between w-100">
                  <div className="d-flex flex-column align-items-center align-items-lg-start justify-content-center">
                    <p
                      className="font-16 font-bolder m-0"
                      style={{ color: "#e55e0e" }}
                    >
                      {couponWithShop?.eshop.domain}
                    </p>
                    <h6 className="font-33 font-bolder dark-color m-0">
                      Sconto{" "}
                      {formatNumber(Number(couponWithShop?.coupon.discount), 0)}
                      {couponWithShop.coupon.discount_type === 1 ? "%" : "€"}
                    </h6>
                    <RatingStars
                      rating={couponWithShop.eshop.rating}
                      showAverageRating
                      size="small"
                    />
                    <p className="lightgray-txt text-uppercase m-0">
                      {
                        couponWithShop.categories.find((c) => c.parent === null)
                          ?.description
                      }
                    </p>
                  </div>
                </div>
              </div>
              <p className="font-bolder dark-color m-0 mt-3 mb-2">
                Condizioni di utilizzo
              </p>
              <p className="dark-color m-0 mb-3">
                {couponWithShop?.coupon.conditions}
              </p>
              <p className="font-bolder dark-color m-0 mt-3 mb-2">
                Modalità di utilizzo
              </p>
              <p className="dark-color m-0 mb-3">
                {couponWithShop?.coupon.usage_mod}
              </p>
            </div>
            {!user ? (
              <div className="d-flex align-items-center justify-content-start border-0 gap-1 my-3">
                <Link
                  to={`/it/areariservata/login`}
                  state={{ from: location }}
                  className="btn btn-gradient-orange btn-ecommerce-details py-2 d-flex align-items-center justify-content-center m-0 me-1"
                >
                  Accedi
                </Link>
                <Link
                  to={`/it/registrazione/cliente`}
                  state={{ from: location }}
                  className="btn btn-outline-animation btn-outline-gradient-orange btn-ecommerce-details py-2 d-flex align-items-center justify-content-center m-0 ms-1"
                >
                  Registrati
                </Link>
              </div>
            ) : (
              <div className="d-flex align-items-center justify-content-start border-0 gap-1 my-3">
                {!isUnlocked ? (
                  <span
                    className="unlock-trigger btn btn-gradient-orange py-2 d-flex align-items-center justify-content-center w-100 mb-3"
                    rel="unlock-1691"
                    style={{ height: "50px" }}
                    onClick={() => handleUnlockCoupon(couponWithShop.coupon)}
                  >
                    Sblocca sconto con {couponWithShop.coupon.points_cost}{" "}
                    {couponWithShop.coupon.points_cost == 1 ? "punto" : "punti"}
                  </span>
                ) : (
                  <div className="c-coupon-codice border p-3 mb-3">
                    <span
                      className="c-label-detail cursor-pointer"
                      onClick={async () => {
                        try {
                          await navigator.clipboard.writeText(
                            couponWithShop?.coupon.code,
                          )
                          setIsCopied(true)
                          dispatch(
                            addToast({
                              message: "Testo copiato!",
                              type: "success",
                            }),
                          )
                        } catch (err) {
                          dispatch(
                            addToast({
                              message: "Ops! Impossibile copiare il testo.",
                              type: "danger",
                            }),
                          )
                        }
                      }}
                    >
                      <FontAwesomeIcon
                        icon={["fas", "copy"]}
                        className="me-2"
                      />
                      <span className="c-label-detail">Codice: </span>
                      <span className="c-val-detail">
                        {couponWithShop?.coupon.code}
                      </span>
                    </span>
                    {isCopied && (
                      <span className="gray-text font-14 text-center">
                        <FontAwesomeIcon
                          icon={["fas", "check"]}
                          className="ms-2 me-1"
                        />
                        Copiato
                      </span>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="d-flex align-items-center justify-content-center d-lg-none mt-4">
            <button
              type="button"
              className="btn-close btn-close-white"
              aria-label="Close"
              onClick={() => dispatch(setCouponModal(undefined))}
            ></button>
          </div>
        </div>
      </Modal>
    </>
  )
}

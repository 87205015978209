import React, { useEffect, useState } from "react"
import { Shop } from "../../../../models/base/shop.interface"
import dangerIcon from "../../../../assets/icons/danger_icon.svg"
import warningIcon from "../../../../assets/icons/warning_icon.svg"
import safeIcon from "../../../../assets/icons/safe_icon.svg"
import eCommerceSicuroOrange from "../../../../assets/icons/ecommercesicuro_logo_orange.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import { Tab } from "react-bootstrap"
import loader from "../../../../assets/images/loader.webp"
import { useGetSecureRatingByDomainQuery } from "../../../../app/services/base/general"

interface SecurityRatingTabContentProps {
  shop: Shop
  activeKey: string
}

export const SecurityRatingTabContent: React.FC<
  SecurityRatingTabContentProps
> = ({ shop, activeKey }) => {
  const { data: securityRating, isFetching } = useGetSecureRatingByDomainQuery(
    shop.domain,
  )

  const [progressWidth, setProgressWidth] = useState<number>(0)

  function renderParameterList(startIndex: number, endIndex: number) {
    return Object.values(securityRating!.parameters)
      .slice(startIndex, endIndex)
      .map((parameter: any) => (
        <li
          key={parameter.title}
          className="d-flex align-items-center dark-color font-14"
        >
          <span>
            <img
              src={
                parameter.value === true || parameter.value === 1
                  ? safeIcon
                  : parameter.value === false
                  ? warningIcon
                  : dangerIcon
              }
              alt={
                parameter.value === true
                  ? "Safe Icon"
                  : parameter.value === false
                  ? "Warning Icon"
                  : "Danger Icon"
              }
              className="me-2 me-lg-2"
            />
          </span>{" "}
          {parameter.title}
        </li>
      ))
  }

  const ratingRange = securityRating
    ? securityRating.ecs_rating >= 75
      ? "safe"
      : securityRating.ecs_rating >= 45 && securityRating.ecs_rating < 75
      ? "warning"
      : "danger"
    : "danger"

  useEffect(() => {
    if (activeKey === "securityRating" && securityRating) {
      const intervalId = setInterval(() => {
        setProgressWidth((prevProgress) => {
          const newProgress =
            prevProgress >= securityRating.ecs_rating
              ? prevProgress
              : prevProgress + 1

          if (newProgress >= securityRating.ecs_rating) {
            clearInterval(intervalId)
          }

          return newProgress
        })
      }, 7)
      return () => clearInterval(intervalId)
    }
  }, [activeKey])

  return (
    <Tab.Pane eventKey="securityRating" aria-labelledby="Security Rating">
      <p className="font-16 font-bolder dark-color m-0 mb-2 mt-1 mt-lg-4">
        <span className="shop-url"> {shop.domain} </span> è sicuro?
      </p>
      <p className="m-0 mb-4">
        Prima di acquistare controlla l’affidabilità di questo sito.
      </p>
      {isFetching || !securityRating ? (
        <div id="ecs-rating-load" className="row align-items-center d-none">
          <span className="d-block w-100 text-center">
            Calcolo rating in corso...
            <img className="spin mx-1" height="20px" src={loader} />
          </span>
        </div>
      ) : (
        <div id="ecs-rating" className="d-flex flex-column">
          <div className="d-flex d-none d-lg-block mb-lg-2">
            <div className="lightgray-txt font-16">Security Rating</div>
          </div>
          <div className="d-flex flex-column flex-lg-row align-items-center mb-lg-5">
            <div className="d-flex align-items-center justify-content-between security-rating-custom-width mb-4 mb-lg-0">
              <div className="lightgray-txt font-16 d-lg-none">
                Security Rating
              </div>
              <div className="d-flex align-items-center me-0 me-lg-3">
                <span id="ratingShield" className="me-3">
                  <img
                    src={
                      ratingRange === "safe"
                        ? safeIcon
                        : ratingRange === "warning"
                        ? warningIcon
                        : dangerIcon
                    }
                    alt={
                      ratingRange === "safe"
                        ? "Safe Icon"
                        : ratingRange === "warning"
                        ? "Warning Icon"
                        : "Danger Icon"
                    }
                    className="me-2 me-lg-2"
                    width="30px"
                    height="30px"
                  />
                </span>
                <span className="secure-rating-text dark-color font-bolder me-2 insert-ecs-rating">
                  {progressWidth}
                </span>
                <span className="font-23 lightgray-txt">/100</span>
              </div>
            </div>
            <div
              className="progress-security-rating flex-grow-1 w-100 ms-0 ms-lg-3 mb-5 mb-lg-0"
              role="progressbar"
              aria-label="Security Rating Progress Bar"
              aria-valuenow={securityRating.ecs_rating}
              aria-valuemin={0}
              aria-valuemax={100}
            >
              <FontAwesomeIcon
                icon={["fas", "caret-down"]}
                className="font-12 lightgray-txt position-absolute"
                style={{
                  top: "-13px",
                  fontWeight: "900 !important",
                  left: `${progressWidth - 1}%`,
                }}
              />
              <div
                className="progress-bar-security-rating full"
                style={{ width: `${progressWidth}%` }}
              ></div>
              <div
                className="d-flex align-items-center justify-content-between"
                style={{ marginTop: "15px" }}
              >
                {[dangerIcon, warningIcon, safeIcon].map((icon, index) => (
                  <div key={index} className="d-flex align-items-center">
                    <img
                      src={icon}
                      alt={`${
                        index == 0 ? "Danger" : index == 1 ? "Warning" : "Safe"
                      } Icon`}
                      className="m-0 me-lg-2"
                    />
                    <p className="lightgray-txt m-0 d-none d-lg-block">
                      Rischio{" "}
                      {index == 0 ? "Alto" : index == 1 ? "Medio" : "Basso"}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <p className="font-16 font-bolder dark-color m-0 mt-5">
            Parametri di calcolo del Security Rating
          </p>
          <div
            id="rating-parameters"
            className="d-flex flex-column flex-lg-row justify-content-between gap-0 gap-lg-3 my-4"
          >
            <ul
              id="rating-parameters1"
              className="col-12 col-lg-6 list-unstyled d-flex flex-column gap-4 m-0 mb-4 mb-lg-0"
            >
              {renderParameterList(
                0,
                Object.keys(securityRating.parameters).length / 2,
              )}
            </ul>
            <ul
              id="rating-parameters2"
              className="col-12 col-lg-6 list-unstyled d-flex flex-column align-items-start gap-4 m-0"
            >
              {renderParameterList(
                Object.keys(securityRating.parameters).length / 2,
                Object.keys(securityRating.parameters).length,
              )}
            </ul>
          </div>
          <div className="d-flex align-items-center secure-rating-verification py-3 py-lg-2 my-3">
            <span className="ms-3 ms-lg-2 me-3 me-lg-2">
              <img
                src={eCommerceSicuroOrange}
                alt="eCommerceSicuro"
                className="secure-rating-verification-logo m-0"
              />
            </span>
            <p className="dark-color font-14 m-0">
              Vuoi approfondire quanto è sicuro acquistare su questo eCommerce?{" "}
              <Link
                to={`https://www.ecommercesicuro.com/sito/${shop.domain}`}
                className="font-semi-bolder simple-orange-txt"
                target="_blank"
              >
                Verifica quanto è affidabile
              </Link>
            </p>
          </div>
        </div>
      )}
    </Tab.Pane>
  )
}

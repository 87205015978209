import React from "react"
import { Shop } from "../../../models/base/shop.interface"
import { Link } from "react-router-dom"
import couponCard from "../../../assets/images/coupon-card.png"

interface EShopCouponsCardProps {
  shop: Shop
}

export const EShopCouponsCard: React.FC<EShopCouponsCardProps> = ({ shop }) => {
  return (
    <div className="card eshop-custom-card blue-coupon-card mb-0 mb-lg-2 me-2 me-lg-0">
      <div className="card-body text-start d-flex flex-column justify-content-between px-3 py-4 p-lg-4">
        <div>
          <h5
            className="blue-card-title mb-2 mb-lg-3"
            style={{ color: "#fff", fontSize: "23px" }}
          >
            Coupon sconto disponibili!
          </h5>
          <p className="line-clamp line-clamp-6 blue-coupon-text white-color font-lighter font-14 mb-lg-4">
            Scopri tutti i coupon di {shop.domain} dedicati a te.
          </p>
        </div>
        <Link
          to={`/it/coupon-eshop/${shop.domain}`}
          className="btn btn-outline-animation btn-outline-gradient-blue blue-coupon-btn border-0 d-flex justify-content-center align-items-center font-16 font-semi-bolder"
          target="_blank"
          title={`Coupon sconto di ${shop.domain}`}
        >
          Vai ai Coupon
        </Link>
      </div>
      <img
        src={couponCard}
        className="d-none d-lg-block blue-coupon-card-img"
      />
    </div>
  )
}

import React, { useEffect } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useAppSelector } from "../../../app/hooks"
import { selectProductModal } from "../../../features/modals/modalsSlice"
import {
  SearchFilter,
  SearchFilterOption,
} from "../../../models/base/searchFilter.interface"
import {
  selectSearchProductsDefaultFilters,
  selectSearchProductsDefaultOrders,
} from "../../../features/filters/search_result/searchFiltersSlice"
import { capitalizeFirstLetter } from "../../../utils/stringUtils"
import { skipToken } from "@reduxjs/toolkit/query"
import { useGetShopsQuery } from "../../../app/services/base/shops"
import parse from "html-react-parser"
import { AppBreadcrumb } from "../../../features/app_breadcrumb/AppBreadcrumb"
import { SearchResult } from "../../../features/search_result/SearchResult"
import { ProductModal } from "../../../features/modals/ProductModal"
import { useGetCategoryQuery } from "../../../app/services/base/categories"
import { Helmet } from "react-helmet"

export const ProductsResult: React.FC = () => {
  const { searchText, eShopDomain, categorySlug } = useParams()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const s = searchParams.get("s")
  const productModal = useAppSelector(selectProductModal)
  const { eShop } = useGetShopsQuery(
    eShopDomain ? { domain: eShopDomain } : skipToken,
    {
      selectFromResult: ({ data }) => ({
        eShop: data?.find(
          (shop) => shop.domain == eShopDomain?.replace("*", "/"),
        ),
      }),
    },
  )

  const { data: category } = useGetCategoryQuery(
    categorySlug ? categorySlug : skipToken,
  )

  const productsFilters: SearchFilter[] = useAppSelector(
    selectSearchProductsDefaultFilters,
  )

  const productsOrders: SearchFilterOption[] = useAppSelector(
    selectSearchProductsDefaultOrders,
  )

  useEffect(() => {
    if ((!searchText || searchText == null) && !eShopDomain && !categorySlug) {
      navigate("/")
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>
          {searchText
            ? `${
                searchText && capitalizeFirstLetter(searchText)
              }. Confronta le offerte e trova i migliori prodotti online.`
            : eShopDomain && !s
            ? `Tutti i prodotti di ${eShopDomain}`
            : eShopDomain && s
            ? `Ricerca ${s} tra i prodotti di ${
                eShop
                  ? eShop.eshopData.showcaseData.eshop_name
                  : eShopDomain.replace("*", "/")
              }`
            : categorySlug && category
            ? `${category.name}: i migliori prodotti da eCommerce selezionati`
            : ""}
        </title>
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview: large"
        />
        {eShopDomain && (
          <meta
            name="description"
            content={`Tutti i prodotti di ${eShopDomain.replace(
              "*",
              "/",
            )}. Leggi le recensioni e acquista sul sito dell’ecommerce in sicurezza!`}
          />
        )}
        {searchText && (
          <meta
            name="description"
            content={`Ecco le migliori offerte per ${searchText} e per tutti i prodotti correlati pubblicati dai nostri e-commerce`}
          />
        )}
        <link rel="canonical" type="" href={window.location.href} />
      </Helmet>
      <AppBreadcrumb
        routes={
          searchText
            ? [
                { name: "Home", slug: "/" },
                { name: "Ricerca Prodotti", slug: "" },
              ]
            : eShopDomain
            ? [
                { name: "Home", slug: "/" },
                { name: eShopDomain, slug: `/it/eshop/${eShopDomain}` },
                { name: "Ricerca Prodotti", slug: "" },
              ]
            : categorySlug
            ? [
                { name: "Home", slug: "/" },
                { name: "Ricerca Prodotti", slug: "" },
              ]
            : []
        }
      />
      <section id="top-page">
        <div className="container">
          <div className="row align-items-center mb-3">
            <div className="col-12 col-md-12 my-0 py-2 d-flex align-items-center justify-content-start">
              <h1 className="mb-0 dark-color font-bolder font-47 xs-font-33 fw-700">
                {searchText
                  ? `${
                      searchText && capitalizeFirstLetter(searchText)
                    }. Confronta le offerte e trova i migliori prodotti online.`
                  : eShopDomain && !s
                  ? `Tutti i prodotti di ${eShopDomain.replace("*", "/")}`
                  : eShopDomain && s
                  ? `Ricerca ${s} tra i prodotti di ${
                      eShop
                        ? eShop.eshopData.showcaseData.eshop_name
                        : eShopDomain
                    }`
                  : categorySlug && category
                  ? `${category.name}: i migliori prodotti da eCommerce selezionati`
                  : ""}
              </h1>
            </div>
          </div>
          {((eShopDomain && eShop) || (categorySlug && category)) && (
            <div className="row">
              <div className="col-md-12">
                <div
                  id="accordion"
                  className="xs-transparent-background background-white radius-75 custom-accordion mt-5"
                >
                  <div className="card no-borders mb-3 mb-md-0">
                    <div
                      className="card-header background-white no-borders radius-75 d-none"
                      id="collapse_0"
                    ></div>
                    <div
                      id="collapsebody_0"
                      className="collapse show"
                      aria-labelledby="collapse_0"
                      data-rel-button="collapse-0-trigger"
                      data-bs-parent="#accordion"
                    >
                      <div className="card-body">
                        <span className="border-top pt-3 w-100 d-block d-md-none"></span>
                        {eShopDomain &&
                          eShop &&
                          eShop.eshopData.description_prods &&
                          parse(eShop.eshopData.description_prods)}
                        {categorySlug &&
                          category &&
                          parse(
                            `Stai cercando prodotti e offerte per ${category.name.toLowerCase()}? Ti presentiamo le offerte su prodotti selezionati dai nostri eCommerce e valutati in base alle recensioni dei loro clienti.<br/><br/>Se non trovi il prodotto che cercavi, puoi anzitutto selezionare quali sono i migliori eCommerce che vendono ${category.name.toLowerCase()}`,
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>

      <SearchResult
        filtersToRender={productsFilters}
        ordersToRender={productsOrders}
        searchText={searchText}
        eShopDomain={eShopDomain?.split("*")[0]}
        eShopEsaId={eShop?.id}
        categorySlug={categorySlug}
      />
      {productModal && <ProductModal />}
    </>
  )
}

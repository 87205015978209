import React from "react"
import { BootstrapCoupon } from "./BootstrapCoupon"
import { HomeCoupon } from "../../../../models/base/homeCoupon.interface"

interface BootstrapCouponsProps {
  isActive: boolean
  coupon: HomeCoupon
  nextCoupons?: any[]
  onSelect: (selectedID: string) => void
}

export const BootstrapCoupons: React.FC<BootstrapCouponsProps> = ({
  isActive,
  coupon,
  nextCoupons,
  onSelect,
}) => {
  return (
    <>
      <BootstrapCoupon
        isActive={isActive}
        coupon={coupon}
        onSelect={onSelect}
      />
      {nextCoupons?.map((nextCoupon, index) => (
        <BootstrapCoupon
          isActive={false}
          coupon={nextCoupon}
          onSelect={onSelect}
        />
      ))}
    </>
  )
}

export const getObjectDifferences = (a: any, b: any) =>
  Object.entries(b)
    .filter(([key, val]) => {
      if (Array.isArray(val) && val.length === 0) {
        return false
      }
      return JSON.stringify(a[key]) !== JSON.stringify(val) && key in a
    })
    // .reduce((result, [key, value]) => ({ ...result, [key]: value }), {})
    .map(([key, value]: any) => ({ key, value }))

export const getArrayDifferences = (a: any[], b: any[]) =>
  a.filter((itemA) => {
    const correspondingItemB = b.find((itemB) => {
      return JSON.stringify(itemA) === JSON.stringify(itemB)
    })
    return !correspondingItemB
  })

export const tryToParse = async (json: string) => {
  let correctedString = json.trim()
  if (correctedString.startsWith("(") && correctedString.endsWith(")")) {
    correctedString = correctedString.slice(1, -1)
  }

  correctedString = correctedString.replace(/,\s*$/, "")

  try {
    return await JSON.parse(correctedString)
  } catch (error) {
    return undefined
  }
}

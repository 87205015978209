import { useAppSelector } from "../../app/hooks"
import { selectSendReviewAverageScore } from "../../pages/send_review/sendReviewSlice"
import { RatingStars } from "../rating_stars/RatingStars"

export const ShopRating: React.FC = () => {
  const averageScore = useAppSelector(selectSendReviewAverageScore)
  return (
    <div
      id="totalRating"
      className="d-flex flex-column align-items-center justify-content-center my-4"
    >
      <h4 className="d-none d-lg-block font-23 dark-color font-bolder m-0 mb-3">
        Valutazione Complessiva
      </h4>
      <RatingStars
        rating={averageScore}
        showAverageRating
        showAverageRatingLet
        size="big-xs-small"
        bigRating
        customClass="valComplessiva"
      />
    </div>
  )
}

import React, { useEffect, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import {
  couponsApi,
  useGetCouponsQuery,
  useGetCouponsUserHistoryQuery,
} from "../../app/services/base/coupons"
import {
  selectCouponModal,
  selectInfoModal,
  selectUnlockedCouponsModal,
  setInfoModal,
  setUnlockedCouponsModal,
} from "../../features/modals/modalsSlice"
import { InputGroup } from "react-bootstrap"
import {
  AsyncTypeahead,
  Hint,
  Input,
  TypeaheadMenuProps,
  TypeaheadRef,
} from "react-bootstrap-typeahead"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import codaBianca from "../../assets/images/coda-bianca.webp"
import { selectCurrentUser } from "../../features/auth/authSlice"
import { Option } from "react-bootstrap-typeahead/types/types"
import { skipToken } from "@reduxjs/toolkit/query"
import { findSubstringParts } from "../../utils/stringUtils"
import { AppPagination } from "../../features/app_pagination/AppPagination"
import { Helmet } from "react-helmet"
import { ElementsNotFound } from "../../features/elements_not_found/ElementsNotFound"
import { CouponGuideInfo } from "../../features/info_contents/CouponGuideInfo"
import { CouponRegulationInfo } from "../../features/info_contents/CouponRegulationInfo"
import { useGetSearchDomainsQuery } from "../../app/services/esaScraper/domains"
import { AppBreadcrumb } from "../../features/app_breadcrumb/AppBreadcrumb"
import { CouponMobileFilters } from "../../features/filters/coupon/CouponMobileFilters"
import { CouponTicket } from "../../features/coupon_ticket/CouponTicket"
import { CouponModal } from "../../features/modals/CouponModal"
import { InfoModal } from "../../features/modals/InfoModal"
import { UnlockedCouponsModal } from "../../features/modals/UnlockedCouponsModal"
import { forceIsOpenTo } from "../../features/search_engine/searchEngineSlice"
import parse from "html-react-parser"

export const Coupons: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const user = useAppSelector(selectCurrentUser)
  const unlockedCouponsModal = useAppSelector(selectUnlockedCouponsModal)
  const couponModal = useAppSelector(selectCouponModal)
  const infoModal = useAppSelector(selectInfoModal)
  const [searchParams, setSearchParams] = useSearchParams()
  const coupon = searchParams.get("coupon")

  const { categorySlug, eShopDomain } = useParams()
  const [page, setPage] = useState<number>(1)

  const { data: couponsWithShops } = useGetCouponsQuery(
    // SE URL == coupon
    categorySlug && categorySlug == "ultimi-coupon"
      ? { eshop_id: coupon ? Number(coupon) : undefined, page, perPage: 5 }
      : categorySlug && categorySlug != "ultimi-coupon"
      ? {
          category: categorySlug,
          page,
          perPage: 5,
        }
      : // SE URL == coupon-eshop
      eShopDomain
      ? { domain: eShopDomain, page, perPage: 5 }
      : skipToken,
  )

  const { scraperShop } = useGetSearchDomainsQuery(
    eShopDomain
      ? {
          filters: {
            eShopDomain: { name: "", label: "", value: eShopDomain },
            enrichData: { name: "", label: "", value: true },
            // size: {name:"", label:"", value:1},
          },
          searchAfter: undefined,
        }
      : skipToken,
    {
      selectFromResult: ({ data }) => ({
        scraperShop: data?.hits.hits.find(
          (shop) => shop._source.domain === eShopDomain,
        ),
      }),
    },
  )

  const { data: unlockedCoupons } = useGetCouponsUserHistoryQuery(
    user
      ? {
          userId: user.id,
        }
      : skipToken,
  )

  const typeaheadRef = useRef<TypeaheadRef>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [options, setOptions] = useState<any[]>([])

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true

  async function handleSearch(searchText: string) {
    if (searchText.length > 100) return

    setIsLoading(true)

    const { data, isError } = await dispatch(
      couponsApi.endpoints.getSuggestedCoupons.initiate(searchText),
    )

    if (isError) {
      setOptions([{ domain: "Ops! Qualcosa è andato storto." }])
    } else {
      setOptions(data!)
    }

    setIsLoading(false)
  }

  function handleChange(selected?: Option[]) {
    if (!selected) {
      var inputElement = typeaheadRef.current?.getInput()
      var inputValue = inputElement?.value
      if (inputValue && inputValue?.length > 0) {
        if (inputValue != "") {
          navigate(`/coupon-eshop/${inputValue}`)
        } else {
          navigate("/coupon/ultimi-coupon")
        }
      }
    } else if (selected.length > 0) {
      const selectedOption = selected[0] as any
      navigate(`/coupon-eshop/${encodeURIComponent(selectedOption.domain)}`)
    }
  }

  function handlePageChange(page: number) {
    setPage(page)
  }

  const stripTags = (htmlString: any) => {
    const doc = new DOMParser().parseFromString(htmlString, "text/html")
    return doc.body.textContent || ""
  }

  const categoryDescription =
    categorySlug &&
    categorySlug != "ultimi-coupon" &&
    couponsWithShops &&
    couponsWithShops.items.length > 0
      ? couponsWithShops.items[0].categories.find(
          (c) => c.properties.slug == categorySlug,
        )?.description
      : undefined

  const title = categoryDescription
    ? `Codici sconto e Coupon ${categoryDescription}`
    : `Risparmia con codici sconto e coupon ${
        eShopDomain ? "di: " + eShopDomain : ""
      }`

  const description = categoryDescription
    ? `Stai cercando offerte e codici sconto per l'acquisto di <strong>${categoryDescription}</strong>? Inizia a risparmiare grazie ai coupon offerti dai migliori negozi online`
    : `${
        eShopDomain
          ? "Tutti i codici sconto di: " + eShopDomain
          : "Centinaia di codici sconto e buoni per i tuoi acquisti online messi a disposizione dai negozi italiani che trovi"
      } su eShoppingAdvisor, pronti per essere sbloccati!`

  useEffect(() => {
    dispatch(forceIsOpenTo(false))
  }, [])

  return (
    <>
      <Helmet>
        <meta name="title" content={title} />
        <meta name="description" content={stripTags(description)} />
        <link rel="canonical" type="" href={window.location.href} />
        <title>{title}</title>
        {/*
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@graph":[
                {
                  "@type": "WebSite",
                  "@id": "https://www.eshoppingadvisor.com/#website",
                  "url": "https://www.eshoppingadvisor.com/",
                  "name": "eShoppingAdvisor",
                  "publisher":{
                    "@id":"https://www.eshoppingadvisor.com/#organization"
                  },
                  "potentialAction": {
                    "@type": "SearchAction",
                    "target": "https://www.eshoppingadvisor.com/it/search/prodotti/{search_term}",
                    "query-input": "required name=search_term"
                  },
                  "description": "",
                  "inLanguage": "it-IT"
                },
                
                {
                  "@type": "WebPage",
                  "@id": "${location.pathname}",
                  "url": "${location.pathname}",
                  "name": "",
                  "isPartOf": {
                    "@id": "https://www.eshoppingadvisor.com/#website"
                  },
                  "primaryImageOfPage": {
                    "@id": "https://www.eshoppingadvisor.com/#primaryimage"
                  },
                  "description": "",
                  "inLanguage": "it-IT"
                }
              ]
            }
            `}
        </script>
        */}
      </Helmet>
      <div className="container">
        {eShopDomain && (
          <AppBreadcrumb
            routes={
              scraperShop &&
              scraperShop._source.esa_categories &&
              scraperShop._source.esa_categories.length > 0
                ? [
                    { slug: "/", name: "Home" },
                    { slug: "/coupons/ultimi-coupon", name: "Coupon sconto" },
                    {
                      slug: scraperShop?._source.esa_categories[0].slug,
                      name: scraperShop?._source.esa_categories[0].name,
                    },
                    { slug: `/it/eshop/${eShopDomain}`, name: eShopDomain },
                    { slug: "", name: "Coupons" },
                  ]
                : [
                    { slug: "/", name: "Home" },
                    { slug: "/coupons/ultimi-coupon", name: "Coupon sconto" },
                    { slug: `/it/eshop/${eShopDomain}`, name: eShopDomain },
                    { slug: "", name: "Coupons" },
                  ]
            }
          />
        )}
        <div className="d-flex flex-column align-items-center align-items-lg-start justify-content-center mt-3">
          <div className="d-flex flex-column align-items-start justify-content-start">
            <h1 className="coupon-header-title dark-color font-bolder mt-2 mb-3">
              {title}
            </h1>
            <p className="font-16 lightgray-txt mt-3 mb-4">
              {parse(description)}
            </p>
          </div>

          {user && (
            <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center">
              <p className="points-balance-text dark-color m-0 mb-3 me-lg-4">
                Saldo punti coupons disponibili:{" "}
                <span className="points-balance-text font-bolder simple-orange-txt">
                  <span className="points-val">
                    {unlockedCoupons?.saldo_punti}
                  </span>{" "}
                  pti
                </span>
              </p>
              <div
                className="coupon-list-badge mb-3 cursor-pointer"
                onClick={() => dispatch(setUnlockedCouponsModal(true))}
              >
                Lista coupon sbloccati
              </div>
            </div>
          )}

          <div className="coupons-grid-container mt-lg-4">
            <div className="coupons-filter-container">
              <CouponMobileFilters />
            </div>
            <div className="coupons-search-container mb-3">
              <InputGroup className="search-engine-container">
                <AsyncTypeahead
                  id="searchBar"
                  promptText={"Caricamento in corso"}
                  searchText={"Caricamento in corso"}
                  filterBy={filterBy}
                  ref={typeaheadRef}
                  isLoading={isLoading}
                  options={options}
                  minLength={3}
                  delay={1000}
                  maxResults={3}
                  paginate={false}
                  onSearch={handleSearch}
                  onChange={handleChange}
                  // selectHint={(shouldSelect, event) =>
                  //   event.key === "Enter" || shouldSelect
                  // }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleChange()
                    }
                  }}
                  placeholder="Cerca coupon per eCommerce"
                  labelKey={(option: any) => option.domain}
                  emptyLabel={
                    <>
                      <div className="first-link">
                        <span className="font-14 xs-font-12 blue-color">
                          <FontAwesomeIcon
                            icon={["far", "question-circle"]}
                            className="search-icon"
                          />{" "}
                          Non trovi un ecommerce? Scrivilo correttamente. (es.{" "}
                          <b>ecommerce.it</b>)
                        </span>
                      </div>
                      <div className="first-link">
                        <span className="red-color font-14 font-bold text-center d-block mt-3">
                          <FontAwesomeIcon
                            icon={["fas", "exclamation-triangle"]}
                            className="search-icon"
                          />{" "}
                          Nessun ecommerce trovato!
                        </span>
                      </div>
                    </>
                  }
                  renderInput={({
                    inputRef,
                    referenceElementRef,
                    ...inputProps
                  }) => (
                    <Hint>
                      <Input
                        {...inputProps}
                        ref={(input) => {
                          inputRef(input)
                          referenceElementRef(input)
                        }}
                        className={`px-3 py-3 sRes search-engine search-open typeahead w-100 ${
                          isLoading ? "loading-circle sWait" : ""
                        }`}
                      />
                    </Hint>
                  )}
                  renderMenuItemChildren={(
                    option: any,
                    props: TypeaheadMenuProps,
                    index,
                  ) => (
                    <div className="first-link active">
                      <span className="eshop-search">
                        <span className="font-16 dark-color">
                          <strong>
                            {findSubstringParts(props.text, option.domain)[0]}
                          </strong>
                          {findSubstringParts(props.text, option.domain)[1]} -{" "}
                        </span>
                        <span className="font-16 font-semi-bolder me-2 orange-color">
                          {option.coupons} coupon
                        </span>
                      </span>
                    </div>
                  )}
                >
                  {(state) => (
                    <>
                      {state.text.length > 0 && (
                        <a
                          className="reset-search active"
                          onClick={state.onClear}
                        >
                          <FontAwesomeIcon icon={["fas", "times"]} />
                        </a>
                      )}
                      <button
                        className="btn btn-gradient-blue search-engine-btn float-right justify-content-center align-items-center"
                        type="button"
                        // disabled={state.text.length > 0 ? false : true}
                        style={{ zIndex: 3 }}
                        onClick={() => handleChange()}
                      >
                        {isLoading ? (
                          <img
                            className="wait-res search-engine-loader"
                            src={codaBianca}
                          />
                        ) : (
                          <FontAwesomeIcon icon={["fas", "magnifying-glass"]} />
                        )}
                      </button>
                    </>
                  )}
                </AsyncTypeahead>
              </InputGroup>
            </div>
            <div className="coupons-list-container">
              <div className="d-flex flex-column align-items-center justify-content-center">
                <ul className="list-unstyled p-0 px-4 w-100">
                  <div className="summary"></div>
                  <div className="items">
                    {couponsWithShops && couponsWithShops.items.length > 0 ? (
                      couponsWithShops?.items.map((couponWithShop) => (
                        <CouponTicket
                          key={couponWithShop.coupon.id}
                          couponWithShop={couponWithShop}
                          isUnlocked={
                            unlockedCoupons
                              ? unlockedCoupons?.purchases.some(
                                  (purchase) =>
                                    purchase.id == couponWithShop.coupon.id,
                                )
                              : false
                          }
                          removeLinkVetrina={false}
                          onlyInfo={false}
                          itemCustomClass="coupon-border-bg-white"
                        />
                      ))
                    ) : (
                      <ElementsNotFound text="Nessun coupon trovato" />
                    )}
                  </div>
                  {couponsWithShops &&
                    Number(couponsWithShops.pagination.total_count) > 5 && (
                      <AppPagination
                        currentPage={page}
                        totalPages={Number(
                          couponsWithShops?.pagination.page_count,
                        )}
                        handlePageChange={handlePageChange}
                      />
                    )}
                </ul>
              </div>
            </div>
          </div>

          <div className="d-flex flex-column align-items-center justify-content-center px-0 px-lg-5">
            <p className="dark-color font-16 text-center mb-4 px-0 px-lg-5">
              Scopri quanti punti ti servono per sbloccare i coupon sconto e
              come fare per guadagnarli,{" "}
              <span
                className="simple-orange-txt font-bolder text-decoration-underline font-16 cursor-pointer"
                onClick={() =>
                  dispatch(
                    setInfoModal({
                      title: "Guadagna coupon sconto",
                      body: <CouponGuideInfo />,
                    }),
                  )
                }
              >
                cliccando qui
              </span>
            </p>
            <p className="dark-color font-16 text-center mb-4 px-0 px-lg-5">
              Per utilizzare il coupon sconto scelto, accedi al sito
              dell'e-commerce che lo ha emesso e segui le modalità di utilizzo
              indicate cliccando su "Condizioni e modalità di utilizzo" presenti
              in basso nel singolo coupon.
            </p>
            <p
              className="dark-color font-16 text-center mt-3 px-0 px-lg-5"
              style={{ marginBottom: "60px" }}
            >
              {categoryDescription && (
                <>
                  <Link
                    to={`/it/categorie/${categorySlug}`}
                    className="simple-blue-txt font-semi-bolder text-decoration-underline font-16"
                  >
                    Scopri i migliori negozi di {categoryDescription}
                  </Link>
                  {" | "}
                </>
              )}
              Consulta il nostro{" "}
              <span
                className="simple-blue-txt font-semi-bolder text-decoration-underline font-16 cursor-pointer"
                onClick={() =>
                  dispatch(
                    setInfoModal({
                      title: "Regolamento coupon",
                      body: <CouponRegulationInfo />,
                    }),
                  )
                }
              >
                Regolamento coupon
              </span>
            </p>
          </div>
        </div>
      </div>
      {couponModal && <CouponModal />}
      {infoModal && <InfoModal />}
      {unlockedCouponsModal && <UnlockedCouponsModal />}
    </>
  )
}

import React, { useRef } from "react"
import OwlCarousel from "react-owl-carousel"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { LatestReview } from "./LatestReview"
import { useGetLatestReviewsQuery } from "../../../app/services/base/reviews"

export const LatestReviews: React.FC = () => {
  const owlCarouselRef = useRef<OwlCarousel>(null)
  const { data: latestReviews } = useGetLatestReviewsQuery()

  const handleNext = () => {
    if (owlCarouselRef.current) {
      owlCarouselRef.current.next(650)
    }
  }

  const handlePrev = () => {
    if (owlCarouselRef.current) {
      owlCarouselRef.current.prev(650)
    }
  }

  if (!latestReviews) {
    return <></>
  }

  return (
    <div className="container">
      <div className="row pt-3 align-items-center">
        <div className="col-12 col-md-8 my-0 my-md-3 py-3  d-flex align-items-center justify-content-start">
          <h3 className="bolder-dark-title xs-font-33 font-47 my-1">
            Ultime recensioni verificate
          </h3>
        </div>
        <div className="col-12 col-md-4 my-3 py-3 align-items-center justify-content-end d-none d-md-flex">
          <span
            // id="latestReviewsCert-prev"
            className="carousel-control dark-color mx-2"
            onClick={handlePrev}
          >
            {" "}
            <FontAwesomeIcon icon={["fas", "chevron-left"]} />{" "}
          </span>
          <span
            // id="latestReviewsCert-next"
            className="carousel-control dark-color mx-2"
            onClick={handleNext}
          >
            {" "}
            <FontAwesomeIcon icon={["fas", "chevron-right"]} />{" "}
          </span>
        </div>
      </div>
      <div className="row pb-3">
        <div
          //  id="latest-reviews-cert-carousel"
          className="col-12 mb-3 pb-3"
        >
          <OwlCarousel
            // id="latestReviewsCert-carousel"
            ref={owlCarouselRef}
            className="owl-theme"
            data-tm="TM_home_carousel_latestreviews_title"
            dots={false}
            loop={true}
            dotsEach={3}
            autoplay={true}
            autoplayTimeout={4500}
            autoplayHoverPause={true}
            autoplaySpeed={650}
            nav={false}
            responsive={{
              0: {
                items: 1,
              },
              576: {
                items: 1,
              },
              768: {
                items: 2,
              },
              992: {
                items: 2,
              },
              1200: {
                items: 4,
              },
              1400: { items: 5 },
            }}
          >
            {latestReviews &&
              latestReviews.items.map((lr) => (
                <LatestReview
                  key={lr.review.id}
                  review={lr.review}
                  simpleUser={lr.user}
                  shop={lr.eshop}
                />
              ))}
          </OwlCarousel>
        </div>
      </div>
    </div>
  )
}

import React from "react"
import { Shop } from "../../../models/base/shop.interface"
import { isPremiumShop } from "../../../utils/shopUtils"
import { useGetSecureRatingByDomainQuery } from "../../../app/services/base/general"

interface EShopCertificationsCardProps {
  shop: Shop
}

export const EShopCertificationsCard: React.FC<
  EShopCertificationsCardProps
> = ({ shop }) => {
  const { data: securityRating, isFetching } = useGetSecureRatingByDomainQuery(
    shop.domain,
  )
  return (
    <div className="card eshop-custom-card mb-0 mb-lg-2 me-2 me-lg-0">
      <div className="card-body d-flex flex-column px-3 py-4 p-lg-4">
        <h5 className="blue-card-title m-0 mb-3">Certificazioni</h5>
        <hr className="border border-dark m-0 mb-lg-3 d-none d-lg-block" />
        {shop.plan != "ibrido" && shop.aicel_logo && shop.aicel_logo != "" && (
          <div className="certification-item">
            <img
              className="img-fluid"
              src={shop.aicel_logo}
              width="125px"
              alt="Certificato Aicel"
              loading="lazy"
            />
          </div>
        )}

        {/* Se paganti badge id 7 altrimenti 2 */}
        {shop.eshopData.structured_data.badge_ecs &&
          securityRating &&
          securityRating.ecs_rating > 70 && (
            <div className="certification-item" style={{ height: "75px" }}>
              <iframe
                width="210"
                src={`https://ecommercesicuro.com/badge/index?esa_id=${
                  shop.id
                }&badge_id=${isPremiumShop(shop.plan) ? 7 : 2}`}
                name="imgbox_8"
                id="imgbox_8"
                style={{ border: "none", overflow: "hidden" }}
              ></iframe>
            </div>
          )}
      </div>
    </div>
  )
}

import React, { ChangeEvent, useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link, useNavigate } from "react-router-dom"
import {
  useDeletePendingUpdatePublicMutation,
  useDeletePendingProductUpdatePublicMutation,
  useUpdateReviewPublicMutation,
  useUpdateProductReviewPublicMutation,
  useDeleteReviewPublicMutation,
  useDeleteProductReviewPublicMutation,
} from "../../app/services/base/reviews"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { addToast } from "../../features/toasts/toastsSlice"
import { ReviewShopUserUnion } from "../../models/base/reviewShopUserUnion.interface"
import checkOrange from "../../assets/icons/check-orange.svg"
import infoSvg from "../../assets/icons/info.svg"
import { toStringFromDateTime } from "../../utils/stringUtils"
import { tryToParse } from "../../utils/objectsUtils"
import {
  selectUpdateReviewModal,
  setUpdateReviewModal,
} from "../../features/modals/modalsSlice"
import { UpdateReviewModal } from "../../features/modals/UpdateReviewModal"
import { Button } from "react-bootstrap"

interface ReviewsModifyUpdateProps {
  data: ReviewShopUserUnion
  code: string
}

interface ReviewsModifyFormData {
  title: string
  description: string
  scores: Record<string, number>
  file: File | undefined
}

const infoTexts: Record<string, string> = {
  "Chiarezza del sito web":
    "Il sito web visitato è chiaro e di semplice navigabilità nel suo complesso? Hai trovato facilmente ciò che cercavi?",
  "Valutazione prodotto/servizio":
    "Come valuti il prodotto o il servizio acquistato in ordine alla qualità e al prezzo?",
  "Servizio di pagamento":
    "E' stato facile effettuare il pagamento? Hai trovato il metodo di pagamento che preferisci?",
  "Consegna/Reso": "La consegna è avvenuta entro i tempi promessi?",
  "Assistenza clienti":
    "Se ne hai usufruito, gli operatori dell'assistenza clienti sono stati disponibili e cortesi? La problematica esposta è stata risolta? Se hai dovuto effettuare un reso, come è andata l'operazione in termini di semplicità e velocità?",
  "Lo consiglieresti a qualcuno?":
    "Sulla base della tua esperienza generale di acquisto consiglieresti a qualcuno questo ecommerce?",
}

const ratings = [
  { value: 1, text: "Pessimo" },
  { value: 2, text: "Scarso" },
  { value: 3, text: "Nella media" },
  { value: 4, text: "Molto buono" },
  { value: 5, text: "Eccellente" },
]

export const ReviewsModifyUpdate: React.FC<ReviewsModifyUpdateProps> = ({
  data,
  code,
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [deletePendingUpdate] = useDeletePendingUpdatePublicMutation()
  const [deletePendingProductUpdate] =
    useDeletePendingProductUpdatePublicMutation()
  const [updateReview] = useUpdateReviewPublicMutation()
  const [updateProductReview] = useUpdateProductReviewPublicMutation()
  const updateReviewModal = useAppSelector(selectUpdateReviewModal)
  const [deleteReview, { error }] = useDeleteReviewPublicMutation()
  const [deleteProductReview] = useDeleteProductReviewPublicMutation()

  let reviewReceipt: boolean | Array<any> = false
  if (data.review.receipt !== null && data.review.receipt !== "") {
    try {
      reviewReceipt = JSON.parse(data.review.receipt)
    } catch (error) {
      console.error("Error parsing receipt:", data.review.receipt)
    }
  }

  const [formData, setFormData] = useState<ReviewsModifyFormData>({
    title: data.review.title,
    description: data.review.description,
    scores: {},
    file: undefined,
  })

  const overall_rating = formData.scores.overall_rating
    ? Number(formData.scores.overall_rating)
    : parseInt(data.review.overall_rating)

  async function handleDeletePendingUpdate(reviewId: number) {
    try {
      const response =
        data.review.is_prod_review == 1
          ? await deletePendingProductUpdate({
              reviewId: reviewId,
              code: code,
            }).unwrap()
          : await deletePendingUpdate({
              reviewId: reviewId,
              code: code,
            }).unwrap()

      if (!response.success) throw new Error()

      dispatch(
        addToast({
          message: "Modifica in attesa eliminata correttamente.",
          type: "success",
        }),
      )
    } catch (err) {
      dispatch(
        addToast({
          message:
            "Ops! Impossibile annullare la richiesta di modifica al momento.",
          type: "danger",
        }),
      )
    }
  }

  function handleInputChange(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    const { name, value } = e.currentTarget
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  function updateRating(key: string, value: string) {
    setFormData({
      ...formData,
      scores: { ...formData.scores, [key]: Number(value) },
    })
  }

  function handleUpdateReviewClick() {
    dispatch(setUpdateReviewModal(true))
  }

  async function handleUpdateReview() {
    try {
      debugger
      const response =
        data.review.is_prod_review == 1
          ? await updateProductReview({
              id: data.review.id,
              title: formData.title,
              description: formData.description,
              rating: formData.scores,
              code: code,
            }).unwrap()
          : await updateReview({
              id: data.review.id,
              title: formData.title,
              description: formData.description,
              rating: formData.scores,
              code: code,
            }).unwrap()

      if (!response.success) throw new Error()

      navigate("/reviews-modify-ok")
      setTimeout(() => {
        navigate("/")
      }, 3000)
    } catch (err) {
      dispatch(
        addToast({
          message: "Ops! Impossibile modificare la recensione al momento.",
          type: "danger",
        }),
      )
    }
  }

  async function handleDelete() {
    if (confirm("Sei sicuro di voler eliminare la recensione?")) {
      try {
        const response =
          data.review.is_prod_review == 1
            ? await deleteProductReview({
                id: data.review.id,
                code: code,
              }).unwrap()
            : await deleteReview({
                id: data.review.id,
                code: code,
              }).unwrap()
        if (!response.success) throw new Error()

        navigate("/reviews-modify-ok")
        setTimeout(() => {
          navigate("/")
        }, 5000)
      } catch (err) {
        dispatch(
          addToast({
            message: "Ops! Impossibile eliminare la recensione al momento.",
            type: "danger",
          }),
        )
      }
    }
  }

  useEffect(() => {
    const parseData = async () => {
      if (data && data.review.details_rating) {
        const parsedData = await tryToParse(data.review.details_rating)
        setFormData({ ...formData, scores: parsedData })
      }
    }

    if (data) {
      parseData()
    }
  }, [data])

  return (
    <div className="w-container utente update-review white-background">
      <div className="row">
        <div className="col-md-12 mb-5 text-center blue-color border-bottom">
          <h2>Modifica recensione</h2>
        </div>
      </div>
      <form
        id="review-form"
        className="horizontal-form form form-link form-link-u"
      >
        <div className="review-ins-container review-item">
          <div className="row">
            <div
              className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              id="step2"
            >
              {data.review.pendingUpdate.updateDate && (
                <>
                  <p className="text-danger" style={{ fontSize: "14px" }}>
                    La tua modifica{" "}
                    {data.review.pendingUpdate.updateDate
                      ? `del
                    ${toStringFromDateTime(
                      data.review.pendingUpdate.updateDate,
                    )}`
                      : ""}{" "}
                    è in analisi. Potrai modificare nuovamente la recensione
                    quando la modifica verrà approvata o rifiutata
                  </p>
                  <p>
                    <a
                      onClick={() => handleDeletePendingUpdate(data.review.id)}
                      className="delete_rec_cta"
                    >
                      {" "}
                      Elimina la modifica in attesa{" "}
                    </a>
                  </p>
                </>
              )}
              <div className="form-group">
                {data.review.is_prod_review == 0 ? (
                  <input
                    rel="step4"
                    className="form-control verify"
                    disabled={true}
                    placeholder="Sintetizza in una frase la tua esperienza d’acquisto (max 50 caratteri)"
                    maxLength={50}
                    name="es"
                    id="ReviewUpdateForm_es"
                    type="text"
                    value={data.eshop.domain}
                  ></input>
                ) : (
                  <>
                    <span className="rev-prod-eshop">
                      {data.eshop.domain}
                      {data.eshop.status == 1 && <img src={checkOrange} />}
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div
              className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              id="step3"
            >
              <div className="form-group">
                {data.review.is_prod_review == 0 ? (
                  <input
                    rel="step4"
                    className="form-control verify"
                    placeholder="Sintetizza in una frase la tua esperienza d’acquisto (max 50 caratteri)"
                    maxLength={50}
                    name="title"
                    id="ReviewUpdateForm_titolo"
                    type="text"
                    value={formData.title}
                    onChange={handleInputChange}
                    disabled={
                      data.review.pendingUpdate.updateDate ? true : false
                    }
                  ></input>
                ) : (
                  <div className="rev-prod-desc clearfix">
                    {data.review.prod_review?.img_prod && (
                      <div className="rev-prod-img">
                        <img src={data.review.prod_review.img_prod} />
                      </div>
                    )}

                    <div className="rev-prod-name">
                      {data.review.prod_review?.name_prod}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div
              className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              id="step4"
            >
              <div className="form-group">
                <textarea
                  rel="step5"
                  className="form-control verify rev-desc"
                  placeholder="Sii il più dettagliato possibile al fine di fornire un contributo di qualità agli altri consumatori online. (min 50 caratteri/max 500)"
                  maxLength={500}
                  minLength={50}
                  name="description"
                  id="ReviewUpdateForm_descrizione"
                  disabled={data.review.pendingUpdate.updateDate ? true : false}
                  value={formData.description}
                  onChange={handleInputChange}
                ></textarea>
                <span className="counter">
                  caratteri rimanenti: {500 - formData.description.length}
                </span>
                {/* <?php echo $form->error($model,'descrizione');?> */}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div
                className={`container-rating-main ${
                  data.review.is_prod_review == 1 ? "rating-prod" : ""
                }`}
              >
                {data.review.is_prod_review == 0 ? (
                  <>
                    {Object.entries(formData.scores)?.map(([key, value]) => (
                      <div key={key} className="container-rating">
                        <div className="form-group">
                          <div className="box-body">
                            <div className="box-header">
                              <span
                                data-toggle="pop-over"
                                data-content={infoTexts[key]}
                                data-original-title=""
                                title=""
                              >
                                <img src={infoSvg} />
                              </span>{" "}
                              {key}
                            </div>
                            <label
                              className="sr-only required"
                              htmlFor={`ReviewUpdateForm_${key}`}
                            >
                              {key} <span className="required">*</span>
                            </label>
                            <div className="br-wrapper br-theme-bars-movie">
                              <select
                                className="form-control"
                                name={key}
                                id={`ReviewUpdateForm_${key}`}
                                style={{ display: "none" }}
                                value={
                                  formData.scores[
                                    key as keyof ReviewsModifyFormData
                                  ]
                                }
                              >
                                <option value="">Vota</option>
                                <option value="0"></option>
                                {ratings.map((r) => (
                                  <option key={r.value} value={r.value}>
                                    {r.text}
                                  </option>
                                ))}
                              </select>
                              <div className="br-widget">
                                <div className="br-current-rating br-selected-5 br-active-3 br-active-2 br-active-1 br-active-4 br-active-5">
                                  <em>Eccellente</em>
                                </div>
                                {ratings.map((r) => (
                                  <a
                                    key={r.text}
                                    title={r.text}
                                    className={`${
                                      Number(
                                        formData.scores[
                                          key as keyof ReviewsModifyFormData
                                        ],
                                      ) >= r.value
                                        ? `br-selected-${
                                            formData.scores[
                                              key as keyof ReviewsModifyFormData
                                            ]
                                          }`
                                        : ""
                                    } ${
                                      Number(
                                        formData.scores[
                                          key as keyof ReviewsModifyFormData
                                        ],
                                      ) == r.value
                                        ? "br-current"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      updateRating(key, r.value.toString())
                                    }
                                  >
                                    <FontAwesomeIcon icon={["fas", "star"]} />
                                  </a>
                                ))}
                              </div>
                            </div>
                            {/* <?php echo $form->labelEx($model,$item, ['className' => 'sr-only']); ?>
    									<?php echo $form->dropdownList($model,$item, [''=> Yii::t('review', 'vota')]+ $model->rangeList, ['className'=>'form-control']); ?> */}
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div key="overall_rating" className="container-rating">
                    <div className="form-group">
                      <div className="box-body">
                        <div className="box-header">
                          Valutazione complessiva
                        </div>
                        <label
                          className="sr-only required"
                          htmlFor={`ReviewUpdateForm_overall_rating`}
                        >
                          Valutazione complessiva{" "}
                          <span className="required">*</span>
                        </label>
                        <div className="br-wrapper br-theme-bars-movie">
                          <select
                            className="form-control"
                            name="overall_rating"
                            id={`ReviewUpdateForm_overall_rating`}
                            style={{ display: "none" }}
                            value={
                              formData.scores[
                                "overall_rating" as keyof ReviewsModifyFormData
                              ]
                            }
                          >
                            <option value="">Vota</option>
                            <option value="0"></option>
                            {ratings.map((r) => (
                              <option key={r.value} value={r.value}>
                                {r.text}
                              </option>
                            ))}
                          </select>
                          <div className="br-widget">
                            <div className="br-current-rating br-selected-5 br-active-3 br-active-2 br-active-1 br-active-4 br-active-5">
                              <em>Eccellente</em>
                            </div>
                            {ratings.map((r) => (
                              <a
                                key={r.text}
                                title={r.text}
                                className={`${
                                  overall_rating >= r.value
                                    ? `br-selected-${overall_rating}`
                                    : ""
                                } ${
                                  overall_rating == r.value ? "br-current" : ""
                                }`}
                                onClick={() =>
                                  updateRating(
                                    "overall_rating",
                                    r.value.toString(),
                                  )
                                }
                              >
                                <FontAwesomeIcon icon={["fas", "star"]} />
                              </a>
                            ))}
                          </div>
                        </div>
                        {/* <?php echo $form->labelEx($model,$item, ['className' => 'sr-only']); ?>
    									<?php echo $form->dropdownList($model,$item, [''=> Yii::t('review', 'vota')]+ $model->rangeList, ['className'=>'form-control']); ?> */}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-xs-12">
            <div className="review-actions update-rev">
              <button
                type="button"
                name="delete"
                id="btn-review-ds"
                className="btn btn-danger mx-1"
                onClick={handleDelete}
              >
                X Elimina recensione
              </button>
              <div className="float-right review-right-actions mt-3 mt-lg-0">
                <Button
                  name="update"
                  className="btn btn-gradient-orange"
                  onClick={handleUpdateReviewClick}
                  disabled={data.review.pendingUpdate.updateDate ? true : false}
                >
                  Conferma modifica
                </Button>
              </div>
              <div style={{ clear: "both" }}></div>
            </div>
          </div>
        </div>
      </form>
      {updateReviewModal && (
        <UpdateReviewModal
          callback={() => {
            handleUpdateReview()
          }}
        />
      )}
    </div>
  )
}

import React from "react"
import { RatingProgressBar } from "./RatingProgressBar"
import { useGetReviewStatsQuery } from "../../../app/services/esaScraper/reviews"
import { Shop } from "../../../models/base/shop.interface"

export interface RatingProgressBarContainerProps {
  shop: Shop
}

export const RatingProgressBarContainer: React.FC<
  RatingProgressBarContainerProps
> = ({ shop }) => {
  const { data: reviewStats } = useGetReviewStatsQuery(shop.id)

  return (
    <>
      {[5, 4, 3, 2, 1].map((number) => (
        <RatingProgressBar
          key={number}
          starNumber={number as 5 | 4 | 3 | 2 | 1}
          reviewStats={reviewStats}
        />
      ))}
    </>
  )
}

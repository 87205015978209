export const ProductPriceInfo: React.FC = () => {
  return (
    <div className="d-block font-18 xs-font-16 dark-color my-3">
      <div className="px-3">
        <p className="text-center font-16 font-semi-bold mt-4 mb-0">
          <b>Ultimo prezzo più basso: </b>
        </p>
        <p className="text-center font-16 font-semi-bold gray-color mb-3">
          I prezzi vengono aggiornati in tempo reale negli ultimi 30 giorni.
          L’aggiornamento potrebbe avvenire leggermente in ritardo.
        </p>
        <p className="text-center font-16 font-semi-bold gray-color">
          Il prezzo di riferimento è il prezzo più basso della promozione entro
          30 giorni e la promozione è un attività a tempo limitato. Al termine
          della promozione, il prezzo della promozione non sarà valido e il
          prezzo di acquisto sarà soggetto al prezzo corrente.
        </p>
      </div>
    </div>
  )
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useAppSelector } from "../../../app/hooks"
import { selectCurrentUser } from "../../../features/auth/authSlice"

interface AwardSectionProps {
  type: "Reviews" | "Views" | "World"
  awards: any[]
}

export const AwardSection: React.FC<AwardSectionProps> = ({ type, awards }) => {
  const user = useAppSelector(selectCurrentUser)
  return (
    <div
      id={
        type == "Reviews"
          ? "c_recensioni"
          : type == "Views"
          ? "c_visualizzazioni"
          : type == "World"
          ? "c_geografica"
          : ""
      }
    >
      <h2>
        {type == "Reviews"
          ? "Carriera recensioni"
          : type == "Views"
          ? "Carriera visualizzazioni"
          : type == "World"
          ? "Carriera geografica"
          : ""}
        <FontAwesomeIcon icon={["fas", "chevron-down"]} className="d-lg-none" />
      </h2>
      {type == "Reviews" && (
        <div className="container_scritte">
          <span className="rec_scritte">
            Hai scritto <strong>{user?.reviews}</strong>{" "}
            {user?.reviews == 1 ? "recensione" : "recensioni"} e ottenuto{" "}
            <strong>{user?.points}</strong>{" "}
            {user?.points == 1 ? "punto" : "punti"} carriera
          </span>
        </div>
      )}
      <div className="rewards-container">
        {awards.map((award, index) => {
          const prevAward = awards[index - 1] ? awards[index - 1] : undefined
          const nextAward = awards[index + 1] ? awards[index + 1] : undefined
          const isCurrentAward =
            !prevAward && user?.points! < award.points && type != "World"
              ? true
              : prevAward &&
                nextAward &&
                user?.points! >= prevAward.points &&
                user?.points! <= award.points &&
                user?.points! < nextAward.points
              ? true
              : !nextAward && user?.points! >= award.points
              ? true
              : type == "World" && award.level == user?.geo_carreer
              ? true
              : false
          const isCompleted =
            type != "World" && user?.points! > award.points
              ? true
              : type == "World" && award.level == user?.geo_carreer
              ? true
              : false
          const pointsDiff = parseFloat(
            (award.points - user?.points!).toFixed(2),
          )
          const width =
            user?.points! >= award.points
              ? "100%"
              : isCurrentAward
              ? `${
                  (Math.round(
                    (user?.points! * 100) /
                      (prevAward
                        ? award.points + prevAward?.points
                        : award.points),
                  ),
                  1)
                }%`
              : "0%"
          return (
            <div
              className={`r_review_item ${type == "Views" ? "vis_item" : ""} ${
                type == "World" ? "vis_item gc" : ""
              } ${!prevAward ? "first" : ""} ${!nextAward ? "last" : ""} ${
                isCurrentAward && isCompleted ? "completed" : ""
              }`}
            >
              <div className="barra"></div>
              <div className="barra-punteggio" style={{ width: width }}></div>
              {type == "World" && (
                <div className="indicazione">{award.points}</div>
              )}
              {index == 0 && (
                <div className="traguardo first">
                  {type != "World" && <span>0</span>}
                </div>
              )}
              <div
                className={`traguardo ${
                  index != 0 && !prevAward ? "first" : ""
                } ${!nextAward ? "last" : ""}`}
              >
                {type != "World" && (
                  <>
                    <span>{award.points}</span>
                    {isCurrentAward && (
                      <strong className="punteggio" style={{ left: width }}>
                        {user?.points}
                      </strong>
                    )}
                  </>
                )}
              </div>
              {/* <span className="scritto">
                Hai scritto <strong>4</strong> recensioni e ottenuto{" "}
                <strong>1.75</strong> punti
              </span>{" "} */}
              <div className="riconoscimento">
                {!isCurrentAward ? (
                  <span id="carriera-r-a">Attuale riconoscimento</span>
                ) : (
                  isCurrentAward && "Il tuo prossimo obiettivo"
                )}
              </div>
              <div>
                <div className={`img ${!isCompleted ? "completed" : ""}`}>
                  <img
                    src={
                      isCompleted
                        ? type == "Reviews"
                          ? user?.reviews_career_image
                          : type == "Views"
                          ? user?.visualization_career_image
                          : type == "World"
                          ? user?.geo_career_image
                          : award.image
                        : award.image
                    }
                  />
                </div>
                <div className="intestazione">
                  <h3 className={!isCompleted ? "completed" : ""}>
                    {award.level}
                  </h3>
                  <div className={`ottenuto ${isCompleted ? "" : "disabled"}`}>
                    {type != "World" ? (
                      <>
                        {isCompleted
                          ? `Ottenuto con ${award.points} ${
                              award.points == 1 ? "punto" : "punti"
                            }`
                          : `Ottieni con ${
                              pointsDiff == 1 ? "un altro" : "altri"
                            } ${pointsDiff} ${
                              pointsDiff == 1 ? "punto" : "punti"
                            }`}
                      </>
                    ) : (
                      <>
                        {isCompleted
                          ? `Ottenuto con ${award.label}`
                          : `Ottieni con ${award.label}`}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <div style={{ clear: "both" }}></div>
    </div>
  )
}

import React from "react"
import { Link } from "react-router-dom"

export const ReviewsValidationInfo: React.FC = () => {
  return (
    <div
      className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
      style={{ marginTop: "15px" }}
    >
      <p className="p2">
        <span className="s1" style={{ fontSize: "12pt" }}>
          Una recensione può essere validata e quindi pubblicata sulla vetrina
          dell’e-commerce recensito solo se rispetta i seguenti requisiti:
        </span>
      </p>
      <ul style={{ listStyleType: "disc" }}>
        <li className="p2">
          <span className="s1" style={{ fontSize: "12pt" }}>
            La recensione deve descrivere una vera e propria esperienza
            d’acquisto;
          </span>
        </li>
        <li className="p2">
          <span className="s1" style={{ fontSize: "12pt" }}>
            La recensione non può contenere parole offensive e diffamatorie, le
            quali potrebbero essere censurate o determinare la mancata
            pubblicazione della recensione stessa;
          </span>
        </li>
        <li className="p2">
          <span className="s1" style={{ fontSize: "12pt" }}>
            La recensione potrà essere certificata solo se la prova caricata
            attesterà l’effettivo acquisto (fattura, ricevuta, conferma
            d’ordine, ecc.)
          </span>
        </li>
      </ul>
      <p className="p2">
        <span className="s1">
          <span style={{ fontSize: "12pt" }}>
            Per ogni domanda o chiarimento è possibile inviare una e-mail
            all’indirizzo{" "}
            <Link to="mailto:info@eshoppingadvisor.com">
              <span className="s2">info@eshoppingadvisor.com</span>
            </Link>{" "}
            o contattarci via chat.
          </span>
          <span className="Apple-converted-space">&nbsp;</span>
        </span>
      </p>{" "}
    </div>
  )
}

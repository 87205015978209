import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react"
import { RootState } from "../../store"

const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_API_ESASCRAPER_BASE_URL,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const token = import.meta.env.VITE_API_ESASCRAPER_TOKEN
    // const userId = (getState() as RootState).auth.user?.id
    if (token) {
      headers.set("Authorization", `Bearer ${token}`)
    }
    // if (userId) {
    //   headers.set("UserId", userId.toString())
    // }
    return headers
  },
})

const baseQueryWithRetry = retry(baseQuery, {
  // maxRetries: 3,
  retryCondition: (_: any, __: any, { attempt }: any) => attempt.code == 500,
})

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const esaScraperApi = createApi({
  /**
   * `reducerPath` is optional and will not be required by most users.
   * This is useful if you have multiple API definitions,
   * e.g. where each has a different domain, with no interaction between endpoints.
   * Otherwise, a single API definition should be used in order to support tag invalidation,
   * among other features
   */
  reducerPath: "esaScraperApi",
  /**
   * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
   */
  baseQuery: baseQueryWithRetry,
  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  tagTypes: [
    "Domains",
    "Products",
    "ProductReviews",
    "Reviews",
    "ReviewsTags",
    "ReviewStats",
  ],
  /**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   * If you want all endpoints defined in the same file, they could be included here instead
   */
  endpoints: () => ({}),
})

// export const enhancedApi = api.enhanceEndpoints({
//   endpoints: () => ({
//     getPost: () => "test",
//   }),
// })

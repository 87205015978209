import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useGetProfileQuery } from "../../app/services/base/users"
import { tryToParse } from "../../utils/objectsUtils"
import { useGetUserReviewsQuery } from "../../app/services/base/reviews"
import { skipToken } from "@reduxjs/toolkit/query"
import { getUserAvatar } from "../../utils/userUtils"
import avatarPlaceholder from "../../assets/images/avatar_placeh.png"
import ReviewItemOld from "../../features/review_item_old/ReviewItemOld"
import reservedCss from "../reserved_area/layout/reserved.css?inline"
import { Helmet } from "react-helmet"

export const UserPublicPage: React.FC = () => {
  const navigate = useNavigate()
  const { userId, userName } = useParams()

  const { data: userProfile, isError } = useGetProfileQuery(userId)

  const { data: reviewsShops } = useGetUserReviewsQuery(
    userId
      ? { user_id: Number(userId), status: 1, page: 1, perPage: 10 }
      : skipToken,
  )

  const [structuredData, setStructuredData] = useState<any>()

  useEffect(() => {
    const parseData = async () => {
      if (userProfile && userProfile.structuredData) {
        const parsedData = await tryToParse(userProfile.structuredData)
        setStructuredData(parsedData)
      }
    }

    if (userProfile && userProfile.structuredData) {
      parseData()
    }
  }, [userProfile, userProfile?.structuredData])

  if (isError) {
    window.location.href = "/404"
  }

  if (!userProfile) {
    return <></>
  }

  return (
    <>
      <Helmet>
        <title>{`${userProfile.firstname} ha ${userProfile.reviews} recensioni su eShoppingAdvisor`}</title>
        <meta
          name="description"
          content={`Leggi tutte le recensioni di prodotti e negozi online rilasciate da ${userProfile.firstname}`}
        />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <section className="profile-page">
        <div className="container">
          <div className="w-container utente vetrina vetrina-pubblica white-background">
            <div className="top-recensori-link-container">
              <Link to="/it/classifica-recensori">
                Vai alla classifica recensori
              </Link>
            </div>
            <div className="page-user-top">
              <div className="avatar">
                <img
                  alt={userProfile.firstname}
                  src={getUserAvatar(userProfile.id, true)}
                  className="img-fluid rounded-circle h-100"
                  onError={(e) => (e.currentTarget.src = avatarPlaceholder)}
                />
                <img
                  src={userProfile.reviews_career_image}
                  className="img-fluid carriera-user"
                />
              </div>
              <div className="title">
                <h1>{`Le recensioni di ${userProfile.firstname}`}</h1>
                <h4>
                  {structuredData && structuredData.nazione
                    ? structuredData.nazione
                    : ""}
                  {structuredData &&
                  structuredData.nazione &&
                  structuredData.citta
                    ? ", "
                    : " "}
                  {structuredData && structuredData.citta
                    ? structuredData.citta
                    : ""}
                </h4>
              </div>
              <div className="details">
                <span className="details-item">
                  <span className="pipe">
                    {userProfile.rank_position &&
                    userProfile.rank_position > 0 ? (
                      <Link to={`/it/classifica-recensori?p=${userProfile.id}`}>
                        <span className="count">
                          {userProfile.rank_position}°
                        </span>{" "}
                        {`in classifica con ${userProfile.points} punti carriera`}
                      </Link>
                    ) : (
                      "Non in classifica"
                    )}
                  </span>
                </span>
                <span className="details-item">
                  <span className="pipe">
                    <span className="count">{userProfile.reviews}</span>{" "}
                    {userProfile.reviews == 1 ? "recensione" : "recensioni"}
                  </span>
                </span>
                <span className="details-item">
                  <span className="count">{userProfile.visualization}</span>{" "}
                  {userProfile.visualization == 1
                    ? "visualizzazione"
                    : "visualizzazioni"}
                </span>
              </div>
            </div>
            <div className="page-user-middle">
              {reviewsShops &&
                reviewsShops.items.map((reviewShop) => (
                  <ReviewItemOld
                    key={reviewShop.review.id}
                    review={reviewShop.review}
                    shop={reviewShop.eshop}
                    activateActions={false}
                  />
                ))}
            </div>
          </div>
        </div>
        <style>{reservedCss}</style>
      </section>
    </>
  )
}

export default UserPublicPage

import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useSearchParams } from "react-router-dom"
import { ReviewFieldCheck } from "./ReviewFieldCheck"
import { RatingStars } from "../rating_stars/RatingStars"

interface ReviewRecapItemProps {
  item: {
    logo?: string
    title?: string
    domain?: string
    rating: number
    revTitle?: string
    revDescription: string
    checkInputName: string
    anonymous_reviews: number
  }
  changeStep: (stepTo: "prev" | "next", forceStep?: number) => void
  customContainerClass?: string
}

export const ReviewRecapItem: React.FC<ReviewRecapItemProps> = ({
  item,
  changeStep,
  customContainerClass,
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const eShopUrlParam = searchParams.get("e")

  return (
    <>
      <div
        className={`card w-100 mb-3 ${
          customContainerClass ? customContainerClass : ""
        }`}
      >
        <div className="border-bottom-fade d-flex align-items-center justify-content-start p-3">
          {item.logo && (
            <div
              style={{ width: "70px", height: "70px" }}
              className="d-flex align-items-center ms-1 me-3  overflow-hidden"
            >
              <img src={item.logo} alt="" width="100%" />
            </div>
          )}
          <div className="d-flex flex-column flex-grow-1 align-items-start justify-content-center step-header gap-1 me-1">
            <h6
              className="font-16 dark-color font-bolder step-header-title m-0"
              style={{ maxWidth: "375px" }}
            >
              {item.title ? item.title : eShopUrlParam}
            </h6>
            {item.domain && (
              <p className="lightgray-txt m-0" style={{ maxWidth: "375px" }}>
                {item.domain}
              </p>
            )}
            <span className="orange-color font-semi-bolder font-14 d-block d-xl-none reviewed_recap">
              <FontAwesomeIcon icon={["fas", "check"]} className="me-1" />
              Recensito
            </span>
          </div>
          <div className="d-flex align-items-center justify-content-end step-header gap-1 me-1 reviewed_recap">
            <span className="orange-color font-semi-bolder font-14 d-none d-xl-inline-flex align-items-center me-3">
              <FontAwesomeIcon icon={["fas", "check"]} className="me-1" />
              Recensito
            </span>
            <button
              type="button"
              className="btn btn-pencil d-flex align-items-center justify-content-center p-2 white-color font-23 orange-hover min-width-40"
              onClick={() => changeStep("prev", item.domain ? 0 : 1)}
            >
              <FontAwesomeIcon icon={["fas", "pencil"]} />
            </button>
          </div>
        </div>
        <div className="d-flex flex-column align-items-start justify-content-center p-3">
          <div className="d-none d-lg-block dark-color font-bolder mb-3">
            Riepilogo
          </div>
          <div className="d-flex align-items-center justify-content-between mb-3 recap-general-rating">
            <div className="lightgray-txt font-16 font-bolder me-3">
              Valutazione Complessiva
            </div>
            <RatingStars
              rating={item.rating}
              showAverageRating
              size="normal-xs-small"
            />
          </div>
          <div className="d-flex flex-column align-items-start justify-content-center mb-3">
            <div className="lightgray-txt font-16 font-bolder mb-2 recapTitle">
              {item.revTitle ? item.revTitle : "Recensione"}
            </div>
            <p className="lightgray-txt font-14 m-0 recapDescription">
              {item.revDescription}
            </p>
          </div>
          {item.anonymous_reviews && item.anonymous_reviews == 1 ? (
            <div className="d-flex flex-column mb-4">
              <ReviewFieldCheck
                inputName={item.checkInputName}
                infoText={false}
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
}

// import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { store } from "./app/store"
import "./assets/fonts/poppins-v20-latin/poppins-v20-latin-regular.ttf"
import "./assets/fonts/poppins-v20-latin/poppins-v20-latin-500.ttf"
import "./assets/fonts/poppins-v20-latin/poppins-v20-latin-600.ttf"
import "./assets/fonts/poppins-v20-latin/poppins-v20-latin-700.ttf"
import "./assets/fonts/poppins-v20-latin/poppins-v20-latin-regular.eot"
import "./assets/fonts/poppins-v20-latin/poppins-v20-latin-500.eot"
import "./assets/fonts/poppins-v20-latin/poppins-v20-latin-600.eot"
import "./assets/fonts/poppins-v20-latin/poppins-v20-latin-700.eot"
import "./assets/fonts/poppins-v20-latin/poppins-v20-latin-regular.woff"
import "./assets/fonts/poppins-v20-latin/poppins-v20-latin-500.woff"
import "./assets/fonts/poppins-v20-latin/poppins-v20-latin-600.woff"
import "./assets/fonts/poppins-v20-latin/poppins-v20-latin-700.woff"
import "./assets/fonts/poppins-v20-latin/poppins-v20-latin-regular.woff2"
import "./assets/fonts/poppins-v20-latin/poppins-v20-latin-500.woff2"
import "./assets/fonts/poppins-v20-latin/poppins-v20-latin-600.woff2"
import "./assets/fonts/poppins-v20-latin/poppins-v20-latin-700.woff2"
import "./assets/fonts/poppins-v20-latin/poppins-v20-latin-regular.svg#Poppins"
import "./assets/fonts/poppins-v20-latin/poppins-v20-latin-500.svg#Poppins"
import "./assets/fonts/poppins-v20-latin/poppins-v20-latin-600.svg#Poppins"
import "./assets/fonts/poppins-v20-latin/poppins-v20-latin-700.svg#Poppins"
// import { library } from "@fortawesome/fontawesome-svg-core"
// import { fas } from "@fortawesome/free-solid-svg-icons"

// import { fab } from "@fortawesome/free-brands-svg-icons"
// import { far } from "@fortawesome/free-regular-svg-icons"

import { library } from "@fortawesome/fontawesome-svg-core"
import icons from "./utils/icons"
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import * as Icons from "@fortawesome/free-solid-svg-icons"
//import * as Brands from "@fortawesome/free-brands-svg-icons"

import "bootstrap/dist/css/bootstrap.min.css"
// import "bootstrap/dist/js/bootstrap.min.js"
// import "./assets/css/old/int/owl.carousel.min.css"
// import "./assets/css/old/int/color.css"
// import "./assets/css/old/int/custom.css"
// import "./assets/css/old/new_general.css"
import "./assets/css/old/general.css"
// import "./assets/css/old/int/component.css"
// import "./assets/css/old/int/settings.css"
// import "./assets/css/old/int/typo.css"
import "./index.css"
import {
  RouterProvider,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom"
import { GoogleOAuthProvider } from "@react-oauth/google"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { router } from "./Router"
import TagManager from "react-gtm-module"
import LandbotScript from "./utils/landbotScript"

async function deferRender() {
  if (process.env.NODE_ENV !== "development") {
    return
  }

  const { worker } = await import("./mocks/browser")

  return worker.start()
}

// import {
//   faQuestionCircle,
//   faThumbsUp,
//   faUser,
//   faFileLines
// } from "@fortawesome/free-regular-svg-icons"
// const farIconList = [faQuestionCircle, faThumbsUp, faUser, faFileLines]
// farIconList.forEach((icon) => library.add(icon))

// import {
//   faFacebook,
//   faFacebookF,
//   faGoogle,
//   faLinkedin,
//   faTwitter,
//   faPinterest,
//   faYoutube
// } from "@fortawesome/free-brands-svg-icons"
// const fabIconList = [
//   faFacebook,
//   faFacebookF,
//   faGoogle,
//   faLinkedin,
//   faTwitter,
//   faPinterest,
//   faYoutube,
// ]
// fabIconList.forEach((icon) => library.add(icon))

// make items unique
icons.forEach((icon) => library.add(icon))

deferRender().then(() => {
  // *** DANGER: THIS CODE IS A WORKAROUND FOR THE PROBLEM BELOW ***
  // WORKAROUND FOR PROBLEM GENERATED BY GOOGLE TRANSLATE INTEGRATION
  // https://github.com/facebook/react/issues/11538#issuecomment-417504600
  if (typeof Node === "function" && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild as any
    Node.prototype.removeChild = function (child) {
      if (child.parentNode !== this) {
        if (console) {
          console.error(
            "Cannot remove a child from a different parent",
            child,
            this,
          )
        }
        return child
      }
      return originalRemoveChild.apply(this, arguments)
    }

    const originalInsertBefore = Node.prototype.insertBefore as any
    Node.prototype.insertBefore = function (newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        if (console) {
          console.error(
            "Cannot insert before a reference node from a different parent",
            referenceNode,
            this,
          )
        }
        return newNode
      }
      return originalInsertBefore.apply(this, arguments)
    }
  }
  // ***************************************************************

  // GOOGLE TAG MANAGER
  const tagManagerArgs = {
    gtmId: "GTM-KF74PD9",
    dataLayer: {
      url_passthrough: true,
      ads_data_redaction: true,
    },
  }
  TagManager.initialize(tagManagerArgs)
  //*****

  // SENTRY
  /*
  Sentry.init({
    dsn: import.meta.env.VITE_GLICHTIP_DNS,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    tracesSampleRate: 0.1,
  })
    */
  //*****

  const appContent = (
    <Provider store={store}>
      <GoogleOAuthProvider
        clientId={import.meta.env.VITE_GOOGLE_AUTH_CLIENT_ID}
      >
        <GoogleReCaptchaProvider
          reCaptchaKey={import.meta.env.VITE_RECAPTCHA_CLIENT_KEY}
        >
          <RouterProvider router={router(store)} />
          <LandbotScript />
        </GoogleReCaptchaProvider>
      </GoogleOAuthProvider>
    </Provider>
  )

  const rootElement = document.getElementById("root")
  const preRenderPlaceholder = document.getElementById("pre-render-placeholder")
  if (preRenderPlaceholder) {
    // ReactDOM.hydrateRoot(rootElement, appContent)
    // ! FOR the moment do nothing
  } else {
    ReactDOM.createRoot(rootElement!).render(appContent)
  }
})

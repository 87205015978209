import { BaseLoader } from "./base.loader"
import { LoaderFunction, LoaderFunctionArgs } from "react-router-dom"
import { couponsApi } from "../services/base/coupons"
import { Coupon } from "../../models/base/coupon.interface"
import { skipToken } from "@reduxjs/toolkit/query"

export class CouponsLoader extends BaseLoader {
  listLoader: LoaderFunction = async ({
    params,
    request,
  }: LoaderFunctionArgs) => {
    const couponsWithShops = (await this._loader(
      couponsApi.endpoints.getCoupons,
      request,
      params.categorySlug
        ? {
            category: params.categorySlug,
            page: 1,
            perPage: 5,
          }
        : params.eShopDomain
        ? { domain: params.eShopDomain, page: 1, perPage: 5 }
        : skipToken,
    )) as Coupon[]

    return { couponsWithShops }
  }
}

import React from "react"
import { useGoogleLogin } from "@react-oauth/google"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { setUser } from "./authSlice"
import { addToast } from "../toasts/toastsSlice"
import { useAppSelector } from "../../app/hooks"
import {
  selectLoginModal,
  selectRegistrationModal,
  selectUserCheckModal,
  setLoginModal,
  setRegistrationModal,
} from "../modals/modalsSlice"
import {
  useCreateUserMutation,
  useLoginMutation,
} from "../../app/services/base/users"

interface GoogleAuthProps {
  type: "Login" | "Signup"
  showName?: boolean
}

export const GoogleAuth: React.FC<GoogleAuthProps> = ({
  type,
  showName = false,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const loginModal = useAppSelector(selectLoginModal)
  const registrationModal = useAppSelector(selectRegistrationModal)
  const userCheckModal = useAppSelector(selectUserCheckModal)
  const location = useLocation()
  const from = location.state?.from?.pathname || "/"

  const [login, { error }] = useLoginMutation()
  const [createUser] = useCreateUserMutation()

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (response) => {
      fetch(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${response.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${response.access_token}`,
            Accept: "application/json",
          },
        },
      )
        .then((res) => {
          if (!res.ok) {
            throw new Error(
              `Google API request failed with status: ${res.status}`,
            )
          }
          return res.json()
        })
        .then(async (data) => {
          if (data && data.id && data.email) {
            // if (type == "Signup") {
            // const response = await createUser({
            //   email: data.email,
            //   user_id: data.id,
            //   type: "google",
            //   name: data.given_name,
            //   surname: data.family_name,
            //   image_url: data.picture,
            // }).unwrap()
            // if (!response.success) throw new Error()
            // navigate("/registrazione/ok")
            // }
            // // else if (type == "Login") {
            const response = await login({
              email: data.email,
              type: "google",
              user_id: data.id,
              name: data.given_name ? data.given_name : undefined,
              surname: data.family_name ? data.family_name : null,
              image_url: data.picture ? data.picture : null,
            }).unwrap()
            dispatch(setUser(response))
            if (!registrationModal && !loginModal && !userCheckModal) {
              navigate(from, { replace: true })
            } else {
              dispatch(setLoginModal(undefined))
              dispatch(setRegistrationModal(undefined))
            }
            // }
          } else {
            throw new Error("Invalid data received from Google API")
          }
        })
        .catch((err) => {
          dispatch(
            addToast({
              message:
                err.data && err.data.message
                  ? err.data.message
                  : "Ops! Impossibile accedere con Google.",
              type: "danger",
            }),
          )
        })
    },
    onError: (error) => {
      dispatch(
        addToast({
          message: "Ops! Impossibile accedere con Google.",
          type: "danger",
        }),
      )
    },
  })

  return (
    <button
      onClick={() => handleGoogleLogin()}
      className="btn btn-outline-google d-flex align-items-center justify-content-center w-100 p-3 mb-3 mb-lg-0 me-1 font-light"
      style={{ height: "50px" }}
      type="button"
    >
      <FontAwesomeIcon icon={["fab", "google"]} className="font-24" />
      {showName && <span className="ms-2">Google</span>}
    </button>
  )
}

import React, { useState, ChangeEvent } from "react"
import Form from "react-bootstrap/Form"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ProductReviewListItem from "./ProductReviewListItem"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { selectCurrentUser } from "../../../features/auth/authSlice"
import { skipToken } from "@reduxjs/toolkit/query"
import { useGetUserProductReviewsQuery } from "../../../app/services/base/reviews"
import {
  selectInfoModal,
  setInfoModal,
} from "../../../features/modals/modalsSlice"
import { AppPaginationOld } from "../../../features/app_pagination/AppPaginationOld"
import { Link } from "react-router-dom"
import { CouponRegulationInfo } from "../../../features/info_contents/CouponRegulationInfo"
import { ReviewsValidationInfo } from "../../../features/info_contents/ReviewsValidationInfo"
import { InfoModal } from "../../../features/modals/InfoModal"

interface FormProps {
  dateFrom?: string
  dateTo?: string
  shopId?: string
  reviewTitle?: string
}

export const Reviews: React.FC = () => {
  const user = useAppSelector(selectCurrentUser)
  const dispatch = useAppDispatch()
  const infoModal = useAppSelector(selectInfoModal)

  const [page, setPage] = useState<number>(1)

  const [formSubmitted, setFormSubmitted] = useState<FormProps>({
    dateFrom: undefined,
    dateTo: undefined,
    shopId: undefined,
    reviewTitle: undefined,
  })

  const { data: reviewsShops } = useGetUserProductReviewsQuery(
    user
      ? {
          user_id: user.id,
          title: formSubmitted.reviewTitle,
          domain: formSubmitted.shopId,
          since: formSubmitted.dateFrom,
          until: formSubmitted.dateTo,
          page,
          perPage: 10,
          status: [0, 1, 2, 3],
        }
      : skipToken,
  )

  const [formData, setFormData] = useState<FormProps>({
    dateFrom: undefined,
    dateTo: undefined,
    shopId: undefined,
    reviewTitle: undefined,
  })

  function handlePageChange(page: number) {
    setPage(page)
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }))
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    formData.dateFrom = formData.dateFrom != "" ? formData.dateFrom : undefined
    formData.dateTo = formData.dateTo != "" ? formData.dateTo : undefined
    formData.shopId = formData.shopId != "" ? formData.shopId : undefined
    formData.shopId = formData.shopId != "" ? formData.shopId : undefined
    formData.reviewTitle =
      formData.reviewTitle != "" ? formData.reviewTitle : undefined
    setFormSubmitted(formData)
  }

  if (!reviewsShops) {
    return <></>
  }

  return (
    <>
      <div className="w-container utente reviews-list white-background">
        <h2>Le tue recensioni prodotto</h2>
        <span className="coupon_info">
          <Link
            to="#"
            onClick={() =>
              dispatch(
                setInfoModal({
                  title: "Regolamento coupon",
                  body: <CouponRegulationInfo />,
                }),
              )
            }
          >
            Guadagna coupon sconto
          </Link>{" "}
          scrivendo le tue recensioni e scopri i{" "}
          <Link
            to="#"
            onClick={() =>
              dispatch(
                setInfoModal({
                  title: "Requisiti validazione recensioni",
                  body: <ReviewsValidationInfo />,
                }),
              )
            }
          >
            requisiti di validazione
          </Link>
        </span>
        <div className="list-filter">
          <Form className="form-registrazione myform" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <div className="form-group">
                  <div className="input-group">
                    <label className="input-group-text"> Dal </label>
                    <Form.Control
                      name="dateFrom"
                      value={formData.dateFrom}
                      className="form-control date-picker"
                      placeholder="Dal"
                      type="date"
                      autoComplete="off"
                      id="EshopReviewModel_dal"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <div className="form-group">
                  <div className="input-group">
                    <label className="input-group-text"> Al </label>
                    <Form.Control
                      name="dateTo"
                      value={formData.dateTo}
                      className="form-control date-picker"
                      placeholder="Al"
                      type="date"
                      autoComplete="off"
                      id="EshopReviewModel_al"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div className="form-group">
                  <Form.Control
                    name="shopId"
                    value={formData.shopId}
                    className="form-control"
                    placeholder="Eshop"
                    id="EshopReviewModel_shop_id"
                    type="text"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div className="form-group">
                  <Form.Control
                    name="reviewTitle"
                    value={formData.reviewTitle}
                    className="form-control"
                    placeholder="Titolo recensione"
                    id="EshopReviewModel_titolo"
                    type="text"
                    maxLength={50}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="form-group">
                  <button
                    className="btn btn-default btn-filter col-xs-12"
                    type="submit"
                  >
                    <FontAwesomeIcon
                      icon={["fas", "magnifying-glass"]}
                      className="white-color"
                    />{" "}
                    Filtra
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </div>
        <div className="n_rev">
          <span>
            Recensioni scritte:{" "}
            <strong>
              {reviewsShops ? reviewsShops.pagination.total_count : 0}
            </strong>
          </span>
        </div>
        <div className="reviews-table">
          <div className="table-responsive" id="eshopList">
            {reviewsShops ? (
              <>
                <div className="summary" />
                <table className="table table-condensed table-reviews">
                  <thead>
                    <tr>
                      <th>Data</th>
                      <th>Eshop</th>
                      <th>Titolo recensione</th>
                      <th />
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {reviewsShops.items.map((reviewShop) => (
                      <ProductReviewListItem
                        key={reviewShop.review.id}
                        review={reviewShop.review}
                        shop={reviewShop.eshop}
                      />
                    ))}
                  </tbody>
                </table>
                <AppPaginationOld
                  currentPage={page}
                  totalPages={Number(reviewsShops.pagination.page_count)}
                  handlePageChange={handlePageChange}
                />
              </>
            ) : (
              <span className="empty">
                <div className="wellx">Nessuna recensione presente..</div>
              </span>
            )}
          </div>
        </div>
      </div>
      {infoModal && <InfoModal />}
    </>
  )
}

export default Reviews

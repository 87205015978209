import React from "react"
import OwlCarousel from "react-owl-carousel"
import { Article } from "./Article"
import { Link } from "react-router-dom"
import { useGetArticlesQuery } from "../../app/services/base/articles"

interface ArticlesProps {
  customTitle?: string
}

export const Articles: React.FC<ArticlesProps> = ({ customTitle = "" }) => {
  const { data: articles } = useGetArticlesQuery()
  const title =
    customTitle !== ""
      ? customTitle
      : "Guide e Consigli per il tuo Shopping Online"

  if (!articles) {
    return <></>
  }

  return (
    <div className="container my-5">
      <div className="row align-items-end">
        <div className="col-12">
          <h2>
            <Link
              to="https://eshoppingadvisor.com/blog/category/guide/"
              className="bolder-dark-title text-decoration-none font-33"
              target="_blank"
              title={title}
            >
              {title}
            </Link>
          </h2>
        </div>
        <OwlCarousel
          // id="BlogNews-carousel"
          className="owl-theme row"
          dots={false}
          loop={true}
          dotsEach={3}
          autoplay={true}
          autoplayTimeout={4500}
          autoplayHoverPause={true}
          autoplaySpeed={650}
          nav={false}
          responsive={{
            0: {
              items: 1,
            },
            576: {
              items: 1,
            },
            768: {
              items: 2,
            },
            992: {
              items: 2,
            },
            1200: {
              items: 3,
            },
          }}
        >
          {articles.map((article) => (
            <Article key={article.title} article={article} />
          ))}
        </OwlCarousel>
      </div>
    </div>
  )
}

import React, { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"

import {
  SearchFilter,
  SearchFilterOption,
} from "../../../models/base/searchFilter.interface"
import { useAppSelector } from "../../../app/hooks"
import {
  selectSearchShopsDefaultFilters,
  selectSearchDefaultOrders,
} from "../../../features/filters/search_result/searchFiltersSlice"
import { AppBreadcrumb } from "../../../features/app_breadcrumb/AppBreadcrumb"
import { ShopsCarouselSection } from "../../../features/shops_carousel/ShopsCarouselSection"
import { SearchResult } from "../../../features/search_result/SearchResult"
import { Articles } from "../../../features/articles/Articles"
import { Helmet } from "react-helmet"

export const ShopsResult: React.FC = () => {
  const { searchText } = useParams()
  const formattedSearchText = searchText?.replace(/\+/g, " ")
  const navigate = useNavigate()

  const shopsFilters: SearchFilter[] = useAppSelector(
    selectSearchShopsDefaultFilters,
  )

  const shopsOrders: SearchFilterOption[] = useAppSelector(
    selectSearchDefaultOrders,
  )

  useEffect(() => {
    if (!searchText || searchText == null) {
      navigate("/")
    }
  }, [])
  return (
    <>
      <Helmet>
        <title> Ricerca i migliori eCommerce per “{formattedSearchText}”</title>
        <meta
          name="description"
          content={`Ricerca i migliori negozi online per “${formattedSearchText}”`}
        />
        <meta name="robots" content="noindex,nofollow" />
        <link rel="canonical" type="" href={window.location.href} />
      </Helmet>
      <AppBreadcrumb
        routes={[
          { name: "Home", slug: "/" },
          { name: "Ricerca eCommerce", slug: "" },
        ]}
      />
      <section id="top-page">
        <div className="container">
          <div className="row align-items-center mb-3">
            <div className="col-12 col-md-12 my-0 py-2 d-flex align-items-center justify-content-start">
              <h1 className="mb-0 dark-color font-bolder font-47 xs-font-33 fw-700">
                Ricerca i migliori eCommerce per “{formattedSearchText}”
              </h1>
            </div>
          </div>
        </div>
      </section>
      {/* TODO - Implement with a custom query 
        <ShopsCarouselSection
        title={`Consigliati dagli utenti per "${formattedSearchText}"`}
        searchText={searchText}
      /> */}
      <SearchResult
        filtersToRender={shopsFilters}
        ordersToRender={shopsOrders}
        searchText={searchText}
      />
      <Articles />
    </>
  )
}

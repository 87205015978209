import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { SearchFilterOption } from "../../../models/base/searchFilter.interface"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { selectActiveFilters, setIsSidebarOpen } from "./searchFiltersSlice"

export const FiltersButton: React.FC = () => {
  const dispatch = useAppDispatch()
  const activeFilters = useAppSelector(selectActiveFilters) as Record<
    string,
    SearchFilterOption
  >[]

  return (
    <div className="filtro">
      <button
        className={`btn btn-white btn-60 font-23 font-bolder text-start
        ${activeFilters.length > 0 ? "orange-border" : ""}
        `}
        type="button"
        onClick={() => dispatch(setIsSidebarOpen(true))}
      >
        Filtri
        {activeFilters.length > 0 && (
          <span className="orange-border bordered orange-color font-16 full-rounded filter-counter font-lighter ms-1">
            {activeFilters.length}
          </span>
        )}
        <FontAwesomeIcon
          icon={["fas", "chevron-right"]}
          className="float-end"
        />
      </button>
    </div>
  )
}

import React from "react"

export const ReviewsModifyOk: React.FC = () => {
  return (
    <div className="w-container utente white-background">
      <div className="update-review mod-ok">
        <div className="row">
          <div className="col-md-12 text-center">
            <h1 className="blue-title">
              Grazie per aver condiviso la tua esperienza!
            </h1>
            <h2 className="userAreaSubtitle">
              La tua modifica di recensione è stata presa in carico dal sistema.
            </h2>
          </div>
        </div>
      </div>
    </div>
  )
}

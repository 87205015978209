import React from "react"
import { Category } from "../../models/base/category.interface"
import { Helmet } from "react-helmet"
import { removeHtmlTags } from "../../utils/stringUtils"

interface CategorySchemaProps {
  category: Category
}

export const CategorySchema: React.FC<CategorySchemaProps> = ({ category }) => {
  return (
    <Helmet>
      <script type="application/ld+json">
        {`{
              "@context": "http://schema.org",
              "@type": "CollectionPage",
              "name": "${category.name}",
              ${
                category.details.subtitle &&
                `"description": "${removeHtmlTags(
                  category.details.subtitle.replace('"', "'"),
                )}",` + `"image": "${category.imageUrl}",`
              }
              "url": "${
                import.meta.env.VITE_B2C_BASE_URL +
                "/it/categorie/" +
                category.slug
              }"
            }`}
      </script>
    </Helmet>
  )
}

import React, { ChangeEvent, useEffect, useState } from "react"
import dnd from "../../../assets/icons/d_n_d.png"
import dndi from "../../../assets/icons/d_n_d_i.png"
import { Link, useParams, useNavigate } from "react-router-dom"
import { Button, Collapse } from "react-bootstrap"
import mediaReviews from "../../../assets/css/old/media-reviews.css?inline"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useAppDispatch } from "../../../app/hooks"
import { addToast } from "../../../features/toasts/toastsSlice"
import {
  useGetReviewForAuthUserQuery,
  useGetProductReviewForAuthUserQuery,
  useUploadImagesMutation,
  useUploadVideoMutation,
} from "../../../app/services/base/reviews"
import { skipToken } from "@reduxjs/toolkit/query"
import { tryToParse } from "../../../utils/objectsUtils"
import { getReviewImageUrl } from "../../../utils/reviewUtils"
import { ReviewImage, ReviewVideo } from "../../../models/base/review.interface"
import { getVideoThumbnail, parseReviewVideo } from "../../../utils/videoUtils"
import { uploadVimeoVideo } from "../../../utils/videoUtils"

export const UpdateMedia: React.FC<{ isProduct: boolean }> = ({
  isProduct = false,
}) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { reviewId } = useParams()
  const [open, setOpen] = useState<boolean>(true)
  const { data: review } = isProduct
    ? useGetProductReviewForAuthUserQuery(reviewId ? reviewId : skipToken)
    : useGetReviewForAuthUserQuery(reviewId ? reviewId : skipToken)
  const [reviewImages, setReviewImages] = useState<ReviewImage>()
  const [reviewVideo, setReviewVideo] = useState<ReviewVideo>()
  const [maxUploadImages, setMaxUploadImages] = useState<number>(5)
  const [formData, setFormData] = useState<{
    images: File[]
    video: File | null
    urlVideo?: string
  }>({
    images: [],
    video: null,
    urlVideo: "",
  })
  const [uploadImages] = useUploadImagesMutation()
  const [uploadVideo] = useUploadVideoMutation()

  function handleFilesChange(e: ChangeEvent<HTMLInputElement>) {
    const { name, files } = e.currentTarget
    if (files && files.length > 0) {
      if (name == "images") {
        const validImages = Array.from(files)
          .slice(0, maxUploadImages)
          .filter((file) => file.type.startsWith("image/"))
        setFormData((prevFormData) => ({
          ...prevFormData,
          images: [...prevFormData.images, ...validImages].slice(
            0,
            maxUploadImages,
          ),
        }))
      } else if (name == "video") {
        const validVideo = files[0].type.startsWith("video/")

        if (validVideo) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            video: files[0],
          }))
        }
      }
    }
  }

  function handleTextChange(e: ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.currentTarget
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  function removeImage(imageToRemove: File) {
    const updatedImages = formData.images.filter(
      (image) => image !== imageToRemove,
    )
    setFormData((prevFormData) => ({
      ...prevFormData,
      images: updatedImages,
    }))
  }

  function removeVideo() {
    setFormData((prevFormData) => ({
      ...prevFormData,
      video: null,
    }))
  }

  async function updateReviewMedia() {
    try {
      formData.images.forEach(async (image: any) => {
        const formData = new FormData()
        formData.append("review_id", reviewId ? reviewId.toString() : "")
        formData.append("is_product", isProduct ? "true" : "false")
        formData.append("images", image, image.name)
        const response = await uploadImages(formData).unwrap()
      })

      let selectedVideo = formData.video
      if (selectedVideo) {
        let domainName = review?.eshop?.domain

        let vimeoTitle = review?.user
          ? `${review?.user?.name} recensisce ${domainName}`
          : `Recensione su ${domainName}`

        const videoUrl = await uploadVimeoVideo(selectedVideo, vimeoTitle)
        if (videoUrl) {
          const formData = new FormData()
          formData.append("review_id", reviewId ? reviewId.toString() : "")
          formData.append("is_product", isProduct ? "true" : "false")
          formData.append("video_url", videoUrl)
          const videoResponse = await uploadVideo(formData)
        }
      }
      navigate("/areariservata/reviews-modify-ok")
    } catch (err) {
      dispatch(
        addToast({
          message:
            "Ops! Impossibile modificare i media della recensione al momento.",
          type: "danger",
        }),
      )
    }
  }

  useEffect(() => {
    const parseData = async () => {
      if (review && review.review.images) {
        const parsedData = await tryToParse(review.review.images)
        setReviewImages(parsedData)
        setMaxUploadImages(5 - Object.keys(parsedData).length)
      }

      if (review && review.review.video_url) {
        const parsedData = await parseReviewVideo(review.review.video_url)
        setReviewVideo(parsedData)
      }
    }

    if (review) {
      parseData()
    }
  }, [review])

  return (
    <section className="media-review-page internal-media-update">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="media-insert-container">
              <div className="row mb-4">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <h1 className="review-media-title">
                    Fotografa e filma i prodotti che hai acquistato
                  </h1>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div className="review-media-section">
                    <h4>
                      Aggiungi foto dei prodotti alla tua recensione (opzionale)
                    </h4>
                    <div className="upload-photo-area">
                      {reviewImages &&
                        review &&
                        Object.entries(reviewImages).map(([key, value]) => (
                          <div className="img-prev-container">
                            <img
                              className="img-fluid"
                              src={getReviewImageUrl(
                                value.name,
                                review.review.id,
                                isProduct,
                              )}
                            />
                          </div>
                        ))}
                      {formData.images?.map((image) => (
                        <div id="file_0" className="img-prev-container">
                          <img
                            className="img-fluid"
                            src={URL.createObjectURL(image)}
                          />
                          <a
                            onClick={() => removeImage(image)}
                            className="remove-img"
                            rel="file_0"
                          >
                            <FontAwesomeIcon icon={["fas", "times"]} />
                          </a>
                        </div>
                      ))}
                      {formData.images.length < maxUploadImages && (
                        <label htmlFor="browseI">
                          <div id="img_zone">
                            <img src={dndi} />
                            Aggiungi foto prodotti
                          </div>
                        </label>
                      )}
                    </div>
                    <div className="img-specs">
                      <ul>
                        <input
                          id="browseI"
                          type="file"
                          style={{ display: "none" }}
                          multiple={true}
                          max={maxUploadImages}
                          name="images"
                          accept=".jpg,.png,.jpeg"
                          onChange={handleFilesChange}
                        />
                        <li>* E' possibile caricare fino a 5 immagini</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {!reviewVideo && (
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="review-media-section review-media-video">
                      <h4
                        className="collapse-video-section"
                        onClick={() => setOpen(!open)}
                        aria-controls="collapseVideo"
                        aria-expanded={open}
                      >
                        Aggiungi un video alla recensione (opzionale)
                      </h4>

                      <Collapse in={open}>
                        <div id="collapseVideo">
                          <div id="progress-container" className="progress">
                            {/* <div
                  id="progress"
                  className="progress-bar progress-bar-info progress-bar-striped active"
                  role="progressbar"
                  aria-valuenow="46"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style="width: 0%"
                ></div> */}
                            <span className="progress-perc">0%</span>
                          </div>
                          <div id="results"></div>
                          <div className="upload-video-area">
                            <label htmlFor="browse">
                              <div id="drop_zone">
                                <img src={dnd} />
                                Trascina qui il tuo file video oppure clicca per
                                caricarlo
                              </div>
                            </label>
                            <div className="review-video-details">
                              <div className="video-specs">
                                <ul>
                                  <li>
                                    *Il video può durare al massimo 1:30 minuti
                                  </li>
                                  <li>
                                    *Il video non può pesare più di 250 MB
                                  </li>
                                </ul>
                              </div>
                              <label className="btn btn-block btn-orange">
                                Sfoglia…{" "}
                                <input
                                  id="browse"
                                  type="file"
                                  style={{ display: "none" }}
                                  name="video"
                                  accept=".mp4,.mov,.wmv,.avi,.flv"
                                  onChange={handleFilesChange}
                                />
                              </label>
                            </div>
                            <p className="videos-op">oppure</p>
                            <div className="video-url-input">
                              <input
                                type="text"
                                id="video-url"
                                name="urlVideo"
                                className="form-control"
                                placeholder="Carica video da URL (Youtube o Vimeo)"
                                value={formData.urlVideo}
                                onChange={handleTextChange}
                              />
                            </div>
                          </div>
                          <div className="video-preview">
                            {formData.video && (
                              <>
                                <div className="preview-player-container">
                                  <video className="player-preview" controls>
                                    <source
                                      src={URL.createObjectURL(formData.video)}
                                    />
                                  </video>
                                </div>
                                <div className="preview-desc">
                                  <span className="video-name">
                                    {formData.video.name}
                                  </span>
                                  <br />
                                  <span className="file-size">
                                    {(
                                      formData.video.size /
                                      (1024 * 1024)
                                    ).toFixed(2)}{" "}
                                    MB
                                  </span>
                                  <a
                                    className="remove-video"
                                    onClick={removeVideo}
                                  >
                                    <FontAwesomeIcon icon={["fas", "times"]} />{" "}
                                    Rimuovi
                                  </a>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="review-media-f-actions">
                    <div className="main-actions">
                      <Link
                        to={`/it/areariservata/reviews-modify/${reviewId}?ac=view`}
                        className="skip-media-reviews"
                      >
                        Torna indietro
                      </Link>
                      <Button
                        className={`submit-media final-btn-orange ${
                          !(
                            formData.images.length ||
                            formData.video ||
                            formData.urlVideo
                          )
                            ? "hide-btn"
                            : ""
                        }`}
                        onClick={() => {
                          if (
                            !(
                              formData.images.length ||
                              formData.video ||
                              formData.urlVideo
                            )
                          ) {
                            return
                          }
                          updateReviewMedia()
                        }}
                      >
                        Carica foto/video
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>{mediaReviews}</style>
    </section>
  )
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { SearchFilterOption } from "../../../models/base/searchFilter.interface"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import {
  removeFilter,
  selectActiveFilters,
  selectOrderBy,
} from "./searchFiltersSlice"

export const ActiveSearchFilters: React.FC = () => {
  const dispatch = useAppDispatch()
  const activeFilters = useAppSelector(selectActiveFilters) as Record<
    string,
    SearchFilterOption
  >[]

  const orderBy = useAppSelector(selectOrderBy)

  const renderActiveFilters =
    orderBy.value != ""
      ? [...activeFilters, { key: "orderBy", value: orderBy }]
      : activeFilters

  return (
    <div className="overflow-row-mobile hide-scrollbar my-3">
      {renderActiveFilters.map(({ key, value }) => (
        <span
          key={value.label}
          className="btn btn-secondary-orange custom-rounded me-3 my-1 d-inline-block"
        >
          <span className="me-2" onClick={() => dispatch(removeFilter(value))}>
            <FontAwesomeIcon icon={["fas", "times"]} />
          </span>
          {value.label}
        </span>
      ))}
    </div>
  )
}

import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { Link } from "react-router-dom"

interface ReservedMenuItemProps {
  iconName: IconProp
  link: string
  title: string
}

export const ReservedMenuItem: React.FC<ReservedMenuItemProps> = (props) => {
  const { iconName, link, title } = props
  const [isHover, setIsHover] = useState<boolean>(false)
  return (
    <li
      // className={isHover ? "active" : ""}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <FontAwesomeIcon
        icon={iconName}
        className={isHover ? "orange-color" : ""}
      />
      <Link
        to={link}
        className={
          isHover ? "orange-color text-decoration-none" : "text-decoration-none"
        }
      >
        {" "}
        {title}{" "}
      </Link>
    </li>
  )
}

export default ReservedMenuItem
